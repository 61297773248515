const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const twoDigits = date => (date < 10 ? `0${date}` : date);

export const dateToString = date => {
  if (isNaN(Date.parse(date))) return '';

  var month = MONTHS[date.getMonth()];
  return `${twoDigits(date.getDate())}-${month}-${date.getFullYear()}`;
};
