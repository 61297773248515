export function checkHandlePermissions(permissionsArray, handleId, permissions = ['transform', 'debug', 'validate']) {
  if (!Array.isArray(permissionsArray)) {
    throw new Error('Permissions array is not an array');
  }

  if (!handleId) {
    throw new Error('handleId is not defined');
  }

  if (permissionsArray.length === 0) {
    return false;
  }

  const permissionsToCheck = permissions.map(name => `qsf-k8s/${handleId}:${name}`);

  // Function to match individual parts of the permission
  function matchPart(permissionPart, requiredPart) {
    return permissionPart === '*' || permissionPart === requiredPart;
  }

  // Function to match overall permissions
  function matchPermission(permission, requiredPermission) {
    const [permissionNamespace, permissionRest] = permission.split('/');
    const [requiredNamespace, requiredRest] = requiredPermission.split('/');

    // Both must begin with "qsf-k8s" for example
    if (permissionNamespace !== requiredNamespace) {
      return false;
    }

    const [permissionId, permissionAction] = permissionRest.split(':');
    const [requiredId, requiredAction] = requiredRest.split(':');

    // Check if each part (handle ID and action) matches or has a wildcard '*'
    return matchPart(permissionId, requiredId) && matchPart(permissionAction, requiredAction);
  }

  const hasPermission = permissionsArray.some(permission => {
    return permissionsToCheck.some(permissionToCheck => {
      // Check for a match between the permission from the array and the required permission
      return matchPermission(permission, permissionToCheck);
    });
  });

  return hasPermission;
}
