import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@appkit4/react-components/switch';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  > :not(:first-child) {
    margin-left: 10px;
  }
`;

const SwitchField = ({ field, onChange, value }) => {
  const { id } = field;
  const { t } = useTranslation();
  const [checked, setChecked] = useState(!!value);

  useEffect(() => {
    setChecked(!!value);
  }, [value]);

  const localOnChange = (id, newValue) => {
    setChecked(newValue);
    onChange(id, newValue);
  };

  return (
    <Wrapper>
      <div>{t('form.field.no')}</div>
      <Switch large={false} checked={checked} onChange={value => localOnChange(id, value)} />
      <div>{t('form.field.yes')}</div>
    </Wrapper>
  );
};

SwitchField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
};

export default SwitchField;
