import { io } from 'socket.io-client';
import { REACT_APP_QSF_SERVER_PORT } from '../config';

const port = process.env.REACT_APP_QSF_SERVER_PORT ? `:${REACT_APP_QSF_SERVER_PORT}` : undefined;

export const socket = io(port, {
  transportOptions: {
    polling: {
      extraHeaders: () => {
        return {
          Authorization: `Bearer ${JSON.stringify(localStorage.getItem('ROCP_token'))}`
        };
      }
    }
  }
});
