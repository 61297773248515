import React from 'react';
import { useTranslation } from 'react-i18next';
import { AnimatedWrapper } from '../../styles/LoadingElements';

const LoadingIndicator = () => {
  const { t } = useTranslation();

  return (
    <AnimatedWrapper>
      <div data-test-label="loading-indicator">
        <div className="a-loading a-primary a-m-10" />
        <span>{t('message.loading')}</span>
      </div>
    </AnimatedWrapper>
  );
};

export default LoadingIndicator;
