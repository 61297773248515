import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { List, ListItem } from '@appkit4/react-components/list';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';
import { DashboardContext } from '../DashboardContext';

const ResultContainer = React.memo(styled.div`
  height: 100%;
  width: 100%;
  overflow: scroll;
  > ul.a-list {
    width: 100%;
    height: 100%;
    > li.a-list-item {
      padding: 10px 0;
      min-height: 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
      }
      > p {
        line-height: normal;
        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`);

const PlaceholderContainer = React.memo(styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    color: #797979;
  }
`);

const ResultPlaceholder = ({ placeholder }) => {
  if (!placeholder) return null;
  return (
    <PlaceholderContainer>
      <span>{placeholder}</span>
    </PlaceholderContainer>
  );
};

ResultPlaceholder.propTypes = {
  placeholder: PropTypes.string
};

const ResultElement = ({ properties, data }) => {
  const componentData = data[properties.data];
  const { dataFilters } = useContext(DashboardContext);
  const { dataKey, maxResults, filtersActive, placeholder } = properties;

  if (!componentData?.length || !dataKey) return <ResultPlaceholder placeholder={placeholder} />;

  // if the "maxResults" property has been set, only show results if the number of results are below or equal of maxResults.
  if (maxResults && componentData.length > maxResults) return <ResultPlaceholder placeholder={placeholder} />;

  // if the "filtersActive" property has been set, only show results if the dataset is filtered with all dataKeys stated in the filtersActive array.
  if (filtersActive?.length && !filtersActive.every(f => dataFilters[properties.data]?.[f]?.length))
    return <ResultPlaceholder placeholder={placeholder} />;

  const components = {
    a: ({ node, ...props }) => {
      return (
        <a {...props} target={props.target || '_blank'} rel="noopener noreferrer">
          {props.children}
        </a>
      );
    }
  };

  const renderItem = componentInfo => {
    return (
      <ListItem key={componentInfo[dataKey]} className="markdown">
        <ReactMarkdown remarkPlugins={[gfm]} components={components} rehypePlugins={[rehypeRaw]}>
          {componentInfo[dataKey]}
        </ReactMarkdown>
      </ListItem>
    );
  };

  return (
    <ResultContainer>
      <List data={componentData || []} itemKey={dataKey} renderItem={renderItem} />
    </ResultContainer>
  );
};

ResultElement.propTypes = {
  properties: PropTypes.shape({
    data: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    maxResults: PropTypes.number,
    filtersActive: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ResultElement;
