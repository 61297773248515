import React from 'react';
import PropTypes from 'prop-types';
import Rows from './Rows';
import Columns from './Columns';
import BlockElement from './blockElement/BlockElement';
import AccordionElement from './AccordionElement';
import TabsElement from './TabsElement';

const DashboardElement = ({ pageId, type, properties, children, parent }) => {
  if (type === 'rows') {
    return <Rows pageId={pageId} rows={children} {...properties} parent={parent} />;
  }

  if (type === 'columns') {
    return <Columns pageId={pageId} columns={children} {...properties} parent={parent} />;
  }

  if (type === 'component') {
    return <BlockElement {...properties} parent={parent} />;
  }

  if (type === 'accordion') {
    return <AccordionElement pageId={pageId} items={children} {...properties} parent={parent} />;
  }

  if (type === 'tabs') {
    return <TabsElement pageId={pageId} items={children} {...properties} parent={parent} />;
  }

  return null;
};

DashboardElement.propTypes = {
  type: PropTypes.string,
  properties: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.arrayOf(PropTypes.object)
};

export default DashboardElement;
