import React, { useContext, useEffect, useState, useRef } from 'react';
import { ResponsiveContainer, PieChart, Pie } from 'recharts';
import PropTypes from 'prop-types';
import { DashboardContext } from '../../DashboardContext';
import { useUniqueComponentData } from '../../util/useUniqueComponentData';
import RechartsComponents from '../RechartsComponents';
import useAddFilterHandler from '../../util/useAddFilterHandler';

const truncate = label => (label.length > 25 ? `${label.substring(0, 25)}...` : label);

const WrappedPie = (pie, data) => {
  const { dataFilters, setDataFilters } = useContext(DashboardContext);
  const componentData = useUniqueComponentData(data[pie.properties?.data], pie.properties?.bundleByDataKey);

  const addFilterHandler = useAddFilterHandler(dataFilters, setDataFilters);

  addFilterHandler({
    element: pie,
    elementData: componentData
  });

  // If a labelDataKey is assigned it will customize the label and truncate accordingly
  if (pie.properties?.label && pie.properties?.labelDataKey) {
    const { labelDataKey } = pie.properties;
    const renderCustomizedLabel = entry => truncate(entry[labelDataKey]);
    pie.properties = { ...pie.properties, label: renderCustomizedLabel };
  }

  // If labelPositionInside is true the label is modified and positioned inside the chart section
  if (pie.properties?.label && pie.properties?.labelPositionInside) {
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, percent }) => {
      let valueToDisplay = value;
      const radiusMultiplicator = percent < 0.1 ? 0.8 : 0.5;
      const radius = innerRadius + (outerRadius - innerRadius) * radiusMultiplicator;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      if (pie.properties?.numberFormat) {
        const formatProps = { ...pie.properties?.numberFormat };
        delete formatProps.locale;
        valueToDisplay = new Intl.NumberFormat(pie.properties.numberFormat.locale ?? 'nl-NL', formatProps).format(
          +valueToDisplay
        );
      }

      return (
        <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
          {valueToDisplay}
        </text>
      );
    };
    pie.properties = { ...pie.properties, label: renderCustomizedLabel, labelLine: false };
  }

  // Temporary solution due to a bug present in recharts and react-smooth
  // When inside a ResponsiveContainer the labels will not show if isAnimationActive
  if (pie.properties?.label) {
    pie.properties.isAnimationActive = false;
  }

  return (
    <Pie key={pie.properties?.data} {...pie.properties} data={componentData}>
      {RechartsComponents(pie.children)}
    </Pie>
  );
};

const PieDiagram = ({ properties, childComponents, id, data, title }) => {
  const [containerKey, setContainerKey] = useState();
  const { addComponentRef } = useContext(DashboardContext);
  const ref = useRef(null);

  // TODO Remove this when back-end version of PDF-creator is implemented
  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  const pieComponents = childComponents.filter(c => c.type === 'pie');
  const normalComponents = childComponents.filter(c => c.type !== 'pie');
  const pieProps = { ...properties };

  useEffect(() => setContainerKey(id), [data, id]);

  return (
    <ResponsiveContainer key={containerKey} ref={ref}>
      <PieChart {...pieProps}>
        {pieComponents.map(pie => WrappedPie(pie, data))}
        {RechartsComponents(normalComponents)}
      </PieChart>
    </ResponsiveContainer>
  );
};

PieDiagram.defaultProps = {
  properties: {},
  childComponents: []
};

PieDiagram.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  childComponents: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default PieDiagram;
