import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@appkit4/react-components/select';
import { useTranslation } from 'react-i18next';

const AndOr = ({ value, field, onChange }) => {
  const { id } = field;
  const { t } = useTranslation();

  const data = [
    { value: 'or', label: t('form.field.or') },
    { value: 'and', label: t('form.field.and') }
  ];

  return (
      <Select onSelect={value => onChange(id, value)} value={value} style={{ width: 85 }} data={data} />
  );
};

AndOr.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default AndOr;
