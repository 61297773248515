import axios from 'axios';
import { v1 as uuid } from 'uuid';
import { REACT_APP_QSF_SERVER_PORT } from '../config';

let interceptorID;

export const DEFAULT_LANGUAGE_ISO_CODE = 'en';
export const IS_LOCAL_ENV = process.env.NODE_ENV === 'development';
export const LOCALHOST_MIDDLEWARE_URL_PORT = IS_LOCAL_ENV ? `http://localhost:${REACT_APP_QSF_SERVER_PORT}` : '';

axios.defaults.headers.common['X-ID-TOKEN'] = localStorage.getItem('ROCP_idToken');
axios.defaults.headers.common['X-PWC-PPI'] = localStorage.getItem('PwCPPI');
axios.defaults.headers.common['Authorization'] = localStorage.getItem('ROCP_token');

export function initializeAjaxRedirects(t, onError) {
  if (interceptorID !== undefined) {
    axios.interceptors.response.eject(interceptorID);
  }
  interceptorID = axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        onError({
          message: t('message.sessionExpired'),
          timeout: 4000,
          kind: 'popup'
        });
      }

      if (error.response.status === 403 && error.response.data.message) {
        onError({ message: error.response.data.message });
      }

      if (error.response.status === 500) {
        onError({
          message: t('message.processingError'),
          timeout: 4000,
          kind: 'popup'
        });
      }

      if (error.response.data.redirect) {
        // Set redirect uri in localStorage so the pkce app can redirect the user
        localStorage.setItem('ROCP_loginRedirect', window.location.href);
        setTimeout(() => (window.location.href = error.response.data.redirect), 4000);
      }

      return Promise.reject(error);
    }
  );
}

export const getMyApps = (lang = DEFAULT_LANGUAGE_ISO_CODE, type = 'default') => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/${lang}/my-apps?type=${type}`);
};

export const getDiscoverApps = (lang = DEFAULT_LANGUAGE_ISO_CODE) => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/${lang}/discover-apps`);
};

export const getNewsList = () => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/news/list?limit=75&page=1&sort=date&direction=descending`);
};

export const getNewsDetail = uri => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/news/item?uri=${uri}`);
};

export const getHandleById = (handleId, lang = DEFAULT_LANGUAGE_ISO_CODE, type = 'default') => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/${lang}/handle/${handleId}?type=${type}`);
};

export const getDiscoverAppInfo = (handleId, workflow, lang = DEFAULT_LANGUAGE_ISO_CODE) => {
  return axios.get(
    `${LOCALHOST_MIDDLEWARE_URL_PORT}/api/${lang}/discover-app/${handleId}${workflow ? '?workflow=true' : ''}`
  );
};

export const getVideos = handleId => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/videos?handleId=${handleId}`);
};

export const triggerHandleInit = (handleId, query) => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/handle/init/${handleId}${query}`);
};

export const getWorkflowHandleById = (handleId, lang = DEFAULT_LANGUAGE_ISO_CODE) => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/${lang}/workflow-handle/${handleId}`);
};

export const getDashboard = (handle, lang = DEFAULT_LANGUAGE_ISO_CODE) => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/${lang}/dashboard?handleId=${encodeURIComponent(handle)}`);
};

export const getDashboardData = reference => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/dashboard-data?reference=${reference}`);
};

export const getHandleDescription = (id, lang = DEFAULT_LANGUAGE_ISO_CODE) => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/${lang}/handle-description?id=${encodeURIComponent(id)}`);
};

export const downloadPdfReport = reference => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/download-pdf-report/${reference}`, {
    responseType: 'arraybuffer'
  });
};

export function deleteDownload(reference) {
  return axios.delete(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/delete-available-download/${reference}`);
}

export function requestDisclaimer() {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/disclaimer`);
}

export function requestPowerBIRedirect(reference) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/open-files-in-powerbi/${reference}`);
}

export function transformStoredRequest(requestId, handle) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/transform-stored-request/${handle}?requestId=${requestId}`);
}

export function requestHandleUpdateData(handleId, connectionId, reference, data) {
  return axios.put(
    `${LOCALHOST_MIDDLEWARE_URL_PORT}/api/handle-data-update/${handleId}/${reference}?connectionId=${connectionId}`,
    data
  );
}

export function requestInteractiveDashboardOnlyUpdate(handleId, connectionId, reference, data) {
  return axios.put(
    `${LOCALHOST_MIDDLEWARE_URL_PORT}/api/interactive-dashboard-only-update/${handleId}/${reference}?connectionId=${connectionId}`,
    data
  );
}

export function requestChatGptMessage(data, model, chatOperation) {
  return axios.post(
    `${LOCALHOST_MIDDLEWARE_URL_PORT}/api/chatgpt/${model}/message?completion_operation=${chatOperation}`,
    data
  );
}

export function requestHandleDemoData(handleId) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/demo/zip-uploads/${handleId}`, {
    responseType: 'arraybuffer'
  });
}

export function requestHandleDemoFormData(handle) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/demo/form/${handle}`);
}

export function commentLetterMarklogicUpload(requestId) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/comment-letter/upload/${requestId}`);
}

export function downloadFiles(reference) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/download-files/${reference}`, {
    responseType: 'arraybuffer'
  });
}

export function getDownloadFileList(reference) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/files-list/${reference}`);
}

export function downloadFileList(reference, fileName) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/download-files-list/${reference}/${fileName}`, {
    responseType: 'arraybuffer'
  });
}

export function downloadPdfReference(reference, docId) {
  // If no doc is passed, we can request a default
  const pdfid = docId ? `/${docId}` : '';
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/pdf-viewer/download-pdf-viewer/${reference}${pdfid}`, {
    responseType: 'arraybuffer'
  });
}

export function getPdfViewerFilesInBucket(reference) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/pdf-viewer/count-downloads/${reference}`);
}

export function downloadHandleTemplate(reference, connectionId) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/download-template/${reference}?connectionId=${connectionId}`, {
    responseType: 'arraybuffer'
  });
}

export function refreshToken(refreshToken) {
  const cleanToken = refreshToken.replace(/^"|"$/g, '');

  return axios.post(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/token/refresh`, {
    refresh_token: cleanToken
  });
}

export function saveUserToRedis() {
  // TODO: saveUserToRedis will be removed soon together with user state in redis
  // the request will dold the needed authentication and idToken in the headers
  return axios.post(process.env.QSF_USER_SAVE_PATH);
}

export function getStandardData(source) {
  let newSource = source;
  if (source === 'currencies') {
    newSource = 'countries/currencies';
  } else if (source === 'countries') {
    newSource = 'countries/names';
  }
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/standard-data/${newSource}`);
}

export function validateLocalStorageToken(token = localStorage.getItem('ROCP_token')) {
  return axios({
    method: 'post',
    url: `${LOCALHOST_MIDDLEWARE_URL_PORT}/api/token/validate`,
    headers: {
      Authorization: token
    }
  });
}

export function requestFileTransformations(
  { filesToUpload = [], formToUpload },
  connectionId,
  handleId = '',
  isDemo = false,
  lang = DEFAULT_LANGUAGE_ISO_CODE
) {
  const formData = new FormData();

  filesToUpload.forEach(file => {
    formData.append('files', file);
  });

  if (formToUpload) {
    const formString = JSON.stringify(formToUpload, (key, value) => {
      if (value instanceof File) {
        const id = `${value.name}-${uuid()}`;
        formData.append(id, value);
        return id;
      }
      return value;
    });
    formData.append('form', new File([formString], 'form.json'));
  }

  return axios({
    method: 'put',
    url: `${LOCALHOST_MIDDLEWARE_URL_PORT}/api/transform-files/${handleId}/${lang}?connectionId=${connectionId}&isDemo=${isDemo}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  // .catch(error => {
  //   console.log('Axios error:', error);
  //   throw error; // Re-throw to propagate to the component
  // });
}

export function requestUserData() {
  return new Promise((resolve, reject) => {
    try {
      const data = axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/user-information`);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

// get general settings and configuration
export function requestConfiguration() {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/config`);
}

// gets documents for the current user
export function getDocuments(start = 0, pageLength = Number.MAX_SAFE_INTEGER) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/docs/${start}/${pageLength}`);
}

export function getDocument(uri) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/doc?uri=${uri}`);
}

export const headHandleImage = id => {
  return axios.head(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/handle/${id}/image`);
};

export function requestHandleAccess(handleName, handleId) {
  return axios({
    method: 'post',
    url: `${LOCALHOST_MIDDLEWARE_URL_PORT}/api/request-handle-access`,
    data: { handleName, handleId },
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

export const getAllFilters = () => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/filters`);
};

export const getUserConsents = redirectURL => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/api/has-agreed?redirectURL=${redirectURL}`);
};

export const getReviewItems = () => {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/9024/api/v1/review/items?start=1&limit=10`);
};

export const downloadReviewItemAttachment = id => {
  return axios.get(
    `${LOCALHOST_MIDDLEWARE_URL_PORT}/9024/api/v1/review/items/attachments/binary?documentId=${encodeURIComponent(id)}`,
    {
      responseType: 'blob',
      headers: {
        Accept: '*/*'
      },
      timeout: 30000,
      maxContentLength: Infinity,
      maxBodyLength: Infinity
    }
  );
};

export function updateWorkflowStatus(documentId, status) {
  return axios.post(`${LOCALHOST_MIDDLEWARE_URL_PORT}/9024/api/v1/review/approved?documentId=${documentId}`, {
    status
  });
}

export function getReviewInvitations(documentId) {
  return axios.get(`${LOCALHOST_MIDDLEWARE_URL_PORT}/9024/api/v1/review/invitations?documentId=${documentId}`);
}

export function inviteReviewUser(documentId, userData) {
  return axios.post(
    `${LOCALHOST_MIDDLEWARE_URL_PORT}/9024/api/v1/review/invitations?documentId=${documentId}`,
    userData
  );
}

export function removeReviewInvitation(documentId, email) {
  return axios.delete(
    `${LOCALHOST_MIDDLEWARE_URL_PORT}/9024/api/v1/review/invitations?documentId=${documentId}&email=${encodeURIComponent(
      email
    )}`
  );
}
