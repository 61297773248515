import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@appkit4/react-components/button';
import { Panel } from '@appkit4/react-components/panel';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '../../../elements/loading';
import { Wrapper } from '../../../styles/GeneralElements';
import { STATUS } from '../../../util/status';
import { formatDateTime } from '../../../documents/utils';
import { StyledTable } from '../../../styles/HandleComponents';

const ViewWorkflowDetailsButton = ({ t, uri }) => {
  const history = useHistory();
  return <Button onClick={() => history.push(`/workflows/review-and-publish/${uri}`)}>{t('button.details')}</Button>;
};

const CompleteWorkflowFormButton = ({ t, uri }) => {
  const history = useHistory();
  return (
    <Button onClick={() => history.push(`/workflow-form/${uri}/input-form.json`)}>{t('button.completeForm')}</Button>
  );
};

const columns = (email, t) => {
  return [
    {
      Header: t('workflow.label.handleName'),
      accessor: 'handleName'
    },
    {
      Header: t('workflow.label.start'),
      accessor: 'initiatedAt',
      Cell: row => formatDateTime(row.value)
    },
    {
      Header: t('workflow.label.initiator'),
      accessor: 'initiator'
    },
    {
      Header: t('workflow.label.status'),
      accessor: 'status',
      // eslint-disable-next-line
      Cell: row => {
        if (row.value !== 'open') return <div>{t('workflow.status.closed')}</div>;
        if (row.original.reviewSubmissions?.includes(email)) return <div>{t('workflow.status.submitted')}</div>;
        if (row.original.preparerSubmissions?.includes(email)) return <div>{t('workflow.status.submitted')}</div>;
        return <div>{t('workflow.status.open')}</div>;
      }
    },
    {
      Header: '',
      accessor: 'id',
      sortable: false,
      // eslint-disable-next-line
      Cell: row => {
        if (row.original.status !== 'open') return null;
        if (row.original.reviewSubmissions?.includes(email)) return null;
        if (row.original.preparerSubmissions?.includes(email)) return null;
        if (row.original.reviewers.filter(reviewer => reviewer.email === email).length > 0)
          return <ViewWorkflowDetailsButton uri={row.value} t={t} />;
        if (row.original.preparers.filter(preparer => preparer.email === email).length > 0)
          return <CompleteWorkflowFormButton uri={row.value} t={t} />;
        return null;
      }
    }
  ];
};

const ReviewPublishWorkflows = ({ statusData, userData, workflowData }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(STATUS.LOADING);
  const [workflows, setWorkflows] = useState([]);
  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail(userData.emailAddress);
    setWorkflows(workflowData);
    setStatus(statusData);
  }, [statusData, userData, workflowData]);

  const retrieveFailed = t('message.retrieveFailed', { item: t('type.workflow', { count: 2 }) });
  const noneAvailable = t('message.noneAvailable', { type: t('type.workflow', { count: 2 }) });
  return (
    <Wrapper style={{ minWidth: 800 }}>
      <Panel style={{ padding: 30 }} data-test-label="search-documents-panel">
        <StyledTable
          data={workflows}
          loading={status === STATUS.LOADING}
          loadingText={<LoadingIndicator />}
          noDataText={status === STATUS.LOADING ? '' : status === STATUS.ERROR ? retrieveFailed : noneAvailable}
          columns={columns(email, t)}
          showPagination
          sortable
          defaultPageSize={10}
          defaultSorted={[{ id: 'initiatedAt', desc: true }]}
          previousText={t('form.field.table.previous')}
          nextText={t('form.field.table.next')}
          pageText={t('form.field.table.page')}
          ofText={t('form.field.table.of')}
          rowsText={t('form.field.table.rows')}
        />
      </Panel>
    </Wrapper>
  );
};

ReviewPublishWorkflows.prototypes = {
  statusData: PropTypes.symbol.isRequired,
  userData: PropTypes.object.isRequired,
  workflows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ReviewPublishWorkflows;
