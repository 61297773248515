import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Modal } from '@appkit4/react-components/modal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { STATUS_ASSEMBLING_SUCCESS } from '../../config';
import { downloadPdfReport, requestHandleUpdateData } from '../../util/requests';
import { QSFContext } from '../../context';
import { DashboardContext } from './DashboardContext';
import dashboardPagePropType from './dashboardPagePropType';

const StyledModal = styled(Modal)`
  .modal-content {
    max-height: 100%;
  }

  .modal-body {
    white-space: pre-wrap;
    overflow-y: scroll;
    text-align: justify;
  }
`;

const downloadLink = (data, fileName) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(data);
  link.setAttribute('download', fileName);

  return link;
};

const ReportPrint = ({ pages }) => {
  const [prepareToDownload, setPrepareToDownload] = useState(false);
  const { componentRefs, filters, activeDataSubset, generatingReport, setGeneratingReport, setReportHasBeenGenerated } =
    useContext(DashboardContext);
  const { connectionId, updateStatus } = useContext(QSFContext);
  const { reference, handleName, handleId } = useParams();
  const didMount = useRef(false);
  const history = useHistory();
  const status = updateStatus[handleId]?.status;
  const { t } = useTranslation();

  useEffect(() => {
    if (!didMount.current) didMount.current = true;
    else if (!generatingReport) {
      const htmlEntries = Object.entries(componentRefs)
        .map(([key, value]) => {
          let html = value.ref.innerHTML;
          // Match the properties in any order and extract the max dimensions
          const maxHeightMatch = html.match(/max-height:\s*(\d+)px/);
          const maxWidthMatch = html.match(/max-width:\s*(\d+)px/);

          if (maxHeightMatch && maxWidthMatch) {
            const height = maxHeightMatch[1];
            const width = maxWidthMatch[1];

            // Replace the entire style section with fixed dimensions
            html = html.replace(
              /(width:\s*100%;\s*height:\s*100%|height:\s*100%;\s*width:\s*100%);\s*max-height:\s*\d+px;\s*max-width:\s*\d+px/g,
              `width: ${width}px; height: ${height}px`
            );
          }
          return [key, html];
        })
        .filter(([_, value]) => value.length > 500);
      const htmls = Object.fromEntries(htmlEntries);

      requestHandleUpdateData(handleId, connectionId, reference, {
        data: htmls,
        meta: { filters, dataSubset: activeDataSubset }
      }).catch(e => {
        console.error('Error fetching handle update: ', e);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatingReport]);

  useEffect(() => {
    if (status === STATUS_ASSEMBLING_SUCCESS && prepareToDownload) {
      setPrepareToDownload(false);
      downloadPdfReport(updateStatus[handleId].reference).then(response => {
        const rawBlobData = new Blob([response.data], { type: 'application/json' });
        const fileName = `report-${handleName}.pdf`;

        downloadLink(rawBlobData, fileName).click();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const recursiveVisit = (ids, cb) => {
    if (ids.length <= 0) return cb();
    const [nextId, ...leftIds] = ids;
    history.push(`/handles/${handleId}/${handleName}/dashboard/${reference}/${nextId}`);

    setTimeout(() => {
      recursiveVisit(leftIds, () => {
        document.body.style.width = '';
        cb();
      });
    }, 1000);
    return null;
  };

  const generatePdf = () => {
    setGeneratingReport(true);
    setPrepareToDownload(true);
    const pageIds = Object.keys(pages);
    const totalPages = pageIds.length;
    recursiveVisit(pageIds, () => {
      history.go(-totalPages);
      setReportHasBeenGenerated(true);
      setGeneratingReport(false);
    });
  };

  return (
    <>
      <Button className="a-btn-lg" onClick={generatePdf} kind="secondary" data-test-label="print-page-btn">
        <span className="Appkit4-icon icon-pdf-outline" />
      </Button>
      <StyledModal data-test-label="pdf-creator" visible={generatingReport}>
        {t('dashboard.reportGeneration')}
      </StyledModal>
    </>
  );
};

ReportPrint.propTypes = {
  pages: PropTypes.objectOf(PropTypes.shape(dashboardPagePropType)).isRequired
};

export default ReportPrint;
