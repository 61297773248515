import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { DashboardContext } from '../DashboardContext';
import { sanitizer } from '../../../util';
import CopyToClipboard from '../../CopyToClipboard';

const TableWrapper = React.memo(
  styled.div(props => ({
    width: '100%',
    height: '100%',
    overflow: 'scroll',
    marginTop: '15px',
    '.rt-tbody': {
      maxHeight: '100%',
      overflow: 'visible !important',
      '.rt-td': {
        whiteSpace: 'normal',
        lineHeight: '1.125rem',
        minHeight: '2.125rem'
      }
    },
    '.rt-table': props.tableStyle ?? {}
  }))
);

const TableElement = ({ properties, id, title, data }) => {
  const [filteredData, setFilteredData] = useState(data[properties.data]);
  const tableInstance = useRef();
  const { addComponentRef, setLinkToPdfSelected } = useContext(DashboardContext);

  const ref = useCallback(
    node => {
      setTimeout(() => {
        addComponentRef(id, title, node);
      }, 200);
    },
    [addComponentRef, id, title]
  );
  const componentData = data[properties.data];
  const tableProps = cloneDeep(properties);
  delete tableProps.data;
  delete tableProps.style;

  useEffect(() => setFilteredData(data[properties.data]), [data, properties.data]);

  (tableProps.columns || []).forEach(column => {
    if (column.filterable || tableProps.filterable) {
      const fuzzy = column.filterType === 'fuzzy' || tableProps.filterType === 'fuzzy';
      // fuzzy will look for parts of words, while strict needs to match word from the start
      column.filterMethod = (filter, row) => {
        const filterValue = filter.value.toString().toLowerCase().trim();
        const rowValue = row[filter.id]?.toString().toLowerCase().trim() || '';
        return fuzzy ? rowValue.includes(filterValue) : rowValue.startsWith(filterValue);
      };
    }

    if (column.numberFormat) {
      const formatProps = { ...column.numberFormat };
      delete formatProps.locale;
      column.Cell = row => {
        return new Intl.NumberFormat(column.numberFormat?.locale ?? 'nl-NL', formatProps).format(+row.value);
      };
    }

    if (column.htmlFormat) {
      // eslint-disable-next-line react/display-name
      column.Cell = ({ value }) => {
        if (column.accessor === 'answer') {
          return (
            <CopyToClipboard tooltipPosition="top-end">
              <div dangerouslySetInnerHTML={{ __html: sanitizer(value) }} />
            </CopyToClipboard>
          );
        }
        return <div dangerouslySetInnerHTML={{ __html: sanitizer(value) }} />;
      };
    }

    if (column.isPdfLinks) {
      const linkToPdf = link => {
        setLinkToPdfSelected(link);
      };
      // Group links by their pdfId so we can
      // render them properly
      const groupLinksByPdfId = links => {
        return links.reduce((acc, link) => {
          if (!acc[link.pdfId]) {
            acc[link.pdfId] = [];
          }
          acc[link.pdfId].push(link);
          return acc;
        }, {});
      };

      // eslint-disable-next-line react/display-name
      column.Cell = row => {
        const groupedLinks = groupLinksByPdfId(row.value);
        const { referenceStyle } = properties;

        return (
          <div key={uuid()}>
            {Object.entries(groupedLinks).map(([pdfId, links]) => (
              <div key={pdfId}>
                <div>
                  <span className="pdf-title">
                    <b>{pdfId}.pdf</b>
                  </span>
                </div>

                {referenceStyle === 'inline' || referenceStyle === 'inlineWithoutText' ? (
                  <>
                    {referenceStyle !== 'inlineWithoutText' && links[0].linkText && (
                      <span className="link-pdf-text">{links[0].linkText}</span>
                    )}

                    {links.map((link, index) => (
                      <div key={link.linkId} style={{ display: 'inline' }}>
                        <span className="link-pdf" onClick={() => linkToPdf(link)}>
                          {link.page}
                        </span>
                        {index < links.length - 1 && ', '}
                      </div>
                    ))}
                    <br />
                  </>
                ) : (
                  <div>
                    {links.map(link => (
                      <div key={link.linkId}>
                        {link.linkText && <span className="link-pdf-text">{link.linkText}</span>}
                        <span className="link-pdf" onClick={() => linkToPdf(link)}>
                          {link.page}
                        </span>
                      </div>
                    ))}
                    <br />
                  </div>
                )}

                <br />
              </div>
            ))}
          </div>
        );
      };
    }
  });

  // In case that filters change gets the filtered data from the table instance
  const filterChange = () => setFilteredData(tableInstance?.current?.state?.sortedData);

  if (tableProps.showFooter) {
    const modifiedColumns = (tableProps.columns || []).map(column => {
      const { footerValue, accessor, numberFormat, footerLabel } = column;
      // Calculates the average of the column data and formats it if necessary
      if (footerValue === 'avg') {
        const sumValue = filteredData.reduce((acc, item) => acc + item[accessor], 0);
        let avgValue = sumValue / filteredData.length || 0;
        if (numberFormat) {
          const formatProps = { ...numberFormat };
          delete formatProps.locale;
          avgValue = new Intl.NumberFormat(numberFormat.locale ?? 'nl-NL', formatProps).format(+avgValue);
        } else {
          avgValue = Math.round(avgValue * 100) / 100;
        }
        const avgLabel = footerLabel ?? 'Avg: ';
        return { ...column, Footer: `${avgLabel}${avgValue}` };
        // Calculates the total sum of the column data and formats it if necessary
      } else if (footerValue === 'sum') {
        let sumValue = filteredData.reduce((acc, item) => acc + item[accessor], 0);
        if (numberFormat) {
          const formatProps = { ...numberFormat };
          delete formatProps.locale;
          sumValue = new Intl.NumberFormat(numberFormat.locale ?? 'nl-NL', formatProps).format(+sumValue);
        }
        const sumLabel = footerLabel ?? 'Total: ';
        return { ...column, Footer: `${sumLabel}${sumValue}` };
      }
      if (footerLabel) {
        return { ...column, Footer: `${footerLabel}` };
      }
      return { ...column, Footer: '-' };
    });

    tableProps.columns = modifiedColumns;
  }

  const tableStyle = tableProps.showFooter
    ? {
        ...properties.style,
        '.rt-tfoot': { borderTop: '2px solid #000', backgroundColor: '#FFF' }
      }
    : properties.style;

  return (
    <TableWrapper ref={ref} tableStyle={tableStyle} className="table-element">
      <ReactTable
        ref={tableInstance}
        onFilteredChange={filterChange}
        data={componentData}
        showPagination
        minRows={0}
        resizable={false}
        {...tableProps}
      />
    </TableWrapper>
  );
};

TableElement.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  properties: PropTypes.shape({
    data: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    showPagination: PropTypes.bool,
    defaultPageSize: PropTypes.number,
    showPageSizeOptions: PropTypes.bool,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    sortable: PropTypes.bool,
    defaultSorted: PropTypes.arrayOf(PropTypes.any),
    hover: PropTypes.bool,
    striped: PropTypes.bool,
    referenceStyle: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'lg']),
    manual: PropTypes.bool,
    resizable: PropTypes.bool,
    filterable: PropTypes.bool,
    showFooter: PropTypes.bool
  }).isRequired
};

export default TableElement;
