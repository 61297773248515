import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appkit4/react-components/button';
import { useTranslation } from 'react-i18next';
import { ModalWrapper } from '../../styles/GeneralElements';
import { SUPPORT_EMAIL } from '../../config';

const RequestAccessModal = ({ isVisible, onCloseModal, requestAccess }) => {
  const supportEmail = `mailto:${SUPPORT_EMAIL}`;
  const [accessRequested, setAccessRequested] = useState(false);
  const { t } = useTranslation();

  const title = accessRequested ? t('handle.modalAccess.requestSubmitted') : t('handle.modalAccess.getAccess');
  const descriptionText = accessRequested
    ? `${t('handle.modalAccess.requestDescription')} ${supportEmail}.`
    : t('handle.modalAccess.accessDescription');

  const onRequestAccess = () => {
    requestAccess();
    setAccessRequested(true);
  };

  const ButtonModal = () => {
    return accessRequested ? (
      <Button className="a-btn-md" onClick={onCloseModal} data-test-label="close-btn">
        {' '}
        {t('button.close')}{' '}
      </Button>
    ) : (
      <Button className="a-btn-md" onClick={onRequestAccess} data-test-label="request-access-btn">
        {' '}
        {t('button.requestAccess')}{' '}
      </Button>
    );
  };

  return (
    <ModalWrapper
      className="baseline-modal-showcase"
      visible={isVisible}
      onCancel={onCloseModal}
      data-test-label="request-access-modal"
      title={` ${title}`}
      ariaLabel={` ${title}`}
      footer={<ButtonModal />}
      footerStyle={{ justifyContent: 'flex-end' }}
      bodyStyle={{ minHeight: '92px' }}
    >
      {descriptionText}
    </ModalWrapper>
  );
};

RequestAccessModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  requestAccess: PropTypes.func.isRequired
};

export default RequestAccessModal;
