import {
  STATUS_ASSEMBLING_SUCCESS,
  STATUS_TRANSFORMATION_RECEIVED,
  STATUS_TRANSFORMED,
  STATUS_VALIDATION_VALID
} from '../../config';

export const transformResults = downloads => {
  return Object.values(downloads || {})
    .filter(
      download =>
        download.status === STATUS_TRANSFORMATION_RECEIVED ||
        download.status === STATUS_TRANSFORMED ||
        download.status === STATUS_ASSEMBLING_SUCCESS ||
        download.status === STATUS_VALIDATION_VALID
    )
    .reduce((results, download) => ({ ...results, [download.handle]: download }), {});
};
