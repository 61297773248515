import PropTypes from 'prop-types';
import { FieldProps } from './fieldProps';

export const FormProps = Object.freeze({
  version: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape(FieldProps)).isRequired,
  disableSubmitIf: PropTypes.objectOf(PropTypes.any)
});
