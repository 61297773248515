import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { CheckboxGroup, Checkbox } from '@appkit4/react-components/checkbox';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { sanitizer } from '../../../../util';

const Group = styled(CheckboxGroup)`
  display: inline-flex;
  flex-direction: column;

  .ap-checkbox-text {
    display: flex;
    width: 100%;
  }

  .a-tooltip-ref {
    margin-left: auto;
    padding-left: 10px;
  }
`;

const MultipleChoice = ({ field, value, onChange }) => {
  const { id, options } = field;
  const currentValue = value || [];

  const HandleOnChange = value => {
    onChange(id, value);
  };

  const renderContent = tooltip => <div dangerouslySetInnerHTML={{ __html: sanitizer(tooltip) }} />;

  return (
    <Group onChange={HandleOnChange} value={currentValue}>
      {options.map(option => (
        <Checkbox key={uuidv4()} value={option.value} className="a-mb-10">
          {option.label}
          {option.tooltip && (
            <Tooltip content={renderContent(option.tooltip)} position="top">
              <div className="icon-information-fill a-font-14" />
            </Tooltip>
          )}
        </Checkbox>
      ))}
    </Group>
  );
};

MultipleChoice.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        tooltip: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MultipleChoice;
