import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';
import {
  NewsTag,
  RelevantNewsWrapper,
  RelevantNewsTileWrapper,
  RelevantText,
  RelevantImageWrapper,
  RelevantNewsTitle,
  RelevantNewsDescription,
  RelevantNewsButton,
  ChevronRight,
  RelevantTagsWrapper
} from '../styles/NewsElements';
import { stripHtml } from '../util';

const NewsItem = ({ item }) => {
  const history = useHistory();
  const { subscriptionsSelected } = useContext(QSFContext);
  const { t } = useTranslation();
  const { title, subscriptions, excerpt, id, uri } = item;
  const mainSubscription = subscriptions.find(sub => subscriptionsSelected.find(s => s === sub.name))?.name;
  const image = item.image?.sizes?.thumb;

  const goToDetailPage = useCallback(() => history.push(`/news/${id}?uri=${uri}`), [history, id, uri]);

  return (
    <RelevantNewsTileWrapper className="ap-panel a-shadow-sm">
      <RelevantImageWrapper image={image} />
      <RelevantText>
        <RelevantNewsTitle>
          <span>{title}</span>
        </RelevantNewsTitle>
        <RelevantNewsDescription>{stripHtml(excerpt)}</RelevantNewsDescription>
      </RelevantText>
      <RelevantTagsWrapper>{mainSubscription && <NewsTag>{mainSubscription}</NewsTag>}</RelevantTagsWrapper>
      <RelevantNewsButton className="a-btn-md" onClick={() => goToDetailPage(uri)}>
        {t('news.readMore')} <ChevronRight size={14} />
      </RelevantNewsButton>
    </RelevantNewsTileWrapper>
  );
};

const RelevantNewsBlock = ({ list }) => {
  return (
    <RelevantNewsWrapper>
      <NewsItem className="ap-panel a-shadow-sm" item={list[0]} />
      {list[1] && <NewsItem className="ap-panel a-shadow-sm" item={list[1]} />}
      {list[2] && <NewsItem className="ap-panel a-shadow-sm" item={list[2]} />}
      {list[3] && <NewsItem className="ap-panel a-shadow-sm" item={list[3]} />}
    </RelevantNewsWrapper>
  );
};

export default RelevantNewsBlock;
