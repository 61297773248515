import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QSFContext } from '../../context';
import { APP_NAME } from '../../config';
import { Container, ContentWrapper } from '../../styles/GeneralElements';
import FontoViewer from './FontoViewer';

const FontoViewerPage = () => {
  const { documentId } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { isPwCUser } = useContext(QSFContext);
  const goHome = useCallback(() => history.push('/'), [history]);
  const goToReview = useCallback(() => history.push('/review'), [history]);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="review" onClick={goToReview}>
      <span className="bc-text" tabIndex={2}>
        {t('menu.review')}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="fontoViewer">
      <span className="bc-text" tabIndex={2}>
        {/* {t('menu.fontoViewer')} */}
        Document Viewer
      </span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  return (
    <Container>
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <ContentWrapper>
        <FontoViewer documentId={documentId} />
      </ContentWrapper>
    </Container>
  );
};

export default FontoViewerPage;
