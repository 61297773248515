import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { useReactToPrint } from 'react-to-print';
import PropTypes from 'prop-types';

const PagePrint = ({ pageRef }) => {
  const printPage = useReactToPrint({
    content: () => pageRef.current,
    documentTitle: 'export',
    onBeforePrint: () => window.history.pushState({}, '', '/'),
    onAfterPrint: () => window.history.back(),
    pageStyle:
      '@page { margin: 15mm 15mm 0 !important; }' +
      'body { margin: 0 0 15mm !important; }' +
      '.recharts-wrapper, .recharts-surface { width: calc(100%) !important; height: calc(100%) !important; }' +
      '.ap-panel { border: 1px solid #ececec; }'
  });

  if (!pageRef) {
    return null;
  }

  return (
    <Button className="a-btn-lg" onClick={printPage} kind="secondary" data-test-label="print-page-btn">
      <span className="Appkit4-icon icon-printer-outline" />
    </Button>
  );
};

PagePrint.propTypes = {
  pageRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
};

export default PagePrint;
