import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: inline-flex;
  margin-bottom: 20px;
  font-weight: bolder;
  justify-content: flex-end;
`;

const Export = ({ onExport }) => {
  const { t } = useTranslation();

  return (
    <Container data-test-label="export-form">
      <Tooltip content={t('form.tooltip.export')} position="top">
        <Button
          style={{ marginLeft: 10 }}
          kind="text"
          className="a-btn-lg"
          onClick={onExport}
          data-test-label="export-btn"
        >
          <span style={{ margin: 'auto', fontSize: 16 }} className="icon-download-outline Appkit4-icon " />
        </Button>
      </Tooltip>
    </Container>
  );
};

export default Export;
