import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledPanel, StyledTab, StyledTabs } from '../../styles/HandleComponents';
import { getWorkflows } from '../util/requests';
import { requestUserData } from '../../util/requests';
import { STATUS } from '../../util/status';
import { HANDLE_TYPE } from '../../util/handleType';
import WaitAssembleWorkflows from './wait-assemble/WaitAssembleWorkflows';
import ReviewPublishWorkflows from './review-publish/ReviewPublishWorkflows';

const Workflows = () => {
  const [statusData, setStatusData] = useState(STATUS.LOADING);
  const [waitAssembleWorkflows, setWaitAssembleWorkflows] = useState([]);
  const history = useHistory();
  const { tab } = queryString.parse(useLocation().search);
  const [reviewPublishWorkflows, setReviewPublishWorkflows] = useState([]);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getWorkflows();
        const user = await requestUserData();
        setUserData(user.data);
        setStatusData(STATUS.OK);
        setWaitAssembleWorkflows(res.data.filter(wf => wf.workflowType === HANDLE_TYPE.WAIT_ASSEMBLE));
        setReviewPublishWorkflows(res.data.filter(wf => wf.workflowType === HANDLE_TYPE.REVIEW_AND_PUBLISH));
      } catch (e) {
        setStatusData(STATUS.ERROR);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    if ((!tab && waitAssembleWorkflows.length > 0) || tab === HANDLE_TYPE.WAIT_ASSEMBLE) {
      history.push(`/workflows?tab=${HANDLE_TYPE.WAIT_ASSEMBLE}`);
    } else if ((!tab && reviewPublishWorkflows.length > 0) || tab === HANDLE_TYPE.REVIEW_AND_PUBLISH) {
      history.push(`/workflows?tab=${HANDLE_TYPE.REVIEW_AND_PUBLISH}`);
    }
  }, [tab, history, waitAssembleWorkflows, reviewPublishWorkflows]);

  const handleTabChange = (e, newTab) => {
    history.push(`/workflows?tab=${newTab}`);
  };

  return (
    <StyledPanel>
      <StyledTabs onChange={handleTabChange} defaultValue={tab} value={tab} data-test-label="tab-information">
        <StyledTab value={HANDLE_TYPE.WAIT_ASSEMBLE} label="Wait and Assemble">
          <WaitAssembleWorkflows statusData={statusData} userData={userData} workflowData={waitAssembleWorkflows} />
        </StyledTab>
        <StyledTab value={HANDLE_TYPE.REVIEW_AND_PUBLISH} label="Review and Publish">
          <ReviewPublishWorkflows statusData={statusData} userData={userData} workflowData={reviewPublishWorkflows} />
        </StyledTab>
      </StyledTabs>
    </StyledPanel>
  );
};

export default Workflows;
