import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { sanitizer } from '../../../../util';

const TextContent = ({ text, star, tooltip }) => {
  return (
    <>
      {text} {star && '*'} {tooltip}
    </>
  );
};

const Title = ({ text, star, tooltip }) => {
  return (
    <h4 style={{ display: 'flex ' }}>
      <TextContent text={text} star={star} tooltip={tooltip} />
    </h4>
  );
};

const SubTitle = ({ text, star, tooltip }) => {
  return (
    <h5 style={{ display: 'flex ' }}>
      <TextContent text={text} star={star} tooltip={tooltip} />
    </h5>
  );
};

const TopText = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
`;

const Text = ({ text, star, tooltip }) => {
  return (
    <TopText>
      <TextContent text={text} star={star} tooltip={tooltip} />
    </TopText>
  );
};

const ELEMENT = Object.freeze({
  title: Title,
  subtitle: SubTitle,
  text: Text
});

const TooltipContainer = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 500px;

  ul,
  ol {
    margin-bottom: 0;
  }

  ul,
  ul > li {
    list-style: disc inside;
  }

  ol,
  ol > li {
    list-style: decimal inside;
  }
`;

const Hint = ({ text }) => {
  if (text == null) return null;

  // allow sanitized HTML in the tooltip
  const Content = <div dangerouslySetInnerHTML={{ __html: sanitizer(text) }} />;

  return (
    <TooltipContainer>
      <StyledTooltip content={Content} position="top">
        <span className="Appkit4-icon icon-information-fill ap-font-14" aria-hidden="true" />
      </StyledTooltip>
    </TooltipContainer>
  );
};

const TextElements = ({ textElements, tooltip, id, required }) => {
  return Object.keys(textElements).map((k, index) => {
    const Element = ELEMENT[k];
    const Tooltip = index === Object.keys(textElements).length - 1 ? <Hint text={tooltip} /> : null;
    return <Element key={`${id}-${k}`} text={textElements[k]} star={index === 0 && required} tooltip={Tooltip} />;
  });
};

export default TextElements;
