import { Button } from '@appkit4/react-components/button';
import { Panel } from '@appkit4/react-components/panel';
import styled from 'styled-components';

export const BlockPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  max-width: ${props => props.width ?? '100%'};
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  height: ${props => props.height ?? '100%'};
  min-height: unset;

  .ap-panel-header {
    min-height: 50px;
    height: 50px;
    border-bottom: ${props => props.title === undefined && 'none'};
    .ap-panel-header-right .component-actions-container {
      .component-actions-btn {
        cursor: pointer !important;
        > span {
          margin-right: 0;
        }
      }
      &:not(:only-child) {
        margin-right: 5px;
      }
    }
  }

  .has-extra {
    display: ${props => props.title === undefined && 'none'};
  }

  .ap-panel-body {
    height: 100%:
  }

   .ap-panel-body {
    height: 100%;
    flex: 1;
  }


  .ap-panel-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-height: 100%;
    height: 100%;
    padding: ${props => (props.title === undefined ? '15px 0 0' : '15px 0')};
  }

  &:hover {
    .ap-panel-header .ap-panel-header-right .component-actions-btn {
      display: block;
    }
  }
`;

export const BlockPanelWrapper = styled.div`
  height: 100%;
  max-height: ${props => props.maxheight ?? '100%'};
  position: relative;
  &.closed ${BlockPanel} {
    height: auto;
    min-height: unset;
    .ap-panel-header {
      border-bottom: none;
    }

    .ap-panel-content {
      height: 100%
      opacity: 0;
      display: none;
    }

  }
`;

export const RightHeaderContainer = styled.div`
  display: flex;
  align-content: center;
`;

export const BlockDescription = styled.p`
  border-top: 0.0625rem solid #eeeeee;
  margin: 10px 0 0;
  padding-top: 10px;
`;

export const ToggleButton = styled(Button)`
  background: transparent !important;
  padding: 4px 0.25rem !important;
  margin-left: 5px;
  > span {
    margin-right: 0 !important;
    transition: transform 550ms ease;
  }
  &:hover > span {
    color: #d04a02;
  }
  &.closed > span {
    transform: rotate(-180deg);
    transition: transform 550ms ease;
  }
`;

export const ExternalLink = styled.a`
  font-size: 14px;
`;
