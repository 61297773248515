import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputNumber } from '@appkit4/react-components/input-number';
import { useTranslation } from 'react-i18next';

const OutOf = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const MinParticipantsWrapper = styled.div`
  display: flex;
  .a-input-size-sm {
    height: 2.125rem;
  }
`;

const MinParticipants = ({ field, onChange, value, max }) => {
  const { id } = field;
  const { t } = useTranslation();

  return (
    <MinParticipantsWrapper>
      <InputNumber
        min={0}
        max={max}
        step={1}
        className="a-input-size-sm"
        value={value}
        onChange={value => onChange(id, value)}
      />
      <OutOf>{t('form.field.outOf', { amount: max })}</OutOf>
    </MinParticipantsWrapper>
  );
};

MinParticipants.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired
};

export default MinParticipants;
