import React from 'react';
import styled from 'styled-components';
import { LockIcon as Lock, ChevronRightIcon } from '@primer/octicons-react';
import { Button } from '@appkit4/react-components/button';
import { Modal } from '@appkit4/react-components/modal';

export const LayoutWrapper = React.memo(styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`);

export const Container = React.memo(styled.div`
  padding: ${props => (props.displayMode === 'handle_iframe' ? '0px 0px 0px' : '40px 64px 5px')};
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
`);

export const NewsContainer = React.memo(styled.div`
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
`);

export const DemoAlertWrapper = React.memo(styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #25b4e8;
  padding: 8px 0;
  color: #ffffff;

  .demo-headline {
    font-weight: 700;
    margin-right: 5px;
  }
`);

export const ContentWrapper = React.memo(styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`);

export const BreadcrumbWrapper = React.memo(styled.div`
  display: flex;
  justify-content: space-between;
`);

export const FilterButton = React.memo(styled(Button)`
  width: 40px;
  height: 40px;

  span {
    font-size: 16px !important;
  }
`);

export const ChatButton = React.memo(styled(Button)`
  width: 40px;
  height: 40px;
  margin-left: 20px;

  span {
    font-size: 26px !important;
  }
`);

export const SideDrawerWrapper = React.memo(styled.div`
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: 1200;
  position: fixed;
  top: 0px;
  outline: 0px;
  right: ${props => (props.isVisible ? '0' : '-337px')};
`);

export const SideDrawerWrapperWide = React.memo(styled(SideDrawerWrapper)`
  right: ${props => (props.isVisible ? '0' : '-620px')};
`);

export const SideDrawerContainer = React.memo(styled.div`
  width: 320px;
  padding: 24px;
`);

export const SideDrawerContainerWide = React.memo(styled(SideDrawerContainer)`
  width: 600px;
  padding: 24px;
`);

export const SideDrawerHeader = React.memo(styled.div`
  display: flex;
  justify-content: space-between;
`);

export const DisclaimerChat = React.memo(styled.div`
  display: flex;
  background-color: #fff0cc;
  padding: 10px;
  margin-top: 10px;
  color: #855f00;
  flex: 1;
  align-items: baseline;
`);

export const DisclaimerText = React.memo(styled.div`
  margin-left: 10px;
  font-size: 12px;
  white-space: pre-line;
`);

export const ClearChatButton = React.memo(styled(Button)`
  margin-top: 20px;
`);

export const CloseFiltersButton = React.memo(styled.div`
  width: 18px;
  height: 18px;
  cursor: pointer;
  padding-top: 2px;

  span {
    font-size: 16px !important;
  }
`);

export const MessageWrapper = React.memo(styled.div`
  margin: 30px 0;
`);

export const GptChatIcon = React.memo(styled.div`
  display: flex;
  justify-content: center;

  span {
    color: white;
    font-size: 22px;
  }
`);

export const GptUserIcon = React.memo(styled.div`
  display: flex;
  justify-content: center;

  span {
    color: white;
    font-size: 18px;
  }
`);

export const MessageChatInput = React.memo(styled.div`
  padding: 20px 0px;
  bottom: 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  border-top: 1px solid #ddd;
`);

export const ModelSelector = React.memo(styled.div`
  margin-top: 20px;
`);

export const OperationSelector = React.memo(styled.div`
  margin-top: 20px;

  button {
    width: 150px;
  }
`);

export const CharCounter = React.memo(styled.div`
  text-align: right;
  color: #464646;
  margin-top: 5px;
  font-size: 12px;
`);

export const ClearMessagesButtonWrapper = React.memo(styled.div`
  display: flex;
  justify-content: end;
  margin-top: -20px;
`);

export const EmptyTagsTextWrapper = React.memo(styled.div`
  margin-top: 20px;
  font-size: 14px;
`);

export const FilterGroupWrapper = React.memo(styled.div`
  margin: 20px 0 40px;

  h4 {
    font-size: 16px;
    margin-bottom: 12px;
  }
`);

export const CheckboxTagWrapper = React.memo(styled.div`
  margin-bottom: 5px;
`);

export const FiltersSelectedWrapper = React.memo(styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 5px;
`);

export const SelectableTag = React.memo(styled.div`
  border-radius: 15px;
  background-color: #dedede;
  font-size: 11px;
  padding: 2px 6px;
  margin-right: 8px;
  margin-bottom: 8px;
`);

export const DeleteTagIcon = React.memo(styled.span`
  margin-left: 5px;
  font-size: 10px;
  cursor: pointer;
`);

export const EnabledEntry = React.memo(styled.div`
  min-width: 200px;
  flex: 1;
  display: flex;
  height: 90px;
  cursor: pointer;
  padding: 12px 16px !important;
`);

export const DisabledEntry = React.memo(styled(EnabledEntry)`
  cursor: not-allowed;
  color: #7d7d7d !important;
`);

export const LockIcon = React.memo(styled(Lock)`
  float: right;
`);

export const EntryImageWrapper = React.memo(styled.div`
  flex: 0 0 66px;
  margin-right: 12px;
  padding: 2px;
`);

export const EntryText = React.memo(styled.div`
  min-width: 0;
`);

export const EntryHeader = React.memo(styled.div`
  font-weight: 700;
  margin-top: 5px;
  display: flex;

  span {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`);

export const EntryDescription = React.memo(styled.div`
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #7d7d7d;
`);

export const Wrapper = React.memo(styled.div`
  min-width: 300px;
  margin: 16px 0;
  flex: 1;
`);

export const AssistantWrapper = React.memo(styled.div`
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 16px auto;
`);

export const HeaderAssistant = React.memo(styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`);

export const NewsListWrapper = React.memo(styled.div`
  max-width: 1200px;
  margin: 140px auto 16px;

  @media (max-width: 1400px) {
    max-width: 1000px;
  }

  @media (max-width: 1100px) {
    max-width: 900px;
  }
`);

export const TilesWrapper = React.memo(styled.div`
  margin-top: 40px;

  h4 {
    margin-bottom: 12px;
    font-size: 21px;
  }
`);

export const GridWrapper = React.memo(styled.div`
  display: grid;
  grid-gap: 24px 60px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: start;

  .a-tooltip-ref-inner {
    height: 100%;

    .ap-panel.a-shadow-sm {
      height: 100%;
      margin-bottom: 0;
    }
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`);

export const ExtendedGridWrapper = React.memo(styled.div`
  display: grid;
  grid-gap: 40px 32px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: start;

  .a-tooltip-ref-inner {
    height: 100%;

    .ap-panel.a-shadow-sm {
      height: 100%;
      margin-bottom: 0;
    }
  }

  @media (max-width: 1600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
`);

export const ExtendedTileWrapper = React.memo(styled.div`
  min-width: 200px;
  height: 500px;
  position: relative;
  padding: 8px !important;
`);

export const ExtendedImageWrapper = React.memo(styled.div`
  height: 144px;
  width: 100%;
`);

export const ExtendedTagsWrapper = React.memo(styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 8px 0px;

  &.bottom-tags {
    position: absolute;
    bottom: 65px;
  }
`);

export const DiscoverTag = React.memo(styled.div`
  border-radius: 15px;
  background-color: #dedede;
  font-size: 11px;
  padding: 2px 6px;
  margin-right: 8px;
  margin-bottom: 8px;
`);

export const VideoTag = React.memo(styled(DiscoverTag)``);

export const CoverageTag = React.memo(styled.div`
  border-radius: 15px;
  font-size: 11px;
  padding: 1px 5px;
  margin-right: 8px;
  margin-bottom: 8px;
  border: 1px solid #7d7d7d;
`);

export const ExtendedEntryHeader = React.memo(styled.div`
  font-weight: 700;
  margin: 8px;

  span {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`);

export const ExtendedEntryDescription = React.memo(styled.div`
  color: #7d7d7d;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0 8px;
  line-height: 28px;
`);

export const ChevronRight = React.memo(styled(ChevronRightIcon)`
  margin-left: 5px;
  margin-top: 1px;
  margin-right: -5px;

  path {
    fill: #ffffff;
  }
`);

export const ChevronRightDark = React.memo(styled(ChevronRightIcon)`
  margin-left: 5px;
  margin-top: 1px;
  margin-right: -2px;

  path {
    fill: #2d2d2d;
  }
`);

export const ExtendedEntryButton = React.memo(styled(Button)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background-color: #464646 !important;

  &:hover {
    background-color: #7d7d7d !important;
  }
`);

export const ModalWrapper = React.memo(styled(Modal)`
  max-width: 800px;
  margin: 200px auto;

  .ap-modal-header {
    padding: 56px 56px 0;
    font-weight: 700;
    font-size: 21px;
    border-bottom: 0;
  }

  .ap-modal-body {
    padding: 20px 56px 56px;
    color: #7d7d7d;
    font-size: 16px;
    line-height: 28px;
  }

  .ap-modal-footer {
    border-top: 0;
    height: 80px;
    padding: 20px 56px;

    button {
      background-color: #464646 !important;

      &:hover {
        background-color: #7d7d7d !important;
      }
    }
  }
`);

export const ButtonHoverPointer = styled(Button)`
  &:hover {
    cursor: pointer !important;
  }
`;
