import React, { useContext } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  Area,
  ZAxis,
  PolarAngleAxis,
  Radar,
  PolarRadiusAxis,
  PolarGrid,
  RadialBar,
  LabelList,
  Brush,
  Label,
  Cell,
  ErrorBar,
  Cross,
  Curve,
  Dot,
  Polygon,
  Rectangle,
  Sector,
  ReferenceLine
} from 'recharts';
import PropTypes from 'prop-types';
import { DashboardContext } from '../DashboardContext';
import useAddFilterHandler from '../util/useAddFilterHandler';

const AvailableComponents = {
  xaxis: XAxis,
  yaxis: YAxis,
  zaxis: ZAxis,
  cartesianGrid: CartesianGrid,
  tooltip: Tooltip,
  legend: Legend,
  line: Line,
  bar: Bar,
  brush: Brush,
  area: Area,
  referenceLine: ReferenceLine,

  // Radar & Radical Bar Chart
  polarGrid: PolarGrid,
  polarAngleAxis: PolarAngleAxis,
  polarRadiusAxis: PolarRadiusAxis,
  radar: Radar,
  radialBar: RadialBar,

  // Child Components
  cell: Cell,
  errorBar: ErrorBar,
  label: Label,
  labelList: LabelList,
  cross: Cross,
  curve: Curve,
  dot: Dot,
  polygon: Polygon,
  rectangle: Rectangle,
  sector: Sector
};

const RechartsComponents = (childComponents, parentData) => {
  const { dataFilters, setDataFilters } = useContext(DashboardContext);
  const addFilterHandler = useAddFilterHandler(dataFilters, setDataFilters);

  if (childComponents == null) {
    return null;
  }

  return childComponents.map((child, index) => {
    const Component = AvailableComponents[child.type];

    if (Component == null) return null;

    if (parentData && child && ['bar', 'radialBar'].includes(child.type)) {
      addFilterHandler({ dataFilters, setDataFilters, element: child, elementData: parentData });
    }

    if (child.children) {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Component key={index} {...child.properties}>
          {RechartsComponents(child.children)}
        </Component>
      );
    }

    if (child?.properties?.numberFormat) {
      const formatProps = { ...child.properties.numberFormat };
      delete formatProps.locale;

      const formatter = value =>
        new Intl.NumberFormat(child.properties.numberFormat?.locale ?? 'nl-NL', formatProps).format(value);

      // the X and Y Axis can have the values formatted by a different property than other elements, so check type.
      if (child.type === 'xaxis' || child.type === 'yaxis') child.properties.tickFormatter = formatter;
      else child.properties.formatter = formatter;
    }

    // eslint-disable-next-line react/no-array-index-key
    return <Component key={index} {...child.properties} />;
  });
};

RechartsComponents.propTypes = {
  childComponents: PropTypes.arrayOf(PropTypes.object).isRequired,
  parentData: PropTypes.objectOf(PropTypes.any)
};

export default RechartsComponents;
