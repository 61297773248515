import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { CalendarPicker } from '@appkit4/react-components/datepicker';
// TODO: check build error "Dependency cycle via..."
// so we can remove next eslint line.
// eslint-disable-next-line import/no-cycle
import { dateToString } from '../helpers';
import { isStringDate } from '../propTypes';
import { validateAndParseDate } from '../../../../util/validateAndParseDate';

const DatePicker = ({ field, value, onChange }) => {
  const { id, maxDate, minDate } = field;
  const dateValue = value === 'today' ? new Date() : new Date(value);

  return (
    <div className="timepicker-container">
      <CalendarPicker
        locale={i18n.language}
        format="DD-MMM-YYYY"
        value={dateValue}
        onChange={value => onChange(id, dateToString(value))}
        minDate={validateAndParseDate(minDate)}
        maxDate={validateAndParseDate(maxDate)}
      />
    </div>
  );
};

DatePicker.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minDate: PropTypes.string,
    maxDate: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: isStringDate
};

export default DatePicker;
