import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Notification } from '@appkit4/react-components/notification';
import { Button } from '@appkit4/react-components/button';
import { Progress } from '@appkit4/react-components/progress';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../../context';
import {
  errorStatus,
  getErrorMessage,
  getStatusUpdate,
  progressSteps,
  TRANSFORMATION_STATUS
} from '../../util/progressBar';
import { dots } from '../../styles/LoadingElements';
import HandleLogs from './handleLogs';

const ProgressWrapper = React.memo(styled.div`
  width: 100%;
  > div.actions {
    display: flex;
    align-items: center;
    margin-top: 50px;
    > div.a-alert-wrapper {
      display: flex;
      flex: 1;
      margin: 0;
      box-shadow: none;
      .a-alert {
        width: 100%;
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    > div:not(.a-alert-wrapper) {
      margin-left: auto;
      > button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  > .ap-progress-wrapper {
    margin: 30px 20px;
    .ap-progress-bar {
      transition: width 0.6s ease-in-out;
    }
    .ap-progress-text > div:not(:nth-child(${TRANSFORMATION_STATUS.DONE + 1})):nth-child(${props => props.step + 1}) {
      ${props => props.hasError && 'color: red;'}
      flex-direction: row;
      &:after {
        ${props => !props.hasError && `content: '.';`}
        margin-left: 2px;
        animation: ${dots} 2s steps(1, end) infinite;
      }
    }
  }
`);

const HandleProgress = ({
  handleName,
  handleId,
  status,
  error,
  isHandleSide,
  pauseAtDashboardReady,
  isDemo = false
}) => {
  const { handleLogs, requestId, downloads, availableDownloads } = useContext(QSFContext);
  const [activeStep, setActiveStep] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [logsVisible, setLogsVisible] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();

  const handleDownload = useMemo(() => {
    return downloads[handleId] || {};
  }, [downloads, handleId]);

  const availableDownload = useMemo(() => {
    return availableDownloads[handleId]?.find(download => download.reference === downloads[handleId]?.reference) ?? {};
  }, [availableDownloads, handleId, downloads]);

  useEffect(() => {
    const path = !isDemo
      ? `/handles/${handleId}/${handleName}/dashboard/${handleDownload.reference}`
      : `/discover/${handleId}/${handleName}/demo?tab=dashboard&demoReference=${handleDownload.reference}`;

    if (!hasError && activeStep === 3 && availableDownload.dashboardData && (isHandleSide || !pauseAtDashboardReady)) {
      localStorage.removeItem(`${handleId}-dashboard-update-reference`);
      history.push(path);
    }
  }, [
    isDemo,
    hasError,
    activeStep,
    availableDownload,
    handleId,
    handleName,
    handleDownload?.reference,
    history,
    isHandleSide,
    pauseAtDashboardReady
  ]);

  // Update active step according to current status
  useEffect(() => {
    // Check for current requestId to match with the requestId of the status update to avoid other updates.
    if (handleDownload?.requestId === undefined || handleDownload?.requestId === requestId) {
      setActiveStep(getStatusUpdate(status));
    }
  }, [activeStep, handleDownload, requestId, status, downloads]);

  // Reset variables when a different handle gets mounted.
  useEffect(() => {
    setActiveStep(0);
    setHasError(false);
  }, [requestId]);

  // Show error message if the error property changes.
  useEffect(() => {
    // If the requestId is undefined, it means that the files were invalid for upload
    // Check for current requestId to match with the requestId of the status update to avoid other updates.
    if (handleDownload?.requestId === undefined || handleDownload?.requestId === requestId) {
      setHasError(!!error || errorStatus.includes(status));
    }
  }, [error, handleDownload, hasError, requestId, status]);

  // const currentPercent = (100 / (progressSteps(t).length - 1)) * activeStep;
  const getSteps = () =>
    progressSteps(t).map((step, index) => {
      if (activeStep === index) return step.busy;
      return activeStep > index ? step.after : step.before;
    });

  const filteredLogs = handleLogs?.filter(l => l.handleId === handleId && l.requestId === requestId) ?? [];

  return (
    <ProgressWrapper step={activeStep} hasError={hasError}>
      <Progress style={{ minWidth: '360px', width: '100%' }} readonly activeIndex={activeStep} steps={getSteps()} />
      <div className="actions">
        {hasError && (
          <Notification
            title=""
            message={getErrorMessage(handleName, status, error, t)}
            closeable={false}
            status="error"
          />
        )}
        <div>
          {filteredLogs.length > 0 && (
            <Button className="a-btn-md" onClick={() => setLogsVisible(!logsVisible)} data-test-label="logs-btn">
              <span className="Appkit4-icon icon-comment-outline" />
              {logsVisible ? t('button.hideLogs') : t('button.showLogs')}
            </Button>
          )}
        </div>
      </div>
      {logsVisible && <HandleLogs logs={filteredLogs} />}
    </ProgressWrapper>
  );
};

HandleProgress.defaultProps = {
  isHandleSide: false
};

HandleProgress.propTypes = {
  handleName: PropTypes.string.isRequired,
  handleId: PropTypes.string.isRequired,
  status: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  isHandleSide: PropTypes.bool
};

export default HandleProgress;
