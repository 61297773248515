import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import {
  STATUS_ASSEMBLING_ERROR,
  STATUS_ASSEMBLING_SUCCESS,
  STATUS_DONE,
  STATUS_FORM_REQUIRED,
  STATUS_IDLE,
  STATUS_INVALID,
  STATUS_PROCESSING,
  STATUS_REJECTED,
  STATUS_TRANSFORMATION_ERROR,
  STATUS_TRANSFORMATION_SUCCESS,
  STATUS_VALIDATION_ERROR,
  STATUS_VALIDATION_INVALID,
  STATUS_VALIDATION_VALID,
  QSF_COUNTRY_CODE
} from '../config';
import { padNumber } from '../util/padNumber';
import { getAllFilters, requestUserData, requestConfiguration } from '../util/requests';
import { initSocketListeners, disconnect } from './events';
import { handleAvailableDownloads } from './handlers/handleAvailableDownloads';
import { handleDownloads } from './handlers/handleDownloads';
import { transformResults } from './handlers/transformResults';

const defaultObject = {};
const MODEL_3_5 = 'gpt-35-turbo-16k';

export const ContextProvider = props => {
  const { children } = props;
  const [connectionId, setConnectionId] = useState('');
  const [requestId, setRequestId] = useState('');
  const [downloads, setDownloads] = useState(defaultObject);
  const [status, setStatus] = useState(STATUS_IDLE);
  const [transformedResults, setTransformedResults] = useState(defaultObject);
  const [availableDownloads, setAvailableDownloads] = useState(defaultObject);
  const [error, setError] = useState(null);
  const [handleLogs, setHandleLogs] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(defaultObject);
  const [filterDrawerVisible, setFilterDrawerVisible] = useState(false);
  const [filtersMyApps, setFiltersMyApps] = useState([]);
  const [filtersDiscoverApps, setFiltersDiscoverApps] = useState([]);
  const [allFilters, setAllFilters] = useState({});
  const [isPwCUser, setIsPwCUser] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [environment, setEnvironment] = useState('');
  const [configuration, setConfiguration] = useState([]);
  const [subscriptionsSelected, setSubscriptionsSelected] = useState([]);
  const [assistantMessages, setAssistantMessages] = useState([]);
  const [chatModel, setChatModel] = useState(MODEL_3_5);

  const resetTransformedResults = useCallback(() => {
    setTransformedResults(defaultObject);
    setDownloads(defaultObject);
  }, [setTransformedResults]);

  const startHandleUpload = useCallback(
    handle => {
      setStatus(STATUS_PROCESSING);
      setDownloads(downloads => ({
        ...downloads,
        [handle]: { handle, status: 'unprocessed' }
      }));
    },
    [setStatus, setDownloads]
  );

  const state = useMemo(
    () => ({
      availableDownloads,
      connectionId,
      requestId,
      downloads,
      status,
      transformedResults,
      error,
      handleLogs,
      setHandleLogs,
      updateStatus,
      setUpdateStatus,
      filterDrawerVisible,
      setFilterDrawerVisible,
      filtersMyApps,
      setFiltersMyApps,
      filtersDiscoverApps,
      setFiltersDiscoverApps,
      allFilters,
      isPwCUser,
      setIsPwCUser,
      permissions,
      setPermissions,
      environment,
      setEnvironment,
      configuration,
      setConfiguration,
      subscriptionsSelected,
      setSubscriptionsSelected,
      assistantMessages,
      setAssistantMessages,
      chatModel,
      setChatModel,

      resetTransformedResults,
      setStatus,
      setError,
      setRequestId,
      setConnectionId,
      setAvailableDownloads,
      startHandleUpload
    }),
    [
      availableDownloads,
      connectionId,
      requestId,
      downloads,
      status,
      transformedResults,
      error,
      handleLogs,
      setHandleLogs,
      filterDrawerVisible,
      setFilterDrawerVisible,
      filtersMyApps,
      setFiltersMyApps,
      filtersDiscoverApps,
      setFiltersDiscoverApps,
      allFilters,
      isPwCUser,
      setIsPwCUser,
      permissions,
      setPermissions,
      environment,
      setEnvironment,
      configuration,
      setConfiguration,
      subscriptionsSelected,
      setSubscriptionsSelected,
      assistantMessages,
      setAssistantMessages,
      chatModel,
      setChatModel,

      resetTransformedResults,
      setStatus,
      setError,
      setRequestId,
      setConnectionId,
      setAvailableDownloads,
      startHandleUpload,
      updateStatus,
      setUpdateStatus
    ]
  );

  const setters = useMemo(
    () => ({
      availableDownloads: message =>
        setTimeout(() => setAvailableDownloads(s => handleAvailableDownloads(s, message)), 200),
      setConnectionId: newConnectionId => setConnectionId(connectionId || newConnectionId),
      setRequestId,
      transformFiles: message => {
        setStatus(STATUS_PROCESSING);
        setDownloads(s => {
          const downloads = handleDownloads(s, message);
          setTransformedResults(s => {
            const transformed = transformResults(downloads);
            if (
              Object.values(downloads).every(d =>
                [
                  STATUS_REJECTED,
                  STATUS_VALIDATION_ERROR,
                  STATUS_VALIDATION_VALID,
                  STATUS_VALIDATION_INVALID,
                  STATUS_INVALID,
                  STATUS_FORM_REQUIRED,
                  STATUS_TRANSFORMATION_SUCCESS,
                  STATUS_TRANSFORMATION_ERROR,
                  STATUS_ASSEMBLING_SUCCESS,
                  STATUS_ASSEMBLING_ERROR
                ].includes(d.status)
              )
            ) {
              setStatus(STATUS_DONE);
            }
            return transformed;
          });

          return downloads;
        });
      },
      logging: message => {
        const now = new Date();
        const formattedTime = [now.getHours(), now.getMinutes(), now.getSeconds()].map(padNumber).join(':');
        setHandleLogs(currentState => [
          ...currentState,
          {
            requestId: message.response.requestId,
            handleId: message.response.handleId,
            handleName: message.response.handleName,
            time: formattedTime,
            message: message.response.message
          }
        ]);
      },
      handleDataUpdate: message =>
        setUpdateStatus(current => {
          return {
            ...current,
            [message.response.handleId]: message.response
          };
        })
    }),
    [setAvailableDownloads, setConnectionId, setRequestId, connectionId, setStatus, setDownloads, setUpdateStatus]
  );

  useEffect(() => {
    getAllFilters().then(res => setAllFilters(res.data));
  }, []);

  useEffect(() => {
    requestUserData().then(res => {
      if (res.data?.isInternal) {
        setIsPwCUser(true);
      }
      setPermissions(res.data?.permissions || []);
    });
  }, []);

  useEffect(() => {
    setEnvironment(QSF_COUNTRY_CODE);
  }, []);

  useEffect(() => {
    requestConfiguration().then(res => setConfiguration(res.data));
  }, []);

  useEffect(() => {
    initSocketListeners(setters);

    return () => {
      disconnect();
    };

    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line react/jsx-filename-extension
  return <QSFContext.Provider value={state}>{children}</QSFContext.Provider>;
};

export const QSFContext = createContext({
  availableDownloads: defaultObject,
  connectionId: '',
  requestId: '',
  downloads: defaultObject,
  status: 'idle',
  transformedResults: defaultObject,
  updateStatus: defaultObject,
  error: null,
  handleLogs: null,
  filterDrawerVisible: false,
  filtersMyApps: [],
  filtersDiscoverApps: [],
  allFilters: [],
  isPwCUser: false,
  permissions: [],
  environment: '',
  configuration: defaultObject,
  subscriptionsSelected: [],
  assistantMessages: [],
  chatModel: MODEL_3_5,

  startHandleUpload: handle => null,
  resetTransformedResults: () => null,
  setStatus: () => null,
  setError: () => null,
  setRequestId: () => null,
  setConnectionId: () => null,
  setAvailableDownloads: () => null,
  setHandleLogs: () => null,
  setUpdateStatus: () => null,
  setFilterDrawerVisible: () => null,
  setFiltersMyApps: () => null,
  setFiltersDiscoverApps: () => null,
  setIsPwCUser: () => null,
  setPermissions: () => null,
  setEnvironment: () => null,
  setConfiguration: () => null,
  setSubscriptionsSelected: () => null,
  setAssistantMessages: () => null,
  setChatModel: () => null
});
