import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@appkit4/react-components/select';
import { useTranslation } from 'react-i18next';

const Selector = ({ value, field, onChange }) => {
  const { t } = useTranslation();
  const { id, options, searchable, hasEmptyOption = false } = field;
  const canSearch = searchable == null ? true : searchable;

  const newOptions = hasEmptyOption
    ? [{ value: '', label: `— ${t('form.field.chooseOption')} —` }, ...options]
    : options;

  return (
    <Select
      data={newOptions}
      value={value}
      searchable={canSearch}
      dropdownRenderMode="portal"
      onSelect={value => onChange(id, value)}
      placeholder={`— ${t('form.field.chooseOption')} —`}
    />
  );
};

Selector.propTypes = {
  value: PropTypes.string.isRequired,
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    ).isRequired,
    searchable: PropTypes.bool,
    hasEmptyOption: PropTypes.bool
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default Selector;
