import PropTypes from 'prop-types';
import { FIELD } from '../enums';

export const FieldProps = Object.freeze({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.keys(FIELD).map(key => FIELD[key])).isRequired,
  required: PropTypes.bool,
  visible: PropTypes.bool,
  visibleIf: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string
});
