import {
  Select,
  Hidden,
  Input,
  Boolean,
  TextArea,
  Number,
  SingleChoice,
  MultipleChoice,
  DatePicker,
  FileUploader,
  SelectPredefined,
  SelectMultiple,
  Html,
  Switch,
  Slider,
  Tags,
  Tabular,
  Group,

  // workflow fields
  MinParticipants,
  MinParticipantsPercent,
  Participants,
  RunAfterDateTime,
  AndOr,
  MultipleChoiceSelector
} from '../fields';
import { FIELD } from './field';

export const COMPONENT = Object.freeze({
  [FIELD.INPUT]: Input,
  [FIELD.HIDDEN]: Hidden,
  [FIELD.TEXTAREA]: TextArea,
  [FIELD.NUMBER]: Number,
  [FIELD.SELECT]: Select,
  [FIELD.SELECT_MULTIPLE]: SelectMultiple,
  [FIELD.SELECT_PREDEFINED]: SelectPredefined,
  [FIELD.DATE]: DatePicker,
  [FIELD.SINGLE_CHOICE]: SingleChoice,
  [FIELD.MULTIPLE_CHOICE]: MultipleChoice,
  [FIELD.BOOLEAN]: Boolean,
  [FIELD.FILE_UPLOAD]: FileUploader,
  [FIELD.TABULAR]: Tabular,
  [FIELD.SWITCH]: Switch,
  [FIELD.SLIDER]: Slider,
  [FIELD.TAGS]: Tags,
  [FIELD.HTML]: Html,
  [FIELD.GROUP]: Group,

  // workflow fields
  [FIELD.MIN_PARTICIPANTS]: MinParticipants,
  [FIELD.MIN_PARTICIPANTS_PERCENT]: MinParticipantsPercent,
  [FIELD.PARTICIPANTS]: Participants,
  [FIELD.RUN_AFTER_DATETIME]: RunAfterDateTime,
  [FIELD.AND_OR]: AndOr,
  [FIELD.MULTIPLE_CHOICE_SELECTOR]: MultipleChoiceSelector
});
