import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../../../../context';

const UploadDrop = () => {
  const { t } = useTranslation();

  return (
    <div className="a-upload">
      <div className="a-upload-drag-zone">
        <div className="a-upload-ajax-context">
          <div className="upload-drop-small">
            <span className="upload-drop-span">{t('uploader.dragDrop')}</span>
            <span className="upload-browse-span">&nbsp;{t('uploader.chooseFiles')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const StoredUploads = props => {
  const { files, onDelete } = props;
  return (
    <div className="a-upload">
      <div className="a-upload-file-list multiple" data-test-label="upload-file-list">
        {(files || []).map(file => {
          if (!file.name) return null;
          return (
            <div key={`${file.name}-${file.lastModified}`}>
              <span className="fileName" title={file.name}>
                {file.name}
              </span>
              <span
                onClick={() => onDelete(file)}
                tabIndex="0"
                className="Appkit4-icon icon-circle-delete-outline a-keyboard-hover-only-div"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

// AppKit Upload component doesn't play nice with what we want (display/delete files from local storage as well).
// So we just create our own dropfiles component and rip the styling from AppKit Upload
const FileUploader = ({ field, value, onChange }) => {
  const { configuration } = useContext(QSFContext);
  const { validFileExtensions = [] } = configuration;
  const { id, multiple } = field;
  const multiUpload = multiple == null ? true : multiple;

  // eslint-disable-next-line prefer-template
  const validFileExtensionsFormatted = validFileExtensions.reduce((acc, ext) => acc + `.${ext},`, '');

  const onDrop = useCallback(
    acceptedFiles => {
      const files = [...value];
      if (!multiUpload) {
        if (files.length >= 1) {
          files.splice(0, files.length);
        }
      }
      acceptedFiles.forEach(file => {
        const exist = files.find(f => f.name === file.name && f.lastModified === file.lastModified);
        if (exist == null) {
          files.push(file);
        }
      });
      onChange(id, files);
    },
    [id, onChange, value, multiUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: multiUpload,
    accept: validFileExtensionsFormatted
  });
  const onRemoveFile = file => {
    const files = [...value];
    const removeAt = value.indexOf(file);
    files.splice(removeAt, 1);
    onChange(id, files);
  };

  return (
    <div style={{ width: '100%' }}>
      <div {...getRootProps()}>
        <input {...getInputProps()} multiple={multiUpload} />
        <UploadDrop />
      </div>
      <StoredUploads files={value} onDelete={onRemoveFile} />
    </div>
  );
};

FileUploader.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    multiple: PropTypes.bool
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default FileUploader;
