const useAddFilterHandler = (dataFilters, setDataFilters) => {
  const addFilterHandler = ({ element, elementData }) => {
    const elementProps = element.properties;

    if (!elementProps?.dataFilter) return;

    const filterProps = { ...elementProps.dataFilter };
    if (!filterProps?.data || !filterProps?.dataKey || !filterProps?.targetKey) return;

    const currentDataFilters = { ...dataFilters };

    // Initialize filters if not set
    if (typeof currentDataFilters[filterProps.data] !== 'object') {
      currentDataFilters[filterProps.data] = {};
    }
    if (!Array.isArray(currentDataFilters[filterProps.data][filterProps.targetKey])) {
      currentDataFilters[filterProps.data][filterProps.targetKey] = filterProps?.active ?? [];
    }

    const filters = currentDataFilters[filterProps.data][filterProps.targetKey];

    elementProps.onClick = data => {
      const value = data.payload?.[filterProps.dataKey];
      if (!value) return;

      let newFilters;
      if (filters.includes(value)) {
        newFilters = filters.filter(item => item !== value);
      } else {
        newFilters = filterProps?.single ? [value] : [...filters, value];
      }

      setDataFilters(prev => ({
        ...prev,
        [filterProps.data]: {
          ...prev[filterProps.data],
          [filterProps.targetKey]: newFilters
        }
      }));
    };

    // Update element children for visual feedback
    if (filterProps?.fill || filterProps?.stroke) {
      element.children = [
        ...(element.children?.filter(child => child.type !== 'cell') ?? []),
        ...(elementData.map(entry => {
          const cellProps = { type: 'cell', properties: { cursor: 'pointer' } };
          if (filters.includes(entry[filterProps.dataKey])) {
            if (entry.fill || elementProps?.fill)
              cellProps.properties.fill = filterProps.fill ?? entry.fill ?? elementProps?.fill;
            if (entry.stroke || elementProps?.stroke)
              cellProps.properties.stroke = filterProps.stroke ?? entry.stroke ?? elementProps?.stroke;
          }
          return cellProps;
        }) ?? [])
      ];
    }
  };

  return addFilterHandler;
};

export default useAddFilterHandler;
