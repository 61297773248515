import React, { useRef } from 'react';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Button } from '@appkit4/react-components/button';
import PropTypes from 'prop-types';

const CopyToClipboard = ({ children, tooltipPosition = 'left' }) => {
  const contentRef = useRef(null);

  const handleCopyClick = () => {
    const text = contentRef.current.innerText;

    navigator.clipboard.writeText(text).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  return (
    <div>
      <Tooltip
        placement={tooltipPosition}
        tooltipTheme="light"
        mouseLeaveDelay={5000}
        content={
          <Button kind="transparent" onClick={handleCopyClick}>
            Copy to clipboard
          </Button>
        }
      >
        <div ref={contentRef} className="docqa-info-tooltip">
          {children}
        </div>
      </Tooltip>
    </div>
  );
};

CopyToClipboard.propTypes = {
  children: PropTypes.node,
  tooltipPosition: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end'
  ])
};

export default CopyToClipboard;
