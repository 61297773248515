import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appkit4/react-components/button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styledLink } from '../../buttons/allResults/AllResults';

const Dashboard = ({ handleId, handleName, reference, disabled, isDemo }) => {
  const { t } = useTranslation();

  if (disabled) {
    return (
      !isDemo && (
        <Button style={{ marginLeft: '10px' }} data-test-label="dashboard-btn" disabled compact>
          {t('button.dashboard')}
        </Button>
      )
    );
  }

  const path = !isDemo
    ? `/handles/${handleId}/${handleName}/dashboard/${reference}`
    : `/discover/${handleId}/${handleName}/demo?tab=dashboard&demoReference=${reference}`;

  return (
    <Link style={styledLink} to={path}>
      <Button data-test-label="dashboard-btn" compact>
        {t('button.dashboard')}
      </Button>
    </Link>
  );
};

Dashboard.propTypes = {
  handleId: PropTypes.string.isRequired,
  handleName: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired
};

export default Dashboard;
