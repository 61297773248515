import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { sanitizer } from '../util';
import {
  ExtendedTileWrapper,
  ExtendedImageWrapper,
  ExtendedTagsWrapper,
  VideoTag,
  ExtendedEntryHeader,
  ExtendedEntryDescription,
  EntryText,
  ExtendedEntryButton,
  ChevronRight
} from '../styles/GeneralElements';
import AppImage from './image/AppImage';

const VideoAppTile = ({ handle }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id, name, singleLineDescription, tags } = handle;

  const onClick = () => history.push(`/videos/${id}/${name}`);

  return (
    <ExtendedTileWrapper className="ap-panel a-shadow-sm" data-test-label={id}>
      <ExtendedImageWrapper>
        <AppImage id={id} name={name} />
      </ExtendedImageWrapper>
      <EntryText>
        <ExtendedEntryHeader data-test-label={`${id}-header`}>
          <span>{name}</span>
        </ExtendedEntryHeader>
        <ExtendedEntryDescription
          data-test-label={`${id}-description`}
          dangerouslySetInnerHTML={{ __html: sanitizer(singleLineDescription) }}
        />
      </EntryText>
      {tags?.length > 0 && (
        <ExtendedTagsWrapper className="bottom-tags">
          {tags.map(tag => (
            <VideoTag key={tag}>{t(`filters.tags.${tag}`)}</VideoTag>
          ))}
        </ExtendedTagsWrapper>
      )}
      <ExtendedEntryButton className="a-btn-md" onClick={onClick}>
        {t('handle.episodesInfo')} <ChevronRight size={14} />
      </ExtendedEntryButton>
    </ExtendedTileWrapper>
  );
};

VideoAppTile.propTypes = {
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    singleLineDescription: PropTypes.string.isRequired,
    handleType: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default VideoAppTile;
