import React from 'react';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';

export const DiscoverAppLayout = React.memo(styled.div`
  flex: 1;
`);

export const HeaderWrapper = React.memo(styled.div`
  padding: 20px;
  display: flex;
  align-content: space-around;
  background-color: #ffffff;
`);

export const TitleWrapper = React.memo(styled.div`
  flex-grow: 1;
  display: flex;
`);

export const ImageLogoWrapper = React.memo(styled.div`
  width: 160px;
  height: 160px;
  border-radius: 4px;
`);

export const TitleApp = React.memo(styled.div`
  margin-left: 40px;
  font-size: 36px;
  font-weight: 300;
`);

export const Actions = React.memo(styled.div`
  display: flex;
  align-items: flex-end;
`);

export const ButtonDemo = React.memo(styled(Button)`
  margin-right: 24px;
  background-color: #fff !important;
  color: #2d2d2d !important;
  border: 1px solid #2d2d2d;

  &:hover {
    background-color: #efefef !important;
  }
`);

export const CloseDemoIcon = React.memo(styled.span`
  font-size: 9px !important;
  margin-left: 6px;
  margin-right: 0px !important;
`);

export const DemoFormLayout = React.memo(styled.div`
  flex: 1;
`);

export const DemoInfoWrapper = React.memo(styled.div`
  margin-bottom: 20px;
`);

export const DemoInfoHeader = React.memo(styled.div`
  padding: 8px 24px;
  background-color: #25b4e8;
  color: #fff;
`);

export const DemoInfoTitle = React.memo(styled.span`
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
`);

export const DemoInfoBody = React.memo(styled.div`
  background-color: #e4f5fc;
  padding: 24px;
  border: 1px solid #27b4e8;
  color: #27b4e8;
`);

export const ButtonAccess = React.memo(styled(Button)`
  background-color: #464646 !important;

  &:hover {
    background-color: #7d7d7d !important;
  }
`);

export const InfoWrapper = React.memo(styled.div`
  display: flex;
  margin-top: 20px;

  @media (max-width: 1550px) {
    flex-direction: column;
  }
`);

export const MainInfo = React.memo(styled.div`
  width: 60%;
  background: #fff;
  padding: 56px;
  font-size: 16px;
  line-height: 28px;
  margin-right: 32px;
  color: #464646;

  h4 {
    font-size: 21px;
    margin-bottom: 28px;
  }

  ul {
    list-style: disc inside;
    margin-left: 20px;
    margin-bottom: 0px;

    li {
      list-style: inherit;
    }
  }

  ol {
    list-style: lower-roman inside;
    margin-left: 20px;
    margin-bottom: 0px;

    li {
      list-style: inherit;
    }
  }

  @media (max-width: 1550px) {
    width: 100%;
    margin-bottom: 20px;
  }
`);

export const TabsInfo = React.memo(styled.div`
  width: 40%;
  background: #fff;
  font-size: 16px;

  h4 {
    font-size: 21px;
    margin-bottom: 10px;
  }

  .ap-tabset-toggle-wrapper {
    bottom: -13px;
    width: 100%;
    .ap-tabset-toggle {
      margin-right: 3px;

      &.active {
        padding-bottom: 18px;
      }
    }
  }

  .ap-tabset-content {
    padding: 10px 32px;
  }

  @media (max-width: 1550px) {
    width: 100%;
  }
`);

export const TextBlockInfo = React.memo(styled.div`
  margin: 4px;
`);
