import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appkit4/react-components/button';
import { BASE_URL } from '../../../config';

const DownloadButton = ({ downloadReference }) => {
  return (
    <Button data-test-label="download-btn">
      <a
        href={`${BASE_URL}/api/download-files/${downloadReference}`}
        style={{ color: 'white', textDecoration: 'none' }}
        download
      >
        <span className="Appkit4-icon icon-download-fill" style={{ margin: 0 }} />
      </a>
    </Button>
  );
};

DownloadButton.propTypes = {
  downloadReference: PropTypes.string.isRequired
};

export default DownloadButton;
