import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pickBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FIELD } from '../enums';
import { ARRAY_TYPES } from '../helpers';
import { FieldProps } from '../propTypes';
import TextElements from './TextElements';

const CustomError = styled.div`
  color: red;
`;

const FieldWrapper = ({ field, errors, register, unregister, children }) => {
  const { t } = useTranslation();
  const { id, type, required, title, subtitle, text, tooltip, errorMessage, regex } = field;
  const textElements = pickBy({ title, subtitle, text });

  useEffect(() => {
    // HTML field doesn't require input and thus doesn't need to be registered
    if (type === FIELD.HTML) return () => {};

    let fieldInfo = { name: id, type: 'custom', message: errorMessage };
    const validate = { required };

    // Input fields can have a regex to validate user input against
    if (type === FIELD.INPUT && regex) {
      const regExp = new RegExp(regex);
      validate.validate = value => regExp.test(value);
    }

    // Workflow participants list must contains at least one row
    if (type === FIELD.PARTICIPANTS) {
      validate.validate = value => value?.length > 0;
      fieldInfo = { ...fieldInfo, message: t('workflow.form.needParticipant') };
    }

    // Workflow participants must be larger than zero
    if (type === FIELD.MIN_PARTICIPANTS || type === FIELD.MIN_PARTICIPANTS_PERCENT) {
      validate.validate = value => value > 0;
      fieldInfo = { ...fieldInfo, message: t('workflow.form.zeroParticipants') };
    }

    // Workflow run at date must be in the future
    if (type === FIELD.RUN_AFTER_DATETIME) {
      validate.validate = value => value > Date.now();
      fieldInfo = { ...fieldInfo, message: t('workflow.form.invalidDate') };
    }

    // Array fields should be considered as empty when their value is an empty array
    if (ARRAY_TYPES.includes(type) && required) {
      validate.validate = value => value?.length > 0;
      fieldInfo = { ...fieldInfo, message: t('form.field.required') };
    }

    if (type === FIELD.TABULAR) {
      const withRegex = field.columns.filter(col => col.regex != null).map(col => col.id);
      const required = field.columns.filter(col => col.required === true).map(col => col.id);

      validate.validate = value => {
        // check if fields with regex are all valid
        let i;
        for (i = 0; i < withRegex.length; i++) {
          const columnId = withRegex[i];
          const column = field.columns.find(c => c.id === columnId);
          const regExp = new RegExp(column.regex);
          if (!value.map(row => row[columnId]).every(v => regExp.test(v))) {
            return false;
          }
        }

        // check if required fields are all filled
        let k;
        for (k = 0; k < required.length; k++) {
          const columnId = required[k];
          const column = field.columns.find(c => c.id === columnId);
          const allFilled = value
            .map(row => row[columnId])
            .every(v => (ARRAY_TYPES.includes(column.type) ? v?.length > 0 : v !== ''));
          if (!allFilled) {
            return false;
          }
        }

        return true;
      };
      fieldInfo = { ...fieldInfo, message: t('form.field.tableError') };
    }

    if (type === FIELD.GROUP) {
      const withRegex = field.fields.filter(col => col.regex != null).map(col => col.id);
      const required = field.fields.filter(col => col.required === true).map(col => col.id);

      validate.validate = value => {
        let i;
        for (i = 0; i < value.length; i++) {
          const group = value[i];
          let k;
          for (k = 0; k < Object.keys(group).length; k++) {
            const fieldId = Object.keys(group)[k];

            const isRequired = required.includes(fieldId);
            const hasRegex = withRegex.includes(fieldId);
            const groupField = field.fields.find(f => f.id === fieldId);

            if (hasRegex) {
              const regExp = new RegExp(groupField.regex);
              const valid = regExp.test(group[fieldId]);
              if (!valid) return false;
            }

            if (isRequired) {
              const filled = ARRAY_TYPES.includes(groupField.type) ? group[fieldId]?.length > 0 : group[fieldId] !== '';
              if (!filled) return false;
            }
          }
        }
        return true;
      };
      fieldInfo = { ...fieldInfo, message: ' ' };
    }

    register(fieldInfo, validate);
    return () => unregister(id);
  }, [id, type, required, regex, register, unregister, errorMessage, field, t]);

  return (
    <div style={{ marginBottom: 20 }} data-test-label={id}>
      <TextElements textElements={textElements} id={id} tooltip={tooltip} required={required} />
      {children}
      {errors[id]?.type === 'required' && (
        <CustomError data-test-label="field-required-error">{t('form.field.required')}</CustomError>
      )}
      {errors[id]?.type === 'validate' && (
        <CustomError data-test-label="field-validation-error">
          {errors[id].ref?.message || t('form.field.formatError')}
        </CustomError>
      )}
    </div>
  );
};

FieldWrapper.propTypes = {
  field: PropTypes.shape(FieldProps).isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default FieldWrapper;
