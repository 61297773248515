import React from 'react';
import { Input } from '@appkit4/react-components/field';
import PropTypes from 'prop-types';

const Hidden = ({ field, value }) => {
  const { id } = field;

  return <Input type="hidden" id={id} value={value} defaultValue={value} name={id} style={{ display: 'none' }} />;
};

Hidden.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string
  }).isRequired,
  value: PropTypes.bool.isRequired
};

export default Hidden;
