import React, { useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { useTranslation } from 'react-i18next';
import { Container } from '../styles/GeneralElements';
import { APP_NAME } from '../config';
import { QSFContext } from '../context';
import Footer from '../footer/Footer';
import HeroImage from './hero-image.png';

const StyledPannel = styled.div`
  padding: 100px 60px 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: white;

  @media (max-width: 1350px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;

const TextContainer = styled.div`
  max-width: 460px;
  font-size: 16px;

  h1 {
    padding-bottom: 20px;
  }
  button {
    margin-top: 40px;
  }

  @media (max-width: 1350px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
`;

const UnavailablePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isPwCUser } = useContext(QSFContext);
  const goHome = useCallback(() => history.push('/'), [history]);

  const handleButtonClick = () => {
    window.open('https://eu.chat.pwc.com', '_blank');
  };

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="chatpwc">
      <span className="bc-text" tabIndex={2}>
        {t('menu.chatPwC')}
      </span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  return (
    <Container>
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <StyledPannel>
        <TextContainer>
          <h1 className="ap-typography-heading-l">New ChatPwC live</h1>
          <p className="ap-font-weight-1">
            Having been exclusively available to the Dutch firm for one and a half years, ChatPwC has been discontinued
            as of 27-02-2025. While ChatPwC has proven to be a pioneering application adopted by many colleagues, the
            new global ChatPwC is now live, offering enhanced functionality to assist you in your daily tasks.
          </p>

          <Button onClick={handleButtonClick}>Visit new ChatPwC</Button>
        </TextContainer>
        <div>
          <img src={HeroImage} alt="" height={450} />
        </div>
      </StyledPannel>
      <Footer />
    </Container>
  );
};

export default UnavailablePage;
