import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Button } from '@appkit4/react-components/button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DashboardContext } from '../DashboardContext';
import { requestHandleUpdateData, requestInteractiveDashboardOnlyUpdate } from '../../../util/requests';
import { QSFContext } from '../../../context';
import {
  DASHBOARD_NO_INPUT_REFERENCE,
  STATUS_ASSEMBLING_SUCCESS,
  INTERACTIVE_DASHBOARD_ONLY_REFERENCE,
  STATUS_VALIDATION_ERROR,
  STATUS_VALIDATION_INVALID
} from '../../../config';
import { FORMAT } from '../../forms/forms2.0/enums';
import { convertData } from '../../forms/forms2.0/helpers';
import { FieldProps } from '../../forms/forms2.0/propTypes';

const ReloadButton = ({ fields, handleSubmit, getValues, disabled, label }) => {
  const { t } = useTranslation();
  const [isFormLoading, setFormLoading] = useState(false);
  const { data, dataFilters, setDataFilters } = useContext(DashboardContext);
  const { handleId, reference } = useParams();
  const { connectionId, updateStatus, setUpdateStatus, setHandleLogs } = useContext(QSFContext);
  const status = updateStatus[handleId]?.status;
  const [didReload, setDidReload] = useState(false);

  // reset update status when unmounting, this prevents that 'requestHandleDashboardData' gets
  // triggered on a new handle run when going back between pages
  useEffect(() => () => setUpdateStatus({}), [setUpdateStatus]);

  const startDataUpdate = () => {
    setUpdateStatus({ ...updateStatus, [handleId]: { status: '' } });
    setHandleLogs([]);
    setFormLoading(true);
    if (connectionId !== '' && reference !== DASHBOARD_NO_INPUT_REFERENCE) {
      // Convert dates to epoch
      const data = convertData(fields, getValues(), FORMAT.EPOCH);

      // Transform any HTML content to use fixed dimensions
      if (data && typeof data === 'object') {
        Object.keys(data).forEach(key => {
          if (typeof data[key] === 'string' && data[key].includes('recharts-wrapper')) {
            // Match the properties in any order and extract the max dimensions
            const maxHeightMatch = data[key].match(/max-height:\s*(\d+)px/);
            const maxWidthMatch = data[key].match(/max-width:\s*(\d+)px/);

            if (maxHeightMatch && maxWidthMatch) {
              const height = maxHeightMatch[1];
              const width = maxWidthMatch[1];

              // Replace the entire style section with fixed dimensions
              data[key] = data[key].replace(
                /(width:\s*100%;\s*height:\s*100%|height:\s*100%;\s*width:\s*100%);\s*max-height:\s*\d+px;\s*max-width:\s*\d+px/g,
                `width: ${width}px; height: ${height}px`
              );
            }
          }
        });
      }

      if (reference === INTERACTIVE_DASHBOARD_ONLY_REFERENCE) {
        requestInteractiveDashboardOnlyUpdate(handleId, connectionId, reference, data).catch(e => {
          console.error('Error fetching interactive dashboard only update: ', e);
          setFormLoading(false);
        });
      } else {
        requestHandleUpdateData(handleId, connectionId, reference, data).catch(e => {
          console.error('Error fetching handle update: ', e);
          setFormLoading(false);
        });
      }
    }
  };

  useEffect(() => {
    if (status === STATUS_ASSEMBLING_SUCCESS && isFormLoading === true) {
      // reset filters
      const resetFilters = async () => {
        const newFilters = {};
        Object.keys(dataFilters).forEach(filter => {
          newFilters[filter] = {};
        });

        setDataFilters(newFilters);
      };
      resetFilters();
      setFormLoading(false);
    }
    if ((status === STATUS_VALIDATION_ERROR || status === STATUS_VALIDATION_INVALID) && isFormLoading === true) {
      setFormLoading(false);
    }
  }, [
    status,
    handleId,
    updateStatus,
    reference,
    connectionId,
    setFormLoading,
    setDataFilters,
    data,
    dataFilters,
    isFormLoading
  ]);

  useEffect(() => {
    if (didReload) {
      // keep filters that are still valid
      const newFilters = {};
      Object.keys(dataFilters).forEach(filter => {
        if (data[filter] != null) {
          newFilters[filter] = {};
          Object.keys(dataFilters[filter]).forEach(key => {
            const possibleValues = data[filter].map(x => x[key]);
            newFilters[filter][key] = [...dataFilters[filter][key]].filter(x => possibleValues.includes(x));
          });
        }
      });

      setDataFilters(newFilters);
      setDidReload(false);
    }
    // only need to know about the didReload variable as this will indicate a data update has completed and we need to update the filters.
    // eslint-disable-next-line
  }, [didReload]);

  return (
    <Button
      key="dashboard-reload-button"
      className="a-btn-lg"
      isLoading={isFormLoading}
      disabled={disabled || isFormLoading}
      onClick={handleSubmit(startDataUpdate)}
      data-test-label="dashboard-reload-btn"
    >
      {label || t('dashboard.reloadData')}
    </Button>
  );
};

ReloadButton.defaultProps = {
  disabled: false
};

ReloadButton.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape(FieldProps)).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string
};

export default ReloadButton;
