import React, { useContext } from 'react';
import { Button } from '@appkit4/react-components/button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { generatePreliminaryResults } from '../../util/requests';
import { QSFContext } from '../../../context';

const PreliminaryResultsButton = ({ handleName, handleId, workflowId, workflowStage }) => {
  const { t } = useTranslation();
  const { connectionId } = useContext(QSFContext);

  const gen = (handleName, handleId, workflowId, workflowStage) => {
    generatePreliminaryResults(connectionId, handleName, handleId, workflowId, workflowStage);
    window.location.reload();
  };

  return (
    <Button onClick={() => gen(handleName, handleId, workflowId, workflowStage)}>
      {t('button.generatePreliminaryResults')}
      <span style={{ marginLeft: 5 }} className="Appkit4-icon icon-arrow-right-fill" />
    </Button>
  );
};

PreliminaryResultsButton.propTypes = {
  handleName: PropTypes.string.isRequired,
  handleId: PropTypes.string.isRequired,
  workflowId: PropTypes.string.isRequired,
  workflowStage: PropTypes.string.isRequired
};

export default PreliminaryResultsButton;
