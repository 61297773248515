import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../../../context';
import {
  STATUS_VALIDATION_INVALID,
  STATUS_VALIDATION_ERROR,
  STATUS_TRANSFORMATION_ERROR,
  STATUS_ASSEMBLING_ERROR,
  STATUS_ASSEMBLING_SUCCESS,
  STATUS_IDLE,
  STATUS_PROCESSING
} from '../../../config';
import HandleProgress from '../../../handles/handleTabs/handleProgress';
import { Form } from '../../../handles/forms/forms2.0/formComponents';
import { validateForm } from '../../../handles/forms/forms2.0/helpers';
import { submitWorkflow } from '../../util/requests';
import { RESPONSE } from '../../util';

export const Container = styled.div`
  overflow-y: visible;
  padding-left: 0.625rem;

  // Override for checkbox, AppKit alignment seems off by default
  // Logged issue: https://github.pwc.com/AdvisoryAnalyticApps/Appkit-React/issues/1687
  .ap-checkbox {
    display: inline-grid !important;
  }
`;

export const ResultContainer = styled.div`
  margin-top: 20px;
`;

const STOPLOADING = Object.freeze([
  STATUS_VALIDATION_INVALID,
  STATUS_VALIDATION_ERROR,
  STATUS_TRANSFORMATION_ERROR,
  STATUS_ASSEMBLING_ERROR,
  STATUS_ASSEMBLING_SUCCESS
]);

const STATUS = Object.freeze({
  LOADING: Symbol('loading'),
  ERROR: Symbol('error'),
  ENABLED: Symbol('enabled'),
  DISABLED: Symbol('disabled')
});

const InvalidForm = ({ error }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ marginBottom: 10 }}>
        <b>{t('form.message.invalid')}</b>
      </span>
      <span>
        <b>{t('form.message.error')}:</b>
      </span>
      <span>{error}</span>
    </div>
  );
};

const FormWrapper = ({
  form,
  handleName,
  handleId,
  workflowId,
  isParticipant,
  isReviewer,
  formName,
  submissionId,
  prefilledFields
}) => {
  const { connectionId, setRequestId, downloads, error, status, setStatus, resetTransformedResults, setHandleLogs } =
    useContext(QSFContext);

  const downloadStatus = downloads[handleId]?.status ?? '';
  const [formStatus, setFormStatus] = useState(STATUS.ENABLED);
  const { valid, errors } = validateForm(form);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    resetTransformedResults();
    // Clear handle logs and status when this handle unmounts
    return () => setStatus(STATUS_IDLE);
  }, [setStatus, resetTransformedResults]);

  useEffect(() => {
    if (STOPLOADING.includes(downloadStatus)) {
      setFormStatus(STATUS.ENABLED);
    }
  }, [setFormStatus, downloadStatus]);

  useEffect(() => {
    if (error) {
      setFormStatus(STATUS.ERROR);
    }
  }, [setFormStatus, error]);

  const onSubmitForm = async (data, rejected) => {
    setHandleLogs([]);
    setFormStatus(STATUS.DISABLED);
    setStatus(STATUS_PROCESSING);
    try {
      const response = await submitWorkflow(
        { formToUpload: data },
        connectionId,
        handleName,
        handleId,
        workflowId,
        isParticipant ? 'input' : 'create',
        rejected,
        formName,
        submissionId
      );
      setRequestId(response?.data?.requestId);
    } catch (e) {
      console.log(e);
    }
  };

  const onReset = () => {
    resetTransformedResults();
    setStatus(STATUS_IDLE);
    setFormStatus(STATUS.ENABLED);
  };

  if (downloadStatus === RESPONSE.SUBMISSION_SUCCESS) {
    return <div>{t('workflow.message.submissionSuccess')}</div>;
  }

  if (downloadStatus === RESPONSE.CREATE_SUCCESS) {
    return (
      <div>
        <div style={{ marginBottom: 60 }}>{t('workflow.message.submissionSuccess')}</div>
        <Button onClick={onReset} style={{ marginBottom: 40 }} data-test-label="create-workflow-btn">
          {t('button.createAnotherWorkflow')}
        </Button>
      </div>
    );
  }

  if (downloadStatus === RESPONSE.REJECTED) {
    return (
      <div>
        <div style={{ marginBottom: 60 }}>{t('workflow.message.rejectResponse')}</div>
        <Button
          onClick={() => history.push('/workflows')}
          style={{ marginBottom: 40 }}
          data-test-label="back-overview-btn"
        >
          {t('button.backToOverview')}
        </Button>
      </div>
    );
  }

  if (downloadStatus === RESPONSE.CREATE_FAIL || downloadStatus === RESPONSE.SUBMISSION_FAIL) {
    return (
      <div>
        <div style={{ marginBottom: 60 }}>{t('message.errorUnexpected2')}</div>
        <Button onClick={onReset} style={{ marginBottom: 40 }} data-test-label="back-btn">
          {t('button.back')}
        </Button>
      </div>
    );
  }

  if (!valid) {
    return <InvalidForm error={errors} />;
  }

  return (
    <Container data-test-label="form-v2">
      {/* FIXME: React state update on a unmounted component error comes from Appkit ModalInstaller triggered by <Form/>. */}
      <Form
        form={form}
        handleName={handleName}
        submitData={onSubmitForm}
        disabled={formStatus === STATUS.DISABLED}
        reviewMode={isReviewer}
        resetResults={onReset}
        formName={formName}
        submissionId={submissionId}
        workflowId={workflowId}
        prefilledFields={prefilledFields}
      />
      {status !== STATUS_IDLE && (
        <HandleProgress handleName={handleName} handleId={handleId} status={downloadStatus} error={error} />
      )}
    </Container>
  );
};

FormWrapper.defaultProps = {
  workflowId: '',
  isParticipant: false
};

FormWrapper.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleName: PropTypes.string.isRequired,
  handleId: PropTypes.string.isRequired,
  workflowId: PropTypes.string,
  isParticipant: PropTypes.bool,
  isReviewer: PropTypes.bool,
  formName: PropTypes.string,
  submissionId: PropTypes.string,
  prefilledFields: PropTypes.object
};

export default React.memo(FormWrapper);
