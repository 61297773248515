import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router';
import queryString from 'query-string';
import { requestDisclaimer, getDashboard, getDashboardData } from '../../util/requests';
import {
  DASHBOARD_NO_INPUT_REFERENCE,
  INTERACTIVE_DASHBOARD_ONLY_REFERENCE,
  STATUS_ASSEMBLING_SUCCESS
} from '../../config';
import { QSFContext } from '../../context';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../../elements/loading';
import { STATUS } from '../../util/status';
import DashboardContent from './DashboardContent';
import { DashboardContext } from './DashboardContext';
import DashboardWrapper from './DashboardWrapper';

const Dashboard = ({ isDemo }) => {
  const { handleId, reference } = useParams();
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [data, setData] = useState({});
  const [disclaimer, setDisclaimer] = useState('');
  const [dataLoading, setDataLoading] = useState(STATUS.LOADING);
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);
  const [printPage, setPrintPage] = useState(true);
  const [reportPrint, setReportPrint] = useState(false);
  const { demoReference } = queryString.parse(useLocation().search);
  const dashboardReference = !isDemo ? reference : demoReference;
  const { connectionId, updateStatus, setUpdateStatus } = useContext(QSFContext);
  const { generatingReport, reportHasBeenGenerated, setReportHasBeenGenerated } = useContext(DashboardContext);
  const statusUpdate = updateStatus[handleId]?.status;

  // reset update status when unmounting, this prevents that 'getDashboard' gets
  // triggered on a new handle run when going back between pages
  useEffect(() => () => setUpdateStatus({}), [setUpdateStatus]);

  useEffect(() => {
    const getData = async () => {
      try {
        const disclaimer = await requestDisclaimer();
        const dashboard = await getDashboard(handleId);

        setDisclaimer(disclaimer?.data ?? '');
        setDashboardInfo(dashboard?.data);
        setShowBreadcrumbs(
          isDemo ? false : dashboard?.data?.dashboard?.pages?.['dashboard']?.globalProperties?.breadcrumbs ?? true
        );
        setPrintPage(dashboard?.data?.dashboard?.pages?.['dashboard']?.globalProperties?.printPage ?? true);
        setReportPrint(dashboard?.data?.dashboard?.pages?.['dashboard']?.globalProperties?.reportPrint ?? false);

        const isDashboardOnly =
          dashboardReference === DASHBOARD_NO_INPUT_REFERENCE ||
          dashboardReference === INTERACTIVE_DASHBOARD_ONLY_REFERENCE;
        if (isDemo) {
          const dashboardData = await getDashboardData(dashboardReference);
          setData(dashboardData?.data);
        }
        if (isDashboardOnly) {
          setData(dashboard?.data?.dashboard?.data);
        } else {
          // updateReference if there is an update reference, we load the updated reference
          // this prevents the data being default dashboardReference when refreshing the page
          const updateReference = localStorage.getItem(`${handleId}-dashboard-update-reference`);
          if (updateReference || dashboardReference) {
            const dashboardData = await getDashboardData(updateReference || dashboardReference);
            // check if the update is not triggered by pdf report
            if (dashboardData?.data !== undefined) {
              setData(dashboardData?.data);
            }
          }
        }

        setDataLoading(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setDataLoading(status);
      }
    };

    getData();
  }, [handleId, dashboardReference, isDemo]);

  useEffect(() => {
    if (statusUpdate === STATUS_ASSEMBLING_SUCCESS && !generatingReport) {
      const UpdateDashboard = async () => {
        try {
          const dashboard = await getDashboard(handleId);
          setDashboardInfo(dashboard?.data);
          let updateReference;
          // check if the update is not triggered by pdf report
          if (!reportHasBeenGenerated) {
            updateReference = updateStatus[handleId]?.reference;
            localStorage.setItem(`${handleId}-dashboard-update-reference`, updateReference);
          } else {
            updateReference = localStorage.getItem(`${handleId}-dashboard-update-reference`) || dashboardReference;
            setReportHasBeenGenerated(false);
          }
          const dashboardData = await getDashboardData(updateReference);
          if (dashboardData?.data !== undefined) {
            setData(dashboardData?.data);
          }

          setDataLoading(STATUS.OK);
        } catch (e) {
          const responseStatus = e?.response?.status;
          const status =
            responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
          setDataLoading(status);
        }
      };
      UpdateDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusUpdate, handleId, connectionId, dashboardReference, generatingReport]);

  const hasIndexComponent =
    Object.values(dashboardInfo?.dashboard?.components ?? {}).find(component => component.type === 'index') != null;

  return (
    <DashboardWrapper showBreadcrumbs={showBreadcrumbs} printPage={printPage} hasIndexComponent={hasIndexComponent}>
      <Content
        dataLoading={dataLoading}
        data={data}
        dashboard={dashboardInfo?.dashboard}
        disclaimer={disclaimer}
        printPage={printPage}
        reportPrint={reportPrint}
        isDemo={isDemo}
      />
    </DashboardWrapper>
  );
};

export const Content = ({ dataLoading, data, dashboard, disclaimer, printPage, reportPrint, isDemo }) => {
  const { t } = useTranslation();

  if (dataLoading === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  // Temporary fix for demo dashboards that take long to load and present data [DC-1839]
  // TODO: Implement better loading - ok status for demo dashboard results.
  if (dataLoading === STATUS.OK && isDemo && !data) {
    return <LoadingIndicator />;
  }

  if (dataLoading === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (dataLoading === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (dataLoading === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator />;
  }

  if (data == null) {
    return <div style={{ textAlign: 'center', marginTop: 50 }}>{t('dashboard.missingData')}</div>;
  }

  if (dashboard == null || dashboard.pages == null || dashboard.components == null) {
    return <div style={{ textAlign: 'center', marginTop: 50 }}>{t('dashboard.missingInfo')}</div>;
  }

  return (
    <DashboardContent
      printPage={printPage}
      reportPrint={reportPrint}
      dashboard={dashboard}
      initialData={data}
      disclaimer={disclaimer}
      isDemo={isDemo}
    />
  );
};

export default Dashboard;
