import { Geographies as ReactSimpleGeographies, Geography } from 'react-simple-maps';

import React, { useContext } from 'react';
import { DashboardContext } from '../../DashboardContext';
import defaultSource from './util/countries.json';
import { geographiesPropTypes } from './propTypes';

// Default values for geography styles
const DEFAULT_COUNTRY_CODE = 'ISO_A3';
const DEFAULT_FILL_COLOR = '#DEDEDE';
const DEFAULT_STROKE_COLOR = '#FFFFFF';
const DEFAULT_STROKE_WIDTH = 1;

// Function to check if a geography matches certain given filters.
export const isGeographyFilterValid = (filter, geography) =>
  Object.keys(filter).find(key => {
    if (Array.isArray(filter[key])) {
      return filter[key].includes(geography.properties?.[key]);
    }
    return geography.properties?.[key] === filter[key];
  }) !== undefined;

const Geographies = ({ child, componentData, filterGeo }) => {
  const { data, dataFilters, setDataFilters } = useContext(DashboardContext);

  return (
    <ReactSimpleGeographies geography={child.properties?.source ?? defaultSource}>
      {({ geographies }) =>
        geographies.map(geo => {
          const geoProps = child.properties?.geography;

          // Data filter
          const filterProps = geoProps?.dataFilter;
          let isActive = false;
          let onClick = () => null;

          // If the filter properties have been set, enable the dataFilters for the given countries.
          if (filterProps?.data || filterProps?.dataKey || filterProps?.targetKey) {
            const currentDataFilters = { ...dataFilters };

            // If not set yet, set (context) dataFilters
            if (typeof dataFilters[filterProps.data] !== 'object') {
              currentDataFilters[filterProps.data] = {};
            }
            if (!Array.isArray(dataFilters[filterProps.data]?.[filterProps.targetKey])) {
              currentDataFilters[filterProps.data][filterProps.targetKey] = filterProps?.active ?? [];
            }
            if (dataFilters?.[filterProps.data]?.[filterProps.targetKey] === undefined && filterProps?.active) {
              setDataFilters(currentDataFilters);
            }

            let filters = [...currentDataFilters[filterProps.data][filterProps.targetKey]];

            // If the geo properties contain the given dataKey (for example geo.properties.ISO_3),
            // add the onClick event to that specific area / country.
            if (geo.properties[filterProps.dataKey] != null) {
              isActive = filters.indexOf(geo.properties[filterProps.dataKey]) > -1;

              onClick = () => {
                // Get value from onClick event
                const value = geo.properties[filterProps.dataKey];
                if (!value) return;

                // Only allow clickable countries which country code exist in the connected dataset.
                const originalFilterableData = data?.[`${filterProps.data}_original`] ?? data?.[filterProps.data];
                if (originalFilterableData?.find(item => item?.[filterProps.targetKey] === value) === undefined) return;

                // Check or uncheck filter
                if (filters.indexOf(value) > -1) {
                  filters = filters.filter(item => item !== value);
                } else {
                  filters = filterProps?.single ? [value] : [...filters, value];
                }

                // Update the context dataFilters
                currentDataFilters[filterProps.data][filterProps.targetKey] = filters;
                setDataFilters(currentDataFilters);
              };
            }
          }

          // If the datafile has specific properties for this country (selected by iso3 key), then apply that.
          const countryCode = child.properties?.countryCode ?? DEFAULT_COUNTRY_CODE;
          const countryProps = componentData.countries?.[geo.properties?.[countryCode]] ?? {};
          const fillColor = countryProps?.fill ?? geoProps?.style?.fillColor ?? DEFAULT_FILL_COLOR;
          const strokeColor = geoProps?.style?.strokeColor ?? DEFAULT_STROKE_COLOR;
          const strokeWidth = geoProps?.style?.strokeWidth ?? DEFAULT_STROKE_WIDTH;

          // Return nothing if the geoFilter is set and doesn't match this geography.
          if (Object.keys(filterGeo).length > 0 && !isGeographyFilterValid(filterGeo, geo)) return null;

          return (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              style={geoProps?.style}
              onClick={onClick}
              {...countryProps}
              fill={isActive && filterProps.fill != null ? filterProps.fill : fillColor}
              stroke={strokeColor}
              strokeWidth={strokeWidth}
            />
          );
        })
      }
    </ReactSimpleGeographies>
  );
};

Geographies.defaultProps = {
  filterGeo: null,
  data: {}
};

Geographies.propTypes = geographiesPropTypes;

export default Geographies;
