import React, { useEffect, useState, useContext, useRef } from 'react';
import { Button } from '@appkit4/react-components/button';
import { Checkbox, CheckboxGroup } from '@appkit4/react-components/checkbox';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { QSFContext } from '../context';
import {
  SideDrawerWrapper,
  SideDrawerContainer,
  SideDrawerHeader,
  CloseFiltersButton,
  EmptyTagsTextWrapper,
  FilterGroupWrapper,
  CheckboxTagWrapper,
  FiltersSelectedWrapper,
  SelectableTag,
  DeleteTagIcon
} from '../styles/GeneralElements';

const FilterTagsSideDrawer = ({ availableHandles, setFilteredHandles, type }) => {
  const {
    filterDrawerVisible,
    setFilterDrawerVisible,
    filtersMyApps,
    setFiltersMyApps,
    filtersDiscoverApps,
    setFiltersDiscoverApps,
    allFilters
  } = useContext(QSFContext);
  const { t } = useTranslation();
  const ref = useRef(null);

  // Sets the filtered tags from the global context depending of the page type
  const filteredTagsPage = type === 'myApps' ? filtersMyApps : filtersDiscoverApps;

  const [filteredTags, setFilteredTags] = useState(filteredTagsPage);

  // Persists the filtered tags into the proper context when unmounts the component
  useEffect(() => {
    return () => {
      type === 'myApps' ? setFiltersMyApps(filteredTags) : setFiltersDiscoverApps(filteredTags);
    };
  });

  // Hides the filter side drawer when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilterDrawerVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [setFilterDrawerVisible]);

  useEffect(() => {
    if (filteredTags.length) {
      const newFilteredHandles = availableHandles.filter(
        handle =>
          handle.tags?.find(tag => filteredTags.includes(tag)) ||
          handle.coverage?.find(country => filteredTags.includes(country))
      );
      setFilteredHandles(newFilteredHandles);
    } else {
      setFilteredHandles(availableHandles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTags, availableHandles]);

  const availableTagCategories = [];
  const availableCoverageCountries = [];

  // Loop through available handles to get all available tags
  (availableHandles || []).forEach(handle => {
    const { tags, coverage } = handle;

    // Add coverage tags
    (coverage || []).forEach(tag => {
      const coverage = allFilters.coverage?.find(c => c.tag === tag);
      const exist = availableCoverageCountries?.find(c => c?.tag === tag);
      if (!coverage || exist) return;

      availableCoverageCountries.push({ name: coverage.name, tag });
    });

    // Add category tags
    (tags || []).forEach(tag => {
      const item = allFilters.tags?.find(cat => cat.tags.find(t => t === tag) != null);
      if (!item) return;

      const category = availableTagCategories?.find(c => c?.category === item.category);

      // Add new category and tag
      if (!category) {
        availableTagCategories.push({ category: item.category, tags: [tag] });
        return;
      }

      // Category already exist, push tag to existing list is it isn't present yet
      if (!category.tags.includes(tag)) {
        category.tags.push(tag);
      }
    });
  });

  const changeTagsSelected = value => {
    setFilteredTags(value);
  };

  const removeSelectedTag = tag => {
    setFilteredTags(filteredTags.filter(t => t !== tag));
  };

  const removeAllSelectedTags = () => {
    setFilteredTags([]);
  };

  return (
    <SideDrawerWrapper isVisible={filterDrawerVisible} ref={ref} data-test-label="filter-tags-side-drawer">
      <SideDrawerContainer>
        <SideDrawerHeader>
          <CloseFiltersButton
            onClick={() => setFilterDrawerVisible(false)}
            data-test-label="close-filter-section-button"
          >
            <span className="Appkit4-icon icon-close-outline" />
          </CloseFiltersButton>
          <h4>{t('filters.filters')}</h4>
        </SideDrawerHeader>
        {availableTagCategories.length === 0 && availableCoverageCountries.length === 0 && (
          <EmptyTagsTextWrapper>{t('filters.noFiltersAvailable')}</EmptyTagsTextWrapper>
        )}
        {filteredTags.length > 0 && (
          <div>
            <FiltersSelectedWrapper>
              {filteredTags.map(tag => {
                const coverage = allFilters.coverage?.find(c => c.tag === tag)?.name;
                return (
                  <SelectableTag key={uuidv4()}>
                    {coverage ? t(`filters.coverageTags.${tag}`) : t(`filters.tags.${tag}`)}
                    <DeleteTagIcon onClick={() => removeSelectedTag(tag)} className="Appkit4-icon icon-close-outline" />
                  </SelectableTag>
                );
              })}
            </FiltersSelectedWrapper>
            <Button
              kind="secondary"
              className="a-btn-sm a-btn-gray a-btn-negative"
              negative
              gray
              onClick={removeAllSelectedTags}
              data-test-label="remove-tags-btn"
            >
              {t('filters.removeSelectedTags')}
            </Button>
          </div>
        )}
        {availableCoverageCountries.length > 0 && (
          <FilterGroupWrapper>
            <h4>{t('filters.coverage')}</h4>
            <CheckboxGroup onChange={changeTagsSelected} value={filteredTags}>
              {availableCoverageCountries
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(coverageCountry => {
                  const { tag } = coverageCountry;
                  return (
                    <CheckboxTagWrapper key={tag}>
                      <Checkbox value={tag}>{t(`filters.coverageTags.${tag}`)}</Checkbox>
                    </CheckboxTagWrapper>
                  );
                })}
            </CheckboxGroup>
          </FilterGroupWrapper>
        )}
        {availableTagCategories.length > 0 &&
          availableTagCategories.map(group => {
            const { category, tags } = group;
            return (
              <FilterGroupWrapper key={category}>
                <h4>{t(`filters.tags.category.${category}`)}</h4>
                <CheckboxGroup onChange={changeTagsSelected} value={filteredTags}>
                  {tags.sort().map(tag => (
                    <CheckboxTagWrapper key={tag}>
                      <Checkbox value={tag}>{t(`filters.tags.${tag}`)}</Checkbox>
                    </CheckboxTagWrapper>
                  ))}
                </CheckboxGroup>
              </FilterGroupWrapper>
            );
          })}
      </SideDrawerContainer>
    </SideDrawerWrapper>
  );
};

export default FilterTagsSideDrawer;
