import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { WatchMoreContainer, WatchMoreVideoEntry } from '../../styles/VideosElements';
import { dateTimeAgo } from './util';

const MoreVideos = ({ videos }) => {
  const history = useHistory();
  const { handleId, handleName } = useParams();
  const { t } = useTranslation();

  return (
    <WatchMoreContainer data-test-label="watch-more-container">
      <h6>{t('handle.moreVideos')}</h6>
      {videos.length === 0 ? (
        <div style={{ textAlign: 'center' }}>{t('message.noOthers', { type: t('type.video', { count: 2 }) })}</div>
      ) : (
        videos.map(video => {
          const { id, name, thumbnailUrl, createdAt } = video;
          const onClick = () => history.push(`/videos/${handleId}/${handleName}/${id}`);

          return (
            <WatchMoreVideoEntry
              onClick={onClick}
              key={name}
              className="ap-panel"
              data-test-label={`videos-${name}-panel`}
            >
              <div className="image-block">
                <LazyLoadComponent>
                  <img
                    src={`${thumbnailUrl}/height/125/width/210/type/3`}
                    alt={name}
                    style={{ maxWidth: '100%', height: '125px' }}
                  />
                </LazyLoadComponent>
              </div>
              <div className="info-block">
                <h6 data-test-label={`videos-${handleId}-panel-header`}>{name}</h6>
                <div className="info" data-test-label={`videos-${handleId}-panel-info`}>
                  <span className="Appkit4-icon icon-time-outline" style={{ marginRight: 5 }} />
                  <span className="date">{dateTimeAgo(createdAt)}</span>
                </div>
              </div>
            </WatchMoreVideoEntry>
          );
        })
      )}
    </WatchMoreContainer>
  );
};

MoreVideos.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      createdAt: PropTypes.number.isRequired,
      ks: PropTypes.string.isRequired
    })
  ).isRequired
};

export default MoreVideos;
