import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChevronRightDark } from '../../styles/GeneralElements';
import { sanitizer } from '../../util';
import {
  VideoHeaderWrapper,
  ActionsVideos,
  ButtonContact,
  DescriptionVideos,
  DescriptionText
} from '../../styles/HandleComponents';
import ContactModal from './ContactModal';

const VideosHeader = ({ id, description, contact }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  const showContactModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);

  return (
    <VideoHeaderWrapper image={id}>
      <DescriptionVideos>
        <DescriptionText dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
      </DescriptionVideos>
      <ActionsVideos>
        <ButtonContact className="a-btn-md" onClick={showContactModal} data-test-label="get-in-touch-btn">
          {t('button.getInTouch')} <ChevronRightDark size={14} />
        </ButtonContact>
      </ActionsVideos>
      <ContactModal isVisible={modalVisible} onCloseModal={closeModal} contact={contact} />
    </VideoHeaderWrapper>
  );
};

VideosHeader.defaultProps = {
  contact: ''
};

VideosHeader.propTypes = {
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  contact: PropTypes.string
};

export default VideosHeader;
