import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { useTranslation } from 'react-i18next';

const Reset = ({ onReset }) => {
  const { t } = useTranslation();

  return (
    <Tooltip content={t('form.tooltip.reset')} position="top">
      <Button
        style={{ marginLeft: 10 }}
        kind="transparent"
        className="a-btn-sm"
        onClick={onReset}
        data-test-label="reset-btn"
      >
        <span style={{ fontSize: '20px' }} className="Appkit4-icon icon-refresh-outline a-btn-icon" />
      </Button>
    </Tooltip>
  );
};

export default Reset;
