export const APP_NAME = 'On Demand';

// Old statuses
export const STATUS_TRANSFORMED = 'transformed'; // TODO: check if still needed
export const STATUS_INVALID = 'invalid'; // TODO: check if still needed
export const STATUS_DONE = 'done';

// New statuses
export const STATUS_UPLOADING = 'uploading';
export const STATUS_IDLE = 'idle';
export const STATUS_UNPROCESSED = 'unprocessed';
export const STATUS_UNAUTHORIZED = 'unauthorized';
export const STATUS_PROCESSING = 'processing';
export const STATUS_VALIDATING = 'validating';
export const STATUS_VALID = 'valid';

export const STATUS_FORM_REQUIRED = 'form_required';
export const STATUS_REJECTED = 'rejected';

export const STATUS_VALIDATION_STARTED = 'validation_started';
export const STATUS_VALIDATION_UPDATE = 'validation_update';
export const STATUS_VALIDATION_ERROR = 'validation_error';
export const STATUS_VALIDATION_VALID = 'validation_valid';
export const STATUS_VALIDATION_INVALID = 'validation_invalid';

export const STATUS_TRANSFORMATION_RECEIVED = 'transformation_received';
export const STATUS_TRANSFORMATION_STARTED = 'transformation_started';
export const STATUS_TRANSFORMATION_UPDATE = 'transformation_update';
export const STATUS_TRANSFORMATION_ERROR = 'transformation_error';
export const STATUS_TRANSFORMATION_SUCCESS = 'transformation_success';

export const STATUS_ASSEMBLING_STARTED = 'assembling_started';
export const STATUS_ASSEMBLING_UPDATE = 'assembling_update';
export const STATUS_ASSEMBLING_ERROR = 'assembling_error';
export const STATUS_ASSEMBLING_SUCCESS = 'assembling_success';

// TODO: get from server instead of hard-coded
export const UPLOADER_MAX_SIZE = '25MB';
export const UPLOADER_MAX_FILES = 75;

// TODO: Temporary fix. Implement proper request/responses pattern and remove timeout
export const TIMEOUT_HANDLE_LOADING = 20000;

export const REACT_APP_QSF_SERVER_PORT = 3000;
export const BASE_URL = process.env.NODE_ENV === 'development' ? `http://localhost:${REACT_APP_QSF_SERVER_PORT}` : '';
export const DASHBOARD_NO_INPUT_REFERENCE = '0';

export const INTERACTIVE_DASHBOARD_ONLY = 'interactive-dashboard-only';
export const INTERACTIVE_DASHBOARD_ONLY_REFERENCE = '1';

export const SUPPORT_EMAIL = process.env.QSF_SUPPORT_EMAIL ?? 'nl_ondemand_support@pwc.com';

export const AW_URL =
  process.env.QSF_AW_URL !== undefined
    ? process.env.QSF_AW_URL
    : 'https://assistedwriting.pwcinternal.com/aw/documents';

export const QSF_COUNTRY_CODE = process.env.QSF_COUNTRY_CODE ?? 'nl';
