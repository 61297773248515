import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RadioGroup, Radio } from '@appkit4/react-components/radio';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { v4 as uuidv4 } from 'uuid';
import { sanitizer } from '../../../../util';

const Group = styled(RadioGroup)`
  display: inline-block;

  .a-radio-text {
    display: flex;
    width: 100%;
  }

  .a-tooltip-ref {
    margin-left: auto;
    padding-left: 10px;
  }
`;

const SingleChoice = ({ field, value, onChange }) => {
  const { id, options } = field;

  // allow sanitized HTML in the tooltip
  const renderContent = tooltip => <div dangerouslySetInnerHTML={{ __html: sanitizer(tooltip) }} />;

  return (
    <Group value={value} onChange={value => onChange(id, value)}>
      {options.map(option => (
        <Radio key={uuidv4()} id={uuidv4()} value={option.value}>
          {option.label}
          {option.tooltip && (
            <Tooltip content={renderContent(option.tooltip)} position="top">
              <div className="Appkit4-icon icon-information-fill a-font-14" />
            </Tooltip>
          )}
        </Radio>
      ))}
    </Group>
  );
};

SingleChoice.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        tooltip: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default SingleChoice;
