import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE_ISO_CODE, getWorkflowHandleById } from '../../util/requests';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../../elements/loading';
import { STATUS } from '../../util/status';
import { Container, StyledPanel, StyledTabs, StyledTab } from '../../styles/HandleComponents';
import HandleHeader from '../../handles/HandleHeader';
import Form from '../shared-components/form/Form';
import { LANGUAGE_KEY } from '../../menu/LanguageMenuItem';
import History from './History';

const Handle = () => {
  const { id } = useParams();
  const [info, setInfo] = useState({});
  const [status, setStatus] = useState(STATUS.LOADING);
  useEffect(() => {
    const getData = async () => {
      try {
        const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
        const response = await getWorkflowHandleById(id, lang);
        setInfo(response.data);
        setStatus(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
      }
    };

    getData();
  }, [id]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator />;
  }

  return <HandleContent info={info} />;
};

const HandleContent = ({ info }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { tab } = queryString.parse(useLocation().search);

  useEffect(() => {
    if (!tab) {
      history.replace(`/handles/${info.id}/${info.name}?tab=input&workflow=true`);
    }
  }, [tab, info, history]);

  const handleTabChange = (e, newTab) => {
    history.push(`/handles/${info.id}/${info.name}?tab=${newTab}&workflow=true`);
  };

  return (
    <Container data-test-label="handle-info-container" style={{ minWidth: 820 }}>
      <HandleHeader id={info.id} workflow handleName={info.name} />
      <StyledPanel>
        <StyledTabs
          onChange={handleTabChange}
          defaultValue={tab}
          value={tab}
          data-test-label="tab-information"
          className="a-tab-item-padding-10"
        >
          <StyledTab value="input" data-test-label="tab-upload-data" label={t('handle.label.insertData')}>
            <Form form={info.form} handleName={info.name} handleId={info.id} />
          </StyledTab>
          <StyledTab value="history" label={t('workflow.label.history')} data-test-label="tab-history">
            <History handleId={info.id} key={tab} />{' '}
            {/* add key to force re-render so that new workflows are retrieved */}
          </StyledTab>
        </StyledTabs>
      </StyledPanel>
    </Container>
  );
};

export default Handle;
