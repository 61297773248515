import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DataComponents from '../../data-components';
import { DashboardContext } from '../../DashboardContext';
import ComponentFilters from './ComponentFilters';

export const ChartWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

const setModifiedPropertiesDataSubset = (type, dataSubsets, properties, dataSubsetActive) => {
  if (
    dataSubsets.length &&
    properties?.dataSubsets &&
    dataSubsetActive &&
    properties.dataSubsets.find(ds => ds.id === dataSubsetActive.id)
  ) {
    if (type === 'gauge') {
      return type === 'text'
        ? { ...properties, value: properties.dataSubsets.find(ds => ds.id === dataSubsetActive.id).value }
        : {
            ...properties,
            data: properties.dataSubsets.find(ds => ds.id === dataSubsetActive.id).subsetData,
            displayData: properties.dataSubsets.find(ds => ds.id === dataSubsetActive.id).displayData
          };
    } else if (!['pie', 'scatter'].includes(properties.type)) {
      return type === 'text'
        ? { ...properties, value: properties.dataSubsets.find(ds => ds.id === dataSubsetActive.id).value }
        : { ...properties, data: properties.dataSubsets.find(ds => ds.id === dataSubsetActive.id).subsetData };
    }
  }
  return properties;
};

const setModifiedChildComponentsDataSubset = (dataSubsets, childComponents, dataSubsetActive) => {
  return childComponents?.map(child => {
    if (
      dataSubsets.length &&
      child.properties?.dataSubsets &&
      dataSubsetActive &&
      child.properties.dataSubsets.find(ds => ds.id === dataSubsetActive.id) &&
      ['pie', 'scatter'].includes(child.type)
    ) {
      return {
        ...child,
        properties: {
          ...child.properties,
          data: child.properties.dataSubsets.find(ds => ds.id === dataSubsetActive.id).subsetData
        }
      };
    }
    return child;
  });
};

const ValidComponent = ({ component }) => {
  const { data, disabledDataKeys, dataSubsets } = useContext(DashboardContext);
  const [filters, setFilters] = useState(component.filters ?? []);

  const onFilterChange = filter => {
    const newFilters = [...filters];
    newFilters[filters.indexOf(filter)].active = !filter.active;
    setFilters(newFilters);
  };

  const disabledComponentDataKeys = filters
    .filter(f => !f.active)
    .map(f => f.dataKeys)
    .flat();

  const { type, properties, id, title, children } = component;

  // filter out child components that should be hidden due to active filters
  const allDisabledDataKeys = [...disabledDataKeys, ...disabledComponentDataKeys];
  const filteredChildComponents = children?.filter(c => !allDisabledDataKeys.includes(c.properties?.dataKey));

  // Change properties or child components data pointer to a dataSubset if its present and matches the active dataSubset
  const dataSubsetActive = dataSubsets.find(subset => subset.active);
  const modifiedProperties = setModifiedPropertiesDataSubset(type, dataSubsets, properties, dataSubsetActive);
  const modifiedChildComponents = setModifiedChildComponentsDataSubset(
    dataSubsets,
    filteredChildComponents,
    dataSubsetActive
  );

  const Component = DataComponents[type];

  return (
    <>
      <ComponentFilters filters={filters} onChange={onFilterChange} />
      <ChartWrapper>
        <Component
          data={data}
          properties={modifiedProperties}
          childComponents={modifiedChildComponents}
          id={id}
          title={title}
        />
      </ChartWrapper>
    </>
  );
};

ValidComponent.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(DataComponents)),
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        dataKeys: PropTypes.arrayOf(PropTypes.string).isRequired
      })
    ),
    properties: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.arrayOf(PropTypes.object)
  })
};

export default ValidComponent;
