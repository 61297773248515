import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '@appkit4/react-components/field';
import { TEXTAREA_MAX_LENGTH } from '../../config';

const TextAreaField = ({ field, value, onChange, onBlur }) => {
  const { id, placeholder, maxLength } = field;
  const maxInput = maxLength ?? TEXTAREA_MAX_LENGTH;

  return (
    <TextArea
      maxLength={maxInput}
      placeholder={placeholder}
      value={value}
      onChange={val => onChange(id, val)}
      onBlur={onBlur}
    />
  );
};

TextAreaField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func
};

export default TextAreaField;
