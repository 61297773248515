import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appkit4/react-components/button';
import { useHistory, useParams } from 'react-router-dom';

const DashboardButton = ({ workflowId }) => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <Button onClick={() => history.push(`/workflow/dashboard/${id}/${workflowId}/dashboard`)}>
      <span className="Appkit4-icon icon-grid-view-fill" style={{ margin: 0 }} />
    </Button>
  );
};

DashboardButton.propTypes = {
  workflowId: PropTypes.string.isRequired
};

export default DashboardButton;
