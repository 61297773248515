import React, { useContext, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Button } from '@appkit4/react-components/button';
import { Notification } from '@appkit4/react-components/notification';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Upload } from '@appkit4/react-components/upload';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  STATUS_IDLE,
  STATUS_ASSEMBLING_ERROR,
  STATUS_ASSEMBLING_SUCCESS,
  STATUS_FORM_REQUIRED,
  STATUS_PROCESSING,
  STATUS_TRANSFORMATION_ERROR,
  STATUS_VALIDATION_ERROR,
  STATUS_VALIDATION_INVALID,
  STATUS_VALIDATION_VALID,
  SUPPORT_EMAIL
} from '../../config';
import { QSFContext } from '../../context';
import {
  DEFAULT_LANGUAGE_ISO_CODE,
  requestFileTransformations,
  requestHandleDemoData,
  requestPowerBIRedirect,
  transformStoredRequest
} from '../../util/requests';
import { AllResults } from '../buttons';
import DownloadStatus from '../downloadStatus/DownloadStatus';
import WebForm from '../forms/Form';
import HandleDemoDescription from '../handle/HandleDemoDescription';
// import SubmitButton from '../buttons/submit/SubmitButton';
import { STATUS } from '../../util/status';
import { LANGUAGE_KEY } from '../../menu/LanguageMenuItem';
import HandleProgress from './handleProgress';

// Styled Components
const Wrapper = React.memo(styled.div`
  border: none;
  padding: 20px 0 20px 0;
  width: 100%;
  overflow-y: visible;
`);

const WrapperResults = React.memo(styled.div`
  margin: 25px 0 5px 0;
  padding: 0px;
  border: none;
  width: 100%;
  overflow-y: auto;
`);
const DownloadFile = React.memo(styled.div`
  display: flex;
  justify-content: flex-start;
`);

const Container = React.memo(styled.div`
  .a-alert-wrapper.one-line {
    display: table;
    margin-top: 20px;
    > .a-alert.one-line {
      cursor: pointer;
    }
  }
`);

const UploaderWrapper = React.memo(styled.div`
  span {
    max-width: 500px !important;
  }

  .a-upload-drag-zone {
    display: block;
  }
`);

const HandleUpload = props => {
  const { handleName, handleId, transformAllowed, hasDashboard, demoDescription, isDemo, pauseAtDashboardReady } =
    props;
  const {
    status,
    requestId,
    error,
    connectionId,
    transformedResults,
    resetTransformedResults,
    downloads,
    configuration,
    setRequestId,
    startHandleUpload,
    setStatus,
    setError,
    setHandleLogs
  } = useContext(QSFContext);
  const uploadRef = useRef(null);
  const [filesArray, setFilesArray] = useState([]);
  const [filesForm, setFilesForm] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingIntegrationRedirect, setLoadingIntegrationRedirect] = useState(false);
  const [integrationError, setIntegrationError] = useState('');
  const [uploadDisabled, setUploadDisabled] = useState(isDemo);
  const { t } = useTranslation();
  const [preservedFiles, setPreservedFiles] = useState([]);

  const stopLoadingStatuses = useMemo(() => {
    return [
      STATUS_VALIDATION_INVALID,
      STATUS_VALIDATION_ERROR,
      STATUS_TRANSFORMATION_ERROR,
      STATUS_ASSEMBLING_ERROR,
      STATUS_ASSEMBLING_SUCCESS
    ];
  }, []);

  const integrations = [
    {
      id: 'powerbi',
      name: 'PowerBI',
      isEnabled: res => res?.powerBI === true && res.reference,
      isValid: res => res?.powerBIRegisteredUser === true,
      loadRedirect: requestPowerBIRedirect,
      errorMessage: t('message.errorPowerBI', { email: SUPPORT_EMAIL })
    }
  ];

  // To prevent default browser action while drag and dropping outside the drag and drop zone
  // the following event listeners were added to the window calling preventDefault();
  // A bug issue was opened to Appkit team to add preventDefault() into their drag and drop component,
  // that would result in a more elegant solution
  useEffect(() => {
    const listener = e => e.preventDefault();
    window.addEventListener('dragover', listener, false);
    window.addEventListener('drop', listener, false);
    return () => {
      window.removeEventListener('dragover', listener, false);
      window.removeEventListener('drop', listener, false);
    };
  }, []);

  // Clear error when this handle unmounts
  useEffect(() => () => setError(null), [setError]);

  // Clear handle logs and status when this handle unmounts
  useEffect(() => () => setStatus(STATUS_IDLE), [setStatus]);

  const downloadStatus = downloads[handleId] ? downloads[handleId].status : null;

  // file and fileList arguments are provided from the ref element from appkit
  const onFileSelect = (file, fileList) => {
    resetTransformedResults();
    setFilesArray(fileList);
    setFilesForm(fileList);
    setStatus(STATUS_IDLE);
    setPreservedFiles(fileList);
    setHandleLogs([]);

    if (isDemo) {
      focusTooltipById('submitTooltip');
    }
  };

  // method to reset the upload form and state
  const resetUploadForm = useCallback(() => {
    setFilesArray([]);
    setFilesForm([]);
    setLoading(false);
    setUploadDisabled(false);
  }, [setFilesArray, setFilesForm, setLoading, setUploadDisabled]); // setError

  // In case of an error, the form should be repopulated with the preserved files
  // so the user can try to upload the files again, without having to select them again
  // as well as remove files from the form
  const repopulateFormWithPreservedFiles = useCallback(() => {
    setFilesArray(preservedFiles);
    setFilesForm(preservedFiles);
  }, [setFilesArray, setFilesForm, preservedFiles]);

  const onUploadFile = () => {
    setLoading(true);
    setError(null);
    startHandleUpload(handleId);
    if (filesArray.length > 0) {
      const filesToUpload = filesArray.map(file => file.originFile);
      const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
      requestFileTransformations({ filesToUpload }, connectionId, handleId, isDemo, lang)
        .then(response => {
          if (response) {
            setRequestId(response.data.requestId);
            setFilesArray([]);
          } else {
            resetFormAndRepopulate();
          }
        })
        .catch(err => {
          setStatus(STATUS_VALIDATION_ERROR);
          console.log('onError triggered:', err);
          resetFormAndRepopulate();
          // Format error for HandleProgress
          setError({ message: err.response?.data?.message || 'Upload failed' });
        });
    } else {
      setLoading(false);
    }

    setLoadingIntegrationRedirect(false);
    if (isDemo) {
      setTimeout(() => focusTooltipById('progressBar'), 100);
    }
  };

  const resetFormAndRepopulate = useCallback(() => {
    resetUploadForm();
    repopulateFormWithPreservedFiles();
  }, [resetUploadForm, repopulateFormWithPreservedFiles]);

  useEffect(() => {
    if (stopLoadingStatuses.includes(downloadStatus)) {
      resetFormAndRepopulate();
    }
  }, [downloadStatus, stopLoadingStatuses, resetFormAndRepopulate]);

  // when file was uploaded from other handle and valid for this handle, transform
  useEffect(() => {
    if (
      transformAllowed === true &&
      requestId &&
      downloadStatus === STATUS_VALIDATION_VALID &&
      !loading &&
      status === STATUS_IDLE
    ) {
      setStatus(STATUS_PROCESSING);
      setLoading(true);
      transformStoredRequest(requestId, handleName).then(response => {
        if (response && !response.data.success) {
          setRequestId('');
          setLoading(false);
        }
      });
    }
  }, [transformAllowed, requestId, handleName, setStatus, status, setRequestId, downloadStatus, loading]);

  // If a user clicks on the 'Go to {Integration}' button, the data must be sent and a url is returned.
  const loadIntegration = integration => () => {
    const result = transformedResults[handleName];
    if (!result || !integration.isEnabled(result)) return;

    // Show an error if there is no account available for Integration.
    if (!integration.isValid(result)) {
      setLoadingIntegrationRedirect(false);
      setIntegrationError(integration.errorMessage);
      return;
    }

    setLoadingIntegrationRedirect(true);

    if (result.reference) {
      integration
        .loadRedirect(result.reference)
        .then(response => {
          if (response?.data.url) {
            window.open(response.data.url, '_blank');
          }
          setLoadingIntegrationRedirect(false);
        })
        .catch(err => setLoadingIntegrationRedirect(false));
    }
  };

  // const canUpload = !loading && filesArray.length > 0;
  const showAllResults = Object.keys(transformedResults).length > 0;

  // TODO: this webform case will break 100% because there's no prop 'form' included
  const webForm = <WebForm files={filesForm} connectionId={connectionId} />;
  const downloadResult = (
    <DownloadStatus handleName={handleName} handleId={handleId} hasDashboard={hasDashboard} isDemo={isDemo} />
  );

  const focusTooltipById = id => document.getElementById(id)?.focus();

  const tempDownloadLink = data => {
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(data);
    tempLink.setAttribute('download', `demo-data-${handleName}.zip`);
    return tempLink;
  };

  const downloadDemoFiles = handleId => {
    requestHandleDemoData(handleId)
      .then(res => {
        const rawBlobData = new Blob([res.data], { type: 'application/zip' });

        tempDownloadLink(rawBlobData).click();
        focusTooltipById('uploadTooltip');
        setUploadDisabled(false);
      })
      .catch(e => setStatus(STATUS.ERROR));
  };

  // Only accept allowed file extensions
  const acceptFiles = fileList => {
    const { validFileExtensions } = configuration;
    return fileList.filter(e => {
      const fileName = e.name.toLocaleLowerCase();
      return validFileExtensions.some(ext => fileName.endsWith(ext));
    });
  };

  return (
    <Container>
      <Wrapper>
        <UploaderWrapper className="upload-with-button" data-test-label="uploader-container">
          {isDemo && (
            <Tooltip
              id="uploadTooltip"
              content={t('tooltip.uploadDemo')}
              trigger="focus"
              mouseLeaveDelay={3000}
              position="top"
            >
              <div />
            </Tooltip>
          )}
          <Upload
            data-test-label="uploader-element"
            autoUpload={false}
            ref={uploadRef}
            className="upload-for-button"
            errorMessage={error}
            drag
            multiple
            showFileList={!loading}
            showFullFileName
            accept={acceptFiles}
            onChange={onFileSelect}
            onUpload={onUploadFile}
            uploadButtonProps={{
              disabled: loading || uploadDisabled || status === STATUS_PROCESSING || filesArray.length === 0
            }}
            headers={{ 'Content-Type': 'multipart/form-data' }}
            config={{
              trigger: true,
              type: 'inline',
              size: true,
              chunk: {
                enabled: false
              }
            }}
          />
          {/* <SubmitButton properties={{ canUpload, isDemo, fn: onUploadFile }} />  */}
        </UploaderWrapper>
        {status !== STATUS_IDLE && !stopLoadingStatuses.includes(downloadStatus)}
      </Wrapper>

      {isDemo && (
        <Button
          style={{ marginTop: 10, marginBottom: 25 }}
          data-test-label="demo-file-download-trigger"
          onMouseDown={() => downloadDemoFiles(handleId)}
        >
          {t('button.demoData')}
        </Button>
      )}

      {isDemo && demoDescription && <HandleDemoDescription description={demoDescription} />}
      {status !== STATUS_IDLE && (
        <HandleProgress
          handleName={handleName}
          handleId={handleId}
          status={downloadStatus}
          error={error}
          pauseAtDashboardReady={pauseAtDashboardReady}
          isDemo={isDemo}
        />
      )}
      {!error && !loading && (
        <WrapperResults>
          <DownloadFile data-test-label="download-container">
            {downloadStatus === STATUS_FORM_REQUIRED ? webForm : downloadResult}
            {showAllResults && !isDemo && <AllResults handleName={handleName} />}
            {integrations.map(integration => {
              if (!integration.isEnabled(transformedResults[handleName])) return null;
              return (
                <Button
                  className="a-btn-md"
                  key={integration.id}
                  style={{ marginLeft: '10px' }}
                  isLoading={loadingIntegrationRedirect}
                  disabled={!!integrationError}
                  onClick={loadIntegration(integration)}
                >
                  <span data-test-label={`${integration.id}-open`} className="Appkit4-icon icon-link-outline" />
                  {t('button.openIn', { integration: integration.name })}
                </Button>
              );
            })}
          </DownloadFile>
          {integrationError && (
            <Notification data-test-label="qlik-no-account" status="warning" message={integrationError} />
          )}
        </WrapperResults>
      )}
    </Container>
  );
};

export default HandleUpload;
