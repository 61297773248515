import React, { useCallback, useContext, memo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container, ContentWrapper } from '../../styles/GeneralElements';
import Footer from '../../footer/Footer';
import { APP_NAME } from '../../config';
import { QSFContext } from '../../context';

const BreadcrumbWrapper = memo(styled.div`
  z-index: 30;

  .ap-breadcrumb.negative span {
    color: white;
  }
`);

const VideoListWrapper = ({ videoName, children }) => {
  const { isPwCUser } = useContext(QSFContext);
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const goHome = useCallback(() => history.push('/handles'), [history]);
  const goVideos = useCallback(() => history.push('/videos'), [history]);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="videos" onClick={goVideos}>
      <span className="bc-text" tabIndex={2}>
        {t('menu.videos')}
      </span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  if (routeMatch.params?.handleId) {
    breadcrumbs.push(
      <BreadcrumbItem
        key={routeMatch.params.handleId}
        onClick={() => history.push(`/videos/${routeMatch.params.handleId}/${routeMatch.params.handleName}`)}
      >
        <span className="bc-text" tabIndex={3}>
          {routeMatch.params.handleName}
        </span>
      </BreadcrumbItem>
    );
  }

  if (videoName) {
    breadcrumbs.push(
      <BreadcrumbItem key={routeMatch.params?.videoId}>
        <span className="bc-text" tabIndex={4}>
          {videoName}
        </span>
      </BreadcrumbItem>
    );
  }

  return (
    <Container data-test-label="handles-container">
      <BreadcrumbWrapper>
        <Breadcrumb className="negative text-white">{breadcrumbs}</Breadcrumb>
      </BreadcrumbWrapper>
      <ContentWrapper>
        {children}
        <Footer />
      </ContentWrapper>
    </Container>
  );
};

VideoListWrapper.defaultProps = {
  videoName: ''
};

VideoListWrapper.propTypes = {
  videoName: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any).isRequired
};

export default VideoListWrapper;
