import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const PARTNER_ID = '1516131';
const UICONF_ID = '33735942';

export const Player = ({ targetId }) => {
  return (
    <div style={{ width: '100%', height: 543 }}>
      <div id={targetId} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

const KalturaPlayer = ({ videoId, ks }) => {
  const [loaded, setLoaded] = useState(false);
  const [targetId, setTargetId] = useState(null);

  // make sure this script is loaded before KalturaPlayer.setup calls
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://cdnapisec.kaltura.com/p/${PARTNER_ID}/embedPlaykitJs/uiconf_id/${UICONF_ID}`;
    document.getElementsByTagName('head')[0].appendChild(script);
    script.onload = () => setLoaded(true);
  }, []);

  useEffect(() => {
    if (videoId) {
      setTargetId(`v7_Player_DynamicEmbed_${videoId}`);
    }
  }, [videoId]);

  return (
    <>
      <Player targetId={targetId} />
      {loaded && (
        <Helmet>
          <script>
            {`
              try {
                if (window.kalturaPlayer) {
                  window.kalturaPlayer.destroy();
                }

                window.kalturaPlayer = KalturaPlayer.setup({
                "targetId": "${targetId}",
              
                "provider": {
                  "partnerId": "${PARTNER_ID}",
                  "uiConfId": "${UICONF_ID}",
                  "ks": "${ks}",
                },
                playback: {
                    autoplay: true,                                     
                }
                });

                window.kalturaPlayer.loadMedia({
                  "entryId": "${videoId}"
                });
              } catch (e) {
                console.error(e.message)
              }
        `}
          </script>
        </Helmet>
      )}
    </>
  );
};

KalturaPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  ks: PropTypes.string.isRequired
};

export default KalturaPlayer;
