import React, { useContext } from 'react';
import { Button } from '@appkit4/react-components/button';
import { List, ListItem } from '@appkit4/react-components/list';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../../context';
import Timer from '../../elements/Timer';
import { deleteDownload } from '../../util/requests';
import Result from '../downloadStatus/result/Result';

const HandleAvailableDownloads = props => {
  const { handleName, handleId, isDemo } = props;
  const { availableDownloads, setAvailableDownloads } = useContext(QSFContext);
  const { t } = useTranslation();
  const available =
    (!isDemo
      ? availableDownloads[handleId]?.filter(d => !d.isDemo)
      : availableDownloads[handleId]?.filter(d => d.isDemo)) ?? [];
  available.sort((a, b) => moment(b.created).diff(moment(a.created)));

  const handleDelete = reference => () => {
    deleteDownload(reference).then(data => {
      if (data?.data.success) {
        const filteredDownloads = available.filter(f => f.reference !== reference);
        if (filteredDownloads.length > 0) {
          setAvailableDownloads({ ...availableDownloads, [handleId]: filteredDownloads });
        } else {
          delete availableDownloads[handleId];
          setAvailableDownloads({ ...availableDownloads });
        }
      }
    });
  };

  const renderItem = download => {
    const expires = moment(download.expires);
    const created = moment(download.created);

    return (
      <ListItem key={download.id} role="checkbox" aria-live="off" className="py-0">
        <span>
          <div className="a-h6" style={{ marginTop: 15 }}>
            {t('handle.download.created')}: {created.format('DD-MMM-YYYY HH:mm:ss')}
          </div>
          <div className="a-opacity-50 a-h6 font-weight-normal" title={expires.format('DD-MMM-YYYY HH:mm:ss')}>
            {t('handle.download.expires')}&nbsp;
            <Timer end={expires} />
          </div>
        </span>
        <div className="ml-auto mb-auto" style={{ marginTop: 15, display: 'flex' }}>
          <Button
            data-test-label="delete-download"
            className="a-btn-md"
            onClick={handleDelete(download.reference)}
            compact
          >
            <span className="Appkit4-icon icon-delete-fill" />
          </Button>
          &nbsp;
          <Result
            data-test-label="stored-download"
            handle={handleName}
            handleId={handleId}
            reference={download.reference}
            template={() => t('button.download')}
          />
        </div>
      </ListItem>
    );
  };

  return (
    <List
      data={available}
      itemKey="reference"
      renderItem={renderItem}
      width="100%"
      style={{ display: 'inline-block' }}
    />
  );
};

export default HandleAvailableDownloads;
