import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Button } from '@appkit4/react-components/button';

export const dots = keyframes`
    0%, 12.5% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        text-shadow: .5em 0;
    }
    75% {
        text-shadow: .5em 0, 1em 0;
    }
`;

export const LoadingWrapper = React.memo(styled.div`
  height: calc(100% - 55px);
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
  }
`);

export const AnimatedWrapper = styled(LoadingWrapper)`
  > div {
    > span:after {
      content: ' .';
      animation: ${dots} 2s steps(1, end) infinite;
    }
  }
`;

export const ButtonHome = React.memo(styled(Button)`
  margin-right: 24px;
  background-color: #fff !important;
  color: #2d2d2d !important;
  border: 1px solid #2d2d2d;
  margin-top: 30px;
  font-size: 14px !important;

  &:hover {
    background-color: #efefef !important;
  }
`);
