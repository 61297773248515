import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormV2 from './forms2.0/FormWrapper';

const Form = ({ form, handleName, handleId, hasDashboard, hasInit, isDemo, isHandleSide, pauseAtDashboardReady }) => {
  const { version } = form;
  const { t } = useTranslation();

  if (version === 2) {
    return (
      <FormV2
        form={form}
        handleName={handleName}
        handleId={handleId}
        hasDashboard={hasDashboard}
        hasInit={hasInit}
        isDemo={isDemo}
        isHandleSide={isHandleSide}
        pauseAtDashboardReady={pauseAtDashboardReady}
      />
    );
  }

  return <div>{t('form.message.notSupported', { version })}</div>;
};

Form.defaultProps = {
  hasInit: false,
  isDemo: false,
  isHandleSide: false
};

Form.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleName: PropTypes.string.isRequired,
  handleId: PropTypes.string.isRequired,
  hasInit: PropTypes.bool,
  isDemo: PropTypes.bool,
  isHandleSide: PropTypes.bool
};

export default React.memo(Form);
