import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tag } from '@appkit4/react-components/tag';

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  overflow-x: auto;
  > span:not(:last-child) {
    margin-right: 10px;
  }
  > .ap-tag {
    cursor: pointer;
    min-height: 30px;
    margin-right: 5px;
    margin-bottom: 5px;

    .icon-close-outline {
      display: none;
    }
  }
  > .ap-tag-light {
    border: 1px solid #6c757d;
    color: #6c757d !important;
    background: transparent !important;
  }
`;

export const ComponentFilters = ({ filters, onChange }) => {
  if (filters == null || filters?.length === 0) {
    return null;
  }

  return (
    <SelectWrapper className="component-filters">
      {(filters || []).map(filter => (
        <Tag onClick={() => onChange(filter)} className={filter.active ? null : 'ap-tag-light'} key={filter.name}>
          {filter.name}
        </Tag>
      ))}
    </SelectWrapper>
  );
};

ComponentFilters.defaultProps = {
  filters: []
};

ComponentFilters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      dataKeys: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  ),
  onChange: PropTypes.func.isRequired
};

export default ComponentFilters;
