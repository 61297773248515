import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { sanitizer } from '../util';
import { getNewsDetail } from '../util/requests';
import { STATUS } from '../util/status';
import { HANDLE_TYPE } from '../util/handleType';
import { APP_NAME } from '../config';
import Footer from '../footer/Footer';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../elements/loading';
import { Wrapper, ContentWrapper, Container } from '../styles/GeneralElements';
import {
  NewsDetailPanelWrapper,
  NewsDetailContentWrapper,
  NewsDetailTitle,
  BackButton,
  NewsDetailInfoWrapper,
  NewsDetailTime,
  NewsDetailImage,
  MainBreakingNewsWrapper,
  ExtendedImageNewsWrapper
} from '../styles/NewsElements';
import Timer from '../elements/Timer';
import { QSFContext } from '../context';
import NewsListHeader from './NewsListHeader';

const NewsDetailPage = () => {
  const [info, setInfo] = useState({});
  const [status, setStatus] = useState(STATUS.LOADING);
  const history = useHistory();
  const { t } = useTranslation();
  const { isPwCUser } = useContext(QSFContext);
  const { uri, format } = queryString.parse(useLocation().search);
  const { title, excerpt, id, date, image } = info;
  const pastDate = moment(new Date(date));
  const newsBigImage = image?.sizes?.app;
  const newsSmallImage = image?.sizes?.thumb;

  const goHome = useCallback(() => history.push('/'), [history]);
  const goToNews = useCallback(() => history.push('/news'), [history]);

  useEffect(() => {
    const getData = async uri => {
      try {
        const response = await getNewsDetail(uri);
        setInfo(response.data);
        setStatus(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
      }
    };

    getData(uri);
  }, [uri]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator handleType={HANDLE_TYPE.DEFAULT} />;
  }

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="news" onClick={goToNews}>
      <span className="bc-text" tabIndex={2}>
        {t('menu.news')}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key={`news-${id}`}>
      <span className="bc-text" tabIndex={3}>
        {title}
      </span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  const isBreakingNews = format === 'highlighted';

  const HeaderRegular = () => (
    <div>
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <BackButton onClick={goToNews}>{t('button.back')}</BackButton>
    </div>
  );

  const HeaderBreaking = () => (
    <ExtendedImageNewsWrapper>
      <MainBreakingNewsWrapper image={newsBigImage}>
        <Breadcrumb className="negative">{breadcrumbs}</Breadcrumb>
        <BackButton onClick={goToNews}>{t('button.back')}</BackButton>
        <NewsListHeader info={info} isDetail />
      </MainBreakingNewsWrapper>
    </ExtendedImageNewsWrapper>
  );

  return (
    <Container>
      {isBreakingNews ? <HeaderBreaking /> : <HeaderRegular />}
      <ContentWrapper>
        <Wrapper data-test-label="news-detail-container">
          <NewsDetailPanelWrapper>
            <NewsDetailInfoWrapper>
              {!isBreakingNews && (
                <NewsDetailTime>
                  <span className="Appkit4-icon icon-time-outline" style={{ marginRight: 5 }} />
                  <Timer end={pastDate} />
                </NewsDetailTime>
              )}
            </NewsDetailInfoWrapper>
            <NewsDetailContentWrapper>
              {!isBreakingNews && <NewsDetailTitle>{title}</NewsDetailTitle>}
              {newsSmallImage && !isBreakingNews && <NewsDetailImage image={newsSmallImage} />}
              <div dangerouslySetInnerHTML={{ __html: sanitizer(excerpt) }} />
            </NewsDetailContentWrapper>
          </NewsDetailPanelWrapper>
        </Wrapper>
        <Footer />
      </ContentWrapper>
    </Container>
  );
};

export default NewsDetailPage;
