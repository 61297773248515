import React, { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';
import { Container, ContentWrapper } from '../styles/GeneralElements';
import Footer from '../footer/Footer';
import { APP_NAME } from '../config';

const DocumentsWrapper = ({ children }) => {
  const { isPwCUser } = useContext(QSFContext);
  const history = useHistory();
  const { t } = useTranslation();
  const goHome = useCallback(() => history.push('/'), [history]);
  const goDocs = useCallback(() => history.push('/documents'), [history]);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={0}>{APP_NAME}</span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="docs" onClick={goDocs}>
      <span className="bc-text" tabIndex={1}>{t('menu.documents')}</span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text">Doing Work</span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  const parsed = queryString.parse(useLocation().search);
  if (parsed.uri != null) {
    breadcrumbs.push(
      <BreadcrumbItem key="document-details">
        <span className="bc-text" tabIndex={2}>{t('menu.documentDetails')}</span>
      </BreadcrumbItem>
    );
  }

  return (
    <Container data-test-label="handles-container">
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <ContentWrapper>
        {children}
        <Footer />
      </ContentWrapper>
    </Container>
  );
};

export default DocumentsWrapper;
