import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DemoInfoWrapper, DemoInfoHeader, DemoInfoTitle, DemoInfoBody } from '../../styles/DiscoverElements';

const Li = styled.li`
  list-style: decimal;
  margin-left: 18px;
`;

const DemoInformationBlock = () => {
  const { t } = useTranslation();

  return (
    <DemoInfoWrapper>
      <DemoInfoHeader>
        <span className="Appkit4-icon icon-information-fill" />
        <DemoInfoTitle>{t('demo.pleaseUnderstand')}</DemoInfoTitle>
      </DemoInfoHeader>
      <DemoInfoBody>
        <ul>
          <Li>{t('demo.note1')}</Li>
          <Li>{t('demo.note2')}</Li>
          <Li>{t('demo.note3')}</Li>
        </ul>
      </DemoInfoBody>
    </DemoInfoWrapper>
  );
};

export default DemoInformationBlock;
