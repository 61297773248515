import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Button } from '@appkit4/react-components/button';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
// TODO: check build error "Dependency cycle via..."
// so we can remove next eslint line.
// eslint-disable-next-line import/no-cycle
import { getDefaultValue } from '../../helpers';
import { FIELD } from '../../enums';
import { FormContext } from '../../formComponents';
import GroupedFields from './GroupedFields';

const STATIC_FIELDS = Object.freeze([FIELD.HTML]);
const getDefault = fields => {
  const defaultValues = {};
  fields.forEach(field => {
    if (field.visible === false || STATIC_FIELDS.includes(field.type)) return;
    defaultValues[field.id] = getDefaultValue(field);
  });
  return defaultValues;
};

const Group = ({ field, value, onChange }) => {
  const { t } = useTranslation();
  const {
    id,
    fields,
    required,
    style,
    removeText,
    addText,
    collapseText,
    expandText,
    groupNameText,
    useGroupNameText
  } = field;
  // when field is required, at least one group of fields must be filled
  const canRemoveGroup = (required && value.length > 1) || !required;
  const {
    formImport,
    updateGroupName,
    updateGroupExpanded,
    groupNames: groups,
    expanded: expand
  } = useContext(FormContext);
  const groupNames = groups?.[id] || value.map(_ => '');
  const expanded = expand?.[id] || value.map((_, i) => i);
  const [keys, setKeys] = useState(value.map(_ => uuidv4()));

  // when value changes on import, update the keys to trigger a re-render of grouped fields
  useEffect(() => {
    if (formImport) {
      setKeys(value.map(_ => uuidv4()));
    }
  }, [formImport, value]);

  // if field is required, make sure there is always at least one group of fields
  useEffect(() => {
    if (value.length === 0) {
      onChange(id, [getDefault(fields)]);
    }
  }, [fields, id, onChange, required, value]);

  const onFieldChange = useCallback(
    (index, v) => {
      const newValue = cloneDeep(value);
      newValue[index] = v;
      onChange(id, newValue);
    },
    [onChange, value, id]
  );

  const onRemove = useCallback(
    index => {
      const newValue = cloneDeep(value);
      newValue.splice(index, 1);
      onChange(id, newValue);
      updateGroupExpanded(
        id,
        expanded.filter(i => i !== index).map(i => (i > index ? i - 1 : i))
      );
      const newGroupNames = [...groupNames];
      newGroupNames.splice(index, 1);
      updateGroupName(id, newGroupNames);
      setKeys(newValue.map(_ => uuidv4()));
    },
    [id, onChange, value, expanded, groupNames, updateGroupExpanded, updateGroupName]
  );

  const onAdd = useCallback(() => {
    const newValue = cloneDeep(value);
    const emptyRow = getDefault(fields);
    newValue.push({ ...emptyRow });
    onChange(id, newValue);
    updateGroupExpanded(id, [...expanded, value.length]);
    updateGroupName(id, [...groupNames, '']);
    const newKeys = [...keys];
    newKeys.push(uuidv4());
    setKeys(newKeys);
  }, [id, onChange, value, fields, expanded, groupNames, updateGroupExpanded, updateGroupName, keys]);

  const onExpand = useCallback(
    index => {
      updateGroupExpanded(id, [...expanded, index]);
    },
    [expanded, id, updateGroupExpanded]
  );

  const onCollapse = useCallback(
    index => {
      updateGroupExpanded(
        id,
        expanded.filter(i => i !== index)
      );
    },
    [expanded, id, updateGroupExpanded]
  );

  const onGroupNameChange = useCallback(
    (index, name) => {
      const newGroupNames = [...groupNames];
      newGroupNames[index] = name;
      updateGroupName(id, newGroupNames);
    },
    [groupNames, id, updateGroupName]
  );

  return (
    <>
      {value.map((_, index) => (
        <GroupedFields
          key={keys[index]}
          value={value[index]}
          index={index}
          style={style}
          fields={fields}
          groupName={groupNames[index]}
          isExpanded={expanded.includes(index)}
          canRemoveGroup={canRemoveGroup}
          expandText={expandText}
          collapseText={collapseText}
          removeText={removeText}
          groupNameText={groupNameText}
          onFieldChange={onFieldChange}
          onRemoveGroup={onRemove}
          onCollapse={onCollapse}
          onExpand={onExpand}
          onGroupNameChange={onGroupNameChange}
          useGroupNameText={useGroupNameText}
        />
      ))}
      <Button onClick={() => onAdd()} data-test-label="add-group-btn">
        {addText || t('button.add')}
      </Button>
    </>
  );
};

Group.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    style: PropTypes.objectOf(PropTypes.any),
    removeText: PropTypes.string,
    addText: PropTypes.string,
    collapseText: PropTypes.string,
    expandText: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Group;
