import React, { useRef, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Gauge } from '../../../../components/Gauge';
import { DashboardContext } from '../../DashboardContext';

const GaugeContainer = styled.div`
  width: 100%;
  height: calc(100% - 20px) !important;
  > div {
    width: auto !important;
    display: flex;
    justify-content: center;
    > svg {
      overflow: visible;
    }
  }
`;

export const GaugeValue = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 1.8125rem;
  font-weight: 300;
  line-height: 1.31;
`;

const GaugeDiagram = ({ properties, id, title, data }) => {
  const { addComponentRef } = useContext(DashboardContext);

  const containerRef = useRef();
  const ref = useRef(null);

  // TODO Remove this when back-end version of PDF-creator is implemented
  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node?.gaugeDiv);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  useEffect(() => {
    const resizeListener = () => {
      const height = containerRef.current.offsetHeight;
      const width = containerRef.current.offsetWidth;
      if (width < height * 2) setContainerWidth(width);
      else setContainerWidth(height * 2);
    };
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  const componentData = data[properties.data];
  const displayData = properties?.displayData ? data[properties.displayData] : null;
  const [containerWidth, setContainerWidth] = useState(100);

  return (
    <>
      <GaugeContainer ref={containerRef}>
        <Gauge
          {...properties}
          ref={ref}
          value={componentData}
          fluidWidth={false}
          width={containerWidth}
          height={containerWidth / 2}
        />
      </GaugeContainer>
      {displayData && <GaugeValue>{displayData}</GaugeValue>}
    </>
  );
};

GaugeDiagram.defaultProps = {
  properties: {}
};

GaugeDiagram.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default GaugeDiagram;
