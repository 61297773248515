import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const TextContainer = React.memo(
  styled.div(({ additionalStyle }) => ({
    height: '100%',
    width: '100%',
    overflow: 'hidden auto',
    wordBreak: 'break-word',
    'p:last-child': {
      marginBottom: 0
    },
    '>': additionalStyle ?? {}
  }))
);

// Regex looking for a string which matches {{something}} or nested with dots, like {{something.something}}
const variableRegex = /{{((\w+\.?)*)}}/g;

const TextElement = ({ properties, data }) => {
  const { numberFormat, value, style } = properties;

  const componentValue = (value ?? '').replace(variableRegex, (match, matchWithoutBrackets) => {
    // Extract the variable. If it includes dots, then look for a nested data variable.
    const splitted = matchWithoutBrackets.split('.');
    const nested = splitted.map(b => [b]);
    let variableData = nested.reduce((a, b) => a?.[b], data);

    if (variableData === null) return match;

    // Check if the given variable exists in the numberFormat property.
    if (numberFormat?.[matchWithoutBrackets] == null) return variableData;

    const formatProps = { ...numberFormat[matchWithoutBrackets] };
    delete formatProps.locale;
    variableData = new Intl.NumberFormat(numberFormat[matchWithoutBrackets]?.locale ?? 'nl-NL', formatProps).format(
      +variableData
    );

    return variableData;
  });

  const components = {
    a: ({ node, ...props }) => {
      return (
        <a {...props} target={props.target || '_blank'} rel="noopener noreferrer">
          {props.children}
        </a>
      );
    }
  };

  return (
    <TextContainer additionalStyle={style} className="text-element markdown">
      <ReactMarkdown remarkPlugins={[gfm]} components={components} rehypePlugins={[rehypeRaw]}>
        {componentValue}
      </ReactMarkdown>
    </TextContainer>
  );
};

TextElement.propTypes = {
  properties: PropTypes.shape({
    value: PropTypes.string.isRequired,
    style: PropTypes.objectOf(PropTypes.any),
    numberFormat: PropTypes.objectOf(PropTypes.objectOf(PropTypes.any))
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default TextElement;
