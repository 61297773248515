import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TIMEOUT_HANDLE_LOADING } from '../../config';
// TODO: check build error "Dependency cycle via..."
// so we can remove next eslint line.
// eslint-disable-next-line import/no-cycle
import { LoadingIndicator, UnauthorizedIndicator } from './index';

const TimeoutLoadingIndicator = ({ TimedOutIndicator }) => {
  const [didTimeout, setDidTimeout] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setDidTimeout(true), TIMEOUT_HANDLE_LOADING);
    return () => clearTimeout(timer);
  }, []);

  if (didTimeout) {
    if (TimedOutIndicator) return TimedOutIndicator;
    return <UnauthorizedIndicator />;
  }
  return <LoadingIndicator />;
};

TimeoutLoadingIndicator.defaultProps = {
  TimedOutIndicator: null
};

TimeoutLoadingIndicator.propTypes = {
  TimedOutIndicator: PropTypes.element
};

export default TimeoutLoadingIndicator;
