import React from 'react';
import DashboardContent from '../dashboard/DashboardContent';
import { ContextProvider } from '../dashboard/DashboardContext';

// data
import data from './data.json';

// pages
import dashboard_page from './pages/dashboard.json';
import order_statistics_detail_page from './pages/order_statistics_detail.json';
import pie_charts_detail_page from './pages/pie_charts_detail.json';
import waterfall_chart_detail_page from './pages/waterfall_chart_detail.json';

// components
import index_tree_detail from './components/index_tree_detail.json';
import activity_per_month from './components/activity_per_month.json';
import area_chart from './components/area_chart.json';
import composed_chart from './components/composed_chart.json';
import filter_element from './components/filter_element.json';
import filter_element2 from './components/filter_element2.json';
import filter_quarter from './components/filter_quarter.json';
import filter_world_map from './components/filter_world_map.json';
import funnel_chart from './components/funnel_chart.json';
import form_element from './components/form_element.json';
import gauge_chart from './components/gauge_chart.json';
import image_element from './components/image_element.json';
import order_statistics_detail from './components/order_statistics_detail.json';
import order_statistics from './components/order_statistics.json';
import pdf_viewer from './components/pdf_viewer.json';
import pie_chart from './components/pie_chart.json';
import pie_charts_detail from './components/pie_charts_detail.json';
import radar_chart from './components/radar_chart.json';
import radial_bar_chart from './components/radial_bar_chart.json';
import result_element from './components/result_element.json';
import sankey_chart from './components/sankey_chart.json';
import scatter_chart from './components/scatter_chart.json';
import simple_pie_chart from './components/simple_pie_chart.json';
import links_to_pdf from './components/links_to_pdf.json';
import table_element from './components/table_element.json';
import table_element_aggregated from './components/table_elements_aggregated.json';
import text_element from './components/text_element.json';
import top_bar from './components/top_bar.json';
import total_cost from './components/total_cost.json';
import total_revenue from './components/total_revenue.json';
import total_sales from './components/total_sales.json';
import treemap_chart from './components/treemap_chart.json';
import world_map from './components/world_map.json';
import waterfall_chart from './components/waterfall_chart.json';
import waterfall_chart_detail from './components/waterfall_chart_detail.json';

const dashboard = {
  pages: {
    dashboard: dashboard_page,
    order_statistics_detail: order_statistics_detail_page,
    pie_charts_detail: pie_charts_detail_page,
    waterfall_chart_detail: waterfall_chart_detail_page
  },
  components: {
    index_tree_detail,
    activity_per_month,
    area_chart,
    composed_chart,
    filter_element,
    filter_element2,
    filter_quarter,
    filter_world_map,
    funnel_chart,
    form_element,
    links_to_pdf,
    gauge_chart,
    image_element,
    order_statistics_detail,
    order_statistics,
    pdf_viewer,
    pie_chart,
    pie_charts_detail,
    radar_chart,
    radial_bar_chart,
    result_element,
    sankey_chart,
    scatter_chart,
    simple_pie_chart,
    table_element,
    table_element_aggregated,
    text_element,
    top_bar,
    total_cost,
    total_revenue,
    total_sales,
    treemap_chart,
    world_map,
    waterfall_chart,
    waterfall_chart_detail
  }
};

const DummyDashboard = () => {
  return (
    <ContextProvider>
      <DashboardContent dashboard={dashboard} initialData={data} disclaimer="i am a dummy disclaimer" />
    </ContextProvider>
  );
};

export default DummyDashboard;
