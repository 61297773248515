import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { useHistory, useRouteMatch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '../../styles/GeneralElements';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../../elements/loading';
import { STATUS } from '../../util/status';
import { getHandleById } from '../../util/requests';
import { INTERACTIVE_DASHBOARD_ONLY, APP_NAME } from '../../config';
import HandleHeader from '../HandleHeader';
import { QSFContext } from '../../context';
import { DashboardContext } from './DashboardContext';

// use the page ID as page title, replace _ with spaces and capitalize first letter of each word
const getPageName = pageId => {
  return pageId.replace(/_/g, ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
};

// The RecursiveBreadcrumb component gets pushed as one breadcrumb which makes it lose the separators.
// Make sure to style the individual crumbs within RecursiveBreadcrumb the same as appkit.
export const CustomBreadcrumb = ({ pageId, onClick }) => {
  return (
    <span className="a-bread-item">
      <span className="a-breadcrumb-content" onClick={onClick}>
        <span className="bc-text">{getPageName(pageId)}</span>
      </span>
      <span className="a-breadcrumb-separator">/</span>
    </span>
  );
};

export const RecursiveBreadcrumb = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const goToPage = url => history.push(url);
  const { page } = match.params;

  if (match.isExact) {
    return (
      <BreadcrumbItem>
        <span className="bc-text" tabIndex={5}>{getPageName(page)}</span>
      </BreadcrumbItem>
    );
  }

  return (
    <Route path={`${match.url}/:page`}>
      <CustomBreadcrumb pageId={page} onClick={() => goToPage(match.url)} />
      <RecursiveBreadcrumb />
    </Route>
  );
};

const DashboardWrapper = props => {
  const { children, showBreadcrumbs, printPage, hasIndexComponent } = props;
  const match = useRouteMatch();
  const { t } = useTranslation();
  const [info, setInfo] = useState({});
  const { handleName, handleId, reference, page } = match.params;
  const [status, setStatus] = useState(STATUS.LOADING);
  const history = useHistory();
  const notFunctional = info?.functional !== true;
  const interactiveDashboardOnly = info?.handleType === INTERACTIVE_DASHBOARD_ONLY;
  const { filters, dataSubsets } = useContext(DashboardContext);
  const { isPwCUser } = useContext(QSFContext);

  const goHome = () => history.push('/handles');
  const goToHandle = () => history.push(`/handles/${handleId}`);
  const goToDashboard = () => history.push(`/handles/${handleId}/${handleName}/dashboard/${reference}`);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>{APP_NAME}</span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={2}>{t('menu.myApps')}</span>
    </BreadcrumbItem>,
    <BreadcrumbItem key={handleName} onClick={goToHandle}>
      <span className="bc-text" tabIndex={3}>{handleName}</span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="dashboard" onClick={goToDashboard}>
      <span className="bc-text" tabIndex={4}>{t('menu.dashboard')}</span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>Doing Work</span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  // if we're not on the default page, we add a recursive breadcrumb containing as many breadcrumbs as necessary
  if (page) {
    breadcrumbs.push(<RecursiveBreadcrumb key="recursive-crumbs" />);
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getHandleById(handleId);
        setInfo(response.data);
        setStatus(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
      }
    };

    getData();
  }, [handleName, handleId]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator />;
  }

  // adjust the margins for (interactive) dashboard only handles when there are no buttons to render
  const noButtons = !printPage && !hasIndexComponent && filters.length === 0 && dataSubsets.length === 0;

  return (
    <Container data-test-label="handles-container">
      {showBreadcrumbs && <Breadcrumb>{breadcrumbs}</Breadcrumb>}
      {(notFunctional || interactiveDashboardOnly) && (
        <div style={{ marginBottom: noButtons ? '20px' : '40px' }}>
          <HandleHeader id={info.id} handleName={handleName} />
        </div>
      )}
      {children}
    </Container>
  );
};

DashboardWrapper.defaultProps = {
  children: null,
  showBreadcrumbs: true,
  printPage: true
};

DashboardWrapper.propTypes = {
  children: PropTypes.node,
  showBreadcrumbs: PropTypes.bool,
  printPage: PropTypes.bool
};

export default DashboardWrapper;
