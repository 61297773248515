import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Checkbox } from '@appkit4/react-components/checkbox';
import { List, ListItem } from '@appkit4/react-components/list';
import { Input } from '@appkit4/react-components/field';
import { useTranslation } from 'react-i18next';
import { DashboardContext } from '../DashboardContext';

const FilterContainer = React.memo(styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  > ul.a-list {
    width: 100%;
    height: 100%;
    > li.a-list-item {
      padding: 6px 0;
      min-height: 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  > .a-input-size-sm {
    margin-bottom: 15px;
    input:focus {
      box-shadow: none !important;
      border-color: #d04a02;
    }
  }

  .ap-list {
    overflow: scroll;
  }

  .ap-list-item {
    padding: 0;
  }
`);

const FilterElement = ({ properties, data }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const { dataKey, single, filterSelf, label, sort, stickSelected, searchable, selectAll, selectFirst } = properties;
  const componentData = filterSelf
    ? data[properties.data]
    : data[`${properties.data}_original`] ?? data[properties.data];
  const { dataFilters, setDataFilters } = useContext(DashboardContext);

  const contextDataFilters = { ...dataFilters };

  // Check if the filters for this given dataset and dataKey already exist in the context, if not add it.
  if (contextDataFilters?.[properties.data] === undefined) {
    contextDataFilters[properties.data] = {};
  }
  if (contextDataFilters[properties.data]?.[dataKey] === undefined) {
    contextDataFilters[properties.data][dataKey] = [];
  }

  const activeFilters = contextDataFilters[properties.data][dataKey];

  // Select the first element in the list if 'selectFirst' is true to reduce overwhelming the user with data in the dashboard component
  useEffect(() => {
    if (selectFirst && componentData?.length > 0) {
      const firstElement = componentData[0][dataKey];

      // Add the first element to the dataFilters if it doesn't already exist
      setDataFilters(prevDataFilters => {
        const currentDataFilters = prevDataFilters[properties.data] || {};
        const currentDataKeyFilters = currentDataFilters[dataKey] || [];
        if (!currentDataKeyFilters.includes(firstElement) && currentDataKeyFilters.length === 0) {
          return {
            ...prevDataFilters,
            [properties.data]: {
              ...currentDataFilters,
              [dataKey]: [firstElement, ...currentDataKeyFilters]
            }
          };
        }
        return prevDataFilters;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!componentData?.length || !dataKey) return null;

  // Get unique items from the componentData array and filter if enabled.
  const uniqueFilters = [...componentData].filter((item, index, all) => {
    // If searchable is enabled and query has been entered, filter the list.
    if (
      searchable &&
      item[label ?? dataKey].toString().toLowerCase().trim().indexOf(searchQuery.toLowerCase().trim()) === -1
    )
      return false;

    return all.findIndex(firstItem => firstItem[dataKey] === item[dataKey]) === index;
  });

  // Sort the items alphabetically
  if (sort) {
    uniqueFilters.sort((a, b) => (a[label ?? dataKey] < b[label ?? dataKey] ? -1 : 1));
  }

  // Place selected items on the top of the filter box
  if (stickSelected) {
    uniqueFilters.sort(a => (activeFilters.indexOf(a[dataKey]) > -1 ? -1 : 1));
    // If sort is also enabled, first place selected items on top then sort that part afterwards.
    if (sort) {
      uniqueFilters.sort((a, b) => {
        if (activeFilters.indexOf(a[dataKey]) === -1) return 0;
        return a[label ?? dataKey] < b[label ?? dataKey] ? -1 : 1;
      });
    }
  }

  const onCheckAll = checked => {
    const filters = !checked ? [] : uniqueFilters.map(filter => filter[dataKey]);
    const currentFilters = { ...contextDataFilters };
    currentFilters[properties.data][dataKey] = filters;
    setDataFilters(currentFilters);
  };

  const selectAllItem = (
    <>
      <ListItem key="list-item-check-all-checkbox">
        <Checkbox checked={activeFilters.length === uniqueFilters?.length} onChange={value => onCheckAll(value)}>
          {t('dashboard.selectAll')}
        </Checkbox>
      </ListItem>
      <hr style={{ margin: '5px 0 0 0', paddingBottom: 10 }} />
    </>
  );

  const dataArray = (uniqueFilters || []).map(item => ({
    id: item[dataKey],
    label: label ? item[label] : item[dataKey]
  }));

  const eventCheck = event => {
    return event.type === 'click' || event.key === 'Enter';
  };

  const handleSelection = (event, item) => {
    if (eventCheck(event)) {
      const { id } = item;

      let filters = [...activeFilters];
      if (activeFilters.indexOf(id) > -1) {
        filters = filters.filter(item => item !== id);
      } else filters = single ? [id] : [...filters, id];

      const currentFilters = { ...contextDataFilters };
      currentFilters[properties.data][dataKey] = filters;
      setDataFilters(currentFilters);
    }
  };

  const getAriaLabelWithCheckbox = item => {
    return activeFilters.indexOf(item.id) !== -1 ? item.label : ' ';
  };

  const renderItem = (item, index) => {
    return (
      <ListItem
        key={index}
        role="checkbox"
        aria-label={getAriaLabelWithCheckbox(item)}
        aria-live="off"
        aria-checked={activeFilters.indexOf(item.id) !== -1}
        onClick={e => {
          handleSelection(e, item);
        }}
      >
        <Checkbox checked={activeFilters.indexOf(item.id) !== -1} tabIndex={-1}>
          {item.label}
        </Checkbox>
      </ListItem>
    );
  };

  return (
    <FilterContainer>
      {searchable && (
        <Input type="text" placeholder={t('dashboard.search')} value={searchQuery} onChange={setSearchQuery} />
      )}
      {selectAll && selectAllItem}
      <List itemKey="id" data={dataArray} renderItem={renderItem} style={{ display: 'inline-block' }} />
    </FilterContainer>
  );
};

FilterElement.propTypes = {
  properties: PropTypes.shape({
    data: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    label: PropTypes.string,
    single: PropTypes.bool,
    filterSelf: PropTypes.bool,
    stickSelected: PropTypes.bool,
    sort: PropTypes.bool,
    searchable: PropTypes.bool,
    selectAll: PropTypes.bool
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default FilterElement;
