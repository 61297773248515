import React, { useState, useContext } from 'react';
import { Select } from '@appkit4/react-components/select';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';

const subscriptionsStyle = {
  width: 300,
  marginBottom: 40
};

const SubscriptionsSelector = ({ onModifySelected, subsSet }) => {
  const { subscriptionsSelected } = useContext(QSFContext);
  const { t } = useTranslation();
  const subsSelectedContextTemp = subscriptionsSelected.length === 0 ? subsSet : subscriptionsSelected;
  const [subsSelectedTemp, setSubsSelectedTemp] = useState(subsSelectedContextTemp);

  return (
    <Select
      placeholder={t('news.selectTopics')}
      multiple
      showSelectAll
      style={subscriptionsStyle}
      value={subsSelectedTemp}
      onSelect={e => setSubsSelectedTemp(e)}
      onClose={onModifySelected(subsSelectedTemp)}
      data={subsSet}
      />
  );
};

export default SubscriptionsSelector;
