import React from 'react';

const ZoomInIcon = ({ height, width, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} fill={fill}>
    <path
      fill={fill}
      d="M3.75 7.5a.75.75 0 0 1 .75-.75h2.25V4.5a.75.75 0 0 1 1.5 0v2.25h2.25a.75.75 0 0 1 0 1.5H8.25v2.25a.75.75 0 0 1-1.5 0V8.25H4.5a.75.75 0 0 1-.75-.75Z M7.5 0a7.5 7.5 0 0 1 5.807 12.247l2.473 2.473a.749.749 0 1 1-1.06 1.06l-2.473-2.473A7.5 7.5 0 1 1 7.5 0Zm-6 7.5a6 6 0 1 0 12 0 6 6 0 0 0-12 0Z"
    />
  </svg>
);

export default ZoomInIcon;
