import React from 'react';
import styled from 'styled-components';
import { ChevronRightIcon } from '@primer/octicons-react';
import { Button } from '@appkit4/react-components/button';

export const MainBreakingNewsWrapper = React.memo(styled.div`
  padding-top: 40px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  background: ${props =>
    `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(api/news/static/images?uri=${props.image}), #ccc`};
  background-position: center center;
  background-size: cover;
  .ap-breadcrumb {
    padding-left: 64px;
  }

  .ap-breadcrumb.negative span {
    color: white;
  }

  button {
    margin-left: 64px;
  }
`);

export const ExtendedImageNewsWrapper = React.memo(styled.div`
  margin-top: -40px;
  margin-bottom: -83px;
  margin-left: -64px;
  margin-right: -64px;
`);

export const MainNewsItem = React.memo(styled.div`
  padding: 170px 16px 0;
  max-width: 1200px;
  margin: 0 auto;
`);

export const BreakingIcon = React.memo(styled.div`
  display: flex;
  font-size: 14px;
  margin-bottom: 6px;
  color: #fff;
`);

export const BreakingRedDot = React.memo(styled.div`
  height: 12px;
  width: 12px;
  background-color: #e0301e;
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 5px;
`);

export const MainNewsTitle = React.memo(styled.h2`
  width: 800px;
  margin-bottom: 24px;
  color: #fff;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &.link {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media (max-width: 1400px) {
    width: 600px;
    font-size: 26px;
  }
`);

export const MainNewsInfo = React.memo(styled.div`
  display: flex;
`);

export const MainNewsTime = React.memo(styled.span`
  font-size: 14px;
  margin-right: 10px;
  color: #fff;

  @media (max-width: 1400px) {
    font-size: 12px;
  }
`);

export const NewsTag = React.memo(styled.div`
  border-radius: 15px;
  background-color: #e0301e;
  color: #fff;
  font-size: 11px;
  padding: 2px 6px;
  margin-right: 8px;
  margin-bottom: 8px;

  @media (max-width: 1400px) {
    font-size: 10px;
  }
`);

export const NewsTagInverted = React.memo(styled.div`
  color: #e0301e;
  font-size: 12px;
  padding: 2px 6px;
  margin-right: 8px;
  margin-bottom: 8px;

  @media (max-width: 1400px) {
    font-size: 10px;
  }
`);

export const HighlightedWrapper = React.memo(styled.div`
  max-width: 1200px;
  margin: -110px auto;
  z-index: 20;
`);

export const HighlightedIcon = React.memo(styled.span`
  background-color: #e0301e;
  color: #fff;
  padding: 10px 24px;
  display: inline-block;
`);

export const HighlightedBlockWrapper = React.memo(styled.div`
  width: 1200px;
  min-height: 140px;
  background-color: #fff;
  display: flex;
  -webkit-box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);

  @media (max-width: 1400px) {
    width: 1000px;
  }

  @media (max-width: 1100px) {
    width: 900px;
  }
`);

export const HighlightedNewsItem = React.memo(styled.div`
  width: 33.3%;
  border-left: ${props => (props.middle ? '1px solid #eee' : '0')};
  border-right: ${props => (props.middle ? '1px solid #eee' : '0')};
  padding: 24px;
  display: flex;
`);

export const HighlightedNewsImage = React.memo(styled.div`
  width: 92px;
  height: 92px;
  background: ${props => `url(api/news/static/images?uri=${props.image}), #ccc`};
  background-position: center center;
  background-size: cover;
  margin-right: 16px;
`);

export const HighlightedNewsItemInfoWrapper = React.memo(styled.div`
  flex: 1;
  margin-top: 5px;
`);

export const HighlightedNewsInfo = React.memo(styled.div`
  display: flex;
`);

export const HighlightedNewsTime = React.memo(styled.span`
  font-size: 14px;
  margin-right: 10px;
  color: #7d7d7d;

  @media (max-width: 1400px) {
    font-size: 12px;
  }

  @media (max-width: 1100px) {
    font-size: 10px;
  }
`);

export const HighlightedNewsTitle = React.memo(styled.h4`
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 5px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
  }
`);

export const FeaturedNewsWrapper = React.memo(styled.div`
  display: inline-flex;
  gap: 64px;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 1400px) {
    gap: 32px;
  }
`);

export const FeaturedNews = React.memo(styled.div`
  height: 365px;
  width: 50%;
  background: ${props =>
    `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(api/news/static/images?uri=${props.image}), #ccc`};
  background-position: center center;
  background-size: cover;
  padding: 24px;
  display: flex;
  align-items: flex-end;
  border-radius: 2px;
`);

export const FeaturedNewsInfo = React.memo(styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`);

export const FeaturedNewsTitle = React.memo(styled.h4`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #fff;
  font-size: 22px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`);

export const RelevantNewsWrapper = React.memo(styled.div`
  display: grid;
  grid-gap: 40px 32px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: start;
  margin-bottom: 64px;

  @media (max-width: 1400px) {
    grid-gap: 40px 16px;
  }

  .a-tooltip-ref-inner {
    height: 100%;

    .ap-panel.a-shadow-sm {
      height: 100%;
      margin-bottom: 0;
    }
  }
`);

export const RelevantNewsTileWrapper = React.memo(styled.div`
  min-width: 200px;
  height: 450px;
  position: relative;
  padding: 8px !important;
`);

export const RelevantImageWrapper = React.memo(styled.div`
  height: 144px;
  width: 100%;
  background: ${props => `url(api/news/static/images?uri=${props.image}), #ccc`};
  background-position: center center;
  background-size: cover;
`);

export const RelevantNewsTagsWrapper = React.memo(styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 8px 0px;

  &.bottom-tags {
    position: absolute;
    bottom: 55px;
  }
`);

export const RelevantText = React.memo(styled.div`
  min-width: 0;
`);

export const RelevantNewsTitle = React.memo(styled.div`
  font-weight: 700;
  margin: 8px;

  span {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media (max-width: 1400px) {
      font-size: 12px;
    }
  }
`);

export const RelevantNewsDescription = React.memo(styled.div`
  color: #7d7d7d;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0 8px;
  line-height: 28px;

  @media (max-width: 1400px) {
    font-size: 12px;
  }
`);

export const RelevantNewsButton = React.memo(styled(Button)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background-color: #464646 !important;

  &:hover {
    background-color: #7d7d7d !important;
  }
`);

export const BackButton = React.memo(styled(Button)`
  background-color: #464646 !important;
  width: fit-content;

  &:hover {
    background-color: #7d7d7d !important;
  }
`);

export const RelevantTagsWrapper = React.memo(styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 8px 0px;

  &.bottom-tags {
    position: absolute;
    bottom: 55px;
  }
`);

export const ChevronRight = React.memo(styled(ChevronRightIcon)`
  margin-left: 5px;
  margin-top: 1px;
  margin-right: -5px;

  path {
    fill: #ffffff;
  }
`);

export const RegularNewsWrapper = React.memo(styled.div`
  display: flex;
  flex-wrap: wrap;
`);

export const RegularNewsItem = React.memo(styled.div`
  width: 33.3%;
  border-right: ${props => (props.position % 3 === 0 ? '0' : '1px solid #dedede')};
  height: 120px;
  padding: ${props => ((props.position - 1) % 3 === 0 ? '0 10px 40px 0px' : '0 10px 40px 20px')};
  display: flex;
`);

export const RegularNewsImage = React.memo(styled.div`
  width: 76px;
  height: 76px;
  background: ${props => `url(api/news/static/images?uri=${props.image}), #ccc`};
  background-position: center center;
  background-size: cover;
  margin-right: 24px;
`);

export const RegularNewsItemInfoWrapper = React.memo(styled.div`
  flex: 1;
`);

export const RegularNewsTitle = React.memo(styled.h4`
  font-size: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
  }
`);

export const RegularNewsInfo = React.memo(styled.div`
  display: flex;
`);

export const RegularNewsTime = React.memo(styled.span`
  font-size: 14px;
  margin-right: 10px;
  color: #7d7d7d;

  @media (max-width: 1400px) {
    font-size: 12px;
  }

  @media (max-width: 1100px) {
    font-size: 10px;
  }
`);

export const NewsDetailPanelWrapper = React.memo(styled.div`
  max-width: 1200px;
  min-height: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  -webkit-box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 20%);
`);

export const NewsDetailTitle = React.memo(styled.h2`
  margin-bottom: 36px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`);

export const NewsDetailContentWrapper = React.memo(styled.div`
  padding: 56px 120px;
  color: #464646;
  line-height: 28px;
`);

export const NewsDetailImage = React.memo(styled.div`
  height: 365px;
  width: 568px;
  background: ${props => `url(api/news/static/images?uri=${props.image}), #ccc`};
  background-position: center center;
  background-size: cover;
  margin: 40px auto;
`);

export const NewsDetailInfoWrapper = React.memo(styled.div`
  padding: 0px 120px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #dedede;
`);

export const NewsDetailTime = React.memo(styled.span`
  padding: 16px 0;
  font-size: 16px;
  color: #7d7d7d;
`);

export const ActionButton = React.memo(styled.span`
  padding: 19px;
  font-size: 18px;
  color: #7d7d7d;
  cursor: pointer;

  &:hover {
    background-color: #dedede;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
  }
`);
