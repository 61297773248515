import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataComponents from '../../data-components';
import ValidComponent from './ValidComponent';

export const InvalidComponent = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`;

const BlockContent = ({ component }) => {
  const { t } = useTranslation();

  if (component == null) {
    return <InvalidComponent>{t('dashboard.invalidComponent')}</InvalidComponent>;
  }

  const { type } = component;
  if (DataComponents[type] == null) {
    return <InvalidComponent>{t('dashboard.invalidComponentType', { type: component.type })}</InvalidComponent>;
  }

  return <ValidComponent component={component} />;
};

BlockContent.propTypes = {
  component: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    properties: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.arrayOf(PropTypes.object)
  })
};

export default BlockContent;
