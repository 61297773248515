import {
  STATUS_ASSEMBLING_ERROR,
  STATUS_ASSEMBLING_STARTED,
  STATUS_ASSEMBLING_SUCCESS,
  STATUS_ASSEMBLING_UPDATE,
  STATUS_TRANSFORMATION_ERROR,
  STATUS_TRANSFORMATION_RECEIVED,
  STATUS_TRANSFORMATION_STARTED,
  STATUS_TRANSFORMATION_SUCCESS,
  STATUS_TRANSFORMATION_UPDATE,
  STATUS_UNPROCESSED,
  STATUS_VALIDATION_ERROR,
  STATUS_VALIDATION_INVALID,
  STATUS_VALIDATION_STARTED,
  STATUS_VALIDATION_UPDATE,
  STATUS_VALIDATION_VALID
} from '../config';

export const TRANSFORMATION_STATUS = Object.freeze({
  UPLOAD: 0,
  VALIDATE: 1,
  PROCESS: 2,
  DONE: 3
});

export const getStatusUpdate = status => {
  switch (status) {
    case STATUS_UNPROCESSED:
      return TRANSFORMATION_STATUS.UPLOAD;
    case STATUS_VALIDATION_STARTED:
    case STATUS_VALIDATION_UPDATE:
    case STATUS_VALIDATION_VALID:
    case STATUS_VALIDATION_INVALID:
    case STATUS_VALIDATION_ERROR:
      return TRANSFORMATION_STATUS.VALIDATE;
    case STATUS_TRANSFORMATION_RECEIVED:
    case STATUS_TRANSFORMATION_STARTED:
    case STATUS_TRANSFORMATION_UPDATE:
    case STATUS_TRANSFORMATION_SUCCESS:
    case STATUS_TRANSFORMATION_ERROR:
    case STATUS_ASSEMBLING_STARTED:
    case STATUS_ASSEMBLING_UPDATE:
    case STATUS_ASSEMBLING_ERROR:
      return TRANSFORMATION_STATUS.PROCESS;
    case STATUS_ASSEMBLING_SUCCESS:
      return TRANSFORMATION_STATUS.DONE;
    default:
      return TRANSFORMATION_STATUS.UPLOAD;
  }
};

export const errorStatus = [
  STATUS_VALIDATION_INVALID,
  STATUS_VALIDATION_ERROR,
  STATUS_TRANSFORMATION_ERROR,
  STATUS_ASSEMBLING_ERROR
];

export const progressSteps = t => {
  return [
    { before: t('handle.status.upload'), busy: t('handle.status.uploading'), after: t('handle.status.uploaded') },
    { before: t('handle.status.validate'), busy: t('handle.status.validating'), after: t('handle.status.validated') },
    { before: t('handle.status.process'), busy: t('handle.status.processing'), after: t('handle.status.processed') },
    { before: t('handle.status.done'), busy: t('handle.status.done'), after: t('handle.status.done') }
  ];
};

export const getErrorMessage = (handleName, status, error, t) => {
  if (error?.message) return error.message;

  switch (status) {
    case STATUS_VALIDATION_INVALID:
      return t('handle.error.noOutput', { handleName });
    case STATUS_VALIDATION_ERROR:
      return t('handle.error.validateError', { handleName });
    case STATUS_TRANSFORMATION_ERROR:
    case STATUS_ASSEMBLING_ERROR:
      return t('handle.error.processError', { handleName });
    default:
      return t('handle.error.error', { handleName });
  }
};
