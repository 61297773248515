import React, { useState, useEffect, useRef, useContext } from 'react';
import { Layer, Rectangle, ResponsiveContainer, Sankey } from 'recharts';
import PropTypes from 'prop-types';
import { DashboardContext } from '../../DashboardContext';
import RechartsComponents from '../RechartsComponents';

// Custom Sankey Node component to make styling options available.
// Source: https://github.com/recharts/recharts/blob/master/demo/component/DemoSankeyNode.js
const SankeyNode = ({ x, y, width, height, index, payload, containerWidth, properties }) => {
  const isOut = x + width + 6 > containerWidth;
  return (
    <Layer key={`sankey-node-${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fillOpacity="1"
        {...properties}
        stroke={payload.stroke ?? properties.stroke ?? '#ccc'}
        fill={payload.fill ?? properties.fill ?? '#5192ca'}
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + (height / 2 + 6)}
        fontSize="14"
        {...properties.text}
        stroke={payload.color ?? properties.text?.color ?? '#333'}
      >
        {payload.name}
      </text>
    </Layer>
  );
};

// Custom Sankey Link component to make styling options available.
// Source: https://github.com/recharts/recharts/blob/master/demo/component/DemoSankeyLink.js
const SankeyLink = ({
  sourceX,
  targetX,
  sourceY,
  targetY,
  sourceControlX,
  targetControlX,
  linkWidth,
  index,
  payload,
  properties
}) => (
  <Layer key={`sankey-link-${index}`}>
    <path
      {...properties}
      d={`M${sourceX},${sourceY + linkWidth / 2}
          C${sourceControlX},${sourceY + linkWidth / 2}
            ${targetControlX},${targetY + linkWidth / 2}
            ${targetX},${targetY + linkWidth / 2}
          L${targetX},${targetY - linkWidth / 2}
          C${targetControlX},${targetY - linkWidth / 2}
            ${sourceControlX},${sourceY - linkWidth / 2}
            ${sourceX},${sourceY - linkWidth / 2}
          Z`}
      fill={payload.fill ?? properties.fill}
      stroke={payload.stroke ?? properties.stroke}
    />
  </Layer>
);

const SankeyDiagram = ({ properties, childComponents, id, data, title }) => {
  const [containerKey, setContainerKey] = useState();
  const { addComponentRef } = useContext(DashboardContext);
  const ref = useRef(null);

  // TODO Remove this when back-end version of PDF-creator is implemented
  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  const componentData = data[properties.data];

  useEffect(() => setContainerKey(id), [data, id]);

  return (
    <ResponsiveContainer key={containerKey} ref={ref}>
      <Sankey
        {...properties}
        data={componentData}
        node={props => <SankeyNode {...props} properties={properties.node} />}
        link={props => <SankeyLink {...props} properties={properties.link} />}
      >
        {RechartsComponents(childComponents)}
      </Sankey>
    </ResponsiveContainer>
  );
};

SankeyDiagram.defaultProps = {
  properties: {},
  childComponents: []
};

SankeyDiagram.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  childComponents: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default SankeyDiagram;
