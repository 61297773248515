import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { useTranslation } from 'react-i18next';
import { AW_URL, SUPPORT_EMAIL } from '../config';
import { QSFContext } from '../context';
import { MenuUserIcon, MenuAWIcon } from '../styles/MenuElements';
import { checkHandlePermissions } from '../util/permissions';
import MenuItem from './MenuItem';
import LanguageMenuItem from './LanguageMenuItem';


const LeftNavigation = React.memo(styled.div`
  width: 80px;
  border-right: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  position: fixed;
  background: #ffffff;
  height: 100%;
`);

const LogoWrapper = React.memo(styled.div`
  margin: 24px 14px 36px;
  cursor: pointer;

  & > .a-pwc-logo.a-md {
    width: 54px;
    height: 40px;
  }
`);

const MenusWrapper = React.memo(styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`);

const RoutesMenu = React.memo(styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`);

const UserMenu = React.memo(styled.div`
  display: flex;
  flex-direction: column;
`);

const UserLabel = React.memo(styled.span`
  display: block;
  margin-bottom: 10px;
  border: none;
  padding: 0px 10px;
  font-size: 14px;
  color: #7d7d7d;
`);

const MemoizedMenuItem = React.memo(MenuItem);

const UserInfoTooltip = ({ emailAddress }) => {
  const { t } = useTranslation();

  return (
    <div>
      <UserLabel data-test-label="user">{emailAddress}</UserLabel>
      <div className="a-log-out-btn">
        <Button className="a-btn-lg logout-button" onClick={signOut} data-test-label="logout-btn">
          {t('button.signOut')}
        </Button>
      </div>
    </div>
  );
};

const signOut = async () => {
  if (process.env.NODE_ENV !== 'development') {
    const res = await axios.get('/logout');
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = res.data.url;
  }
};

const sendQuestion = () => {
  const a = document.createElement('a');
  a.href = `mailto:${SUPPORT_EMAIL}`;
  a.target = '_blank';

  a.click();
};

const goToAW = () => {
  const a = document.createElement('a');
  a.href = `${AW_URL}`;
  a.target = '_blank';

  a.click();
};

// Commented out as we removed 'fire all' handles feature. Uncomment when the feature is reworked.
// const hasNonDemoResultsFn = results => {
//   let hasRes = false;
//   results.forEach(result => {
//     if (hasRes) {
//       return;
//     }
//     hasRes = !result[1].isDemo;
//   });
//   return hasRes;
// };

const hasNonDemoDownloadsFn = downloads => {
  let hasRes = false;
  downloads.forEach(download => {
    if (hasRes) {
      return;
    }
    hasRes = download[1].filter(item => !item.isDemo).length > 0;
  });
  return hasRes;
};

const Menu = ({ emailAddress, handleDisplayMode }) => {
  // Removed transformedResultsEntries, add again when 'fire all' handles feature is reworked
  const { /* transformedResults, */ availableDownloads, setAvailableDownloads, isPwCUser, permissions, environment } =
    useContext(QSFContext);
  const [
    // transformedResultsEntries,
    availableDownloadsEntries
  ] = [/* Object.entries(transformedResults), */ Object.entries(availableDownloads)];

  const [hasDocuments, setHasDocuments] = useState(false);
  const [hasWorkflows, setHasWorkflows] = useState(false);
  const [showUserTooltip, setShowUserTooltip] = useState(true);
  const { t } = useTranslation();

  const history = useHistory();
  const goHome = useCallback(() => history.push('/'), [history]);
  const [canReadNews, setCanReadNews] = useState(false);
  const [isUSEnvironment, setIsUSEnvironment] = useState(true);
  const [canUseGpt, setCanUseGpt] = useState(false);
  const [canUseDocQA, setCanUseDocQA] = useState(false);
  const [canUseReview, setCanUseReview] = useState(false);

  // TODO: change mechanism to check for document presence so that 'Docs' tab
  //       automatically becomes visible after a user goes from 0 to 1+ documents
  useEffect(() => {
    axios
      .get(`/docs/0/1`)
      .then(res => setHasDocuments(res.data?.length === 1))
      .catch(e => setHasDocuments(false));

    axios
      .get('/api/workflows')
      .then(res => setHasWorkflows(res.data?.length > 0))
      .catch(e => setHasWorkflows(false));
  }, []);

  useEffect(() => {
    axios.get(`/api/downloads`).then(res => setAvailableDownloads(res?.data ?? {}));
  }, [setAvailableDownloads]);

  useEffect(() => {
    // Set can read news
    const canReadNews = checkHandlePermissions(permissions || [], 'qsf-api-news', ['view']);

    setCanReadNews(Boolean(canReadNews));

    // Set is US environment
    setIsUSEnvironment(environment === 'us');

    // Set can use chat assistant
    const canUseGpt = checkHandlePermissions(permissions || [], 'qsf-api-chatgpt', ['view']);
    setCanUseGpt(Boolean(canUseGpt));

    // Set can use DocQ&A
    const canUseDocQA = checkHandlePermissions(permissions || [], 'qsf-handle-gen-doc-qa', ['debug']);

    // Set can use Review
    const canUseReview = checkHandlePermissions(permissions || [], 'qsf-fonto-review', ['view']);


    setCanUseReview(Boolean(canUseReview));
    setCanUseDocQA(Boolean(canUseDocQA));
    setCanUseReview(Boolean(canUseReview));
  }, [setCanReadNews, setIsUSEnvironment, setCanUseGpt, setCanUseDocQA, setCanUseReview, permissions, environment]);

  return !handleDisplayMode ? (
    <div className="a-navigation-content d-flex" data-test-label="menu-container">
      <LeftNavigation>
        <LogoWrapper onClick={goHome}>
          <div className="ap-pwc-logo a-md" data-test-label="icon" />
        </LogoWrapper>
        <MenusWrapper>
          <RoutesMenu>
            <MemoizedMenuItem name={t('menu.myApps')} path="/handles" icon="Appkit4-icon icon-compass-fill" />
            <MemoizedMenuItem
              name={t('menu.discoverApps')}
              path="/discover"
              icon="Appkit4-icon icon-digital-techtransformation-fill"
            />
            {canReadNews && (
              <MemoizedMenuItem name={t('menu.news')} path="/news" icon="Appkit4-icon icon-news-report-fill" />
            )}
            <MemoizedMenuItem name={t('menu.videos')} path="/videos" icon="Appkit4-icon icon-video-fill" />
            {hasWorkflows && (
              <MemoizedMenuItem
                name={t('menu.workflows')}
                path="/workflows"
                icon="Appkit4-icon icon-workflow-outline"
              />
            )}
            {hasDocuments && <MemoizedMenuItem name={t('menu.documents')} path="/documents" icon="icon-news-fill" />}
            {/*
              Commented out as we removed 'fire all' handles feature. Uncomment when the feature is reworked.
              {hasNonDemoResultsFn(transformedResultsEntries) && (
              <MemoizedMenuItem name={t('menu.results')} path="/results" icon="icon-folder-opened-fill" />
            )} */}
            {hasNonDemoDownloadsFn(availableDownloadsEntries) && (
              <MemoizedMenuItem name={t('menu.downloads')} path="/downloads" icon="icon-download-light-fill" />
            )}
            {isPwCUser && (
              <Tooltip
                content={<div style={{ fontSize: 10 }}>Assisted Writing</div>}
                position="right"
                mouseEnterDelay={50}
              >
                <MenuAWIcon data-test-label="assisted-writing" onClick={goToAW}>
                  <span className="Appkit4-icon icon-edit-fill" />
                </MenuAWIcon>
              </Tooltip>
            )}
            {canUseGpt && <MemoizedMenuItem name={t('menu.chatPwC')} path="/chatpwc" icon="brain-icon" />}
            {canUseDocQA && <MemoizedMenuItem name={t('menu.docQA')} path="/docqa" icon="docqa-icon" />}
            {canUseReview && <MemoizedMenuItem name={t('menu.review')} path="/review" icon="review-icon" />}
          </RoutesMenu>
          <UserMenu>
            {!isUSEnvironment && <LanguageMenuItem />}
            <Tooltip content={t('menu.help')} position="right" mouseEnterDelay={50}>
              <MenuUserIcon data-test-label="help" onClick={sendQuestion}>
                <span className="Appkit4-icon icon-help-question-fill" />
              </MenuUserIcon>
            </Tooltip>
            <Tooltip
              content={t('menu.user')}
              position="right"
              mouseEnterDelay={50}
              style={{ display: showUserTooltip ? 'inherit' : 'none' }}
            >
              <Tooltip
                content={<UserInfoTooltip emailAddress={emailAddress} />}
                trigger="click"
                refClassName="a-account-info"
                className="a-account-options"
                clickToClose
                position="right-bottom"
                tooltipTheme="light"
                onShow={() => setShowUserTooltip(false)}
                onHide={() => setShowUserTooltip(true)}
              >
                <MenuUserIcon data-test-label="user">
                  <span className="Appkit4-icon icon-avatar-fill" />
                </MenuUserIcon>
              </Tooltip>
            </Tooltip>
          </UserMenu>
        </MenusWrapper>
      </LeftNavigation>
    </div>
  ) : null;
};
Menu.displayName = 'Menu';
export default React.memo(Menu);
