import React from 'react';

const ZoomOutIcon = ({ height, width, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} fill={fill}>
    <path
      fill={fill}
      d="M4.5 6.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1 0-1.5ZM0 7.5a7.5 7.5 0 1 1 13.307 4.747l2.473 2.473a.749.749 0 1 1-1.06 1.06l-2.473-2.473A7.5 7.5 0 0 1 0 7.5Zm7.5-6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Z"
    />
  </svg>
);

export default ZoomOutIcon;
