import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { Input } from '@appkit4/react-components/field';
import styled from 'styled-components';
import { getReviewInvitations, inviteReviewUser, removeReviewInvitation } from '../util/requests';

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const UserList = styled.div`
  margin-top: 24px;
`;

const UserItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #dee2e6;
`;

const ShareDocumentModal = ({ visible, onClose, workflowId }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadUsers = useCallback(async () => {
    try {
      const response = await getReviewInvitations(workflowId);
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to load users:', error);
    }
  }, [workflowId]);

  useEffect(() => {
    if (visible && workflowId) {
      loadUsers();
    }
  }, [visible, workflowId, loadUsers]);

  const handleSubmit = async () => {
    if (!email || !name) return;

    setIsLoading(true);
    try {
      await inviteReviewUser(workflowId, { email, name });
      await loadUsers();
      setName('');
      setEmail('');
    } catch (error) {
      console.error('Failed to invite user:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveUser = async email => {
    try {
      await removeReviewInvitation(workflowId, email);
      await loadUsers();
    } catch (error) {
      console.error('Failed to remove user:', error);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Share Document"
      ariaLabel="Share Document"
      onCancel={onClose}
      modalStyle={{ width: '33.75rem' }}
      footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
      bodyStyle={{ minHeight: '92px' }}
    >
      <p style={{ marginBottom: '24px', color: '#666666' }}>Invite a user to review the document</p>
      <FormGroup>
        <Input
          title="Full name"
          value={name}
          onChange={value => setName(value)}
          placeholder="Enter full name"
          maxLength={128}
          required
        />
      </FormGroup>

      <FormGroup>
        <Input
          title="Email"
          type="text"
          value={email}
          onChange={value => setEmail(value)}
          placeholder="Enter email address"
          maxLength={320}
          required
        />
      </FormGroup>

      <FormGroup>
        <Button kind="primary" onClick={handleSubmit} disabled={isLoading || !email || !name} style={{ width: '100%' }}>
          {isLoading ? 'Sending...' : 'Send Invite'}
        </Button>
      </FormGroup>

      {users.length > 0 && (
        <UserList>
          <h4>External users with viewing permission</h4>
          {users.map(user => (
            <UserItem key={user.email}>
              <div>
                <div>{user.name}</div>
                <div style={{ color: '#666', fontSize: '0.9em' }}>{user.email}</div>
              </div>
              <Button add icon="icon-delete-outline" onClick={() => handleRemoveUser(user.email)} />
            </UserItem>
          ))}
        </UserList>
      )}
    </Modal>
  );
};

export default ShareDocumentModal;
