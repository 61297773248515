import React from 'react';
import { Input } from '@appkit4/react-components/field';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SearchBox = React.memo(styled.div`
  .a-input-text {
    background-color: #f5f5f5;
    border: 1px solid #919191;
    padding: 0 16px;
  }
  > .a-input-size-sm {
    height: 2.125rem;
  }
`);

const Search = () => {
  const { t } = useTranslation();

  return (
    <SearchBox>
      <Input
        type="text"
        className="a-input-size-sm"
        suffix={
          <span
            className="Appkit4-icon icon-search-outline baseline-search-icon"
            style={{ fontSize: '1rem', color: '#9e9e9e' }}
          />
        }
        data-test-label="searchbar-documents"
        placeholder={t('documents.search')}
        title={t('documents.search')}
        onChange={e => {}}
      />
    </SearchBox>
  );
};

export default Search;
