import React, { useState, useEffect } from 'react';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { RadioGroup, Radio } from '@appkit4/react-components/radio';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE_ISO_CODE } from '../util/requests';
import { MenuUserIcon } from '../styles/MenuElements';

const LANGUAGES = Object.freeze({
  en: 'English',
  nl: 'Nederlands'
});

export const LANGUAGE_KEY = 'language';

const LanguageMenuItem = () => {
  const [language, setLanguage] = useState(DEFAULT_LANGUAGE_ISO_CODE);
  const [showTooltip, setShowTooltip] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const initialLanguage = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
    setLanguage(initialLanguage);
    i18n.changeLanguage(initialLanguage);
  }, [i18n]);

  const onLanguageChange = value => {
    localStorage.setItem(LANGUAGE_KEY, value);
    setLanguage(value);
    i18n.changeLanguage(value);
    window.location.reload(false);
  };

  const LanguagePicker = () => (
    <RadioGroup defaultValue={language} onChange={onLanguageChange}>
      {Object.keys(LANGUAGES).map(language => (
        <Radio key={language} value={language}>
          {LANGUAGES[language]}
        </Radio>
      ))}
    </RadioGroup>
  );

  return (
    <Tooltip
      content={t('menu.language')}
      position="right"
      mouseEnterDelay={50}
      style={{ display: showTooltip ? 'inherit' : 'none' }}
    >
      <Tooltip
        content={<LanguagePicker />}
        style={{ backgroundColor: 'white' }}
        trigger="click"
        refClassName="a-language-info"
        className="a-language-options"
        clickToClose
        position="right-bottom"
        onShow={() => setShowTooltip(false)}
        onHide={() => setShowTooltip(true)}
      >
        <MenuUserIcon data-test-label="language">
          <span className="Appkit4-icon">{language.toUpperCase()}</span>
        </MenuUserIcon>
      </Tooltip>
    </Tooltip>
  );
};

export default LanguageMenuItem;
