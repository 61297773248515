import React, { useState, useContext, useEffect, useRef } from 'react';
import { Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button } from '@appkit4/react-components/button';
import { Modal } from '@appkit4/react-components/modal';
import { useTranslation } from 'react-i18next';
import { DASHBOARD_NO_INPUT_REFERENCE, INTERACTIVE_DASHBOARD_ONLY_REFERENCE } from '../../config';
import { DashboardContext } from './DashboardContext';
import PageFilters from './PageFilters';
import PageDataSubsets from './PageDataSubsets';
import IndexTreeButtons from './IndexTreeButtons';
import DashboardElement from './components/DashboardElement';
import dashboardPagePropType from './dashboardPagePropType';
import PagePrint from './PagePrint';
import ReportPrint from './ReportPrint';
import StickyProgressIndicator from './interactive-dashboard/StickyProgressIndicator';
import DownloadFilesButton from './DownloadFilesButton';

export const PageContainer = styled.div`
  /* 37px is the height of the breadcrumbs to avoid unnecessary scrolling */
  height: calc(100% - 37px);
  display: grid;
  grid-gap: 20px;
  .exporting {
    .text-element,
    .table-element {
      overflow: visible;
      background: #fff;
      position: absolute;
      margin: 0 -20px;
      padding: 0 20px;
      .ReactTable .pagination-bottom > .-pagination {
        display: none;
      }
    }
    &.dashboard-tabs .a-tab-bar {
      ul > li.a-tab-bar-item {
        &.active .a-tab-bar-label-text {
          color: #2d2d2d;
        }
        &:not(.active) {
          display: none;
        }
      }
      .a-tab-bar-indicator {
        display: none;
      }
    }
  }
`;

const PageActionsWrapper = styled.div`
  float: right;
  margin-top: -46px;
  display: flex;
  > button {
    > span {
      margin: 0 !important;
    }
  }
  > button,
  > div.data-update {
    margin-right: 10px;
  }
`;

const Disclaimer = styled.div`
  margin-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
`;

export const DisclaimerButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #007bff;

  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: #0056b3;
  }
  :focus {
    outline: 0;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    max-height: 100%;
  }

  .ap-modal-body {
    white-space: pre-wrap;
    overflow-y: scroll;
    text-align: justify;
  }
`;

const DashboardContent = ({ dashboard, initialData, disclaimer, printPage, reportPrint, isDemo }) => {
  const {
    setPageComponents,
    setData,
    data,
    dataFilters,
    setFilters,
    setDataSubsets,
    activeDataSubset,
    setActiveDataSubset
  } = useContext(DashboardContext);

  const { t } = useTranslation();
  const { pageDemo } = queryString.parse(useLocation().search);
  const { components, pages } = dashboard;

  const [currentPage, setCurrentPage] = useState({ id: '', type: '' });
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();
  const page = isDemo ? pageDemo ?? 'dashboard' : match.params.page ?? 'dashboard';
  const dataSubsetsButtonLabel = currentPage?.properties?.dataSubsetsButtonLabel;

  useEffect(() => setCurrentPage(pages[page]), [setCurrentPage, pages, page]);
  useEffect(() => setData(initialData || {}), [setData, initialData]);
  useEffect(() => setPageComponents(components || {}), [setPageComponents, components]);
  useEffect(() => setFilters(currentPage?.properties?.filters || []), [setFilters, currentPage]);
  useEffect(() => {
    const active = activeDataSubset || currentPage?.properties?.dataSubsets?.find(s => s.active === true)?.id || '';
    const subsets = (currentPage?.properties?.dataSubsets || []).map(subsetItem => ({
      ...subsetItem,
      active: active === subsetItem.id
    }));

    setActiveDataSubset(active);
    setDataSubsets(subsets);
  }, [activeDataSubset, setActiveDataSubset, currentPage, setDataSubsets]);

  // Apply data Filters everytime the filters or initialData is changed
  useEffect(() => {
    const applyFilters = (initialDashboardData, dataFilters) => {
      const clonedData = { ...(initialData || {}) };

      for (const dataSet of Object.keys(clonedData)) {
        if (Object.keys(dataFilters).indexOf(dataSet) === -1) continue;

        // Store original dataset to use later.
        const originalDataSet = `${dataSet}_original`;
        if (!initialDashboardData[originalDataSet]) {
          clonedData[originalDataSet] = [...initialDashboardData[dataSet]];
        }

        // Filter the given dataset with the supplied filters.
        clonedData[dataSet] = clonedData[originalDataSet].filter(item =>
          Object.entries(dataFilters[dataSet]).every(
            ([key, values]) => values.length === 0 || values.includes(item[key])
          )
        );
      }

      return clonedData;
    };

    const filteredData = applyFilters(initialData, dataFilters);
    setData(filteredData);
  }, [initialData, dataFilters, setData]);

  const indexComponent = Object.values(components).find(component => component.type === 'index');
  const formComponent = Object.values(components).find(component => component.type === 'form');
  const persistentPopup = formComponent?.properties?.persistentPopup ?? false;
  const reloadPopupTimeout = formComponent?.properties?.timeToCloseReloadPopupInSeconds;
  const hasDownloadableContent = pages.dashboard?.globalProperties?.hasDownloadableContent;

  const pageRef = useRef();

  if (currentPage == null) {
    return (
      <div style={{ textAlign: 'center', marginTop: 50 }}>{t('dashboard.pageNotConfigured', { pageId: page })}</div>
    );
  }

  // Since these handles don't have an upload page, the back button should only be visible for handles with a dashboard and are functional.
  const backButtonVisible =
    (match.params.reference !== DASHBOARD_NO_INPUT_REFERENCE &&
      match.params.reference !== INTERACTIVE_DASHBOARD_ONLY_REFERENCE) ||
    match.params.page !== undefined;

  if (match.isExact) {
    return (
      <div style={{ marginTop: 40 }}>
        <PageActionsWrapper>
          {backButtonVisible && (
            <Button className="a-btn-lg" id="backBtn" onClick={() => history.goBack()} data-test-label="back-btn">
              {t('button.back')}
            </Button>
          )}

          <IndexTreeButtons currentPage={currentPage} indexComponent={indexComponent} isDemo={isDemo} />
          {hasDownloadableContent && <DownloadFilesButton currentPage={currentPage} handleId={match.params.handleId} />}

          {printPage && <PagePrint pageRef={pageRef} />}
          {!isDemo && reportPrint && <ReportPrint pages={pages} />}
          <PageFilters />
          <PageDataSubsets label={dataSubsetsButtonLabel} />
        </PageActionsWrapper>
        <PageContainer ref={pageRef} className="dashboard-page-container">
          <DashboardElement pageId={currentPage.id} {...currentPage} />
        </PageContainer>
        {/* don't show the disclaimer for interactive dashboard only handle (popcorn) */}
        {match.params.reference !== INTERACTIVE_DASHBOARD_ONLY_REFERENCE && (
          <Disclaimer className="a-footer">
            <DisclaimerButton onClick={() => setShowDisclaimer(true)}>{t('button.disclaimer')}</DisclaimerButton>
            <StyledModal
              id="disclaimer-modal"
              data-test-label="disclaimer"
              className="idle-modal"
              closable={false}
              visible={showDisclaimer}
              modalStyle={{ width: '33.75rem' }}
              footerStyle={{ justifyContent: 'flex-end', paddingBottom: '5', paddingTop: '5' }}
              footer={
                <>
                  <Button onClick={() => setShowDisclaimer(false)} data-test-label="disclaimer-button">
                    {' '}
                    {t('button.ok')}{' '}
                  </Button>
                </>
              }
              bodyStyle={{ minHeight: '92px' }}
            >
              <span id="disclaimer-modal-body">{disclaimer}</span>
            </StyledModal>
          </Disclaimer>
        )}
        <StickyProgressIndicator
          handleId={match.params.handleId}
          handleName={match.params.handleName}
          closeAfter={reloadPopupTimeout}
          persistentPopup={persistentPopup}
        />
      </div>
    );
  }

  return (
    <Route path={`${match.url}/:page`}>
      <DashboardContent dashboard={dashboard} initialData={data} disclaimer={disclaimer} />
    </Route>
  );
};

DashboardContent.defaultProps = {
  disclaimer: '',
  printPage: true
};

DashboardContent.propTypes = {
  dashboard: PropTypes.shape({
    pages: PropTypes.objectOf(PropTypes.shape(dashboardPagePropType)).isRequired,
    // TODO: define prop types
    components: PropTypes.objectOf(PropTypes.any).isRequired
  }).isRequired,
  initialData: PropTypes.objectOf(PropTypes.any).isRequired,
  disclaimer: PropTypes.string,
  printPage: PropTypes.bool,
  reportPrint: PropTypes.bool,
  isDemo: PropTypes.bool
};

export default DashboardContent;
