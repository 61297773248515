import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { sanitizer } from '../util';
import { EnabledEntry, EntryText, EntryImageWrapper, EntryHeader, EntryDescription } from '../styles/GeneralElements';
import { HANDLE_TYPE, isWorkflowHandle } from '../util/handleType';
import AppImage from './image/AppImage';

const HandleTile = ({ handle }) => {
  const history = useHistory();
  const { id, name, handleType, singleLineDescription } = handle;

  const onClick = () => {
    const path = handleType === HANDLE_TYPE.VIDEO ? 'videos' : 'handles';
    isWorkflowHandle(handleType) ? history.push(`/${path}/${id}?workflow=true`) : history.push(`/${path}/${id}`);
  };

  return (
    <EnabledEntry onClick={onClick} className="ap-panel a-shadow-sm" data-test-label={id}>
      <EntryImageWrapper>
        <AppImage id={id} name={name} size="a-md" />
      </EntryImageWrapper>
      <EntryText>
        <EntryHeader data-test-label={`${id}-header`}>
          <span title={name}>{name}</span>
        </EntryHeader>
        <EntryDescription
          data-test-label={`${id}-description`}
          dangerouslySetInnerHTML={{ __html: sanitizer(singleLineDescription) }}
        />
      </EntryText>
    </EnabledEntry>
  );
};

HandleTile.propTypes = {
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handleType: PropTypes.string,
    singleLineDescription: PropTypes.string.isRequired
  }).isRequired
};

export default HandleTile;
