import React, { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { APP_NAME, SUPPORT_EMAIL } from '../../config';
import { LoadingWrapper, ButtonHome } from '../../styles/LoadingElements';

const NotFoundPage = () => {
  const supportEmail = `mailto:${SUPPORT_EMAIL}`;
  const { t } = useTranslation();
  const history = useHistory();
  const goHome = useCallback(() => history.push('/'), [history]);

  return (
    <LoadingWrapper>
      <div className="a-flex-column" data-test-label="not-found-page">
        <h3 className="a-h3">{t('message.notFound')}</h3>
        <span>
          <Trans i18nKey="message.contact1" values={{ appName: APP_NAME }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a target="_blank" rel="noopener noreferrer" href={supportEmail} />
          </Trans>
        </span>
        <ButtonHome onClick={goHome} data-test-label="back-home-btn">
          {t('message.backToHomepage')}
        </ButtonHome>
      </div>
    </LoadingWrapper>
  );
};

export default NotFoundPage;
