import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import WorkflowDetails from './history-details/WorkflowDetails';
import HistoryOverview from './HistoryOverview';

const History = ({ handleId }) => {
  const { id } = queryString.parse(useLocation().search);

  if (id != null) {
    return <WorkflowDetails />;
  }

  return <HistoryOverview handleId={handleId} />;
};

History.propTypes = {
  handleId: PropTypes.string.isRequired
};

export default History;
