import React from 'react';
import PropTypes from 'prop-types';
import { TagsInput } from 'react-tag-input-component';

const TagsInputField = ({ field, value, onChange }) => {
  const { id, placeholder, regex } = field;

  const beforeAddValidate = text => {
    const regExp = new RegExp(regex);
    return regExp.test(text);
  };

  return (
    <TagsInput
      value={value}
      onChange={value => onChange(id, value)}
      placeHolder={placeholder}
      beforeAddValidate={beforeAddValidate}
    />
  );
};

TagsInputField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired
};

export default TagsInputField;
