import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { APP_NAME, SUPPORT_EMAIL } from '../../config';
import { HANDLE_TYPE } from '../../util/handleType';
import { LoadingWrapper } from '../../styles/LoadingElements';

const UnauthorizedIndicator = ({ handleType }) => {
  const supportEmail = `mailto:${SUPPORT_EMAIL}`;
  const { t } = useTranslation();
  const isVideoHandle = handleType === HANDLE_TYPE.VIDEO;
  const video = t('type.video', { count: 1 });
  const solution = t('type.solution', { count: 1 });

  return (
    <LoadingWrapper>
      <div className="a-flex-column" data-test-label="no-handles-available-indicator">
        <h3 className="a-h3">{t('message.unauthorized', { type: isVideoHandle ? video : solution })}</h3>
        <span>
          <Trans i18nKey="message.contact1" values={{ appName: APP_NAME }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a target="_blank" rel="noopener noreferrer" href={supportEmail} />
          </Trans>
        </span>
      </div>
    </LoadingWrapper>
  );
};

UnauthorizedIndicator.defaultProps = {
  handleType: null
};

UnauthorizedIndicator.propTypes = {
  handleType: PropTypes.string
};

export default UnauthorizedIndicator;
