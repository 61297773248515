import React from 'react';
import { Button } from '@appkit4/react-components/button';

const SingleResults = props => {
  const { gray, loading, negative, disabled, children, onClick } = props;
  return (
    <Button
      className="a-btn-md"
      gray={gray}
      loading={loading}
      negative={negative}
      disabled={disabled}
      data-test-label="result-btn"
      onClick={onClick}
      compact
    >
      {children}
    </Button>
  );
};

export default SingleResults;
