import React from 'react';
import styled from 'styled-components';
import { Panel } from '@appkit4/react-components/panel';

export const VideoGridWrapper = React.memo(styled.div`
  margin: 40px 0 0 0;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: start;

  .a-tooltip-ref-inner {
    height: 100%;

    .ap-panel.a-shadow-sm {
      height: 100%;
      margin-bottom: 0;
    }
  }

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`);

export const VideosWrapper = React.memo(styled.div`
  width: 100%;
  flex: 1;
  .video-container {
    margin-top: 25px;
  }
`);

export const VideoEntry = React.memo(styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 0 1rem 0 !important;
  > h6 {
    font-size: 14px;
    font-weight: 700;
    padding-top: 10px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  > div.info {
    display: flex;
    align-items: center;
    color: #7d7d7d;
    > .Appkit4-icon {
      font-size: 12px;
      margin-right: 6px;
      line-height: 16px;
    }
    > .date {
      font-size: 12px;
      display: block;
    }
  }
`);

export const WatchMoreVideoEntry = React.memo(styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0 0 1rem 0 !important;
  > h6 {
    font-size: 14px;
    font-weight: 700;
    padding-top: 10px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .image-block {
    display: flex;
    justify-content: center;
    width: 50%;
  }
  .info-block {
    width: 50%;
    margin: 15px;
    color: #464646;
  }
  .info {
    display: flex;
    align-items: center;
    color: #7d7d7d;
    > .Appkit4-icon {
      font-size: 12px;
      margin-right: 6px;
      line-height: 16px;
    }
    > .date {
      font-size: 12px;
      display: block;
    }
  }
`);

export const WatchMoreContainer = React.memo(styled(Panel)`
  max-width: 440px;
  width: 100%;
  margin-left: 56px;
  display: block;
  height: auto;
  position: relative;
  background: none;
  box-shadow: none;
  padding: 0;
  > .ap-panel-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 30px;
    overflow-y: scroll;
    padding: 0;
    > .ap-panel {
      height: auto !important;
      background: none;
      box-shadow: none;
      div.description {
        padding: 0 0.5rem 0.5rem;
      }
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
`);
