import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../../documents/utils';
import { LoadingIndicator } from '../../../elements/loading';
import { STATUS } from '../../../util/status';
import { getWorkflowOverview } from '../../util/requests';
import ErrorMessage from '../../shared-components/ErrorMessage';
import PreliminaryResultsButton from '../../shared-components/buttons/PreliminaryResultsButton';
import Download from './Download';
import ParticipantsTable from './ParticipantsTable';
import Property from './Property';
import Dashboard from './Dashboard';

const Header = styled.div`
  font-size: 16px;
  margin-top: 30px;
`;

const WorkflowDetails = () => {
  const { t } = useTranslation();
  const { id } = queryString.parse(useLocation().search);
  const [loadingStatus, setLoadingStatus] = useState(STATUS.LOADING);
  const [details, setDetails] = useState({});

  useEffect(() => {
    getWorkflowOverview(id)
      .then(res => {
        setDetails(res.data);
        setLoadingStatus(STATUS.OK);
      })
      .catch(() => setLoadingStatus(STATUS.ERROR));
  }, [id]);

  if (loadingStatus === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (loadingStatus === STATUS.ERROR) {
    return <ErrorMessage />;
  }

  const {
    handleId,
    handleName,
    id: workflowId,
    initiatedAt,
    finishedAt,
    status: workflowStatus,
    runAfterDatetime,
    andOr,
    downloadReference,
    minReviewers,
    minPreparers,
    minParticipants,
    minParticipantsPercent,
    reviewers,
    preparers,
    produces,
    participants,
    reviewSubmissions,
    preparerSubmissions,
    submissions
  } = details;

  return (
    <>
      <Header style={{ marginTop: 0 }}>{t('workflow.details.general')}</Header>
      <Property name={t('workflow.details.workflowId')} value={workflowId} />
      <Property name={t('workflow.label.start')} value={formatDateTime(initiatedAt)} />
      <Property name={t('workflow.label.finish')} value={finishedAt ? formatDateTime(finishedAt) : 'N/A'} />
      <Property name={t('workflow.label.status')} value={startCase(workflowStatus)} />
      <Download downloadReference={downloadReference} />
      {produces && <Dashboard workflowId={id} downloadReference={downloadReference} />}

      <Header>{t('workflow.details.startConditions')}</Header>
      {minPreparers && (
        <Property
          name={t('workflow.details.minimumPreparersNeeded')}
          value={`${minPreparers} ${t('workflow.properties.outOf')} ${preparers.length}`}
        />
      )}
      {minReviewers && (
        <Property
          name={t('workflow.details.minimumReviewersNeeded')}
          value={`${minReviewers} ${t('workflow.properties.outOf')} ${reviewers.length}`}
        />
      )}
      {minParticipants && minParticipants > 0 && (
        <Property
          name={t('workflow.details.minimumParticipantsNeeded')}
          value={`${minParticipants} ${t('workflow.properties.outOf')} ${participants.length}`}
        />
      )}
      {minParticipantsPercent && minParticipantsPercent > 0 && (
        <Property name={`${t('workflow.details.minimumParticipantsNeeded')} (%)`} value={`${minParticipantsPercent}`} />
      )}
      {andOr && andOr.toUpperCase()}
      {runAfterDatetime && <Property name={t('workflow.details.runAfter')} value={formatDateTime(runAfterDatetime)} />}

      {preparers && (
        <>
          <Header style={{ marginBottom: 5 }}>{t('workflow.details.preparers')}</Header>
          <ParticipantsTable participants={preparers} submissions={preparerSubmissions} />
        </>
      )}
      {reviewers && (
        <>
          <Header style={{ marginBottom: 5 }}>{t('workflow.details.reviewers')}</Header>
          <ParticipantsTable participants={reviewers} submissions={reviewSubmissions} />
        </>
      )}
      {participants && (
        <>
          <Header style={{ marginBottom: 5 }}>{t('workflow.details.participants')}</Header>
          <ParticipantsTable participants={participants} submissions={submissions} />
        </>
      )}
      {workflowStatus !== 'finished' && (
        <div style={{ marginTop: 5 }}>
          <PreliminaryResultsButton
            handleName={handleName}
            handleId={handleId}
            workflowId={workflowId}
            workflowStage="preliminary"
          />
        </div>
      )}
    </>
  );
};

export default WorkflowDetails;
