import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@appkit4/react-components/button';
import { Panel } from '@appkit4/react-components/panel';
import { Table } from '@appkit4/react-components/table';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getDocuments } from '../util/requests';
import { Wrapper } from '../styles/GeneralElements';
import { LoadingIndicator } from '../elements/loading';
import { STATUS, formatDateTime } from './utils';
import Search from './Search';

const DetailButton = ({ uri }) => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Button onClick={() => history.push(`/documents?uri=${uri}`)} data-test-label="details-btn">
      {t('button.details')}
    </Button>
  );
};

const StyledTable = styled(Table)`
  margin-top: 10px;
  width: 100%;

  .rt-tr,
  .rt-td {
    background-color: white;
  }
`;

const columns = t => [
  {
    Header: t('documents.label.filename'),
    accessor: 'metadata.fileName'
  },
  {
    Header: t('documents.label.solution'),
    accessor: 'metadata.handleName'
  },
  {
    Header: t('documents.label.uploadDate'),
    accessor: 'metadata.creationDateTime',
    Cell: row => formatDateTime(row.value)
  },
  {
    Header: t('documents.label.uploader'),
    // TODO: change to uploader
    accessor: 'metadata.permission.owner[0].id'
  },
  {
    Header: t('documents.label.owner'),
    accessor: 'metadata.permission.owner',
    Cell: row => row.value.map(owner => <div key={owner.id}>{owner.id}</div>)
  },
  {
    Header: '',
    accessor: 'documentUri',
    // eslint-disable-next-line
    Cell: row => <DetailButton uri={row.value} />
  }
];

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [status, setStatus] = useState(STATUS.LOADING);
  const { t } = useTranslation();

  useEffect(() => {
    if (status === STATUS.LOADING) {
      getDocuments()
        .then(res => {
          setDocuments(res.data);
          setStatus(STATUS.OK);
        })
        .catch(e => setStatus(STATUS.ERROR));
    }
  }, [status]);

  // TODO: spinner should be inside table
  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  return (
    <Wrapper>
      <Panel style={{ padding: 30 }} data-test-label="search-documents-panel">
        <Search style={{ marginBottom: 10 }} />

        {status === STATUS.ERROR && (
          <div style={{ margin: '20px 0 0 0' }} data-test-label="documents-not-found">
            {t('message.retrieveFailed', { item: t('type.document', { count: 2 }) })}
          </div>
        )}
        {status === STATUS.OK && (
          <StyledTable
            originalData={documents}
            noDataText={t('message.noneAvailable', { type: t('type.document', { count: 2 }) })}
            columns={columns(t)}
            showPagination
            sortable
            pageSize={10}
            hasTitle
          />
        )}
      </Panel>
    </Wrapper>
  );
};

export default Documents;
