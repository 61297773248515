import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Progress } from '@appkit4/react-components/progress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../../../context';
import { STATUS_ASSEMBLING_SUCCESS } from '../../../config';
import { errorStatus, getErrorMessage, getStatusUpdate, progressSteps } from '../../../util/progressBar';
import HandleLogs from '../../handleTabs/handleLogs';
import { ProgressbarWrapper, ProgressItem } from './StickyProgressIndicator.styles';

const buttonStyles = {
  margin: '20px 20px 10px'
};

const StyledProgress = styled(Progress)`
  width: 100% !important;
`;

const StickyProgressIndicator = ({ handleId, handleName, closeAfter, persistentPopup }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [popupVisible, setPopupVisible] = useState(true);
  const { error, updateStatus, handleLogs } = useContext(QSFContext);
  const [activeStep, setActiveStep] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [logsVisible, setLogsVisible] = useState(true);

  const downloadStatus = updateStatus[handleId]?.status;
  const totalSteps = progressSteps(t);

  const handleDownload = updateStatus[handleId];
  const defaultTimeToClosePopupInSeconds = 5;
  const isDone = [...errorStatus, STATUS_ASSEMBLING_SUCCESS].includes(downloadStatus);

  // Update active step according to current status
  useEffect(() => {
    setActiveStep(getStatusUpdate(downloadStatus));
    setPopupVisible(true);
  }, [activeStep, handleDownload, downloadStatus]);

  useEffect(() => {
    let timer;
    if (popupVisible && !persistentPopup && isDone) {
      const delay = closeAfter ? closeAfter * 1000 : defaultTimeToClosePopupInSeconds * 1000;
      timer = setTimeout(() => {
        setPopupVisible(false);
      }, delay);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [closeAfter, popupVisible, persistentPopup, isDone]);

  // Show error message if the error property changes.
  useEffect(
    () => setHasError(!!error || errorStatus.includes(downloadStatus)),
    [error, handleDownload, hasError, downloadStatus]
  );

  if (!handleId || !handleDownload) return null;

  const currentStep = hasError
    ? `${progressSteps(t)[activeStep].before} ${t('dashboard.progressIndicator.error')}`
    : progressSteps(t)[activeStep].busy;

  return (
    <ProgressbarWrapper expanded={expanded} visible={popupVisible}>
      <div className="bar-header">
        {!expanded && !isDone && <div className="a-loading a-loading-one" />}
        <span>{isDone ? t('dashboard.progressIndicator.updates') : t('dashboard.progressIndicator.updating')}</span>
        <div>
          <span
            className={`Appkit4-icon icon-arrow-${expanded ? 'down' : 'up'}-fill toggle`}
            onClick={() => setExpanded(!expanded)}
          />
          {isDone && <span className="Appkit4-icon icon-close-fill" onClick={() => setPopupVisible(false)} />}
        </div>
      </div>
      {expanded && (
        <div className="bar-content">
          <ProgressItem isDone={isDone}>
            <div className="item-content">
              <span className="handle-name">{handleName}</span>
              <div className="item-right">
                <span className={`step-label ${hasError ? 'error' : ''}`}>{currentStep}</span>
                {isDone && !hasError && <span className="Appkit4-icon icon-confirmed-fill done" />}
                {!isDone && (
                  <>
                    <div className="a-loading a-primary" />
                    <span className="Appkit4-icon icon-close-fill abort" />
                  </>
                )}
              </div>
            </div>

            {/** At the moment appkit4 does not provide a way for us to hide steps labels. The current solution is to pass an array of null values */}
            <StyledProgress activeIndex={activeStep} steps={new Array(totalSteps.length).fill(null)} />
          </ProgressItem>
          <div className="logs">
            <div>
              {handleLogs.length > 0 && (
                <Button
                  className="a-btn-md"
                  onClick={() => setLogsVisible(!logsVisible)}
                  style={buttonStyles}
                  data-test-label="logs-btn"
                >
                  <span className="Appkit4-icon icon-comment-outline" />
                  {logsVisible ? t('button.hideLogs') : t('button.showLogs')}
                </Button>
              )}
            </div>
          </div>
          {logsVisible && <HandleLogs logs={handleLogs} />}
          {hasError && (
            <div className="item-error">
              <span className="Appkit4-icon icon-alert-outline" />
              <span className="error-message">{getErrorMessage(handleName, downloadStatus, error, t)}</span>
            </div>
          )}
        </div>
      )}
    </ProgressbarWrapper>
  );
};

StickyProgressIndicator.propTypes = {
  handleName: PropTypes.string.isRequired,
  handleId: PropTypes.string.isRequired,
  closeAfter: PropTypes.number
};

export default StickyProgressIndicator;
