import FormElement from '../interactive-dashboard/FormElement';
import {
  LineChart,
  BarChart,
  AreaChart,
  ComposedChart,
  PieChart,
  ScatterChart,
  RadarChart,
  RadialBarChart,
  SankeyChart,
  FunnelChart,
  TreemapChart,
  GaugeChart,
  WaterfallChart
} from './charts';

import NumberElement from './NumberElement';
import TableElement from './TableElement';
import TextElement from './TextElement';
import WorldMap from './world-map/WorldMap';
import FilterElement from './FilterElement';
import ResultElement from './ResultElement';
import ImageElement from './ImageElement';
import IndexTree from './IndexTree';
import PdfViewerElement from './PdfViewerElement';

const Components = {
  area: AreaChart,
  bar: BarChart,
  composed: ComposedChart,
  filter: FilterElement,
  funnel: FunnelChart,
  form: FormElement,
  gauge: GaugeChart,
  image: ImageElement,
  line: LineChart,
  number: NumberElement,
  pdf: PdfViewerElement,
  pie: PieChart,
  radar: RadarChart,
  radial: RadialBarChart,
  result: ResultElement,
  sankey: SankeyChart,
  scatter: ScatterChart,
  table: TableElement,
  text: TextElement,
  treemap: TreemapChart,
  worldmap: WorldMap,
  waterfall: WaterfallChart,
  index: IndexTree
};

export default Components;
