import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './main.css';
import { ContextProvider } from './context';
import './i18n';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <ContextProvider>
    <App />
  </ContextProvider>
);
