import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appkit4/react-components/button';
import styled from 'styled-components';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { sanitizer } from '../../../../util';
import { DashboardContext } from '../../DashboardContext';
import BlockContent from './BlockContent';
import { ComponentActions } from './ComponentActions';

import {
  BlockDescription,
  BlockPanel,
  BlockPanelWrapper,
  RightHeaderContainer,
  ToggleButton,
  ExternalLink
} from './BlockElement.styles';

// TODO: what is allowed in here, only text/button?
export const RightHeaderContent = ({ content, goToPage, component }) => {
  if (content == null || content.type == null) {
    return null;
  }

  const { type, text, link } = content;

  if (type === 'text') {
    return <div className="a-text-caption">{text}</div>;
  }

  if (type === 'button') {
    return (
      <Button
        className="right-header-link"
        onClick={() => goToPage(link)}
        data-test-label={`right-header-${component}-btn`}
        compact
      >
        {text}
      </Button>
    );
  }

  if (type === 'link') {
    return (
      <ExternalLink href={link} target="_blank" rel="noreferrer" data-test-label={`right-header-${component}-link`}>
        {text}
      </ExternalLink>
    );
  }

  return null;
};

const iconInfoStyle = {
  marginRight: 0,
  marginLeft: 10,
  fontSize: 18,
  cursor: 'pointer',
  color: '#d04a02'
};

// const renderTooltipContent = content => <div dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />;

const StyledTooltip = styled(Tooltip)`
  .ap-tooltip-reactContent {
    min-width: 200px;
  }
`;

const ComponentTooltip = ({ content }) => {
  return (
    <StyledTooltip
      trigger="click"
      position="top-left"
      content={useMemo(
        () => (
          <div dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
        ),
        [content]
      )}
    >
      <span style={iconInfoStyle} className="Appkit4-icon icon-information-outline" />
    </StyledTooltip>
  );
};

RightHeaderContent.propTypes = {
  content: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string
  }),
  goToPage: PropTypes.func
};

const BlockElement = ({
  title,
  tooltip,
  rightHeaderContent,
  link,
  component,
  height,
  width,
  parent,
  description,
  collapsible,
  collapsed,
  onCollapse,
  allowExport
}) => {
  const { pageComponents } = useContext(DashboardContext);
  const history = useHistory();
  const dashboardMatch = useRouteMatch('/handles/:handleId/:handleName/dashboard/:reference/:page?');
  const demoDashboardMatch = useRouteMatch('/discover/:handleId/:handleName');
  const { demoReference } = queryString.parse(useLocation().search);
  const componentRef = useRef();
  const [isCollapsed, setCollapsed] = useState(collapsed ?? false);
  useEffect(() => setCollapsed(collapsed), [collapsed]);

  const isDemo = Boolean(demoReference);

  // fix to show the header in the panel in case that there's no title available but there is content in the right header side
  const titleModified = title || ((rightHeaderContent || tooltip || allowExport) && ' ');
  const componentProperties = {
    ...pageComponents[component],
    id: pageComponents[component]?.id,
    title: titleModified
  };

  const toggleVisibility = () => {
    // Reset the row grid height to auto so that children will be pushed up instead of showing empty space.
    const dashboardRow = componentRef?.current?.closest('.dashboard-row');
    if (dashboardRow) {
      const initialRowHeight = dashboardRow.getAttribute('height') ?? '500px';
      dashboardRow.style.gridAutoRows = isCollapsed ? initialRowHeight : 'auto';
      // Reset the column grid height to auto so that siblings will be pushed up instead of keeping the position.
      const dashboardColumn = dashboardRow.closest('.dashboard-columns');
      if (dashboardColumn) {
        dashboardColumn.style.gridAutoRows = isCollapsed ? null : 'auto';
      }
    }
    if (onCollapse) {
      onCollapse(component, setCollapsed);
    }
    setCollapsed(!isCollapsed);
  };

  const goToPage = page => {
    if (isDemo) {
      history.push(
        `/discover/${demoDashboardMatch.params.handleId}/${demoDashboardMatch.params.handleName}/demo?tab=dashboard&demoReference=${demoReference}&pageDemo=${page}`
      );
    } else {
      const { handleId, handleName, reference } = dashboardMatch.params;
      if (page === 'dashboard') {
        if (dashboardMatch) {
          history.push(`/handles/${handleId}/${handleName}/dashboard/${reference}`);
        } else {
          history.push(`/handles/dashboard`);
        }
      } else {
        history.push(`/handles/${handleId}/${handleName}/dashboard/${reference}/${page}`);
      }
    }
  };

  const extraNode = (
    <RightHeaderContainer data-test-label={`${component}-right-content`} className={`${component}-right-content`}>
      {!isCollapsed && allowExport && (
        <ComponentActions
          component={component}
          componentRef={componentRef}
          componentHeight={height}
          allowExport={allowExport}
          title={title}
        />
      )}
      <RightHeaderContent content={rightHeaderContent} goToPage={goToPage} component={component} />
      {tooltip && <ComponentTooltip content={tooltip} />}
      {collapsible && (
        <ToggleButton
          className={`component-toggle-btn ${isCollapsed ? 'closed' : ''}`}
          kind="transparent"
          gray
          onClick={() => toggleVisibility()}
        >
          <span className="Appkit4-icon icon-arrow-up-fill" />
        </ToggleButton>
      )}
    </RightHeaderContainer>
  );
  return (
    <BlockPanelWrapper
      ref={componentRef}
      className={`panel-container ${isCollapsed ? 'closed' : ''}`}
      maxheight={height}
    >
      <BlockPanel
        title={titleModified}
        extra={extraNode}
        onClick={link ? () => goToPage(link) : null}
        height={!parent && height ? height : '100%'}
        width={!parent && width ? width : '100%'}
        data-test-label={component}
      >
        <BlockContent component={componentProperties} />
        {description && <BlockDescription>{description}</BlockDescription>}
      </BlockPanel>
    </BlockPanelWrapper>
  );
};

BlockElement.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.string,
  rightHeaderContent: PropTypes.shape({
    type: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string
  }),
  link: PropTypes.string,
  component: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  parent: PropTypes.bool,
  description: PropTypes.string,
  collapsible: PropTypes.bool,
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  allowExport: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)])
};

export default BlockElement;
