import { FIELD } from '../enums';
import { getDefaultValue } from './getDefaultValue';

const STATIC_FIELDS = Object.freeze([FIELD.HTML]);

export const getDefaultValues = fields => {
  const defaultValues = {};
  fields.forEach(field => {
    if (STATIC_FIELDS.includes(field.type)) return;
    defaultValues[field.id] = getDefaultValue(field);
  });
  return defaultValues;
};
