import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalWrapper } from '../../styles/GeneralElements';

const ContactModal = ({ isVisible, onCloseModal, contact }) => {
  const { t } = useTranslation();

  return (
      <ModalWrapper
          className="baseline-modal-showcase"
          visible={isVisible}
          onCancel={onCloseModal}
          title={t('button.getInTouch')}
          >
          <div dangerouslySetInnerHTML={{ __html: contact }} />
      </ModalWrapper>
  );
};

export default ContactModal;
