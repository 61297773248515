import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '../../../elements/loading';
import { getStandardData } from '../../../util/requests';
import { FORMAT } from '../../forms/forms2.0/enums';
import { FormFields, FormContext } from '../../forms/forms2.0/formComponents';
import { getDefaultValues, getSelectorDataKeys, validator } from '../../forms/forms2.0/helpers';
import { FieldProps } from '../../forms/forms2.0/propTypes';
import ReloadButton from './ReloadButton';

const Error = styled.div`
  min-height: 30px;
  color: red;
  padding-top: 5px;
`;

const FormContainer = React.memo(
  styled.div(({ additionalStyle }) => ({
    height: '100%',
    width: '100%',
    padding: '0 4px',
    overflow: 'auto',
    '> div': {
      h4: {
        fontSize: '1rem'
      },
      h5: {
        fontSize: '0.85rem',
        color: '#6b6b6b'
      }
    },
    '>': additionalStyle
  }))
);

export const SUBMIT_FIELD = 'dashboard-submit';

const FormElement = ({ properties, data }) => {
  const { t } = useTranslation();
  const { style, fields, disableSubmitIf, reloadDataButtonLabel, referenceStyle } = properties;

  const { demoReference } = queryString.parse(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [selectorOptions, setSelectorOptions] = useState({});
  const { errors, unregister, reset, register, watch, getValues, handleSubmit, setValue } = useForm({
    defaultValues: getDefaultValues(fields),
    mode: 'onBlur'
  });

  // Disable reload form in case of Demo dashboard
  const isDemo = Boolean(demoReference);

  // Load data for predefined selectors and default values when page loads
  useEffect(() => {
    const loadData = async () => {
      const selectorOptions = {};
      const keys = getSelectorDataKeys(fields);

      await Promise.all(
        keys.map(async dataKey => {
          const response = await getStandardData(dataKey);
          const selectOptions = [];
          for (const [key, value] of Object.entries(response?.data || {})) {
            selectOptions.push({ value: key, label: value });
          }
          selectorOptions[dataKey] = selectOptions;
        })
      );

      const defaultValues = getDefaultValues(fields);
      // validate and override with dashboard data default values
      const dashboardData = data[properties.data];
      const { validateData } = validator(fields, selectorOptions, FORMAT.DATE);
      const validImport = validateData(dashboardData);
      const formData = validImport ? { ...defaultValues, ...dashboardData } : { ...defaultValues };
      reset(formData);
      setSelectorOptions(selectorOptions);
      setLoading(false);
    };
    loadData();
  }, [reset, fields, data, properties.data]);

  const onFieldChange = (name, value) => {
    setValue(name, value, { shouldValidate: true });
  };

  const onSubmitDisabled = disabled => {
    if (disabled !== submitDisabled) {
      setSubmitDisabled(disabled);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <FormContext.Provider value={{ selectorOptions }}>
      <FormContainer additionalStyle={style} className="form-element">
        <FormFields
          fields={fields}
          disableSubmitIf={disableSubmitIf}
          onSubmitDisabled={onSubmitDisabled}
          onFieldChange={onFieldChange}
          referenceStyle={referenceStyle}
          {...{ getValues, watch, errors, register, unregister }}
        />
        <ReloadButton
          fields={fields}
          handleSubmit={handleSubmit}
          getValues={getValues}
          disabled={isDemo || submitDisabled}
          label={reloadDataButtonLabel}
        />
        <Error>{Object.keys(errors).length > 0 && t('dashboard.formError')}</Error>
      </FormContainer>
    </FormContext.Provider>
  );
};

FormElement.propTypes = {
  properties: PropTypes.shape({
    style: PropTypes.objectOf(PropTypes.any),
    fields: PropTypes.arrayOf(PropTypes.shape(FieldProps)).isRequired,
    disableSubmitIf: PropTypes.objectOf(PropTypes.any)
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default FormElement;
