import React from 'react';
import styled from 'styled-components';

const marginStyles = { margin: '1rem' };

export const Notification = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  background-color: #fa5927;
  font-weight: 400;
  color: #fff;
  max-height: 100rem;
  padding: 0 1.25rem;
  transition-timing-function: ease-in-out;
  z-index: 99;
  box-shadow: 0 0.0625rem 0.125rem 0.0625rem rgb(0 0 0 / 8%)
  position: relative;

  .notification-content {
    font-size: 0.875rem;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    padding: .8125rem 2rem .8125rem 0;
  }
`;

const TimeoutNotification = ({ message, timeout = false }) => {
  return (
    <Notification data-test-label="timeout-notification" className="a-notification a-transition-500ms">
      <span className="notification-content">
        {message}
        {timeout && <span className="a-loading-one" style={marginStyles} />}
      </span>
    </Notification>
  );
};

export default TimeoutNotification;
