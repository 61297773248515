import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, TilesWrapper, ExtendedGridWrapper } from '../styles/GeneralElements';
import { LoadingIndicator, NoHandlesAvailableIndicator, LoadingError } from '../elements/loading';
import { STATUS } from '../util/status';
import { DEFAULT_LANGUAGE_ISO_CODE, getMyApps } from '../util/requests';
import { HANDLE_TYPE } from '../util/handleType';
import { LANGUAGE_KEY } from '../menu/LanguageMenuItem';
import HandleSearch from './handleSearch/HandleSearch';
import VideoAppTile from './VideoAppTile';

const VideosList = () => {
  const [availableHandles, setAvailableHandles] = useState([]);
  const [filteredHandles, setFilteredHandles] = useState([]);
  const [status, setStatus] = useState(STATUS.LOADING);
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      try {
        const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
        const response = await getMyApps(lang, HANDLE_TYPE.VIDEO);
        const sortedHandles = Object.values(response?.data ?? []).sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setAvailableHandles(sortedHandles);
        setFilteredHandles(sortedHandles);
        setStatus(STATUS.OK);
      } catch (e) {
        setStatus(STATUS.ERROR);
      }
    };

    getData();
  }, []);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (availableHandles.length === 0) {
    return <NoHandlesAvailableIndicator handleType="video" />;
  }

  return (
    <Wrapper>
      <HandleSearch handles={availableHandles} onResult={result => setFilteredHandles(result ?? availableHandles)} />
      <TilesWrapper>
        <ExtendedGridWrapper data-test-label="video-apps-list-wrapper">
          {filteredHandles.length === 0 ? (
            <div>{t('handle.noResults')}</div>
          ) : (
            filteredHandles.map(handle => <VideoAppTile handle={handle} key={handle.id} />)
          )}
        </ExtendedGridWrapper>
      </TilesWrapper>
    </Wrapper>
  );
};

export default VideosList;
