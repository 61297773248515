import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../../config';

const ImageContainer = React.memo(styled.div`
  height: 100%;
  width: 100%;
  > img {
    width: auto;
    height: auto;
    max-height: 100%;
    max-width: 100%;
  }
`);

const ImageElement = ({ properties, data }) => {
  const { handleId } = useParams();
  const { file, alt, style } = properties;

  // If the data is set and it's a string, use it as a source, else use the file property.
  const image = data[properties.data] && typeof data[properties.data] === 'string' ? data[properties.data] : file;

  // If no image source is available, return nothing.
  if (!image) return null;

  // Add server endpoint and encode path.
  const imageSource = `${BASE_URL}/api/dashboard-image/${handleId}/${encodeURIComponent(image)}`;

  return (
    <ImageContainer style={style}>
      <img src={imageSource} alt={alt} data-test-label={`img-${image}`} />
    </ImageContainer>
  );
};

ImageElement.propTypes = {
  properties: PropTypes.shape({
    file: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any),
    alt: PropTypes.string
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default ImageElement;
