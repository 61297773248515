export const HANDLE_TYPE = Object.freeze({
  DEFAULT: 'default',
  VIDEO: 'video',
  WAIT_ASSEMBLE: 'wait-assemble',
  REVIEW_AND_PUBLISH: 'review-and-publish',
  INTERACTIVE_DASHBOARD_ONLY: 'interactive-dashboard-only'
});

const WORKFLOW_HANDLES = [HANDLE_TYPE.WAIT_ASSEMBLE, HANDLE_TYPE.REVIEW_AND_PUBLISH];

export const isWorkflowHandle = handleType => {
  return WORKFLOW_HANDLES.includes(handleType);
};
