import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { useTranslation } from 'react-i18next';

const SubmitButton = styled(Button)`
  min-width: 200px;
  min-height: 25px;
`;

const Submit = ({ disabled, onSubmit, text }) => {
  const { t } = useTranslation();
  const buttonText = text || t('button.submit');

  return (
    <SubmitButton disabled={disabled} onClick={onSubmit}>
      {buttonText}
    </SubmitButton>
  );
};

Submit.defaultProps = {
  disabled: false
};

Submit.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

export default Submit;
