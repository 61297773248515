import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Table } from '@appkit4/react-components/table';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../documents/utils';
import { LoadingIndicator } from '../../elements/loading';
import { getWorkflowHistory } from '../util/requests';
import { STATUS } from '../../util/status';
import DownloadButton from '../shared-components/buttons/DownloadButton';
import DashboardButton from '../shared-components/buttons/DashboardButton';

const StyledTable = styled(Table)`
  margin-top: 10px;
  width: 100%;

  .rt-tr,
  .rt-td {
    background-color: white;
  }
`;

const DetailButton = ({ workflowId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();

  return (
    <Button
      onClick={() => history.push(`/handles/${id}?tab=history&workflow=true&id=${workflowId}`)}
      data-test-label="details-btn"
    >
      {t('button.details')}
    </Button>
  );
};

const columns = t => {
  return [
    {
      Header: t('workflow.label.start'),
      accessor: 'initiatedAt',
      Cell: row => formatDateTime(row.value),
      width: 200
    },
    {
      Header: t('workflow.label.finish'),
      accessor: 'finishedAt',
      Cell: row => (row.value ? formatDateTime(row.value) : ''),
      width: 200
    },
    {
      Header: t('workflow.label.status'),
      accessor: 'status',
      width: 100,
      Cell: row => startCase(row.value)
    },
    {
      Header: t('workflow.label.submissions'),
      sortable: false,
      width: 120,
      // eslint-disable-next-line
      Cell: row => {
        const submissions =
          row.original.submissions?.length ??
          row.original.reviewSubmissions.length + row.original.preparerSubmissions.length;
        const participants =
          row.original.participants?.length ?? row.original.reviewers.length + row.original.preparers.length;
        return (
          <div>
            {submissions}/{participants}
          </div>
        );
      }
    },
    {
      width: 70,
      accessor: 'id',
      sortable: false,
      // eslint-disable-next-line
      Cell: row => {
        return <DetailButton workflowId={row.value} />;
      }
    },
    {
      width: 50,
      accessor: 'downloadReference',
      sortable: false,
      // eslint-disable-next-line
      Cell: row => {
        if (!row.value) return null;
        return <DownloadButton downloadReference={row.value} />;
      }
    },
    {
      accessor: 'produces',
      sortable: false,
      // eslint-disable-next-line
      Cell: row => {
        if (!row.value) return null;
        if (!row.original.downloadReference) return null;
        return <DashboardButton workflowId={row.original.id} />;
      }
    }
  ];
};

const HistoryOverview = ({ handleId }) => {
  const { t } = useTranslation();
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState(STATUS.LOADING);

  useEffect(() => {
    getWorkflowHistory(handleId)
      .then(res => {
        setStatus(STATUS.OK);
        setHistory(res.data);
      })
      .catch(() => setStatus(STATUS.ERROR));
  }, [handleId]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  return (
    <StyledTable
      originalData={history}
      noDataText={t('message.noneAvailable', { type: t('type.workflow', { count: 2 }) })}
      columns={columns(t)}
      showPagination
      sortable
      hasTitle
      pageSize={10}
      defaultSorted={[{ id: 'initiatedAt', desc: true }]}
    />
  );
};

HistoryOverview.propTypes = {
  handleId: PropTypes.string.isRequired
};

export default HistoryOverview;
