import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';
import { copy } from '../util/copy';
import {
  Container,
  EnabledEntry,
  DisabledEntry,
  LockIcon,
  EntryText,
  EntryHeader,
  Wrapper,
  GridWrapper,
  ContentWrapper,
  EntryDescription,
  EntryImageWrapper
} from '../styles/GeneralElements';
import Footer from '../footer/Footer';
import { getHandleDescription } from '../util/requests';
import { APP_NAME, STATUS_ASSEMBLING_SUCCESS, STATUS_VALIDATION_VALID } from '../config';
import AppImage from '../handles/image/AppImage';

const isEnabled = ({ status, value }) => {
  let enabled = false;

  if (status === STATUS_ASSEMBLING_SUCCESS) {
    enabled = true;
  } else if (status === STATUS_VALIDATION_VALID) {
    enabled = 'transformAllowed' in value ? value.transformAllowed : value.transform_allowed;
  }

  return enabled;
};

const Result = ({ object }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [description, setDescription] = useState('');
  const { handle, handleId } = object;
  const enabled = isEnabled(object);

  const Entry = enabled ? EnabledEntry : DisabledEntry;

  useEffect(() => {
    getHandleDescription(handle).then(response => setDescription(response?.data?.description ?? ''));
  });

  const onClick = () => {
    if (enabled) history.push(`/handles/${handle}`);
  };

  let element = (
    <Entry className="ap-panel a-shadow-sm" data-test-label={`${handleId}-content`} key={handle} onClick={onClick}>
      <EntryImageWrapper>
        <AppImage id={handleId} name={handle} size="a-md" />
      </EntryImageWrapper>
      <EntryText>
        <EntryHeader data-test-label={`${handle}-header`}>
          <span>{handle}</span>
          {!enabled && <LockIcon data-test-label="drag-arrow" />}
        </EntryHeader>
        <EntryDescription data-test-label={`${handle}-description`}>{description}</EntryDescription>
      </EntryText>
    </Entry>
  );

  if (!enabled) {
    element = (
      <Tooltip
        content={t('message.noAccess2', { appName: APP_NAME })}
        position="top"
        style={{
          maxWidth: '250px'
        }}
      >
        {element}
      </Tooltip>
    );
  }

  return element;
};

const ResultsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { transformedResults, isPwCUser } = useContext(QSFContext);
  let results = copy(transformedResults);

  const goHome = useCallback(() => history.push('/'), [history]);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="results">
      <span className="bc-text" tabIndex={2}>
        {t('menu.results')}
      </span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  // Display handles list based on accessibility and alphabetically.
  results = Object.values(results).sort((a, b) => {
    if (isEnabled(a) && !isEnabled(b)) return -1;
    else if (isEnabled(b) && !isEnabled(a)) return 1;
    return a.handle.localeCompare(b.handle);
  });

  const list = results.map(object => <Result object={object} key={object.handle} />);

  return (
    <Container>
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <ContentWrapper>
        <Wrapper data-test-label="results-list-container">
          <GridWrapper style={{ marginTop: 10 }}>{list}</GridWrapper>
        </Wrapper>
        <Footer />
      </ContentWrapper>
    </Container>
  );
};

export default ResultsPage;
