import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DownloadButton from '../../shared-components/buttons/DownloadButton';
import Property from './Property';

const Wrapper = styled.div`
  display: flex;
  .label {
    width: 200px;
    margin: 5px 0;
    align-self: center;
    font-size: 0.75rem;
    line-height: 1.1875rem;
    font-weight: 400;
    color: #2d2d2d;
  }
`;

const Download = ({ downloadReference }) => {
  const { t } = useTranslation();

  if (!downloadReference) {
    return <Property name={t('workflow.properties.download')} value="N/A" />;
  }

  return (
    <Wrapper>
      <div className="label">{t('button.download')}</div>
      <DownloadButton downloadReference={downloadReference} />
    </Wrapper>
  );
};

Download.defaultProps = {
  downloadReference: ''
};

Download.propTypes = {
  downloadReference: PropTypes.string
};

export default Download;
