export const STATUS = Object.freeze({
  LOADING: Symbol('loading'),
  OK: Symbol('ok'),
  ERROR: Symbol('error')
});

// formats GMT date time to local time in the following format: Mon/DD/YY HH:MM:SS
export const formatDateTime = dateTime => {
  const dt = new Date(dateTime);
  return `${dt.toLocaleString('default', { month: 'short' })}/${dt
    .getDate()
    .toString()
    .padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt
    .getHours()
    .toString()
    .padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}:${dt.getSeconds().toString().padStart(2, '0')}`;
};
