import React from 'react';
import styled from 'styled-components';
import { Tree } from '@appkit4/react-components/tree';
import PropTypes from 'prop-types';

const IndexWrapper = React.memo(styled.div`
  .a-tree-sub-node-list > .a-tree-node.is-file > .a-tree-single-node.is-file > .a-tree-node-title {
    font-weight: bold;
    margin-left: 22px;
  }
  .a-tree-sub-node-list.nested > .a-tree-node.is-file > .a-tree-single-node.is-file > .a-tree-node-title {
    font-weight: 400;
    margin-left: 15px;
  }
  .is-folder > .a-tree-node-title {
    font-weight: bold;
  }
`);

const IndexTree = ({ properties, isDemo, demoReference, dashboardMatch, demoDashboardMatch, history }) => {
  const { nodes } = properties;
  const data = nodes.map(node => {
    const nodeChildrenModified = node.children?.map(nodeChild => {
      return { ...nodeChild, title: nodeChild.label, key: nodeChild.value };
    });
    return {
      ...node,
      title: node.label,
      key: node.value,
      children: nodeChildrenModified,
      iconOpened: 'icon-folder-opened-outline',
      iconClosed: 'icon-folder-closed-outline'
    };
  });
  const nodesLinksArray = [];

  nodes.forEach(node => {
    node.link && nodesLinksArray.push({ value: node.value, link: node.link });
    node.children?.forEach(nodeChild => {
      nodeChild.link && nodesLinksArray.push({ value: nodeChild.value, link: nodeChild.link });
    });
  });

  const onSelectNode = node => {
    const nodeLink = nodesLinksArray.find(n => n.value === node.key);
    if (nodeLink) {
      if (isDemo) {
        history.push(
          `/discover/${demoDashboardMatch.params.handleId}/${demoDashboardMatch.params.handleName}/demo?tab=dashboard&demoReference=${demoReference}&pageDemo=${nodeLink.link}`
        );
      } else if (nodeLink.link === 'dashboard') {
        if (dashboardMatch.path === '/handles/:handleId/:handleName/dashboard/:reference/:page?') {
          dashboardMatch
            ? history.push(
                `/handles/${dashboardMatch.params.handleId}/${dashboardMatch.params.handleName}/dashboard/${dashboardMatch.params.reference}`
              )
            : history.push(`/handles/dashboard`);
        }
        if (dashboardMatch.path === '/workflow/dashboard/:handleId/:workflowId/:page') {
          history.push(
            `/workflow/dashboard/${dashboardMatch.params.handleId}/${dashboardMatch.params.workflowId}/${nodeLink.link}`
          );
        }
      } else {
        if (dashboardMatch.path === '/handles/:handleId/:handleName/dashboard/:reference/:page?') {
          dashboardMatch
            ? history.push(
                `/handles/${dashboardMatch.params.handleId}/${dashboardMatch.params.handleName}/dashboard/${dashboardMatch.params.reference}/${nodeLink.link}`
              )
            : history.push(`/handles/dashboard/${nodeLink.link}`);
        }
        if (dashboardMatch.path === '/workflow/dashboard/:handleId/:workflowId/:page') {
          history.push(
            `/workflow/dashboard/${dashboardMatch.params.handleId}/${dashboardMatch.params.workflowId}/${nodeLink.link}`
          );
        }
      }
    }
  };

  return (
    <IndexWrapper>
      <Tree data={data} onClick={onSelectNode} />
    </IndexWrapper>
  );
};

IndexTree.propTypes = {
  properties: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.any).isRequired
  }).isRequired
};

export default IndexTree;
