import React from 'react';
import PropTypes from 'prop-types';
import { sanitizer } from '../../../../util';

const Html = ({ field, value }) => {
  // Usually the value of an HTML field is in field.value. However in the case that
  // it's an HTML field within a table, the value will passed through the value prop.
  const v = value || field.value;
  return <div dangerouslySetInnerHTML={{ __html: sanitizer(v) }} />;
};

Html.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string
  }).isRequired,
  value: PropTypes.string
};

export default Html;
