import React, { useState, useEffect, useRef, useContext } from 'react';
import { ResponsiveContainer, RadialBarChart } from 'recharts';
import PropTypes from 'prop-types';
import { DashboardContext } from '../../DashboardContext';
import RechartsComponents from '../RechartsComponents';

const RadialBarDiagram = ({ properties, childComponents, id, title, data }) => {
  const [containerKey, setContainerKey] = useState();
  const { addComponentRef } = useContext(DashboardContext);
  const ref = useRef(null);

  // TODO Remove this when back-end version of PDF-creator is implemented
  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  useEffect(() => setContainerKey(id), [data, id]);

  const componentData = data[properties.data];

  return (
    <ResponsiveContainer key={containerKey} ref={ref}>
      <RadialBarChart {...properties} data={componentData}>
        {RechartsComponents(childComponents, componentData)}
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

RadialBarDiagram.defaultProps = {
  properties: {},
  childComponents: []
};

RadialBarDiagram.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  childComponents: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default RadialBarDiagram;
