import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import * as worker from 'pdfjs-dist/build/pdf.worker.entry';
import styled from 'styled-components';
import { Pagination } from '@appkit4/react-components/pagination';
import { Select } from '@appkit4/react-components/select';
import { Button } from '@appkit4/react-components/button';
import { Input } from '@appkit4/react-components/field';
import PropTypes from 'prop-types';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@primer/octicons-react';
import { DashboardContext } from '../DashboardContext';
import { downloadPdfReference, getPdfViewerFilesInBucket } from '../../../util/requests';
import { LoadingIndicator } from '../../../elements/loading';
import ZoomInIcon from '../../../styles/ZoomInIcon';
import ZoomOutIcon from '../../../styles/ZoomOutIcon';

pdfjs.GlobalWorkerOptions.worker = worker;

const PdfContainer = React.memo(styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`);

const DocumentToolbarContainer = React.memo(styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  & > * {
    flex: 1 1 50%;
    margin-right: 1px;
    margin-left: 1px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`);

const ZoomButton = React.memo(styled.button`
  max-width: 30px;
  height: 34px;
  background: none;
`);

const SearchContainer = React.memo(styled.div`
  padding: 0 2px;
  max-width: 250px;
`);

const SearchToolbar = React.memo(styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * {
    display: flex;
    font-size: 0.75rem;
    padding: 3px;
  }
`);

function highlightPattern(text, pattern, pageNumber, textToMark) {
  const isPatternMatched =
    text.str.length > 2 && Number(text.pageNumber) === Number(pageNumber) && pattern.includes(text.str);

  const isTextNotEmpty = text.str.trim() !== '';

  const isTextMatched =
    textToMark?.toLowerCase().includes(text.str.toLowerCase()) ||
    text.str.toLowerCase().includes(textToMark?.toLowerCase());

  const isCurrentResultMatched = textToMark && isTextNotEmpty && isTextMatched;

  if (isPatternMatched) {
    return `<mark>${text.str}</mark>`;
  } else if (isCurrentResultMatched) {
    const regex = new RegExp(`(${textToMark})`, 'gi');
    const markedText = text.str.replace(regex, `<mark style="background:yellow">$1</mark>`);
    return markedText;
  }

  return text.str;
}

const PdfViewerElement = ({ properties, data }) => {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [documentPageMap, setDocumentPageMap] = useState({});
  const [searchText, setSearchText] = useState(null);
  const { linkToPdfSelected } = useContext(DashboardContext);
  const bucketReference = data[properties.data];
  const documentRef = useRef();
  const [pdfFileNames, setPdfFileNames] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [fileCount, setFileCount] = useState(0);
  const [searchArray, setSearchArray] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [textTomark, setTextToMark] = useState(null);
  const [selectedSearchResultIndex, setSelectedSearchResultIndex] = useState(null);
  const [resultCount, setResultCount] = useState(null);
  const [scale, setScale] = useState(1);
  const [annotationLayer, setAnnotationLayer] = useState(null);
  const ZOOM_INCREMENT = 0.25;

  const { t } = useTranslation();

  useEffect(() => {
    // Fetch and set the list of PDF files
    const fetchFileNames = async () => {
      try {
        const response = await getPdfViewerFilesInBucket(bucketReference);
        setPdfFileNames(response.data.fileIds);
        setFileCount(response.data.fileCount);
        setSelectedPdf(response.data.fileIds[0]);
      } catch (error) {
        console.error('Error fetching PDF file names', error);
      }
    };

    fetchFileNames();
  }, [bucketReference]);

  useEffect(() => {
    const newDocumentPageMap = pdfFileNames.reduce((acc, pdfFile) => {
      acc[pdfFile] = 1;
      return acc;
    }, {});

    setDocumentPageMap(prev => ({
      ...prev,
      ...newDocumentPageMap
    }));
  }, [pdfFileNames]);

  useEffect(() => {
    // Load the selected PDF
    if (selectedPdf) {
      const loadPdf = async () => {
        const response = await downloadPdfReference(`${bucketReference}/${selectedPdf}`);
        setPdfDocument(response.data);
      };

      loadPdf();
      // Reset search results on pdf change
      setSearchQuery('');
      setSearchResults([]);
    }
  }, [selectedPdf, bucketReference]);

  // If pdfId is = to selected, continue
  // otherwise select the correct pdf with getPdfReference and the useEffect
  useEffect(() => {
    if (linkToPdfSelected) {
      if (`${linkToPdfSelected.pdfId}.pdf` !== selectedPdf) {
        setSelectedPdf(`${linkToPdfSelected.pdfId}.pdf`);
      }

      setCurrentPage(Number(linkToPdfSelected.page));
      setSearchText(linkToPdfSelected.searchText);
      setSearchQuery('');
      setSearchResults([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkToPdfSelected]);

  useEffect(() => {
    const extract = async () => {
      if (!pdfDocument) return;

      try {
        const blob = new Blob([pdfDocument], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const loadingTask = pdfjs.getDocument(url);
        const document = await loadingTask.promise;
        const totalPageCount = document.numPages;

        const getPageTextContent = async pageIndex => {
          const page = await document.getPage(pageIndex + 1);
          const textContent = await page.getTextContent();
          return textContent.items.map(item => item.str).join('');
        };

        const pagesContent = [];
        for (let i = 0; i < totalPageCount; i++) {
          const pageContent = await getPageTextContent(i);
          pagesContent.push({ pageNumber: i + 1, content: pageContent });
        }

        setSearchArray(pagesContent);
      } catch (error) {
        console.error('Error extracting PDF content:', error);
      }
    };

    extract();
  }, [pdfDocument]);

  const removeTextLayerOffset = () => {
    const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
    textLayers.forEach(layer => {
      const { style } = layer;
      style.top = '0';
      style.left = '0';
      style.transform = '';
    });
  };

  const textRenderer = useCallback(
    textItem => highlightPattern(textItem, searchText, linkToPdfSelected?.page, textTomark),
    [searchText, linkToPdfSelected?.page, textTomark]
  );

  const onPageLoadSuccess = useCallback(() => {
    removeTextLayerOffset();
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setCurrentPage(documentPageMap[selectedPdf]);

    if (linkToPdfSelected) {
      setSearchText(linkToPdfSelected.searchText);
    }

    if (`${linkToPdfSelected?.pdfId}.pdf` !== selectedPdf) {
      setCurrentPage(documentPageMap[selectedPdf]);
    } else {
      setDocumentPageMap(prev => {
        return { ...prev, [selectedPdf]: Number(linkToPdfSelected.page) };
      });
      setCurrentPage(Number(linkToPdfSelected.page));
    }
  };

  const onPageChange = pageNumber => {
    resetSearchValues();
    setCurrentPage(parseInt(pageNumber));

    setDocumentPageMap(prev => {
      return { ...prev, [selectedPdf]: parseInt(pageNumber) };
    });
  };

  const onPdfChange = e => {
    setCurrentPage(documentPageMap[selectedPdf]);
    setSelectedPdf(e);
  };

  const handleZoom = direction => {
    if (direction === 'in') {
      setScale(scale + ZOOM_INCREMENT);
    } else if (direction === 'out' && scale > 0.5) {
      setScale(scale - ZOOM_INCREMENT);
    }
  };

  const dataSelect = pdfFileNames.map(fileName => {
    return {
      value: fileName,
      label: fileName
    };
  });

  const countUniqueOccurrences = (searchArray, searchQuery) => {
    let count = 0;

    searchArray.forEach(item => {
      if (item.content.toLowerCase().includes(searchQuery.toLowerCase())) {
        count++;
      }
    });

    return count;
  };

  const resetSearchValues = () => {
    setSearchResults([]);
    setResultCount(null);
    setTextToMark('');
  };
  const handleSearchInputChange = value => {
    resetSearchValues();
    setSearchQuery(value);
  };

  const handleSearch = e => {
    if (e.key === 'Enter' && searchQuery?.trim().length) {
      const results = searchArray.filter(item => item.content.toLowerCase().includes(searchQuery.toLowerCase()));

      const count = countUniqueOccurrences(searchArray, searchQuery);
      setResultCount(count);
      const currentPageResultIndex = results.findIndex(item => item.pageNumber === currentPage);

      if (!results.length) return;

      setTextToMark(searchQuery);
      setSearchResults(results);
      setSearchText('');

      if (currentPageResultIndex > 0) {
        setSelectedSearchResultIndex(currentPageResultIndex);
      } else {
        setCurrentPage(results[0].pageNumber);
        setSelectedSearchResultIndex(0);
      }
    }
  };

  const handleSearchNavigation = direction => {
    if (direction === 'next' && searchResults[selectedSearchResultIndex + 1]) {
      setCurrentPage(searchResults[selectedSearchResultIndex + 1].pageNumber);
      setSelectedSearchResultIndex(selectedSearchResultIndex + 1);
    }

    if (direction === 'previous' && searchResults[selectedSearchResultIndex - 1]) {
      setCurrentPage(searchResults[selectedSearchResultIndex - 1].pageNumber);
      setSelectedSearchResultIndex(selectedSearchResultIndex - 1);
    }
  };

  const handlePageClick = e => {
    if (!annotationLayer) return;
    const annotationId = e.target.dataset.elementId;
    const clickedAnnotationLayer = annotationLayer.find(element => element.id === annotationId);

    switch (clickedAnnotationLayer.action) {
      case 'NextPage':
        setCurrentPage(currentPage + 1);
        break;
      case 'PrevPage':
        setCurrentPage(currentPage - 1);
        break;
      default:
        return;
    }
  };

  const documentOnItemClick = ({ pageNumber }) => {
    setCurrentPage(pageNumber);
  };

  const handleOnGetAnnotationsSuccess = annotationLayer => {
    setAnnotationLayer(annotationLayer);
  };

  return (
    <PdfContainer>
      {/* PDF Viewer */}
      {pdfDocument && (
        <>
          <DocumentToolbarContainer>
            <>
              {
                /* Dropdown for selecting PDF files */
                fileCount && fileCount > 1 && (
                  <Select onSelect={onPdfChange} value={selectedPdf} style={{ maxWidth: '250px' }} data={dataSelect} />
                )
              }
            </>
            <>
              <SearchContainer>
                <Input
                  placeholder={t('dashboard.search')}
                  onChange={handleSearchInputChange}
                  onKeyPress={handleSearch}
                  value={searchQuery}
                />
                <SearchToolbar>
                  <>
                    {resultCount !== null && searchQuery?.length > 0 && (
                      <span>{t('dashboard.foundOnPages', { count: resultCount })}</span>
                    )}
                    {searchResults?.length > 0 && searchQuery?.length > 0 && (
                      <div>
                        <Button
                          onClick={() => handleSearchNavigation('previous')}
                          gray
                          size="12"
                          compact
                          style={{ marginRight: 10 }}
                        >
                          <ChevronLeftIcon size="12" />
                        </Button>{' '}
                        <Button onClick={() => handleSearchNavigation('next')} gray compact>
                          <ChevronRightIcon size="12" />
                        </Button>
                      </div>
                    )}
                  </>
                </SearchToolbar>
              </SearchContainer>
            </>
          </DocumentToolbarContainer>

          {numPages && (
            <DocumentToolbarContainer>
              <ZoomButton onClick={() => handleZoom('in')}>
                <ZoomInIcon height={16} width={16} fill="#9e9e9e" />
              </ZoomButton>
              <ZoomButton onClick={() => handleZoom('out')}>
                <ZoomOutIcon height={16} width={16} fill="#9e9e9e" />
              </ZoomButton>
              <Pagination current={currentPage} total={numPages} defaultPageSize={1} onPageChange={onPageChange} />
            </DocumentToolbarContainer>
          )}

          <Document
            file={pdfDocument}
            onLoadSuccess={onDocumentLoadSuccess}
            ref={documentRef}
            className="pdf-doc-layout"
            noData="Downloading PDF..."
            externalLinkTarget="_blank"
            onItemClick={documentOnItemClick}
          >
            <Page
              key={`page_${currentPage}`}
              className="pdf-doc-page"
              pageNumber={currentPage}
              onGetAnnotationsSuccess={handleOnGetAnnotationsSuccess}
              onLoadSuccess={onPageLoadSuccess}
              customTextRenderer={textRenderer}
              onClick={handlePageClick}
              scale={scale}
            />
          </Document>
        </>
      )}
      {!pdfDocument && <LoadingIndicator />}
    </PdfContainer>
  );
};

PdfViewerElement.propTypes = {
  properties: PropTypes.shape({
    style: PropTypes.objectOf(PropTypes.any),
    file: PropTypes.string
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PdfViewerElement;
