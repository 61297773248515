import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { InputNumber } from '@appkit4/react-components/input-number';
import NumberFormat from 'react-number-format';

const NumberWrapper = styled.div`
  display: flex;
`;

const Prefix = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-top: 20px;
`;

const Suffix = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-top: 20px;
`;

const Input = ({ field, value, onChange, onBlur }) => {
  const {
    id,
    min,
    max,
    step,
    integerOnly,
    suffix,
    prefix,
    formattedInput = false,
    thousandSeparator = ',',
    decimalSeparator = '.'
  } = field;

  const formattedInputValueChange = ({ floatValue }) => {
    let valueModified = floatValue;
    if (max !== undefined && floatValue > max) {
      valueModified = max;
    }
    if (min !== undefined && floatValue < min) {
      valueModified = min;
    }
    onChange(id, valueModified);
  };

  if (formattedInput) {
    return (
      <div className="a-input-number-wrapper a-input-size-sm a-input-number-default">
        <NumberFormat
          className="a-input-text a-input-number formatted-input"
          decimalScale={integerOnly ? 0 : undefined}
          value={value}
          prefix={prefix}
          suffix={suffix}
          onValueChange={formattedValueObject => formattedInputValueChange(formattedValueObject)}
          thousandSeparator={thousandSeparator}
          decimalSeparator={decimalSeparator}
          onBlur={onBlur}
        />
      </div>
    );
  }

  return (
    <NumberWrapper>
      {prefix && (
        <Prefix data-test-label="number-prefix" className="prefix">
          {prefix}
        </Prefix>
      )}
      <InputNumber
        min={min}
        max={max}
        step={step}
        className="a-input-size-sm"
        value={value}
        onChange={value => onChange(id, value)}
        onBlur={onBlur}
      />
      {suffix && (
        <Suffix data-test-label="number-suffix" className="suffix">
          {suffix}
        </Suffix>
      )}
    </NumberWrapper>
  );
};

Input.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    integerOnly: PropTypes.bool,
    suffix: PropTypes.string,
    prefix: PropTypes.string,
    formattedInput: PropTypes.bool,
    thousandSeparator: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    decimalSeparator: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};

export default Input;
