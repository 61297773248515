import axios from 'axios';
import { v4 as uuid } from 'uuid';

const id_token = localStorage.getItem('ROCP_idToken');
const token = localStorage.getItem('ROCP_token');
const pwc_ppi = localStorage.getItem('PwCPPI');

if (token) {
  axios.defaults.headers.common['X-ID-TOKEN'] = id_token;
  axios.defaults.headers.common['X-PWC-PPI'] = pwc_ppi;
  axios.defaults.headers.common['Authorization'] = token;
}

export const getWorkflows = () => {
  return axios.get('/api/workflows');
};

export const getWorkflowOverview = workflowId => {
  return axios.get(`/api/workflow/${workflowId}`);
};

export const getParticipantForm = (workflowId, formName) => {
  return axios.get(`/api/workflow-form/${workflowId}/${formName}`);
};

export const getSubmittedForm = (workflowId, preparerEmail) => {
  return axios.get(`/api/submitted-form/${workflowId}/${preparerEmail}`);
};

export const getWorkflowHistory = handleId => {
  return axios.get(`/api/workflow-history/${handleId}`);
};

export const getWorkflowDashboard = dashboardReference => {
  return axios.get(`/api/workflow-result-dashboard/${dashboardReference}`);
};

export const submitWorkflow = (
  { filesToUpload = [], formToUpload },
  connectionId,
  handleName = '',
  handleId = '',
  workflowId = '',
  workflowStage = '',
  rejected = false,
  formName = '',
  submissionId = ''
) => {
  const formData = new FormData();

  filesToUpload.forEach(file => {
    formData.append('files', file);
  });

  if (formToUpload) {
    const formString = JSON.stringify(formToUpload, (key, value) => {
      if (value instanceof File) {
        const id = uuid();
        formData.append(id, value);
        return id;
      }
      return value;
    });
    formData.append('form', new File([formString], 'form.json'));
  }

  return axios({
    method: 'put',
    url: `/api/submit-workflow/${handleName}?connectionId=${connectionId}&handleId=${handleId}&workflowId=${workflowId}&workflowStage=${workflowStage}&rejected=${rejected}&formName=${formName}&submissionId=${submissionId}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const generatePreliminaryResults = (
  connectionId,
  handleName = '',
  handleId = '',
  workflowId = '',
  workflowStage = ''
) => {
  return axios.get(
    `/api/workflow/preliminary-results/${handleName}?connectionId=${connectionId}&handleId=${handleId}&workflowId=${workflowId}&workflowStage=${workflowStage}`
  );
};
