export const FIELD = Object.freeze({
  INPUT: 'input',
  HIDDEN: 'hidden',
  TEXTAREA: 'textArea',
  SLIDER: 'slider',
  NUMBER: 'number',
  SELECT: 'select',
  SELECT_PREDEFINED: 'selectPredefined',
  DATE: 'date',
  SINGLE_CHOICE: 'singleChoice',
  MULTIPLE_CHOICE: 'multipleChoice',
  SELECT_MULTIPLE: 'selectMultiple',
  BOOLEAN: 'boolean',
  FILE_UPLOAD: 'fileUpload',
  TABULAR: 'tabular',
  SWITCH: 'switch',
  TAGS: 'tags',

  // static non-input ones
  HTML: 'html',

  // special group field
  GROUP: 'group',

  // workflow elements
  MIN_PARTICIPANTS: 'workflow_min_participants',
  MIN_PARTICIPANTS_PERCENT: 'workflow_min_participants_percentage',
  PARTICIPANTS: 'workflow_participants',
  RUN_AFTER_DATETIME: 'workflow_run_after_datetime',
  MULTIPLE_CHOICE_SELECTOR: 'multipleChoiceSelector',
  AND_OR: 'workflow_and_or'
});
