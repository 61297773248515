import React, { useContext, useEffect, useState } from 'react';

import { Tooltip } from '@appkit4/react-components/tooltip';
import { Tree } from '@appkit4/react-components/tree';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getDownloadFileList, downloadFileList, downloadFiles } from '../../util/requests';
import { QSFContext } from '../../context';
import { ButtonHoverPointer } from '../../styles/GeneralElements';

const DownloadFilesButtonWrapper = styled.div`
  margin-right: 10px;
  display: flex;
`;

const TooltipDownloadFiles = styled(Tooltip)`
  .ap-tooltip-reactContent {
    min-width: 300px;
    background-color: #fff !important;
    box-shadow: 0 0.4375rem 1.125rem 0.0625rem rgba(0, 0, 0, 0.16);
  }

  .ap-files-fold.active,
  .ap-files-fold:hover {
    background-color: #fff;
  }

  .ap-files-fold.active .ap-fold-name,
  .ap-files-fold .ap-files-fold {
    background-color: #fff;
  }
`;

const DownloadFilesButton = ({ currentPage, handleId, hasDownloadableContent }) => {
  const { t } = useTranslation();
  const { availableDownloads } = useContext(QSFContext);
  const [downloads, setDownloads] = useState([]);
  const [currentDownloadReference, setCurrentDownloadReference] = useState(null);

  useEffect(() => {
    if (!availableDownloads || !availableDownloads[handleId]?.length) return;
    setCurrentDownloadReference(availableDownloads[handleId][availableDownloads[handleId].length - 1]?.reference);
  }, [availableDownloads, handleId]);

  useEffect(() => {
    if (!currentDownloadReference) return;
    getDownloadFileList(currentDownloadReference)
      .then(res => {
        setDownloads(res.data);
      })
      .catch(e => console.error(e));
  }, [currentDownloadReference]);

  const RenderDownloads = ({ data, reference }) => {
    const attachmentsNodes = data.map((item, index) => {
      return { title: item, key: index };
    });

    if (attachmentsNodes.length > 1) {
      attachmentsNodes.push({
        title: t('menu.downloadAll'),
        key: 'all',
        icon: 'icon-folder-download-fill'
      });
    }

    const onClickEvent = async item => {
      if (item.key === 'all') {
        const response = await downloadFiles(reference);
        const blob = new Blob([response.data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${handleId}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } else {
        const fileName = encodeURIComponent(item.title);
        downloadFileList(reference, fileName).then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = item.title.split('/').pop();
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    };

    return (
      <div>
        <Tree data={attachmentsNodes} onClick={onClickEvent} />
      </div>
    );
  };

  return (
    <DownloadFilesButtonWrapper>
      <TooltipDownloadFiles
        content={() => <RenderDownloads data={downloads} reference={currentDownloadReference} />}
        trigger="click"
        tooltipTheme="light"
        clickToClose
        key={currentPage.id}
        position="bottom-right"
        hideTooltipOnBlur={false}
        appendAfterTarget
      >
        <ButtonHoverPointer
          className="a-btn-lg"
          id="tooltipDownloadFiles"
          data-test-label="tooltip-download-files-btn"
          disabled={!downloads.length || !Array.isArray(downloads)}
        >
          {t('menu.downloads')}
        </ButtonHoverPointer>
      </TooltipDownloadFiles>
    </DownloadFilesButtonWrapper>
  );
};

DownloadFilesButton.propTypes = {
  currentPage: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string
  }),
  handleId: PropTypes.string
};

export default DownloadFilesButton;
