import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { getHandleById, getVideos } from '../../util/requests';
import { VideoGridWrapper, VideoEntry, VideosWrapper } from '../../styles/VideosElements';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../../elements/loading';
import { STATUS } from '../../util/status';
import { HANDLE_TYPE } from '../../util/handleType';
import VideosHeader from './VideosHeader';
import VideoPanel from './VideoPanel';
import { dateTimeAgo } from './util';

const Videos = () => {
  const { t } = useTranslation();
  const { handleId, handleName } = useParams();
  const history = useHistory();
  const [videoList, setVideoList] = useState([]);
  const [info, setInfo] = useState({});
  const [status, setStatus] = useState(STATUS.LOADING);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getHandleById(handleId);
        setInfo(response.data);
        const list = await getVideos(response.data.id);
        // sort videos from newest to oldest
        const videos = list.data.sort((a, b) => b.createdAt - a.createdAt);
        setVideoList(videos);
        setStatus(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
      }
    };

    getData();
  }, [handleId]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator handleType={HANDLE_TYPE.DEFAULT} />;
  }

  const VideosElement = () => {
    switch (videoList.length) {
      case 0:
        return (
          <VideoGridWrapper data-test-label={`videos-${info.id}-list-wrapper`}>
            <div>{t('handle.noResults')}</div>
          </VideoGridWrapper>
        );
      case 1:
        return <VideoPanel video={videoList[0]} />;
      default: {
        return (
          <VideoGridWrapper data-test-label={`videos-${info.id}-list-wrapper`}>
            {videoList.map(video => {
              const { id, name, thumbnailUrl, createdAt } = video;
              const onClick = () => history.push(`/videos/${handleId}/${handleName}/${id}`);

              return (
                <VideoEntry onClick={onClick} key={name} data-test-label={`videos-${name}-panel`}>
                  <LazyLoadComponent>
                    <img
                      src={`${thumbnailUrl}/height/200/width/355/type/3`}
                      alt={name}
                      style={{ maxWidth: '100%', height: '100%' }}
                    />
                  </LazyLoadComponent>
                  <h6 data-test-label={`videos-${id}-panel-header`}>{name}</h6>
                  <div className="info" data-test-label={`videos-${id}-panel-info`}>
                    <span className="Appkit4-icon icon-time-outline" />
                    <span className="date">{dateTimeAgo(createdAt)}</span>
                  </div>
                </VideoEntry>
              );
            })}
          </VideoGridWrapper>
        );
      }
    }
  };

  return (
    <VideosWrapper>
      <VideosHeader id={info.id} description={info.singleLineDescription} contact={info.contact} />
      <VideosElement />
    </VideosWrapper>
  );
};

export default Videos;
