import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@appkit4/react-components/button';
import { Input } from '@appkit4/react-components/field';
import { Panel } from '@appkit4/react-components/panel';
import { Table } from '@appkit4/react-components/table';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { STATUS } from '../../../util/status';
import { getWorkflowOverview } from '../../util/requests';
import { LoadingIndicator } from '../../../elements/loading';
import ErrorMessage from '../../shared-components/ErrorMessage';
import HandleHeader from '../../../handles/HandleHeader';
import { Container } from '../../../styles/HandleComponents';
import { Wrapper } from '../../../styles/GeneralElements';

export const StyledTable = styled(Table)`
  margin-top: 10px;
  width: 100%;

  .rt-tr,
  .rt-td {
    background-color: white;
  }

  .-loading > div {
    transform: translateY(-52%) !important;
  }
`;

const HorizontalInput = styled(Input)`
  display: flex;
  margin: 5px 0;
  label {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    align-self: center;
  }
  input {
    width: 100%;
    background-color: #ffffff;
    font-size: 14px;
    align-self: 'flex-end';
  }
`;

export const InputWrapper = React.memo(styled.div`
  display: flex;
  flex-grow: 12;
`);

const DetailButton = ({ uri, text }) => {
  const history = useHistory();
  return <Button onClick={() => history.push(`/submitted-form/${uri}`)}>{text}</Button>;
};

const formatData = (workflowData, t) => {
  const forms = [];
  workflowData.preparers.forEach(preparer => {
    forms.push({
      workflowId: workflowData.id,
      handleName: workflowData.handleName,
      dateCompleted: preparer.dateCompleted,
      preparer: `${preparer.firstName} ${preparer.lastName}`,
      preparerEmail: preparer.email,
      submitted: workflowData.preparerSubmissions.includes(preparer.email)
    });
  });

  return forms;
};

const columns = t => {
  return [
    {
      Header: t('workflow.details.preparers'),
      accessor: 'preparer'
    },
    {
      Header: t('form.field.email'),
      accessor: 'preparerEmail'
    },
    {
      Header: t('workflow.label.status'),
      accessor: 'submitted',
      Cell: row => {
        return row.value ? t('workflow.status.submitted') : t('workflow.status.incomplete');
      }
    },
    {
      Header: t('workflow.label.dateSubmitted'),
      accessor: 'dateCompleted',
      Cell: row => {
        return !row.original.submitted ? t('workflow.label.na') : row.value.toLocaleString('en-US');
      }
    },
    {
      Header: '',
      accessor: 'submitted',
      Cell: row => {
        return row.value ? (
          <DetailButton
            t={t}
            uri={`${row.original.workflowId}/${row.original.preparerEmail}`}
            text={t('button.viewSubmission')}
          />
        ) : null;
      }
    }
  ];
};

const ReviewPublishWorkflowOverview = () => {
  const { t } = useTranslation();
  const { workflowId } = useParams();

  const [status, setStatus] = useState(STATUS.LOADING);
  const [workflowData, setWorkflowData] = useState({});
  const [formData, setFormData] = useState([]);
  const [handleName, setHandleName] = useState('');
  const [handleId, setHandleId] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getWorkflowOverview(workflowId);
        setWorkflowData(data?.data);
        setFormData(formatData(data?.data));
        setHandleName(data?.data?.handleName);
        setHandleId(data?.data?.handleId);

        setStatus(STATUS.OK);
      } catch (e) {
        setStatus(STATUS.ERROR);
      }
    };

    getData();
  }, [workflowId]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <ErrorMessage />;
  }

  const retrieveFailed = t('message.retrieveFailed', { item: t('type.workflow', { count: 2 }) });
  const noneAvailable = t('message.noneAvailable', { type: t('type.workflow', { count: 2 }) });

  return (
    <Container data-test-label="handle-info-container">
      <HandleHeader id={handleId} handleName={handleName} />
      <Wrapper style={{ minWidth: 800 }}>
        <Panel style={{ padding: 30 }} data-test-label="search-documents-panel">
          <InputWrapper>
            <HorizontalInput
              label={t('workflow.label.dateInitiated')}
              title={t('workflow.label.dateInitiated')}
              value={new Date(workflowData.initiatedAt).toLocaleString()}
              disabled
              readonly
            />
            <HorizontalInput label={t('workflow.label.initiator')} value={workflowData.initiator} disabled readonly />
          </InputWrapper>
          <StyledTable
            originalData={formData}
            loading={status === STATUS.LOADING}
            loadingText={<LoadingIndicator />}
            noDataText={status === STATUS.LOADING ? '' : status === STATUS.ERROR ? retrieveFailed : noneAvailable}
            columns={columns(t)}
            showPagination
            sortable
            hasTitle
            pageSize={10}
            defaultSorted={[{ id: 'initiatedAt', desc: true }]}
            previousText={t('form.field.table.previous')}
            nextText={t('form.field.table.next')}
            pageText={t('form.field.table.page')}
            ofText={t('form.field.table.of')}
            rowsText={t('form.field.table.rows')}
          />
        </Panel>
      </Wrapper>
    </Container>
  );
};

export default ReviewPublishWorkflowOverview;
