import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { STATUS } from '../../../util/status';
import { getWorkflowOverview } from '../../util/requests';
import { LoadingError, LoadingIndicator } from '../../../elements/loading';
import HandleHeader from '../../../handles/HandleHeader';
import Dashboard from './Dashboard';

const Container = styled.div`
  overflow-y: visible;
  padding-left: 0.625rem;
  // Override for checkbox, AppKit alignment seems off by default
  // Logged issue: https://github.pwc.com/AdvisoryAnalyticApps/Appkit-React/issues/1687
  .ap-checkbox {
    display: inline-grid !important;
  }
`;

const DashboardWorkflowWrapper = () => {
  const { workflowId } = useParams();
  const [workflow, setWorkflow] = useState('');
  const [dashboardReference, setDashboardReference] = useState('');
  const [loading, setLoading] = useState(STATUS.LOADING);

  useEffect(() => {
    const getData = async () => {
      try {
        const workflowResult = await getWorkflowOverview(workflowId);
        setWorkflow(workflowResult?.data);
        setDashboardReference(workflowResult?.data?.downloadReference);
        setLoading(STATUS.OK);
      } catch (e) {
        setLoading(STATUS.ERROR);
      }
    };
    getData();
  }, [workflowId]);

  if (loading === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (loading === STATUS.ERROR) {
    return <LoadingError />;
  }

  return (
    <Container data-test-label="workflow-dashboard-wrapper">
      <div>
        <HandleHeader id={workflow.handleId} handleName={workflow.handleName} workflow />
      </div>
      <Dashboard dashboardReference={dashboardReference} />
    </Container>
  );
};

export default DashboardWorkflowWrapper;
