import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appkit4/react-components/button';
import { BASE_URL } from '../../../config';

const DownloadSubmissionButton = ({ workflowId, submissionId }) => {
  return (
    <Button>
      <a
        href={`${BASE_URL}/api/download-submission-files/${workflowId}/${submissionId}`}
        style={{ color: 'white', textDecoration: 'none' }}
        download
      >
        <span className="Appkit4-icon icon-download-fill" style={{ margin: 0 }} />
      </a>
    </Button>
  );
};

DownloadSubmissionButton.propTypes = {
  workflowId: PropTypes.string.isRequired,
  submissionId: PropTypes.string.isRequired
};

export default DownloadSubmissionButton;
