import React, { useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@appkit4/react-components/checkbox';
import { List, ListItem } from '@appkit4/react-components/list';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { ButtonHoverPointer } from '../../styles/GeneralElements';
import { DashboardContext } from './DashboardContext';

export const FilterList = ({ filters, onChange }) => {
  const renderItem = (filter, index) => {
    const eventCheck = event => {
      return event.type === 'click' || event.key === 'Enter';
    };

    const handleSelection = (event, item) => {
      if (eventCheck(event)) {
        onChange(item);
      }
    };

    return (
      <ListItem
        key={index}
        role="checkbox"
        aria-label={filter.name}
        aria-live="off"
        aria-checked={filter.active}
        onClick={e => {
          handleSelection(e, filter);
        }}
        className="py-0"
      >
        <Checkbox defaultChecked={filter.active} tabIndex={-1} onChange={() => onChange(filter)}>
          {filter.name}
        </Checkbox>
      </ListItem>
    );
  };

  return <List itemKey="name" data={filters || []} renderItem={renderItem} style={{ display: 'inline-block' }} />;
};

FilterList.defaultProps = {
  filters: []
};

FilterList.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired
    })
  ),
  onChange: PropTypes.func.isRequired
};

const PageFilters = () => {
  const { filters, setFilters } = useContext(DashboardContext);

  const onChange = useCallback(
    filter => {
      const newFilters = filters.map(f => {
        return f.name === filter.name ? { ...f, active: !f.active } : f;
      });
      setFilters(newFilters);
    },
    [filters, setFilters]
  );

  const MemoizedFilterList = useMemo(() => <FilterList filters={filters} onChange={onChange} />, [onChange, filters]);

  if (filters.length === 0) {
    return null;
  }

  return (
    <div className="page-filter-container">
      <Tooltip
        content={MemoizedFilterList}
        trigger="click"
        tooltipTheme="light"
        position="bottom-right"
        appendAfterTarget
        hideTooltipOnBlur={false}
        clickToClose
        key="page-filters-tooltip"
      >
        <ButtonHoverPointer className="a-btn-lg" data-test-label="filter-btn">
          Filter
        </ButtonHoverPointer>
      </Tooltip>
    </div>
  );
};

export default PageFilters;
