import React, { useContext } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { useReactToPrint } from 'react-to-print';
import DomToImage from 'dom-to-image';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';
import { DashboardContext } from '../../DashboardContext';

const ActionsList = styled.ul`
  li {
    display: flex;
    align-content: center;
    padding: 0 10px !important;
    > span {
      line-height: 32px;
      &.Appkit4-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    &:hover {
      cursor: pointer;
      background: #e5e5e5;
      span.Appkit4-icon {
        color: #d04a03;
      }
    }
  }
`;

const ALLOW_EXPORT = Object.freeze({
  IMAGE: Symbol('image'),
  PDF: Symbol('pdf'),
  CSV: Symbol('csv')
});

const EXPORT_FILETYPE = Object.freeze({
  JPG: Symbol('jpeg'),
  PNG: Symbol('png'),
  CSV: Symbol('zip')
});

const COMPONENT_WITH_MULTIPLE_DATASETS = ['pie', 'scatter'];

const getExportName = (handleName, title) => `${handleName}-${title}`.replace(/ /g, '-').toLowerCase();

const downloadFile = (dataUrl, exportName, type) => {
  if (!dataUrl) return;
  const link = document.createElement('a');
  link.download = `${exportName}.${type?.description}`;
  link.href = dataUrl;
  link.click();
};

const arrayToCsv = items => {
  if (!items?.length) return null;
  const replacer = (key, value) => (value === null ? '' : value);
  const header = Object.keys(items[0]);
  let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');
  return csv;
};

const saveAsCsv = async (dataSets, exportName) => {
  if (!dataSets?.length) return;
  const zip = new JSZip();
  dataSets.forEach((items, index) => {
    const csvData = arrayToCsv(items);
    if (csvData) zip.file(`export${index}.csv`, csvData);
  });
  const zipDataUrl = await zip.generateAsync({ type: 'base64' });
  downloadFile(`data:application/zip;base64,${zipDataUrl}`, exportName, EXPORT_FILETYPE.CSV);
};

export const ComponentActions = ({ component, componentRef, componentHeight, allowExport, title }) => {
  const { data, pageComponents } = useContext(DashboardContext);
  const { handleName } = useParams();
  const { t } = useTranslation();
  const exportName = getExportName(handleName, title);
  const componentProps = pageComponents[component]?.properties || {};

  const saveAsPdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: exportName,
    onBeforePrint: () => window.history.pushState({}, '', '/'),
    onAfterPrint: () => window.history.back(),
    pageStyle:
      '@page { margin: 15mm !important; }' +
      `.panel-container { height: ${componentHeight} !important; }` +
      '.ap-panel-content { padding: 0 !important; overflow: visible !important; }' +
      '.ap-panel-header { border-bottom: none !important; }' +
      '.ap-panel-header-right { display: none !important; }' +
      '.ReactTable .pagination-bottom > .-pagination { display: none !important; }'
  });

  if (componentRef == null) {
    return null;
  }

  // filter options for image exports to avoid showing buttons and other content not necessary for static images.
  const imageOptions = {
    filter: node =>
      node.dataset?.testLabel !== `${component}-right-content` &&
      node.dataset?.testLabel !== `component-actions` &&
      node?.className !== 'a-tab-pane',
    bgcolor: '#fff'
  };

  const saveImage = async type => {
    let dataUrl;
    componentRef.current.classList.add('exporting');
    if (type === EXPORT_FILETYPE.JPG) {
      dataUrl = await DomToImage.toJpeg(componentRef?.current, imageOptions);
    } else if (type === EXPORT_FILETYPE.PNG) {
      dataUrl = await DomToImage.toPng(componentRef?.current, imageOptions);
    }
    downloadFile(dataUrl, exportName, type);
    componentRef.current.classList.remove('exporting');
  };

  const getCsvDatasets = () => {
    // If a component has multiple underlying datasets, then return an array with all these datasets.
    if (COMPONENT_WITH_MULTIPLE_DATASETS.includes(pageComponents[component]?.type)) {
      return pageComponents[component].children
        .map(c => {
          // Check if the type of (child)component has multiple datasets
          if (!c?.type || !COMPONENT_WITH_MULTIPLE_DATASETS?.includes(c.type) || !c?.properties?.data) return null;
          const items = data[c.properties.data];
          // Make sure that the data is valid and is an Array.
          return items && Array.isArray(items) ? items : null;
        })
        .filter(c => c);
    }
    // If the component has a single underlying dataset then just return this.
    const items = data[componentProps.data];
    return items && Array.isArray(items) ? [items] : null;
  };

  const csvDataSets = getCsvDatasets();

  const canExport = type => allowExport === true || allowExport?.includes(type?.description);

  return (
    <div data-test-label="component-actions" className="component-actions-container">
      <Tooltip
        position="bottom-right"
        tooltipTheme="light"
        trigger="click"
        clickToClose
        hasArrow
        content={
          <ActionsList className="a-list no-global-padding">
            {canExport(ALLOW_EXPORT.IMAGE) && (
              <>
                <li className="a-list-item" id="save-jpg" onClick={() => saveImage(EXPORT_FILETYPE.JPG)}>
                  <span className="Appkit4-icon icon-image-outline" />
                  <span>{t('dashboard.saveJpg')}</span>
                </li>
                <li className="a-list-item" id="save-png" onClick={() => saveImage(EXPORT_FILETYPE.PNG)}>
                  <span className="Appkit4-icon icon-image-outline" />
                  <span>{t('dashboard.savePng')}</span>
                </li>
              </>
            )}
            {canExport(ALLOW_EXPORT.PDF) && (
              <li className="a-list-item" id="save-pdf" onClick={saveAsPdf}>
                <span className="Appkit4-icon icon-pdf-outline" />
                <span>{t('dashboard.savePdf')}</span>
              </li>
            )}
            {canExport(ALLOW_EXPORT.CSV) && !!csvDataSets && (
              <li className="a-list-item" id="save-csv" onClick={() => saveAsCsv(csvDataSets, exportName)}>
                <span className="Appkit4-icon icon-table-data-outline" />
                <span>{t('dashboard.saveCsv')}</span>
              </li>
            )}
          </ActionsList>
        }
      >
        <Button className="component-actions-btn" kind="transparent" data-test-label="component-actions-btn" compact>
          <span className="Appkit4-icon icon-horizontal-more-fill" />
        </Button>
      </Tooltip>
    </div>
  );
};

ComponentActions.defaultProps = {
  componentHeight: 500
};

ComponentActions.propTypes = {
  component: PropTypes.string.isRequired,
  componentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  componentHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowExport: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
  title: PropTypes.string
};

export default ComponentActions;
