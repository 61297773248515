import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@appkit4/react-components/field';
import { INPUT_MAX_LENGTH } from '../../config';

const InputField = ({ field, value, onChange, onBlur }) => {
  const { id, placeholder, maxLength, title } = field;
  const max = maxLength ?? INPUT_MAX_LENGTH;

  return (
    <Input
      type="text"
      inputProps={{ maxLength: max }}
      placeholder={placeholder}
      hideTitleOnInput
      title={title}
      value={value}
      onChange={value => onChange(id, value)}
      onBlur={onBlur}
    />
  );
};

InputField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func
};

export default InputField;
