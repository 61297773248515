import React, { useContext, useEffect, useState, useRef } from 'react';
import { ResponsiveContainer, Scatter, ScatterChart } from 'recharts';
import PropTypes from 'prop-types';
import { DashboardContext } from '../../DashboardContext';
import RechartsComponents from '../RechartsComponents';
import useAddFilterHandler from '../../util/useAddFilterHandler';

const tooltipStyle = {
  border: '1px solid rgb(204, 204, 204)',
  backgroundColor: 'white',
  whiteSpace: 'nowrap',
  padding: '10px'
};

const tooltipEntryStyle = {
  margin: '0px',
  paddingBottom: '4px',
  paddingTop: '4px'
};

const customTooltipLabel = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const newPayload = [{ name: payload[0].payload?.label }, ...payload];
    return (
      <div className="recharts-default-tooltip" style={tooltipStyle}>
        {TranscribedPayload(newPayload)}
      </div>
    );
  }
  return null;
};

const TranscribedPayload = payload => {
  return payload.map(entry => (
    <>
      <p className="recharts-tooltip-entry" style={tooltipEntryStyle}>
        {addEntryContents(entry)}
      </p>
    </>
  ));
};

const addEntryContents = entry => {
  if (entry?.name && entry?.value) {
    return `${entry.name} : ${entry.value}${entry?.unit}`;
  } else if (entry?.value) {
    return `${entry.value}${entry?.unit}`;
  } else if (entry?.name) {
    return entry.name;
  }
  return '';
};

const ScatterDiagram = ({ properties, childComponents, id, data, title }) => {
  const [containerKey, setContainerKey] = useState();
  const { dataFilters, setDataFilters, addComponentRef } = useContext(DashboardContext);
  const ref = useRef(null);

  const addFilterHandler = useAddFilterHandler(dataFilters, setDataFilters);

  // TODO Remove this when back-end version of PDF-creator is implemented
  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  const scatterComponents = childComponents.filter(c => c.type === 'scatter');
  const tooltipComponents = childComponents.filter(c => c.type === 'tooltip');
  const normalComponents = childComponents.filter(c => c.type !== 'scatter' && c.type !== 'tooltip');

  const scatterProps = { ...properties };

  tooltipComponents.forEach(childElement => {
    if (childElement.properties.useTooltipLabel === true) {
      childElement.properties.content = customTooltipLabel;
    }
  });

  useEffect(() => setContainerKey(id), [data, id]);

  return (
    <ResponsiveContainer key={containerKey} ref={ref}>
      <ScatterChart {...scatterProps}>
        {scatterComponents.map(scatter => {
          const scatterData = data[scatter.properties?.data];

          addFilterHandler({ element: scatter, elementData: scatterData });

          return (
            <Scatter key={scatter.properties?.data} {...scatter.properties} data={scatterData}>
              {RechartsComponents(scatter.children)}
            </Scatter>
          );
        })}
        {RechartsComponents(normalComponents)}
        {RechartsComponents(tooltipComponents)}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

ScatterDiagram.defaultProps = {
  properties: {},
  childComponents: []
};

ScatterDiagram.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  childComponents: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ScatterDiagram;
