import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { useTranslation } from 'react-i18next';

const RejectButton = styled(Button)`
  margin-left: 10px;
  min-width: 200px;
  min-height: 25px;
`;

const Reject = ({ disabled, onReject }) => {
  const { t } = useTranslation();
  return (
    <RejectButton disabled={disabled} onClick={onReject}>
      {t('button.reject')}
    </RejectButton>
  );
};

Reject.defaultProps = {
  disabled: false
};

Reject.propTypes = {
  disabled: PropTypes.bool,
  onReject: PropTypes.func.isRequired
};

export default Reject;
