import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import { QSFContext } from '../context';
import {
  RegularNewsWrapper,
  RegularNewsItem,
  RegularNewsImage,
  RegularNewsItemInfoWrapper,
  RegularNewsTitle,
  RegularNewsInfo,
  RegularNewsTime,
  NewsTagInverted
} from '../styles/NewsElements';
import Timer from '../elements/Timer';

const NewsItem = ({ item, index }) => {
  const history = useHistory();
  const { subscriptionsSelected } = useContext(QSFContext);
  const { title, date, subscriptions, id, uri } = item;
  const mainSubscription = subscriptions.find(sub => subscriptionsSelected.find(s => s === sub.name))?.name;
  const pastDate = moment(new Date(date));
  const image = item.image?.sizes?.thumb;

  const goToDetailPage = useCallback(() => history.push(`/news/${id}?uri=${uri}`), [history, id, uri]);

  return (
    <RegularNewsItem position={index + 1}>
      {image && <RegularNewsImage image={image} />}
      <RegularNewsItemInfoWrapper>
        <RegularNewsTitle onClick={() => goToDetailPage(uri)}>{title}</RegularNewsTitle>
        <RegularNewsInfo>
          <RegularNewsTime>
            <span className="Appkit4-icon icon-time-outline" style={{ marginRight: 5 }} />
            <Timer end={pastDate} />
          </RegularNewsTime>
          {mainSubscription && <NewsTagInverted>{mainSubscription}</NewsTagInverted>}
        </RegularNewsInfo>
      </RegularNewsItemInfoWrapper>
    </RegularNewsItem>
  );
};

const RegularNewsBlock = ({ list }) => {
  return (
    <RegularNewsWrapper>
      {list.map((item, index) => (
        <NewsItem item={item} index={index} key={item.id} />
      ))}
    </RegularNewsWrapper>
  );
};

export default RegularNewsBlock;
