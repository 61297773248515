import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ChevronRight } from '../styles/GeneralElements';
import {
  HeaderWrapper,
  TitleWrapper,
  ImageLogoWrapper,
  TitleApp,
  Actions,
  ButtonDiscover
} from '../styles/HandleComponents';
import AppImage from './image/AppImage';

const HandleHeader = ({ id, workflow, handleName, isSideHeader }) => {
  const { t } = useTranslation();
  const history = useHistory();

  // handleIframeMode is set by quey parameter display_mode=handle_iframe
  // if true, a version of the FE which only show the handle should be shown.
  const [displayMode, setDisplayMode] = useState('');
  useEffect(() => {
    const displayModeQueryValue = new URLSearchParams(window.location.search).get('display_mode');
    if (displayModeQueryValue) {
      setDisplayMode(`&display_mode=${displayModeQueryValue}`);
    }
  }, [displayMode]);

  const goToDiscoverApp = () => {
    const displayModeQueryString = displayMode ? `?display_mode=${displayMode}` : '';
    workflow ? history.push(`/discover/${id}?workflow=true`) : history.push(`/discover/${id}${displayModeQueryString}`);
  };

  return (
    <HeaderWrapper>
      <TitleWrapper>
        <ImageLogoWrapper>
          <AppImage id={id} name={handleName} />
        </ImageLogoWrapper>
        <TitleApp>{handleName}</TitleApp>
      </TitleWrapper>
      <Actions>
        <ButtonDiscover className="a-btn-md" onClick={goToDiscoverApp} data-test-label="more-info-btn">
          {t('button.moreInfo')} <ChevronRight size={14} />
        </ButtonDiscover>
      </Actions>
    </HeaderWrapper>
  );
};

HandleHeader.defaultProps = {
  workflow: false,
  isSideHeader: false
};

HandleHeader.propTypes = {
  id: PropTypes.string.isRequired,
  workflow: PropTypes.bool
};

export default HandleHeader;
