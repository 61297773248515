import { useEffect, useState } from 'react';

const groupBy = (items, key) => {
  return (items ?? []).reduce((accumulator, currentValue) => {
    if (accumulator[currentValue[key]] === undefined) {
      accumulator[currentValue[key]] = [];
    }
    accumulator[currentValue[key]].push(currentValue);
    return accumulator;
  }, {});
};

/**
 * Hook which aggregates data which is used for the custom visualisation charts. numbers get aggregated and strings joined with ,
 * Example when the dataKey would be "handleId" and the input data is:
 * [
    { "test": "hello", "handleId": "ping", "number": 123 },
    { "test": "bye", "handleId": "ping", "number": 456 }
 ]
 * The hook will then transform this array in the following:
 * [
    { "test": "hello, bye", "handleId": "ping", "number": 579 }
 ]
 * @param data data which has to be aggregated
 * @param dataKey the key which is used for the grouping of the data objects.
 * @returns data filtered data is returned.
 */
export const useUniqueComponentData = (data, dataKey) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    // Only filter data which is an Array
    if (dataKey == null || data === null || !Array.isArray(data)) return;

    setFilteredData(
      Object.values(groupBy(data, dataKey)).map(items =>
        items.reduce((bundle, item) => {
          const bundledItem = { ...item };
          // Loop trough properties to combine or aggregate depending on the type of the property.
          Object.keys(item).forEach(property => {
            if (property === dataKey) return;
            if (typeof item[property] === 'number') {
              bundledItem[property] = bundle[property] + item[property];
            }
            if (typeof item[property] === 'string') {
              bundledItem[property] = `${bundle[property]}, ${item[property]}`;
            }
          });
          return bundledItem;
        })
      )
    );
  }, [data, dataKey]);

  // Only return the filtered data when the dataKey has been set. If not, return given data set.
  return dataKey == null ? data : filteredData;
};
