import React, { useCallback, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { useTranslation } from 'react-i18next';
import { Container, ContentWrapper } from '../../styles/GeneralElements';
import Footer from '../../footer/Footer';
import { APP_NAME } from '../../config';
import { QSFContext } from '../../context';

const VideoDetailWrapper = ({ videoName, children }) => {
  const { isPwCUser } = useContext(QSFContext);
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const goHome = useCallback(() => history.push('/handles'), [history]);
  const goVideos = useCallback(() => history.push('/videos'), [history]);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>{APP_NAME}</span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="videos" onClick={goVideos}>
      <span className="bc-text" tabIndex={2}>{t('menu.videos')}</span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>Doing Work</span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  if (routeMatch.params?.handleId) {
    breadcrumbs.push(
      <BreadcrumbItem
        key={routeMatch.params.handleId}
        onClick={() => history.push(`/videos/${routeMatch.params.handleId}/${routeMatch.params.handleName}`)}
      >
        <span className="bc-text" tabIndex={3}>{routeMatch.params.handleName}</span>
      </BreadcrumbItem>
    );
  }

  if (videoName) {
    breadcrumbs.push(
      <BreadcrumbItem key={routeMatch.params?.videoId}>
        <span className="bc-text" tabIndex={4}>{videoName}</span>
      </BreadcrumbItem>
    );
  }

  return (
    <Container data-test-label="handles-container">
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <ContentWrapper>
        {children}
        <Footer />
      </ContentWrapper>
    </Container>
  );
};

VideoDetailWrapper.defaultProps = {
  videoName: ''
};

VideoDetailWrapper.propTypes = {
  videoName: PropTypes.string,
  children: PropTypes.objectOf(PropTypes.any).isRequired
};

export default VideoDetailWrapper;
