import React, { useState, useEffect, useContext, useRef } from 'react';
import { ResponsiveContainer, FunnelChart, Funnel } from 'recharts';
import PropTypes from 'prop-types';
import { DashboardContext } from '../../DashboardContext';
import RechartsComponents from '../RechartsComponents';
import useAddFilterHandler from '../../util/useAddFilterHandler';
const FunnelDiagram = ({ properties, childComponents, id, title, data }) => {
  const { dataFilters, setDataFilters, addComponentRef } = useContext(DashboardContext);
  const [containerKey, setContainerKey] = useState();
  const ref = useRef(null);
  const addFilterHandler = useAddFilterHandler(dataFilters, setDataFilters);

  // TODO Remove this when back-end version of PDF-creator is implemented
  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  useEffect(() => setContainerKey(id), [data, id]);

  const funnelComponents = childComponents.filter(c => c.type === 'funnel');
  const normalComponents = childComponents.filter(c => c.type !== 'funnel');

  const funnelProps = { ...properties };
  delete funnelProps.data;

  return (
    <ResponsiveContainer key={containerKey} ref={ref}>
      <FunnelChart {...funnelProps}>
        {funnelComponents.map(funnel => {
          const funnelData = data[funnel.properties?.data];
          addFilterHandler({ element: funnel, elementData: funnelData });

          return (
            <Funnel key={funnel.properties?.dataKey} {...funnel.properties} data={funnelData}>
              {RechartsComponents(funnel.children)}
            </Funnel>
          );
        })}
        {RechartsComponents(normalComponents)}
      </FunnelChart>
    </ResponsiveContainer>
  );
};

FunnelDiagram.defaultProps = {
  properties: {},
  childComponents: []
};

FunnelDiagram.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  childComponents: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default FunnelDiagram;
