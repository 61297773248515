import { useContext } from 'react';
// Commented out as we removed 'fire all' handles feature. Uncomment when the feature is reworked.
// import React from 'react';
// import { Button } from '@appkit4/react-components';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { QSFContext } from '../../../context';
import { STATUS_DONE } from '../../../config';

export const styledLink = {
  color: 'white',
  textDecoration: 'none',
  marginLeft: '10px'
};

const AllResults = ({ handleName }) => {
  const { downloads, requestId, status } = useContext(QSFContext);
  // Commented out as we removed 'fire all' handles feature. Uncomment when the feature is reworked.
  // const { t } = useTranslation();
  const handleDownload = downloads[handleName] || {};

  // Don't show results when the download of the given handle is not the current requestId.
  if (status === STATUS_DONE && handleDownload?.requestId !== requestId && handleDownload?.requestId !== undefined)
    return null;

  // Commented out as we removed 'fire all' handles feature. Uncomment when the feature is reworked.
  // return (
  //   <Link data-test-label="download-more-results-element" style={styledLink} to="/results">
  //     <Button size="a-btn-md">
  //       <span className="appkiticon icon-circle-more-fill" />
  //       {t('button.results')}
  //     </Button>
  //   </Link>
  // );
  return null;
};

AllResults.propTypes = {
  handleName: PropTypes.string.isRequired
};

export default AllResults;
