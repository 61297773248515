import React from 'react';
import { useParams, useHistory } from 'react-router';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Panel } from '@appkit4/react-components/panel';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { sanitizer } from '../../util';
import { dateTimeAgo } from './util';
import KalturaPlayer from './KalturaPlayer';

const VideoContainerPanel = React.memo(styled(Panel)`
  width: 100%;
  padding: 0;
  background: none;
  box-shadow: none;
  .ap-panel-content {
    height: 100%;
    color: #464646;
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      margin-bottom: 20px;
      > h3 {
        margin-bottom: 0;
        font-weight: 200;
      }
    }
  }
  .info {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px;
    justify-content: space-between;
    .Appkit4-icon {
      margin-right: 5px;
    }
    .date {
      font-size: 90%;
    }
  }
  .description {
    white-space: pre-line;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    word-break: break-word;
    margin-top: 1rem;
    line-height: 28px;
    > p:last-of-type {
      margin-bottom: 0;
    }
  }
  .ap-btn-lg {
    background-color: #464646 !important;

    &:hover {
      background-color: #7d7d7d !important;
    }
  }
`);

export const VideoPanel = ({ video }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { handleId, handleName } = useParams();
  const { name, id, ks, description, createdAt } = video;

  return (
    <VideoContainerPanel className="video-container" data-test-label={`video-${handleId}-container`}>
      <div className="header" data-test-label={`video-${handleId}-header`}>
        <Button
          className="a-btn-lg"
          onClick={() => history.push(`/videos/${handleId}/${handleName}`)}
          data-test-label="back-btn"
        >
          {t('button.back')}
        </Button>
      </div>
      <KalturaPlayer videoId={id} ks={ks} />
      <div className="info" data-test-label={`video-display-${handleId}-panel-info`}>
        <div className="a-text-heading">{name}</div>
        <div>
          <span className="Appkit4-icon icon-time-outline" />
          <span className="date">{dateTimeAgo(createdAt)}</span>
        </div>
      </div>
      <div className="description" dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
    </VideoContainerPanel>
  );
};

VideoPanel.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    createdAt: PropTypes.number.isRequired,
    ks: PropTypes.string.isRequired
  }).isRequired
};

export default VideoPanel;
