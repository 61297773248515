import Ajv from 'ajv';
import schema from './formSchema.json';

const ajv = new Ajv({ allErrors: true });
const validate = ajv.compile(schema);

export const validateForm = form => {
  var valid = validate(form);

  return {
    valid,
    errors: ajv.errorsText(validate.errors)
  };
};
