import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DashboardElement from './DashboardElement';

const ColumnsWrapper = styled.div(props => ({
  width: props.width,
  maxWidth: '100%',
  height: '100%',
  maxHeight: props.height,
  display: 'grid',
  gridAutoRows: props.childrenHeight,
  gridAutoColumns: 'minmax(0, 1fr)',
  gridGap: '20px'
}));

const Columns = ({ pageId, parent, columns }) => {
  return (columns || []).map((column, index) => {
    const childrenHeight = column.children
      ? column.children
          .flatMap(c => `minmax(${c?.properties?.height ? `auto,${c?.properties?.height}` : '0,1fr'})`)
          .join(' ')
      : '100%';

    return (
      <ColumnsWrapper
        // eslint-disable-next-line react/no-array-index-key
        key={`${pageId}-column-${index}`}
        className="dashboard-columns"
        width={!parent && column?.properties?.width ? column.properties.width : '100%'}
        height={!parent && column?.properties?.height ? column.properties.height : '100%'}
        childrenHeight={childrenHeight}
      >
        <DashboardElement pageId={pageId} parent {...column} />
      </ColumnsWrapper>
    );
  });
};

Columns.propTypes = {
  pageId: PropTypes.string.isRequired,
  parent: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object)
};

export default Columns;
