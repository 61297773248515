import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '@appkit4/react-components/field';
import { useTranslation } from 'react-i18next';
// TODO: replace name with handleID and remove convertNameToTestLabel
import { convertNameToTestLabel } from '../../util/test';
import { formatDateTime } from '../utils';
import Section from './Section';

const HorizontalInput = styled(Input)`
  display: flex;
  margin: 5px 0;
  label {
    width: 200px;
    margin: 0;
    align-self: center;
  }
  input {
    width: 400px;
  }
`;

const Property = ({ name, value }) => {
  return (
    <HorizontalInput data-test-label={convertNameToTestLabel(name)} label={name} value={value} disabled readonly />
  );
};

Property.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string
};

const FileProperties = ({ fileProperties }) => {
  const { t } = useTranslation();
  const { uploadDate, handleName, uploadUser, taxType, dataType, fileName, jobcode, dataClassification } =
    fileProperties;
  return (
    <Section name={t('documents.details.fileProperties')}>
      <Property name={t('documents.details.uploadDate')} value={formatDateTime(uploadDate)} />
      <Property name={t('documents.details.generatedVia')} value={handleName} />
      <Property name={t('documents.details.generatedBy')} value={uploadUser} />
      <Property name={t('documents.details.taxType')} value={taxType} />
      <Property name={t('documents.details.dataType')} value={dataType} />
      <Property name={t('documents.details.filename')} value={fileName} />
      <Property name={t('documents.details.jobcode')} value={jobcode} />
      <Property name={t('documents.details.dataClassification')} value={dataClassification} />
    </Section>
  );
};

FileProperties.propTypes = {
  fileProperties: PropTypes.shape({
    uploadDate: PropTypes.string,
    handleName: PropTypes.string,
    uploadUser: PropTypes.string,
    taxType: PropTypes.string,
    dataType: PropTypes.string,
    fileName: PropTypes.string,
    jobcode: PropTypes.string,
    dataClassification: PropTypes.string
  }).isRequired
};

export default FileProperties;
