import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Slider } from '@appkit4/react-components/slider';
import { InputNumber } from '@appkit4/react-components/input-number';

const Group = styled.div`
  .col-6 {
    padding: 0 !important;
  }
  .a-slider-wrapper {
    margin: 20px 40px;
  }
  .rc-slider-with-marks {
    margin-bottom: 50px;
  }
`;

const SliderField = ({ field, value, onChange }) => {
  const { id, min, max, step, marks, defaultValue, showResult, label, numberFormat } = field;

  let numberValue = value;
  if (numberFormat) {
    const formatProps = { ...numberFormat };
    delete formatProps.locale;
    numberValue = new Intl.NumberFormat(numberFormat?.locale ?? 'nl-NL', formatProps).format(+value);
  }

  const LabelComponent = () => (
    <div className="mr-auto a-label-wrapper">{label && <label className="a-slider-label">{label}</label>}</div>
  );

  const ResultLabel = () => {
    if (showResult === 'label') {
      return (
        <div className="a-label-wrapper">
          <span className="a-slider-val-label">{numberValue}</span>
        </div>
      );
    } else if (showResult === 'input') {
      return (
        <InputNumber
          className="a-label-wrapper"
          style={{ width: '100px' }}
          kind="arrow"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={value => onChange(id, value)}
        />
      );
    }
    return <div className="a-label-wrapper" />;
  };

  const showHeader = showResult === 'label' || showResult === 'input' || label;
  const Header = () => (
    <div className="a-slider-header">
      <LabelComponent />
      <ResultLabel />
    </div>
  );

  return (
    <Group>
      <div className="a-slider-wrapper">
        {showHeader && <Header />}
        <Slider
          sliderId="singleSlider"
          min={min}
          max={max}
          step={step}
          marks={marks}
          hasInterval
          showIntervalLabel={false}
          value={value}
          defaultValue={defaultValue}
          showRange
          onValueChange={value => onChange(id, value)}
        />
      </div>
    </Group>
  );
};

SliderField.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    defaultValue: PropTypes.number,
    showResult: PropTypes.oneOf(['input', 'label']),
    marks: PropTypes.object,
    label: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
};

export default SliderField;
