import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { headHandleImage } from '../../util/requests';
import { STATUS } from '../../util/status';

const ImageWrapper = React.memo(styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.6);
`);

const ImageApp = React.memo(styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
`);

const ImageLogo = React.memo(styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ap-pwc-logo {
    width: 50%;
    height: 50%;
  }
`);

const AppImage = ({ id, name, size }) => {
  const [image, setImage] = useState(STATUS.LOADING);

  useEffect(() => {
    headHandleImage(id)
      .then(res => setImage(res?.status === 200 ? STATUS.OK : STATUS.ERROR))
      .catch(e => setImage(STATUS.ERROR));
  }, [id]);

  return (
    <ImageWrapper>
      {image === STATUS.OK && (
        <LazyLoadComponent>
          <ImageApp src={`/api/handle/${id}/image`} alt={name} data-test-label={`img-${id}`} />
        </LazyLoadComponent>
      )}
      {image === STATUS.ERROR && (
        <ImageLogo>
          <div className={`ap-pwc-logo ${size}`} data-test-label="icon-pwc" />
        </ImageLogo>
      )}
    </ImageWrapper>
  );
};

AppImage.defaultProps = {
  size: 'a-lg'
};

AppImage.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.string
};

export default AppImage;
