import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { STATUS_ASSEMBLING_SUCCESS, STATUS_TRANSFORMED } from '../../config';
import { QSFContext } from '../../context';
import Result from './result/Result';
import Dashboard from './dashboard/Dashboard';

const DownloadStatus = ({ handleId, handleName, hasDashboard, isDemo }) => {
  const { availableDownloads, downloads, requestId } = useContext(QSFContext);
  const handleDownload = isDemo
    ? downloads[handleId] && downloads[handleId].isDemo
      ? downloads[handleId]
      : {}
    : downloads[handleId] && !downloads[handleId].isDemo
    ? downloads[handleId]
    : {};
  const { status, reference } = handleDownload;

  // When this is rendered we can clear the dashboard update reference if it exists,
  // this way when the user goes to the new dashboard, he will not see the transform update data.
  localStorage.removeItem(`${handleId}-dashboard-update-reference`);

  // Don't show results when the download of the given handle is not the current requestId.
  if (handleDownload?.requestId !== requestId && handleDownload?.requestId !== undefined) return null;

  if (status === STATUS_ASSEMBLING_SUCCESS || status === STATUS_TRANSFORMED) {
    const availableDownload = availableDownloads[handleId]?.find(download => download.reference === reference) ?? {};

    return (
      <>
        <Result handleId={handleId} handle={handleName} reference={reference} />
        {hasDashboard && (
          <Dashboard
            handleName={handleName}
            handleId={handleId}
            reference={reference}
            disabled={availableDownload.dashboardData !== true}
            isDemo={isDemo}
          />
        )}
      </>
    );
  }

  return null;
};

DownloadStatus.propTypes = {
  handleId: PropTypes.string.isRequired,
  handleName: PropTypes.string.isRequired
};

export default DownloadStatus;
