import { v1 as uuid } from 'uuid';
import { STATUS_ASSEMBLING_SUCCESS } from '../config';
import { logWhenEnabled } from '../util/log';
import * as emit from './emit';
import { socket } from './socket';

// Send message to the parent window
// Can be used, for example, by handles displayed through an iframe in AW
const sendMessageToParent = message => {
  /**
   *  We allow all origins in our post to the parent window,
   * this will be secure as our CSP
   * only allows the correct origins for iframes,
   * and the message being sent through poastMessage() holds
   * no sensitive data and should never do.
   */
  window.parent.postMessage(message, '*');
};

export const initSocketListeners = ({ setConnectionId, ...setters }) => {
  socket.on('update', message => {
    logWhenEnabled(message.service, message.response);
    setters[message.service](message);

    // For now we only emit the response message if a
    // handle completed a succesfull run or update.
    if (message.response.status === STATUS_ASSEMBLING_SUCCESS) {
      sendMessageToParent(message);
    }
  });

  const connect = () => {
    const connectionId = localStorage.getItem('connectionId') || uuid();

    if (!localStorage.getItem('connectionId')) {
      localStorage.setItem('connectionId', connectionId);
    }

    setConnectionId(connectionId);
    emit.joinRoom(connectionId);

    setConnectionId(connectionId);
    emit.joinRoom(connectionId);
  };

  if (socket.connected) connect();
  socket.on('connect', connect);
};

export const disconnect = () => {
  socket.disconnect();
  localStorage.removeItem('connectionId');
};
