const sortmethod = (data, topChartProps) => {
  if (topChartProps?.dataKey) {
    const { dataKey, sort = true, sortOrder = 'descending' } = topChartProps;
    const dataToSort = [...data];
    if (sort) {
      if (sortOrder === 'descending') {
        dataToSort.sort((a, b) => b[dataKey] - a[dataKey]);
        return dataToSort;
      }
      // sort ascending
      dataToSort.sort((a, b) => a[dataKey] - b[dataKey]);
    }
    return dataToSort;
  }
  return data;
};

const getTopBars = (data, topChartProps) => {
  const sortedData = sortmethod(data, topChartProps);
  if (topChartProps?.maxDataPoints) {
    return sortedData.slice(0, topChartProps?.maxDataPoints);
  }
  return data;
};

export const useTopChartData = (data, topChartProps) => {
  if (data === null || !Array.isArray(data)) return {};
  const topChartData = getTopBars(data, topChartProps);
  return topChartProps == null ? data : topChartData;
};
