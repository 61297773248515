import { cloneDeep } from 'lodash';
import { FIELD } from '../enums';
import { dateToString } from './dateToString';

export const getDefaultValue = field => {
  const { defaultValue, type } = field;

  if (defaultValue != null) {
    return cloneDeep(defaultValue);
  }

  switch (type) {
    case FIELD.BOOLEAN:
    case FIELD.SWITCH:
      return false;
    case FIELD.NUMBER:
    case FIELD.MIN_PARTICIPANTS:
    case FIELD.MIN_PARTICIPANTS_PERCENT:
    case FIELD.SLIDER:
      return 0;
    case FIELD.INPUT:
    case FIELD.HIDDEN:
    case FIELD.TEXTAREA:
    case FIELD.SELECT:
    case FIELD.SELECT_MULTIPLE:
    case FIELD.SINGLE_CHOICE:
    case FIELD.SELECT_PREDEFINED:
      return '';
    case FIELD.AND_OR:
      return 'or';
    case FIELD.MULTIPLE_CHOICE:
    case FIELD.FILE_UPLOAD:
    case FIELD.TABULAR:
    case FIELD.PARTICIPANTS:
    case FIELD.GROUP:
    case FIELD.MULTIPLE_CHOICE_SELECTOR:
    case FIELD.TAGS:
      return [];
    case FIELD.DATE:
      return dateToString(new Date());
    case FIELD.RUN_AFTER_DATETIME:
      return new Date();
    default:
      return null;
  }
};
