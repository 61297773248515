import DOMPurify from 'dompurify';

// Add a hook to make all links open a new window
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank if no target was provided
  if ('target' in node) {
    const target = node.getAttribute('target');
    node.setAttribute('target', target ?? '_blank');
  }
  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});

DOMPurify.setConfig({ ADD_ATTR: ['target'] });

export { parseJson } from './parseJson';
export const sanitizer = DOMPurify.sanitize;

// Remove all html tags from a string
export const stripHtml = htmlText => htmlText.replace(/<[^>]*>?/gm, '');
