import Ajv from 'ajv';
import { FIELD, FORMAT } from '../enums';

const getImportType = fieldType => {
  switch (fieldType) {
    case FIELD.BOOLEAN:
    case FIELD.SWITCH:
      return 'boolean';
    case FIELD.NUMBER:
    case FIELD.SLIDER:
    case FIELD.DATE:
    case FIELD.MIN_PARTICIPANTS:
    case FIELD.MIN_PARTICIPANTS_PERCENT:
      return 'number';
    case FIELD.INPUT:
    case FIELD.HIDDEN:
    case FIELD.TEXTAREA:
    case FIELD.SELECT:
    case FIELD.SINGLE_CHOICE:
    case FIELD.SELECT_PREDEFINED:
    case FIELD.AND_OR:
      return 'string';
    case FIELD.MULTIPLE_CHOICE:
    case FIELD.FILE_UPLOAD:
    case FIELD.TABULAR:
    case FIELD.PARTICIPANTS:
    case FIELD.GROUP:
    case FIELD.TAGS:
    case FIELD.SELECT_MULTIPLE:
      return 'array';
    case FIELD.RUN_AFTER_DATETIME:
      return 'object';
    default:
      return null;
  }
};

const validator = (fields, selectorOptions, format = FORMAT.EPOCH) => {
  // Create JSON schema based on form fields and validate against it.
  const buildSchema = fields => {
    const properties = (fields || []).reduce((props, field) => {
      const fieldProps = getFieldProps(field);
      return fieldProps == null ? { ...props } : { ...props, [field.id]: fieldProps };
    }, {});

    return {
      type: 'object',
      properties,
      additionalProperties: false
    };
  };

  const getFieldProps = field => {
    const { type } = field;
    const fieldType = getImportType(type);
    if (fieldType == null) return null;

    const props = { type: fieldType };

    switch (type) {
      case FIELD.DATE:
        return format === FORMAT.DATE
          ? {
              type: 'string',
              pattern:
                '^(([0-9])|([0-2][0-9])|([3][0-1]))\\-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\\-\\d{4}$'
            }
          : { ...props };
      case FIELD.SINGLE_CHOICE:
      case FIELD.SELECT:
        return { ...props, enum: [...field.options.map(option => option.value), ''] };
      case FIELD.SELECT_MULTIPLE:
        return { ...props, items: { enum: field.options.map(option => option.value) } };
      case FIELD.MULTIPLE_CHOICE:
        return { ...props, items: { enum: field.options.map(option => option.value) } };

      case FIELD.TABULAR: {
        const columnProperties = field.columns.reduce((props, column) => {
          const columnProps = getFieldProps(column);
          return columnProps == null ? { ...props } : { ...props, [column.id]: columnProps };
        }, {});
        return { ...props, items: { type: 'object', properties: columnProperties } };
      }
      case FIELD.GROUP: {
        const fieldProperties = field.fields.reduce((props, f) => {
          const fieldProps = getFieldProps(f);
          return fieldProps == null ? { ...props } : { ...props, [f.id]: fieldProps };
        }, {});
        return {
          ...props,
          items: {
            type: 'object',
            properties: fieldProperties,
            additionalProperties: false
          }
        };
      }
      case FIELD.SELECT_PREDEFINED: {
        const data = selectorOptions?.[field.data]?.map(o => o.value) || [];
        return { ...props, enum: [...data, ''] };
      }
      default:
        break;
    }

    return props;
  };

  const schema = buildSchema(fields, selectorOptions);
  const ajv = new Ajv({ allErrors: true });
  const validate = ajv.compile(schema);

  return { validateData: data => validate(data) };
};

export default validator;
