import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { CalendarPicker } from '@appkit4/react-components/datepicker';

const RunAfterDateTime = ({ field, value, onChange }) => {
  const { id } = field;

  return (
    <div className="timepicker-container">
      <CalendarPicker
        locale={i18n.language}
        value={value}
        onChange={value => onChange(id, value)}
        minDate={new Date(Date.now())}
        mode="showTime"
      />
    </div>
  );
};

RunAfterDateTime.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date)
};

export default RunAfterDateTime;
