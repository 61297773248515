import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { QSFContext } from '../context';

const Container = React.memo(styled.div`
  grid-column: 1;
  grid-row: 3;
  display: block;
  margin: 20px auto auto auto;
  width: 100%;
  padding-top: 5px;
`);

const StyledParagraph = React.memo(styled.p`
  margin-top: -15px;
  display: flex;
  justify-content: center;
`);

const StyledPointSeparator = React.memo(styled.span`
  margin: 0px 10px;
  font-size: 16px;
  line-height: 16px;
`);

const Terms = () => {
  const { environment } = useContext(QSFContext);
  const { t } = useTranslation();

  // Remove footer disclaimer and terms in US environment
  if (environment === 'us') return null;

  return (
    <Container data-test-label="footer-container" className="ap-footer">
      <p style={{ textAlign: 'center' }}>
        <Trans i18nKey="footer.text" values={{ year: new Date().getFullYear() }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a target="_blank" rel="noopener noreferrer" href="https://www.pwc.com/structure" />
        </Trans>
      </p>
      <StyledParagraph>
        <a href="/legal/terms-of-use?agreed" target="_blank">
          {t('footer.terms')}
        </a>
        <StyledPointSeparator>•</StyledPointSeparator>
        <a href="/legal/privacy-statement" target="_blank">
          {t('footer.privacy')}
        </a>
      </StyledParagraph>
    </Container>
  );
};

export default Terms;
