import React from 'react';
import { Annotation, Geographies as ReactSimpleGeographies, Graticule, Line, Marker, Sphere } from 'react-simple-maps';
import { geoCentroid } from 'd3-geo';
// Source: https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json
import defaultSource from './util/countries.json';
import { worldMapContentPropTypes } from './propTypes';
import Geographies, { isGeographyFilterValid } from './Geographies';

const AvailableComponents = {
  annotation: Annotation,
  graticule: Graticule,
  line: Line,
  marker: Marker,
  sphere: Sphere
};

const WorldMapContent = ({ childComponents, componentData, filterGeo }) => {
  if (!childComponents?.length) return null;

  return (
    <>
      {childComponents.map((child, index) => {
        if (child.type === 'geographies') {
          return (
            <Geographies
              // eslint-disable-next-line react/no-array-index-key
              key={`geographies-${index}`}
              child={child}
              componentData={componentData}
              filterGeo={filterGeo}
            />
          );
        }

        if (child.type === 'countryLabels') {
          return (
            <ReactSimpleGeographies key="countryLabels" geography={child.properties?.source ?? defaultSource}>
              {({ geographies }) =>
                geographies.map(geo => {
                  // Return nothing if the geoFilter is set and doesn't match this geography.
                  const labelFilter = child.properties?.filterGeo ?? filterGeo;
                  if (labelFilter && !isGeographyFilterValid(labelFilter, geo)) return null;
                  return (
                    <Marker key={`countryLabel-${geo.rsmKey}`} coordinates={geoCentroid(geo)}>
                      <text textAnchor="middle" style={child.properties?.style ?? { fontSize: '3px' }}>
                        {geo.properties?.[child.properties?.label ?? 'NAME']}
                      </text>
                    </Marker>
                  );
                })
              }
            </ReactSimpleGeographies>
          );
        }

        const Component = AvailableComponents[child.type];
        if (Component == null) return null;

        // The component can also have children which can either be a text and/or circle element.
        if (child.children) {
          const parentKey = `${child.properties?.name}-${child.properties?.coordinates?.join('-')}`;
          return (
            <Component key={parentKey} {...child.properties}>
              {child.children.map(innerChild => {
                if (innerChild.type === 'text') {
                  return (
                    <text key={`${parentKey}-text-${innerChild.properties?.name}`} {...innerChild.properties}>
                      {innerChild.properties.name}
                    </text>
                  );
                }
                if (innerChild.type === 'circle') {
                  return <circle key={`${parentKey}-circle`} {...innerChild.properties} />;
                }
                return null;
              })}
            </Component>
          );
        }

        // eslint-disable-next-line react/no-array-index-key
        return <Component key={index} {...child.properties} />;
      })}
    </>
  );
};

WorldMapContent.defaultProps = {
  childComponents: [],
  filterGeo: {}
};

WorldMapContent.propTypes = worldMapContentPropTypes;

export default WorldMapContent;
