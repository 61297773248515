import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { APP_NAME, SUPPORT_EMAIL } from '../../config';

const ErrorMessage = () => {
  const supportEmail = `mailto:${SUPPORT_EMAIL}`;
  const { t } = useTranslation();

  return (
    <div>
      {t('message.error1')}
      <br />
      <br />
      <Trans i18nKey="message.contact3" values={{ appName: APP_NAME }}>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a target="_blank" rel="noopener noreferrer" href={supportEmail} />
      </Trans>
    </div>
  );
};

export default ErrorMessage;
