import { default as button } from './button.json';
import { default as commentLetter } from './commentLetter.json';
import { default as dashboard } from './dashboard.json';
import { default as demo } from './demo.json';
import { default as documents } from './documents.json';
import { default as filters } from './filters.json';
import { default as form } from './form.json';
import { default as footer } from './footer.json';
import { default as handle } from './handle.json';
import { default as menu } from './menu.json';
import { default as message } from './message.json';
import { default as tooltip } from './tooltip.json';
import { default as type } from './type.json';
import { default as uploader } from './uploader.json';
import { default as workflow } from './workflow.json';
import { default as news } from './news.json';

export const en = {
  translation: {
    button,
    commentLetter,
    dashboard,
    demo,
    documents,
    filters,
    form,
    footer,
    handle,
    menu,
    message,
    tooltip,
    type,
    uploader,
    workflow,
    news
  }
};
