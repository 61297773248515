import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';
import { Wrapper, TilesWrapper, ExtendedGridWrapper } from '../styles/GeneralElements';
import { LoadingIndicator, NoHandlesAvailableIndicator, LoadingError } from '../elements/loading';
import { STATUS } from '../util/status';
import { DEFAULT_LANGUAGE_ISO_CODE, getDiscoverApps } from '../util/requests';
import { LANGUAGE_KEY } from '../menu/LanguageMenuItem';
import HandleSearch from './handleSearch/HandleSearch';
import DiscoverAppTile from './DiscoverAppTile';
import FilterTagsSideDrawer from './FilterTagsSideDrawer';

const DiscoverList = () => {
  const [availableHandles, setAvailableHandles] = useState([]);
  const [filteredHandles, setFilteredHandles] = useState([]);
  const { setFilterDrawerVisible } = useContext(QSFContext);
  const [status, setStatus] = useState(STATUS.LOADING);
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      try {
        const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
        const response = await getDiscoverApps(lang);
        const sortedHandles = Object.values(response?.data ?? []).sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        // TODO: Move this filter to backend as handleType
        const removedSideHandles = sortedHandles.filter(handle => handle.id !== 'qsf-handle-gen-doc-qa');

        setAvailableHandles(removedSideHandles);
        setFilteredHandles(removedSideHandles);
        setStatus(STATUS.OK);
      } catch (e) {
        setStatus(STATUS.ERROR);
      }
    };

    getData();
  }, []);

  // Hide the side filter drawer when the page changes and the component unmounts
  useEffect(() => {
    return () => setFilterDrawerVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (availableHandles.length === 0) {
    return <NoHandlesAvailableIndicator handleType="solution" />;
  }

  return (
    <Wrapper>
      <HandleSearch handles={availableHandles} onResult={result => setFilteredHandles(result ?? availableHandles)} />
      <TilesWrapper>
        <ExtendedGridWrapper data-test-label="discover-apps-list-wrapper">
          {filteredHandles.length === 0 ? (
            <div>{t('handle.noResults')}</div>
          ) : (
            filteredHandles.map(handle => <DiscoverAppTile handle={handle} key={handle.id} />)
          )}
        </ExtendedGridWrapper>
      </TilesWrapper>
      <FilterTagsSideDrawer
        availableHandles={availableHandles}
        setFilteredHandles={setFilteredHandles}
        type="discoverApps"
      />
    </Wrapper>
  );
};

export default DiscoverList;
