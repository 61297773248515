import React, { useState, useEffect } from 'react';
import { Checkbox } from '@appkit4/react-components/checkbox';
import PropTypes from 'prop-types';

const Boolean = ({ field, onChange, value }) => {
  const { id, description } = field;
  const [checked, setChecked] = useState(!!value);

  useEffect(() => {
    setChecked(!!value);
  }, [value]);

  const localOnChange = (id, newValue) => {
    setChecked(newValue);
    onChange(id, newValue);
  };

  return (
    <Checkbox checked={checked} onChange={val => localOnChange(id, val)}>
      {description}
    </Checkbox>
  );
};

Boolean.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
};

export default Boolean;
