import React from 'react';

const ReviewIcon = ({ height, width, fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} fill={fill}>
    <path
      d="M21.5385 6.43333L15.3552 0.213333C15.2219 0.0766667 15.0385 0 14.8485 0H2.96854C2.57187 0 2.24854 0.323333 2.24854 0.723333V23.2767C2.24854 23.6767 2.57187 24 2.96854 24H21.0319C21.4285 24 21.7519 23.6767 21.7519 23.2767V6.94667C21.7519 6.75333 21.6752 6.57 21.5419 6.43667L21.5385 6.43333ZM15.5652 2.47L19.2919 6.22H15.5652V2.47ZM3.6852 22.5533V1.44667H14.1252V7.67H20.3085V22.5533H3.6852ZM7.00854 15.7233H14.3485L12.3652 17.7067C12.2285 17.8433 12.1652 18.0167 12.1652 18.1933C12.1652 18.37 12.2319 18.5467 12.3652 18.68C12.6352 18.95 13.0719 18.95 13.3385 18.68L16.9852 15.0333L13.3385 11.3867C13.0685 11.1167 12.6319 11.1167 12.3652 11.3867C12.0985 11.6567 12.0952 12.0933 12.3652 12.36L14.3485 14.3433H7.01187V15.72L7.00854 15.7233Z"
      fill={fill}
    />
  </svg>
);

export default ReviewIcon;
