import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appkit4/react-components/button';
import { Modal } from '@appkit4/react-components/modal';
import { useTranslation } from 'react-i18next';

const ErrorModal = ({ visible, message, onClose }) => {
  const { t } = useTranslation();
  if (message === '') {
    return null;
  }
  return (
    <Modal
      className="baseline-modal-showcase"
      data-test-label="form-submit-error"
      visible={visible}
      onCancel={onClose}
      footer={
        <Button kind="transparent" onClick={onClose} data-test-label="close-btn">
          {t('button.ok')}
        </Button>
      }
    >
      {message}
    </Modal>
  );
};

ErrorModal.defaultProps = {
  visible: false,
  message: ''
};

ErrorModal.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default ErrorModal;
