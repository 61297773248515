import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal';
import { COMPONENT, FIELD } from '../enums';
import { getInitialFieldVisibility, evaluateCondition, getDefaultValue } from '../helpers';
import { FieldProps } from '../propTypes';
import FieldWrapper from './FieldWrapper';

const FormFields = ({
  fields,
  disableSubmitIf,
  onSubmitDisabled,
  onFieldChange,
  getValues,
  watch,
  errors,
  register,
  unregister,
  referenceStyle
}) => {
  const [visibleFields, setVisibleFields] = useState(getInitialFieldVisibility(fields));
  const getFieldType = useCallback(fieldId => fields.find(f => f.id === fieldId)?.type, [fields]);

  // Update field visibility on each render. getValues returns new values on each render but won't
  // trigger useEffect when included in the dependency array since it's a method, so eslint-disable deps.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const newVisibleFields = { ...visibleFields };
    fields.forEach(field => {
      if (field.visibleIf == null) return;

      const isVisible = evaluateCondition(field.visibleIf, getValues, newVisibleFields, getFieldType);

      if (isVisible !== visibleFields[field.id]) {
        newVisibleFields[field.id] = isVisible;
      }
    });

    // Only update when visibility changes occurred (prevent infinite update loop)
    if (!equal(visibleFields, newVisibleFields)) {
      setVisibleFields(newVisibleFields);
    }

    // check if submit button should be disabled and propagate to parent component
    const submitDisabled = evaluateCondition(disableSubmitIf, getValues, visibleFields, getFieldType);
    onSubmitDisabled(submitDisabled);
  });

  const getField = field => {
    const FormField = COMPONENT[field.type];

    const shouldUseDefaultValue = !watch(field.id) && field.type !== 'textArea';
    const fieldValue = shouldUseDefaultValue ? getDefaultValue(field) : watch(field.id);

    if (FormField == null) return null;

    if (field.type === FIELD.MIN_PARTICIPANTS) {
      const linkedTo = field.linkedTo || FIELD.PARTICIPANTS;
      return <FormField field={field} onChange={onFieldChange} value={fieldValue} max={watch(linkedTo).length} />;
    }

    return <FormField field={field} onChange={onFieldChange} value={fieldValue} referenceStyle={referenceStyle} />;
  };

  return fields.map(field => {
    if (visibleFields[field.id] === false) return null;
    return (
      <FieldWrapper key={field.id} field={field} {...{ errors, register, unregister }}>
        {getField(field)}
      </FieldWrapper>
    );
  });
};

FormFields.defaultProps = {
  disabled: false
};

FormFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape(FieldProps)).isRequired,
  disableSubmitIf: PropTypes.objectOf(PropTypes.any),
  onSubmitDisabled: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.object),
  register: PropTypes.func.isRequired,
  unregister: PropTypes.func.isRequired,
  referenceStyle: PropTypes.string
};

export default React.memo(FormFields);
