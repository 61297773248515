import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button } from '@appkit4/react-components/button';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
// TODO: check build error "Dependency cycle via..."
// so we can remove next eslint line.
// eslint-disable-next-line import/no-cycle
import { COMPONENT } from '../../enums';
import { getDefaultValue } from '../../helpers';

// Use ReactTable instead of AppKit table due to re-rendering issues when the AppKit table cells contain input fields
const Styled = styled(ReactTable)`
  border: 1px solid #eeeeee;

  .-pagination {
    align-items: center;
    justify-content: center;
    border-top: 1px solid #eeeeee;
  }

  .rt-td {
    padding: 0.625rem !important;
    overflow: auto !important;
  }

  .rt-table {
    overflow-x: scroll !important;
  }

  .a-calendar {
    display: flex;
  }

  .a-date-picker-header {
    width: 100%;
    border: 0.0625rem solid #dedede;
  }

  .react-date-picker {
    position: unset !important;
  }

  .a-date-picker-header-date-input {
    border: none !important;
  }

  .a-dropdown.a-dropdown-default.open {
    position: unset !important;
  }

  .a-calender-picker-button {
    position: relative !important;
    top: 0px !important;
  }

  .a-react-date-picker-calendar {
    top: auto !important;
    bottom: unset !important;
    left: auto !important;
    right: unset !important;
  }

  .a-dropdown .a-select-list-out {
    right: auto;
    left: auto;
    top: auto !important;
    width: auto;
  }

  .a-select-list-out {
    top: 0px !important;
    margin-top: 0px !important;
  }

  .rt-noData {
    top: 40px;
    background: transparent;
  }
`;

const getColumns = (t, columns, value, onChange, onDeleteRow) => {
  return columns.map(column => {
    if (column === 'deleteRow') {
      return {
        Header: t('form.field.delete'),
        accessor: 'delete',
        getHeaderProps: () => ({ 'data-test-label': 'delete' }),
        // eslint-disable-next-line react/display-name
        Cell: row => <Delete row={row} onDelete={onDeleteRow} />
      };
    }

    return {
      Header: column.label,
      accessor: column.id,
      getHeaderProps: (state, rowInfo, column) => ({ 'data-test-label': column.id }),
      // eslint-disable-next-line react/display-name
      Cell: row => <Cell row={row} column={column} value={value[row.index][row.column.id]} onChange={onChange} />
    };
  });
};

const Delete = ({ row, onDelete }) => {
  const onClick = useCallback(() => onDelete(row.index), [onDelete, row.index]);
  return (
    <Button
      style={{ backgroundColor: 'transparent' }}
      className="deleteRow"
      onClick={onClick}
      data-test-label="delete-row-btn"
    >
      <span style={{ color: '#d04a02' }} className="Appkit4-icon icon-delete-fill custom-delete" />
    </Button>
  );
};

const Cell = ({ row, column, value, onChange }) => {
  const FormField = COMPONENT[column.type];
  if (FormField == null) return null;

  return <FormField field={column} onChange={(name, value) => onChange(row.index, name, value)} value={value} />;
};

const getColumnIds = columns => {
  const ids = cloneDeep(columns);
  ids.push('deleteRow');
  return ids;
};

const defaultColumns = t => {
  return [
    {
      id: 'firstName',
      label: t('form.field.firstName'),
      type: 'input'
    },
    {
      id: 'lastName',
      label: t('form.field.lastName'),
      type: 'input'
    },
    {
      id: 'email',
      label: t('form.field.email'),
      type: 'input'
    }
  ];
};

const Participants = ({ field, value, onChange }) => {
  const { t } = useTranslation();
  const { id, showPagination, pageSizeOptions } = field;
  const forms = useMemo(
    () =>
      field.workflowForms
        ? field.workflowForms.map(form => {
            return { label: form.label, value: form.value };
          })
        : undefined,
    [field.workflowForms]
  );

  const customCol = useMemo(
    () =>
      forms
        ? [
            ...defaultColumns(t),
            {
              id: 'workflowForms',
              label: t('form.field.workflowForms'),
              type: 'multipleChoiceSelector',
              options: forms
            }
          ]
        : defaultColumns(t),
    [forms, t]
  );
  const columns = useMemo(
    () => (field.columns ? [...customCol, ...field.columns] : customCol),
    [field.columns, customCol]
  );

  const onFieldChange = useCallback(
    (rowIndex, columnId, cellValue) => {
      const newValue = cloneDeep(value);
      newValue[rowIndex][columnId] = cellValue;
      onChange(id, newValue);
    },
    [id, onChange, value]
  );

  const onDeleteRow = useCallback(
    rowIndex => {
      const newValue = cloneDeep(value);
      newValue.splice(rowIndex, 1);
      onChange(id, newValue);
    },
    [id, onChange, value]
  );

  const addEmptyRow = useCallback(() => {
    const newValue = cloneDeep(value);
    const emptyRow = Object.fromEntries(columns.map(k => [k.id, getDefaultValue(k)]));
    newValue.push({ ...emptyRow });
    onChange(id, newValue);
  }, [id, onChange, value, columns]);

  const columnsIds = useMemo(() => getColumnIds(columns), [columns]);
  const tableColumns = useMemo(
    () => getColumns(t, columnsIds, value, onFieldChange, onDeleteRow),
    [t, columnsIds, onDeleteRow, onFieldChange, value]
  );

  return (
    <>
      <Styled
        data={value}
        columns={tableColumns}
        minRows={1}
        showPagination={showPagination}
        pageSizeOptions={pageSizeOptions}
        previousText={t('form.field.table.previous')}
        nextText={t('form.field.table.next')}
        loadingText={t('form.field.table.loading')}
        noDataText={t('form.field.table.noData')}
        pageText={t('form.field.table.page')}
        ofText={t('form.field.table.of')}
        rowsText={t('form.field.table.rows')}
        sortable={false}
        resizable={false}
      />
      <Button onClick={addEmptyRow} className="addRow" data-test-label="add-row-btn">
        {t('button.addRow')}
      </Button>
    </>
  );
};

Participants.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object),
    workflowForms: PropTypes.arrayOf(PropTypes.object),
    defaultValue: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Participants;
