import PropTypes from 'prop-types';

export const worldMapFilterGeo = PropTypes.shape({
  ABBREV: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  CONTINENT: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  FORMAL_EN: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ISO_A2: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ISO_A3: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  NAME: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  NAME_LONG: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  REGION_UN: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  SUBREGION: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
});

export const worldMapPropTypes = Object.freeze({
  properties: PropTypes.shape({
    data: PropTypes.string.isRequired,
    projection: PropTypes.string,
    projectionConfig: PropTypes.shape({
      center: PropTypes.arrayOf(PropTypes.number),
      rotate: PropTypes.arrayOf(PropTypes.number),
      parallels: PropTypes.arrayOf(PropTypes.number),
      scale: PropTypes.number
    }),
    markerConfig: PropTypes.shape({
      circle: PropTypes.objectOf(PropTypes.any),
      text: PropTypes.objectOf(PropTypes.any)
    }),
    filterGeo: worldMapFilterGeo
  }).isRequired,
  childComponents: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
});

export const worldMapContentPropTypes = Object.freeze({
  childComponents: PropTypes.arrayOf(PropTypes.object),
  componentData: PropTypes.objectOf(PropTypes.any).isRequired,
  filterGeo: worldMapFilterGeo
});

export const geographiesPropTypes = Object.freeze({
  child: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
  filterGeo: worldMapFilterGeo
});
