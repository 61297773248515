import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Panel } from '@appkit4/react-components/panel';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '../../elements/loading';
import { getDocument } from '../../util/requests';
import { Wrapper } from '../../styles/GeneralElements';
import { STATUS } from '../utils';
import FileProperties from './FileProperties';
import UserManagement from './UserManagement';

const DocumentDetails = () => {
  const { uri } = queryString.parse(useLocation().search);
  const [document, setDocument] = useState({});
  const [status, setStatus] = useState(STATUS.LOADING);
  const { t } = useTranslation();

  useEffect(() => {
    getDocument(uri)
      .then(res => {
        setDocument(res.data);
        setStatus(STATUS.OK);
      })
      .catch(e => setStatus(STATUS.ERROR));
  }, [uri, status]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  const { permission } = document;
  const fileProperties = {
    uploadDate: document.creationDateTime,
    handleName: document.handleName,
    // TODO: change when available
    uploadUser: permission?.owner[0].id,
    taxType: '',
    dataType: document.type,
    fileName: document.fileName,
    jobcode: '',
    dataClassification: document.documentClassification
  };

  return (
    <Wrapper>
      <Panel style={{ padding: 30, minHeight: 600 }} data-test-label="document-details">
        {status === STATUS.ERROR && (
          <div data-test-label="document-not-found">
            {t('message.retrieveFailed', { item: t('type.document', { count: 1 }) })}
          </div>
        )}
        {status === STATUS.OK && (
          <>
            <FileProperties fileProperties={fileProperties} />
            <UserManagement permission={permission} />
          </>
        )}
      </Panel>
    </Wrapper>
  );
};

export default DocumentDetails;
