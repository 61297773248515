import { isEqual, some } from 'lodash';
import { FIELD } from '../enums';

const isEmptyField = (type, value) => {
  switch (type) {
    case FIELD.INPUT:
    case FIELD.HIDDEN:
    case FIELD.TEXTAREA:
    case FIELD.SELECT:
    case FIELD.SELECT_MULTIPLE:
      return value?.length === 0;
    case FIELD.SINGLE_CHOICE:
    case FIELD.SELECT_PREDEFINED:
      return value === '';
    case FIELD.MULTIPLE_CHOICE:
    case FIELD.FILE_UPLOAD:
    case FIELD.TABULAR:
      return value?.length === 0;
    // All other fields cannot be empty as they always have a default value
    default:
      return false;
  }
};

const containsValue = (type, current, value) => {
  switch (type) {
    case FIELD.INPUT:
    case FIELD.HIDDEN:
    case FIELD.TEXTAREA:
    case FIELD.SELECT:
    case FIELD.SELECT_MULTIPLE:
    case FIELD.SINGLE_CHOICE:
    case FIELD.SELECT_PREDEFINED:
    case FIELD.DATE:
      return (current || '').includes(value);
    case FIELD.MULTIPLE_CHOICE:
      return (value || []).every(elem => (current || []).includes(elem));
    case FIELD.FILE_UPLOAD:
    case FIELD.TABULAR:
      return (value || []).every(elem => some(current, elem));
    // For other fields it makes no sense to check for 'contains'
    default:
      return false;
  }
};

const equals = (type, current, value) => {
  switch (type) {
    // For multiple choice we're going to ignore the order of items
    case FIELD.MULTIPLE_CHOICE: {
      const currentOrdered = (current || []).sort();
      const valueOrdered = (value || []).sort();
      return isEqual(currentOrdered, valueOrdered);
    }
    case FIELD.SELECT_MULTIPLE: {
      const currentOrdered = (current || []).sort();
      const valueOrdered = (value || []).sort();
      return isEqual(currentOrdered, valueOrdered);
    }
    case FIELD.FILE_UPLOAD:
    case FIELD.TABULAR:
      return isEqual(current, value);
    default:
      return current === value;
  }
};

export const evaluateCondition = (condition, getValues, visibleStatuses, getFieldType) => {
  if (condition == null) return false;

  const { every, any, field, operator, value } = condition;

  // Check for nested 'every' statements
  if (every) {
    return every.every(condition => evaluateCondition(condition, getValues, visibleStatuses, getFieldType));
  }

  // Check for nested 'any' statements
  if (any) {
    return any.some(condition => evaluateCondition(condition, getValues, visibleStatuses, getFieldType));
  }

  // Evaluate field visibility
  switch (operator) {
    case '=':
      return equals(getFieldType(field), getValues(field), value) && visibleStatuses[field];
    case '!=':
      return !equals(getFieldType(field), getValues(field), value) && visibleStatuses[field];
    case '<':
      return getValues(field) < value && visibleStatuses[field];
    case '>':
      return getValues(field) > value && visibleStatuses[field];
    case '<=':
      return getValues(field) <= value && visibleStatuses[field];
    case '>=':
      return getValues(field) >= value && visibleStatuses[field];
    case 'empty':
      return isEmptyField(getFieldType(field), getValues(field)) && visibleStatuses[field];
    case 'notEmpty':
      return !isEmptyField(getFieldType(field), getValues(field)) && visibleStatuses[field];
    case 'contains':
      return containsValue(getFieldType(field), getValues(field), value) && visibleStatuses[field];
    case 'notContains':
      return !containsValue(getFieldType(field), getValues(field), value) && visibleStatuses[field];
    default:
      return false;
  }
};
