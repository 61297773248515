import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { sanitizer } from '../../util';
import { QSFContext } from '../../context';
import {
  DEFAULT_LANGUAGE_ISO_CODE,
  downloadHandleTemplate,
  getDiscoverAppInfo,
  requestHandleAccess
} from '../../util/requests';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../../elements/loading';
import { STATUS } from '../../util/status';
import {
  ExtendedTagsWrapper,
  DiscoverTag,
  CoverageTag,
  ChevronRight,
  ChevronRightDark
} from '../../styles/GeneralElements';
import { HANDLE_TYPE, isWorkflowHandle } from '../../util/handleType';
import AppImage from '../image/AppImage';
import { LANGUAGE_KEY } from '../../menu/LanguageMenuItem';
import {
  DiscoverAppLayout,
  HeaderWrapper,
  TitleWrapper,
  TitleApp,
  Actions,
  ButtonDemo,
  ButtonAccess,
  InfoWrapper,
  ImageLogoWrapper,
  MainInfo,
  TabsInfo,
  TextBlockInfo
} from '../../styles/DiscoverElements';
import RequestAccessModal from './RequestAccessModal';

const Paragraph = React.memo(styled.div`
  ul {
    list-style: disc inside;
    margin-left: 20px;
    margin-bottom: 0;

    li {
      list-style: inherit;
    }
  }
  ,
  ol {
    list-style: lower-roman inside;
    margin-left: 20px;
    margin-bottom: 0;

    li {
      list-style: inherit;
    }
  }
`);

const DiscoverApp = () => {
  const { handleId } = useParams();
  const { workflow } = queryString.parse(useLocation().search);
  const [info, setInfo] = useState({});
  const [status, setStatus] = useState(STATUS.LOADING);

  useEffect(() => {
    const getData = async () => {
      try {
        const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
        const idResponse = await getDiscoverAppInfo(handleId, workflow, lang);
        setInfo(idResponse?.data);
        setStatus(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
      }
    };

    getData();
  }, [handleId, workflow]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator />;
  }

  return <DiscoverAppContent info={info} />;
};

const DiscoverAppContent = ({ info }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const history = useHistory();
  const {
    id,
    authorized,
    handleType,
    name,
    functionality,
    singleLineDescription,
    usageDescription,
    contact,
    demo,
    tags,
    coverage,
    pricing,
    pointsOfAttention,
    templates,
    functional,
    dashboard,
    instruction
  } = info;
  const { connectionId } = useContext(QSFContext);
  const { t } = useTranslation();
  const descriptionComposed = `<h4>${singleLineDescription}</h4><p>${functionality}</p><p>${usageDescription}</p>`;

  const showAccessModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const requestAccess = () => {
    requestHandleAccess(name, id);
  };

  const goToDemo = () => {
    history.push(`/discover/${id}/${name}/demo`);
  };

  const { display_mode } = queryString.parse(useLocation().search);

  const goToApp = () => {
    const path = handleType === HANDLE_TYPE.VIDEO ? 'videos' : 'handles';
    const displayModeQueryString = display_mode ? `?display_mode=${display_mode}` : '';

    isWorkflowHandle(handleType)
      ? history.push(`/${path}/${id}?workflow=true`)
      : history.push(`/${path}/${id}${displayModeQueryString}`);
  };

  const tempDownloadLink = (data, entry) => {
    const tempLink = document.createElement('a');
    console.log('tempDownloadLink entry >>>', entry);
    tempLink.href = window.URL.createObjectURL(data);
    tempLink.setAttribute('download', `${entry.displayName || entry.name}.zip`);
    return tempLink;
  };

  const downloadTemplate = async (entry, connectionId, event) => {
    event.preventDefault();
    downloadHandleTemplate(entry.reference, connectionId)
      .then(res => {
        const rawBlobData = new Blob([res.data], { type: 'application/zip' });
        tempDownloadLink(rawBlobData, entry).click();
      })
      .catch(error => {
        console.error('Error downloading template:', error);
      });
  };

  const allowTemplate = (templates && templates.length) > 0;

  return (
    <DiscoverAppLayout key="discover">
      <HeaderWrapper>
        <TitleWrapper>
          <ImageLogoWrapper>
            <AppImage id={id} name={name} />
          </ImageLogoWrapper>
          <TitleApp data-test-label={`title-${id}`}>{name}</TitleApp>
        </TitleWrapper>
        <Actions>
          {demo && (
            <ButtonDemo size="md" onClick={goToDemo} data-test-label="go-to-demo-btn">
              {t('button.demo')} <ChevronRightDark size={14} />
            </ButtonDemo>
          )}
          {(functional || dashboard || handleType === HANDLE_TYPE.VIDEO) &&
            (authorized ? (
              <ButtonAccess size="md" onClick={goToApp} data-test-label="open-app-btn">
                {t('button.openApp')} <ChevronRight size={14} />
              </ButtonAccess>
            ) : (
              <ButtonAccess size="md" onClick={showAccessModal} data-test-label="get-access-btn">
                {t('button.getAccess')} <ChevronRight size={14} />
              </ButtonAccess>
            ))}
        </Actions>
      </HeaderWrapper>
      <InfoWrapper>
        <MainInfo
          dangerouslySetInnerHTML={{ __html: sanitizer(descriptionComposed) }}
          data-test-label={`main-info-${id}`}
        />
        <TabsInfo>
          <Tabs defaultActiveIndex={0}>
            <Tab value="info" label={t('handle.tab.info')} data-test-label="discover-info-tab">
              <TextBlockInfo>
                <h4>{t('handle.contactDetails')}</h4>
                <Paragraph style={{ marginLeft: 0 }} dangerouslySetInnerHTML={{ __html: sanitizer(contact) }} />
              </TextBlockInfo>
              {coverage?.length > 0 && (
                <TextBlockInfo>
                  <h4>{t('filters.coverage')}</h4>
                  <ExtendedTagsWrapper style={{ marginLeft: 0 }}>
                    {coverage.map(country => (
                      <CoverageTag key={country}>{t(`filters.coverageTags.${country}`)}</CoverageTag>
                    ))}
                  </ExtendedTagsWrapper>
                </TextBlockInfo>
              )}
              {tags?.length > 0 && (
                <TextBlockInfo>
                  <h4>{t('handle.tags')}</h4>
                  <ExtendedTagsWrapper style={{ marginLeft: 0 }}>
                    {tags.map(tag => (
                      <DiscoverTag key={tag}>{t(`filters.tags.${tag}`)}</DiscoverTag>
                    ))}
                  </ExtendedTagsWrapper>
                </TextBlockInfo>
              )}
            </Tab>
            {pricing && (
              <Tab value="pricing" label={t('handle.tab.pricing')} data-test-label="discover-pricing-tab">
                <TextBlockInfo dangerouslySetInnerHTML={{ __html: sanitizer(pricing) }} />
              </Tab>
            )}
            {instruction && (
              <Tab value="instruction" label={t('handle.tab.instruction')} data-test-label="discover-instruction-tab">
                <TextBlockInfo dangerouslySetInnerHTML={{ __html: sanitizer(instruction) }} />
              </Tab>
            )}
            {pointsOfAttention && (
              <Tab value="notice" label={t('handle.tab.notice')} data-test-label="discover-notice-tab">
                <TextBlockInfo dangerouslySetInnerHTML={{ __html: sanitizer(pointsOfAttention) }} />
              </Tab>
            )}
            <Tab
              value="supporting-files"
              label={t('handle.tab.supportingFiles')}
              data-test-label="discover-supporting-files-tab"
              disabled={!allowTemplate}
            >
              {allowTemplate ? (
                <TextBlockInfo>
                  {templates.map(entry => (
                    <div key={entry.reference}>
                      <a
                        href="/#"
                        data-test-href={`/api/download-template/${entry.reference}`}
                        onClick={event => downloadTemplate(entry, connectionId, event)}
                      >
                        {entry.displayName || entry.name}
                      </a>
                      <span>: {entry.description}</span>
                    </div>
                  ))}
                </TextBlockInfo>
              ) : (
                <TextBlockInfo />
              )}
            </Tab>
          </Tabs>
        </TabsInfo>
      </InfoWrapper>
      <RequestAccessModal isVisible={modalVisible} onCloseModal={closeModal} requestAccess={requestAccess} />
    </DiscoverAppLayout>
  );
};

export default DiscoverApp;
