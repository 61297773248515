import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import styled from 'styled-components';
import ReactFileReader from 'react-file-reader';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  display: flex;
  padding: 0;
  font-weight: bolder;
  justify-content: flex-end;
`;

const fileTypes = ['.json'];

const Import = ({ onImport }) => {
  const { t } = useTranslation();

  return (
    <Container data-test-label="import-form">
      <Tooltip content={t('form.tooltip.import')} position="bottom">
        <ReactFileReader fileTypes={fileTypes} base64 handleFiles={onImport}>
          <Button style={{ marginLeft: 10 }} kind="text" className="a-btn-lg" data-test-label="import-btn">
            <span style={{ margin: 'auto', fontSize: 16 }} className="Appkit4-icon icon-upload-outline" />
          </Button>
        </ReactFileReader>
      </Tooltip>
    </Container>
  );
};

export default Import;
