import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';
import { DEFAULT_LANGUAGE_ISO_CODE, getMyApps } from '../util/requests';
import { Wrapper, GridWrapper, TilesWrapper } from '../styles/GeneralElements';
import { LoadingIndicator, NoHandlesAvailableIndicator, LoadingError } from '../elements/loading';
import { STATUS } from '../util/status';
import { HANDLE_TYPE } from '../util/handleType';
import { LANGUAGE_KEY } from '../menu/LanguageMenuItem';
import HandleSearch from './handleSearch/HandleSearch';
import HandleTile from './HandleTile';
import FilterTagsSideDrawer from './FilterTagsSideDrawer';

const HandlesList = ({ handleType }) => {
  const { t } = useTranslation();
  const [availableHandles, setAvailableHandles] = useState([]);
  const [filteredHandles, setFilteredHandles] = useState([]);
  const { setFilterDrawerVisible } = useContext(QSFContext);
  const [status, setStatus] = useState(STATUS.LOADING);
  const isVideoHandle = handleType === HANDLE_TYPE.VIDEO;

  useEffect(() => {
    const getData = async () => {
      const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
      try {
        const response = await getMyApps(lang, handleType);
        const sortedHandles = Object.values(response?.data ?? []).sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        // TODO: Move this filter to backend as handleType
        const removedSideHandles = sortedHandles.filter(handle => handle.id !== 'qsf-handle-gen-doc-qa');

        setAvailableHandles(removedSideHandles);
        setFilteredHandles(removedSideHandles);
        setStatus(STATUS.OK);
      } catch (e) {
        setStatus(STATUS.ERROR);
      }
    };

    getData();
  }, [handleType]);

  // Hide the side filter drawer when the page changes and the component unmounts
  useEffect(() => {
    return () => setFilterDrawerVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (availableHandles.length === 0) {
    return <NoHandlesAvailableIndicator handleType={handleType} />;
  }

  return (
    <Wrapper>
      <HandleSearch
        handles={availableHandles}
        onResult={result => setFilteredHandles(result ?? availableHandles)}
        videosOnly={isVideoHandle}
      />
      <TilesWrapper>
        <h4>{t('handle.all')}</h4>
        <GridWrapper data-test-label={`${isVideoHandle ? 'video' : 'handle'}-list-wrapper`}>
          {filteredHandles.length === 0 ? (
            <div>{t('handle.noResults')}</div>
          ) : (
            filteredHandles.map(handle => {
              return <HandleTile handle={handle} key={handle.id} />;
            })
          )}
        </GridWrapper>
      </TilesWrapper>
      <FilterTagsSideDrawer
        availableHandles={availableHandles}
        setFilteredHandles={setFilteredHandles}
        type={isVideoHandle ? 'videos' : 'myApps'}
      />
    </Wrapper>
  );
};

HandlesList.defaultProps = {
  handleType: 'default'
};

HandlesList.propTypes = {
  handleType: PropTypes.string
};

export default HandlesList;
