import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { QSFContext } from '../context';
import {
  Container,
  EnabledEntry,
  EntryHeader,
  GridWrapper,
  Wrapper,
  ContentWrapper,
  EntryText,
  EntryDescription,
  EntryImageWrapper
} from '../styles/GeneralElements';
import Footer from '../footer/Footer';
import AppImage from '../handles/image/AppImage';
import { APP_NAME } from '../config';

const Result = ({ handle }) => {
  const history = useHistory();
  const { description, name, type, id } = handle;

  const onClick = () => {
    if (type === 'default') {
      history.push(`/handles/${id}?tab=downloads`);
    } else {
      history.push(`/handles/${id}?tab=history&workflow=true`);
    }
  };

  if (handle.isDemo) {
    return false;
  }

  return (
    <EnabledEntry className="ap-panel a-shadow-sm" data-test-label={`${id}-content`} key={name} onClick={onClick}>
      <EntryImageWrapper>
        <AppImage id={id} name={name} size="a-md" />
      </EntryImageWrapper>
      <EntryText>
        <EntryHeader data-test-label={`${id}-header`}>
          <span>{name}</span>
        </EntryHeader>
        <EntryDescription data-test-label={`${id}-description`}>{description}</EntryDescription>
      </EntryText>
    </EnabledEntry>
  );
};

const AvailableDownloadsPage = ({ lang }) => {
  const { isPwCUser } = useContext(QSFContext);
  const history = useHistory();
  const [handles, setHandles] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    axios.get(`/api/${lang}/handlesWithDownloads`).then(res => setHandles(res?.data || {}));
  }, [lang]);

  // Display handles list based on accessibility and alphabetically.
  const results = Object.keys(handles).sort((a, b) => a.localeCompare(b));
  const list = results.map(handle => {
    return <Result handle={handles[handle]} key={handle} />;
  });

  const goHome = useCallback(() => history.push('/'), [history]);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={0}>
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="downloads">
      <span className="bc-text" tabIndex={1}>
        {t('menu.downloads')}
      </span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={2}>
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  return (
    <Container>
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <ContentWrapper>
        <Wrapper data-test-label="available-downloads-list-container">
          <GridWrapper style={{ marginTop: 10 }}>{list}</GridWrapper>
        </Wrapper>
        <Footer />
      </ContentWrapper>
    </Container>
  );
};

AvailableDownloadsPage.propTypes = {
  lang: PropTypes.string.isRequired
};

export default AvailableDownloadsPage;
