import React, { useState, useEffect, useRef, useContext } from 'react';
import { ResponsiveContainer, BarChart } from 'recharts';
import PropTypes from 'prop-types';
import { DashboardContext } from '../../DashboardContext';
import RechartsComponents from '../RechartsComponents';

// Default waterfall chart cell colors
const CELL_STATIC_COLOR = '#0089EB'; // Blue
const CELL_POSITIVE_TREND_COLOR = '#4EB523'; // Green
const CELL_NEGATIVE_TREND_COLOR = '#E0301E'; // Red

// Default data accumulator Id
const DEFAULT_DATA_ACC_ID = 'name';

// Default connecting line stroke color and pattern
const DEFAULT_STROKE_COLOR = '#999'; // Dark grey
const DEFAULT_STROKE_PATTERN = 'dashed';

// Additional Bar component to represent the offset values
const invisibleBarChild = {
  type: 'bar',
  properties: {
    legendType: 'none',
    dataKey: 'offsetPoint',
    fill: 'transparent',
    stackId: 'a',
    isAnimationActive: false
  }
};

const WaterfallDiagram = ({ properties, childComponents, id, title, data }) => {
  const [containerKey, setContainerKey] = useState();
  const { addComponentRef } = useContext(DashboardContext);
  const ref = useRef(null);

  // TODO Remove this when back-end version of PDF-creator is implemented
  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  const componentData = data[properties.data];

  useEffect(() => setContainerKey(id), [data, id]);

  // A waterfall chart depends of the correlation of data so is necessary to keep track
  // of the original data offsets in case of filtering
  const componentDataKey = childComponents?.find(child => child.type === 'bar')?.properties?.dataKey;
  const originalComponentData = data[`${properties.data}_original`] || data[properties.data];
  const dataAccumulatorId = properties.dataAccId || DEFAULT_DATA_ACC_ID;
  const offsetPoints = originalComponentData?.map((item, index) => {
    return {
      name: item[dataAccumulatorId],
      offsetPoint: originalComponentData.slice(0, index).reduce((acc, item, i) => {
        return item.static && i !== 0 ? acc : item[componentDataKey] + acc;
      }, 0)
    };
  });

  let colorCellData;
  let connectingLines;
  let modifiedComponentData;

  // Determines the static, positive and negative cell colors
  const setCellFillColor = item => {
    return item.static
      ? properties.staticValueColor || CELL_STATIC_COLOR
      : item.value < 0
      ? properties.negativeTrendColor || CELL_NEGATIVE_TREND_COLOR
      : properties.positiveTrendColor || CELL_POSITIVE_TREND_COLOR;
  };

  // Creates the connecting lines between bar cells
  const customizedLabel = props => {
    const { x, y, width, index } = props;
    // Defines stroke color and show transparent the last connecting line
    const strokeColor =
      index === componentData.length - 1 ? 'transparent' : properties.connectingLinesColor || DEFAULT_STROKE_COLOR;

    const strokeDashPattern = {
      solid: 0,
      dotted: 2,
      dashed: [6, 2]
    };
    const connectingLinesStyle = {
      stroke: strokeColor,
      strokeWidth: 2,
      strokeDasharray: strokeDashPattern[properties.connectingLinesPattern || DEFAULT_STROKE_PATTERN]
    };

    return (
      <g>
        <line x1={x + width} y1={y} x2={x + width + width / 4} y2={y} style={connectingLinesStyle} />
      </g>
    );
  };

  if (componentData) {
    colorCellData = componentData.map(item => ({
      type: 'cell',
      properties: {
        fill: setCellFillColor(item)
      }
    }));

    connectingLines = {
      type: 'labelList',
      properties: {
        position: 'top',
        content: customizedLabel
      }
    };

    // Adds the offset values for the transparent bar comparing with the original data set by name
    modifiedComponentData = componentData.map(item => {
      if (item.static) return { ...item, offsetPoint: 0 };
      return {
        ...item,
        offsetPoint: offsetPoints.find(i => i.name === item[dataAccumulatorId]).offsetPoint
      };
    });
  }

  // Includes the invisible bar component to the children of the bar chart
  const modifiedChildrenData = [
    invisibleBarChild,
    ...childComponents.map(child => {
      if (child.type === 'bar') {
        return {
          ...child,
          properties: {
            ...child.properties,
            stackId: 'a',
            // Set animation to false as the connecting lines makes graph transitions very slow
            isAnimationActive: false
          },
          children: [...colorCellData, connectingLines]
        };
      }
      return child;
    })
  ];

  return (
    <ResponsiveContainer key={containerKey} ref={ref}>
      <BarChart {...properties} data={modifiedComponentData}>
        {RechartsComponents(modifiedChildrenData, modifiedComponentData)}
      </BarChart>
    </ResponsiveContainer>
  );
};

WaterfallDiagram.defaultProps = {
  properties: {},
  childComponents: []
};

WaterfallDiagram.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  childComponents: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default WaterfallDiagram;
