import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@appkit4/react-components/button';
import { Input } from '@appkit4/react-components/field';
import { Panel } from '@appkit4/react-components/panel';
import { Table } from '@appkit4/react-components/table';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { STATUS } from '../../../util/status';
import { getWorkflowOverview } from '../../util/requests';
import { LoadingIndicator } from '../../../elements/loading';
import ErrorMessage from '../../shared-components/ErrorMessage';
import HandleHeader from '../../../handles/HandleHeader';
import { requestUserData } from '../../../util/requests';
import { Container } from '../../../styles/HandleComponents';
import { Wrapper } from '../../../styles/GeneralElements';

export const StyledTable = styled(Table)`
  margin-top: 10px;
  width: 100%;

  .rt-tr,
  .rt-td {
    background-color: white;
  }

  .-loading > div {
    transform: translateY(-52%) !important;
  }
`;

const HorizontalInput = styled(Input)`
  display: flex;
  margin: 5px 0;
  label {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    align-self: center;
  }
  input {
    width: 100%;
    background-color: #ffffff;
    font-size: 14px;
    align-self: 'flex-end';
  }
`;

export const InputWrapper = React.memo(styled.div`
  display: flex;
  flex-grow: 12;
`);

const DetailButton = ({ t, uri }) => {
  const history = useHistory();
  return (
    <Button onClick={() => history.push(`/workflow-form/${uri}`)} data-test-label="complete-form-btn">
      {t('button.completeForm')}
    </Button>
  );
};

const formatFormData = (workflowData, email) => {
  const forms = [];
  workflowData.participants
    .filter(p => p.email === email)[0]
    .workflowForms.forEach(form => {
      forms.push({
        workflowId: workflowData.id,
        handleName: workflowData.handleName,
        status: form.completed,
        formLabel: form.label,
        formName: form.value,
        dateCompleted: new Date(form.dateCompleted)
      });
    });
  return forms;
};

const countCompletedForms = (workflowData, email) => {
  let count = 0;
  workflowData.participants
    .filter(p => p.email === email)[0]
    .workflowForms.forEach(form => {
      if (form.completed) {
        count++;
      }
    });
  return count;
};

const countTotalForms = (workflowData, email) => {
  return workflowData.participants.filter(p => p.email === email)[0].workflowForms.length;
};

const columns = t => {
  return [
    {
      Header: t('workflow.label.workflowForms'),
      accessor: 'formLabel'
    },
    {
      Header: t('workflow.label.status'),
      accessor: 'status',
      Cell: row => {
        return row.value ? t('workflow.status.submitted') : t('workflow.status.incomplete');
      }
    },
    {
      Header: t('workflow.label.dateSubmitted'),
      accessor: 'dateCompleted',
      Cell: row => {
        return !row.original.status ? t('workflow.label.na') : row.value.toLocaleString('en-US');
      }
    },
    {
      Header: '',
      accessor: 'formName',
      Cell: row => {
        return !row.original.status ? <DetailButton t={t} uri={`${row.original.workflowId}/${row.value}`} /> : null;
      }
    }
  ];
};

const WaitAssembleWorkflowOverview = () => {
  const { t } = useTranslation();
  const { workflowId } = useParams();

  const [status, setStatus] = useState(STATUS.LOADING);
  const [workflowData, setWorkflowData] = useState({});
  const [formData, setFormData] = useState([]);
  const [handleName, setHandleName] = useState('');
  const [handleId, setHandleId] = useState('');
  const [completedForms, setCompletedForms] = useState(0);
  const [totalForms, setTotalForms] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getWorkflowOverview(workflowId);
        const user = await requestUserData();

        setWorkflowData(data?.data);
        setFormData(formatFormData(data?.data, user?.data.emailAddress));
        setHandleName(data?.data?.handleName);
        setHandleId(data?.data?.handleId);
        setCompletedForms(countCompletedForms(data?.data, user?.data.emailAddress));
        setTotalForms(countTotalForms(data?.data, user?.data.emailAddress));

        setStatus(STATUS.OK);
      } catch (e) {
        setStatus(STATUS.ERROR);
      }
    };

    getData();
  }, [workflowId]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <ErrorMessage />;
  }

  const retrieveFailed = t('message.retrieveFailed', { item: t('type.workflow', { count: 2 }) });
  const noneAvailable = t('message.noneAvailable', { type: t('type.workflow', { count: 2 }) });

  return (
    <Container data-test-label="handle-info-container">
      <HandleHeader id={handleId} handleName={handleName} />
      <Wrapper style={{ minWidth: 800 }}>
        <Panel style={{ padding: 30 }} data-test-label="search-documents-panel">
          <InputWrapper>
            <HorizontalInput
              label={t('workflow.label.dateInitiated')}
              title={t('workflow.label.dateInitiated')}
              value={new Date(workflowData.initiatedAt).toLocaleString()}
              disabled
              readonly
            />
            <HorizontalInput label={t('workflow.label.initiator')} value={workflowData.initiator} disabled readonly />
            <HorizontalInput
              label={t('workflow.label.formsCompleted')}
              value={`${completedForms}/${totalForms}`}
              disabled
              readonly
            />
          </InputWrapper>
          <StyledTable
            originalData={formData}
            loading={status === STATUS.LOADING}
            loadingText={<LoadingIndicator />}
            noDataText={status === STATUS.LOADING ? '' : status === STATUS.ERROR ? retrieveFailed : noneAvailable}
            columns={columns(t)}
            showPagination
            sortable
            hasTitle
            pageSize={10}
            defaultSorted={[{ id: 'initiatedAt', desc: true }]}
            previousText={t('form.field.table.previous')}
            nextText={t('form.field.table.next')}
            pageText={t('form.field.table.page')}
            ofText={t('form.field.table.of')}
            rowsText={t('form.field.table.rows')}
          />
        </Panel>
      </Wrapper>
    </Container>
  );
};

export default WaitAssembleWorkflowOverview;
