import React, { useCallback, useContext } from 'react';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import queryString from 'query-string';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';
import {
  LayoutWrapper,
  Container,
  ContentWrapper,
  BreadcrumbWrapper,
  FilterButton,
  DemoAlertWrapper
} from '../styles/GeneralElements';
import Footer from '../footer/Footer';
import { APP_NAME } from '../config';

const HandleWrapper = props => {
  const { children, hasTagFilters } = props;
  const { setFilterDrawerVisible, isPwCUser } = useContext(QSFContext);
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { display_mode } = queryString.parse(useLocation().search);

  const goHome = useCallback(() => history.push('/handles'), [history]);
  const goDiscover = useCallback(() => history.push('/discover'), [history]);
  const goVideos = useCallback(() => history.push('/videos'), [history]);

  const isDemo = routeMatch.path.includes('demo');

  const secondaryBreadcrumb =
    children.key === 'video-handles' ? (
      <BreadcrumbItem key="videos">
        <span onClick={goVideos} className="bc-text" tabIndex={2}>
          {t('menu.videos')}
        </span>
      </BreadcrumbItem>
    ) : children.key === 'discover' ? (
      <BreadcrumbItem key="discover">
        <span onClick={goDiscover} className="bc-text" tabIndex={2}>
          {t('menu.discoverApps')}
        </span>
      </BreadcrumbItem>
    ) : (
      <BreadcrumbItem key="handles">
        <span onClick={goHome} className="bc-text" tabIndex={2}>
          {t('menu.myApps')}
        </span>
      </BreadcrumbItem>
    );

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span tabIndex={1} className="bc-text">
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    secondaryBreadcrumb
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span tabIndex={0} className="bc-text">
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  if (isDemo) {
    breadcrumbs.push(
      <BreadcrumbItem key="demo">
        <span tabIndex={3} className="bc-text">
          {t('demo.demo')}
        </span>
      </BreadcrumbItem>
    );
  }

  return (
    <LayoutWrapper>
      {isDemo && (
        <DemoAlertWrapper>
          <span className="demo-headline">{t('demo.environment')}</span>
          <span>{t('demo.testOnly')}</span>
        </DemoAlertWrapper>
      )}
      <Container displayMode={display_mode} data-test-label="handles-container">
        <BreadcrumbWrapper>
          <Breadcrumb>{breadcrumbs}</Breadcrumb>
          <div style={{ display: 'flex' }}>
            {hasTagFilters && (
              <FilterButton
                add
                onClick={() => setFilterDrawerVisible(true)}
                data-test-label="open-filter-section-button"
                icon="icon-filter-fill"
              />
            )}
          </div>
        </BreadcrumbWrapper>
        <ContentWrapper>
          {children}
          <Footer />
        </ContentWrapper>
      </Container>
    </LayoutWrapper>
  );
};

export default HandleWrapper;
