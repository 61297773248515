import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@appkit4/react-components/select';
import { useTranslation } from 'react-i18next';

const SelectMultiple = ({ field, value, onChange }) => {
  const { t } = useTranslation();
  const { id, options, searchable = true } = field;

  return (
    <Select
      searchPlaceholder={t('form.field.search')}
      placeholder={t('form.field.chooseOption')}
      selectAllText={t('form.field.selectAll')}
      multiple
      searchable={searchable}
      showSelectAll
      onSelect={value => onChange(id, value)}
      value={value}
      data={options}
      dropdownRenderMode="portal"
    />
  );
};

SelectMultiple.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      })
    ).isRequired,
    searchable: PropTypes.bool
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string)
};

export default SelectMultiple;
