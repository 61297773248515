import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import localForage from 'localforage';
import FileSaver from 'file-saver';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from '../../../../elements/loading';
import { parseJson } from '../../../../util';
import { getStandardData } from '../../../../util/requests';
import { FIELD, FORMAT, MODAL, ERROR } from '../enums';
import { FormProps } from '../propTypes';
import { Import, Export, Submit, Reject, Reset } from '../buttons';
import { convertData, getDefaultValues, getDefaultValue, getSelectorDataKeys, validator } from '../helpers';
import DownloadSubmissionButton from '../../../../workflows/shared-components/buttons/DownloadSubmissionButton';
import ErrorModal from './ErrorModal';
import { FormContext } from './FormContext';
import FormFields from './FormFields';

const Wrapper = styled.div`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  opacity: ${props => (props.disabled ? '0.3' : '1')};
`;
const InputWrapper = styled.div`
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Form = ({
  form,
  handleName,
  submitData,
  disabled,
  reviewMode,
  resetResults,
  hasInit,
  isDemo,
  formName,
  submissionId,
  workflowId,
  prefilledFields
}) => {
  const STORAGE_NAME = `${handleName}-forms-v2`;
  const EXPANDED_KEY = `${handleName}-forms-v2-group-expanded`;
  const GROUP_NAME_KEY = `${handleName}-forms-v2-group-names`;

  const { title, description, fields, disableSubmitIf } = form;
  const { formsExport } = queryString.parse(useLocation().search);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(MODAL.NONE);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [selectorOptions, setSelectorOptions] = useState({});
  const { errors, unregister, reset, register, watch, getValues, handleSubmit, setValue, formState, trigger } = useForm(
    {
      defaultValues: getDefaultValues(fields),
      mode: 'onBlur'
    }
  );
  const { isSubmitting } = formState;
  const [groupNames, setGroupNames] = useState({});
  const [expanded, setExpanded] = useState({});
  const [isImport, setIsImport] = useState(false);
  const { t } = useTranslation();

  const updateGroupName = async (id, v) => {
    const values = (await localForage.getItem(GROUP_NAME_KEY)) || {};
    values[id] = v;
    localForage.setItem(GROUP_NAME_KEY, values);
    setGroupNames(values);
  };

  const updateGroupExpanded = async (id, v) => {
    const values = (await localForage.getItem(EXPANDED_KEY)) || {};
    values[id] = v;
    localForage.setItem(EXPANDED_KEY, values);
    setExpanded(values);
  };

  // Load data from LocalForage and data for predefined selectors when page loads
  useEffect(() => {
    const loadData = async (isDemo = false) => {
      const selectorOptions = {};
      const keys = getSelectorDataKeys(fields);

      await Promise.all(
        keys.map(async dataKey => {
          const response = await getStandardData(dataKey);
          const selectOptions = [];
          for (const [key, value] of Object.entries(response?.data || {})) {
            selectOptions.push({ value: key, label: value });
          }
          selectorOptions[dataKey] = selectOptions;
        })
      );

      const data = isDemo ? {} : await localForage.getItem(STORAGE_NAME);
      localForage.setItem(STORAGE_NAME, data);
      const defaultValues = getDefaultValues(fields);

      // Validate the data in LocalForage against current form structure. If it's not valid (this can
      // happen when the form has been updated by the handle), show default values instead.
      const { validateData } = validator(fields, selectorOptions, FORMAT.DATE);
      const validImport = validateData(data);
      const formData = validImport ? { ...defaultValues, ...data } : { ...defaultValues };
      // const formData = validImport ? { ...data, ...defaultValues } : { ...defaultValues };

      if (validImport) {
        const groups = await localForage.getItem(GROUP_NAME_KEY);
        const expanded = await localForage.getItem(EXPANDED_KEY);
        setGroupNames(groups);
        setExpanded(expanded);
      }

      reset(formData);
      setSelectorOptions(selectorOptions);
      setLoading(false);
      if (!isDemo) localForage.setItem(STORAGE_NAME, formData);
      if (prefilledFields !== undefined) {
        localForage.setItem(STORAGE_NAME, prefilledFields);
      }
    };
    loadData(isDemo);
  }, [STORAGE_NAME, reset, fields, GROUP_NAME_KEY, EXPANDED_KEY, isDemo, prefilledFields]);

  // If we're trying to submit an invalid form, show modal with error
  useEffect(() => {
    trigger().then(valid => {
      if (isSubmitting && !valid) {
        setModal(MODAL.FORM_INVALID);
      }
    });
  }, [isSubmitting, trigger]);

  useEffect(() => {
    if (hasInit) {
      setTimeout(() => {
        const defaultValues = getDefaultValues(fields);
        reset(defaultValues);
        localForage.setItem(STORAGE_NAME, defaultValues);
        localForage.setItem(GROUP_NAME_KEY, {});
        localForage.setItem(EXPANDED_KEY, {});
      }, 250);
    }
  }, [hasInit, fields, reset, STORAGE_NAME, GROUP_NAME_KEY, EXPANDED_KEY]);

  // True value will have propagated already and we can reset it. We need the isImport value specifically for Grouped fields.
  useEffect(() => {
    if (isImport) {
      setIsImport(false);
    }
  }, [isImport]);

  const onExport = () => {
    const data = getValues();
    const adjustedData = convertData(fields, data, FORMAT.EPOCH);
    const jsonData = JSON.stringify(adjustedData, (key, value) => {
      // uploaded files can't be exported so set any file uploaders to empty array
      return fields.find(f => f.id === key)?.type === FIELD.FILE_UPLOAD ? [] : value;
    });
    const file = new Blob([jsonData], { type: 'text/plain' });

    if (formsExport === 'tab') {
      const a = document.createElement('a');
      a.target = '_blank';
      a.href = URL.createObjectURL(file);
      a.click();
    } else {
      FileSaver.saveAs(file, `${handleName}.json`);
    }
  };

  const onImport = async files => {
    const file = files.fileList[0];
    const result = await new Response(file).text();
    const fileContent = parseJson(result);

    // Cannot parse/load JSON, show modal with error
    if (fileContent == null) {
      setModal(MODAL.JSON_INVALID);
      return;
    }

    const { validateData } = validator(fields, selectorOptions);
    const validImport = validateData(fileContent);
    if (!validImport) {
      setModal(MODAL.IMPORT_INVALID);
      return;
    }

    const adjustedData = convertData(fields, fileContent, FORMAT.DATE);
    // Override the current values with the imported values. By overriding the current values we ensure
    // that in case the import is missing any field values, the current form value(s) remain valid.
    const values = { ...getValues(), ...adjustedData };

    // Make sure tabular HTML fields are not blown away
    const tabularFields = fields.filter(f => f.type === FIELD.TABULAR);
    tabularFields.forEach(f => {
      const defaultTabular = getDefaultValue(f);
      for (let i = 0; i < defaultTabular.length; i++) {
        Object.keys(defaultTabular[i]).forEach(k => {
          if (f.columns.find(c => c.id === k)?.type === FIELD.HTML) {
            // field value is only present if field is visible
            if (values[f.id] != null) {
              values[f.id][i][k] = defaultTabular[i][k];
            }
          }
        });
      }
    });

    reset(values);
    localForage.setItem(STORAGE_NAME, values);
    setIsImport(true);
  };

  const onSubmit = data => {
    const adjustedData = convertData(fields, data, FORMAT.EPOCH);
    submitData(adjustedData, undefined, formName);
  };

  const onReject = data => {
    const adjustedData = convertData(fields, data, FORMAT.EPOCH);
    submitData(adjustedData, true);
  };

  const onReset = () => {
    resetResults();
    const defaultValues = getDefaultValues(fields);
    reset(defaultValues);
    setGroupNames({});
    setExpanded({});
    localForage.setItem(STORAGE_NAME, defaultValues);
    localForage.setItem(GROUP_NAME_KEY, {});
    localForage.setItem(EXPANDED_KEY, {});
  };

  const onFieldChange = (name, value) => {
    setValue(name, value, { shouldValidate: true });

    // when the number of participants is updated, the max number of participants might have to be adjusted
    if (name === FIELD.PARTICIPANTS) {
      const max = watch(FIELD.PARTICIPANTS).length;
      const current = watch(FIELD.MIN_PARTICIPANTS);
      if (current > max) {
        setValue(FIELD.MIN_PARTICIPANTS, max);
      }
    }
    if (!isDemo) localForage.setItem(STORAGE_NAME, getValues());
  };

  const onSubmitDisabled = disabled => {
    if (disabled !== submitDisabled) {
      setSubmitDisabled(disabled);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <FormContext.Provider
      value={{ formImport: isImport, selectorOptions, updateGroupName, updateGroupExpanded, groupNames, expanded }}
    >
      <Wrapper disabled={disabled}>
        <ErrorModal visible={modal !== MODAL.NONE} message={t(ERROR[modal])} onClose={() => setModal(MODAL.NONE)} />
        <InputWrapper disabled={reviewMode}>{!isDemo && <Import onImport={onImport} />}</InputWrapper>
        <h3>{title}</h3>
        <p>{description}</p>
        <StyledForm>
          <InputWrapper disabled={reviewMode}>
            <FormFields
              fields={fields}
              disableSubmitIf={disableSubmitIf}
              onSubmitDisabled={onSubmitDisabled}
              onFieldChange={onFieldChange}
              {...{ getValues, watch, errors, register, unregister }}
            />
          </InputWrapper>
          {!isDemo && <Export onExport={onExport} />}
          <ButtonWrapper data-test-label="reset-form">
            {!reviewMode && <Submit disabled={submitDisabled} onSubmit={handleSubmit(onSubmit)} />}
            {reviewMode && (
              <Submit disabled={submitDisabled} onSubmit={handleSubmit(onSubmit)} text={t('button.approve')} />
            )}
            {reviewMode && <Reject disabled={submitDisabled} onReject={handleSubmit(onReject)} />}
            {!reviewMode && !isDemo && <Reset onReset={onReset} />}
            {reviewMode && <DownloadSubmissionButton workflowId={workflowId} submissionId={submissionId} />}
          </ButtonWrapper>
        </StyledForm>
      </Wrapper>
    </FormContext.Provider>
  );
};

Form.defaultProps = {
  hasInit: false,
  disabled: false,
  reviewMode: false,
  isDemo: false
};

Form.propTypes = {
  form: PropTypes.shape(FormProps).isRequired,
  handleName: PropTypes.string.isRequired,
  hasInit: PropTypes.bool,
  submitData: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  reviewMode: PropTypes.bool,
  resetResults: PropTypes.func.isRequired,
  isDemo: PropTypes.bool,
  formName: PropTypes.string,
  submissionId: PropTypes.string,
  workflowId: PropTypes.string,
  prefilledFields: PropTypes.object
};

export default React.memo(Form);
