import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { sanitizer } from '../../util';

const LogsWrapper = React.memo(styled.div`
  white-space: pre-wrap;
  background: #fff;
  color: #2d2d2d;
  margin: 25px 0 0;
  height: 150px;
  overflow-y: scroll;
  max-height: 100%;
  box-shadow: 0 0px 9px 1px rgb(0 0 0 / 0.1);
  position: relative;
  width: 100%;
  > .time-bg {
    position: absolute;
    height: 100%;
    width: 75px;
    background: #f6f5f5;
  }
  > table {
    width: 100%;
    position: absolute;
    > tbody > tr {
      border-bottom: 2px solid #f6f5f5;
      > td {
        padding: 3px 8px;
      }
      > .time {
        background: #f6f5f5;
        width: 75px;
        text-align: center;
        color: #848484;
        border-bottom: 2px solid #fff;
      }
    }
  }
`);

const HandleLogs = ({ logs }) => {
  useEffect(() => {
    // Whenever the logs change, make sure that the log box is scrolled down to the bottom.
    const logsElement = document.querySelector('.logs-container');
    if (logsElement) logsElement.scrollTop = logsElement.scrollHeight;
  }, [logs]);

  if (!logs?.length) return null;

  return (
    <LogsWrapper className="logs-container">
      <div className="time-bg" />
      <table>
        <tbody>
          {logs.map(l => (
            <tr key={l.time + l.message}>
              <td className="time">{l.time}</td>
              <td className="message" dangerouslySetInnerHTML={{ __html: sanitizer(l.message) }} />
            </tr>
          ))}
        </tbody>
      </table>
    </LogsWrapper>
  );
};

HandleLogs.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired
    })
  ).isRequired
};

export default HandleLogs;
