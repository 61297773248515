import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Badge } from '@appkit4/react-components/badge';
import { Button } from '@appkit4/react-components/button';
import { downloadReviewItemAttachment, getReviewItems, updateWorkflowStatus } from '../util/requests';
import ShareDocumentModal from './ShareDocumentModal';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
`;

const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
`;

const IconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
`;

const NameCell = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
`;

const FileIcon = ({ type }) => {
  switch (type) {
    case 'aw-doc':
      return <span className="Appkit4-icon icon-document-file-outline" />;
    case 'folder':
      return <span className="Appkit4-icon icon-folder-closed-outline" />;
    case 'binary':
      return <span className="Appkit4-icon icon-file-outline" />;
    default:
      return <span className="Appkit4-icon icon-file-outline" />;
  }
};

const AttachmentRow = ({ attachment, level }) => {
  const history = useHistory();

  const handleView = attachment => {
    if (attachment.type === 'aw-doc') {
      history.push(`/review/${encodeURIComponent(attachment.documentId)}`);
    }
  };

  const handleAttachmentDownload = async attachment => {
    if (attachment.type === 'binary') {
      try {
        const response = await downloadReviewItemAttachment(attachment.documentId);
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        // Set filename using attachment title and binary type
        let filename = attachment.title;
        filename += `.${attachment.binaryType}`;
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Failed to download the file:', error);
      }
    }
  };

  return (
    <tr>
      <Td>
        <NameCell style={{ paddingLeft: `${level * 40}px` }}>
          <IconWrapper>
            <FileIcon type={attachment.type} />
          </IconWrapper>
          {attachment.title}
          {attachment.type === 'binary' && attachment.binaryType && (
            <span style={{ marginLeft: '8px', color: '#666', fontSize: '0.9em' }}>({attachment.binaryType})</span>
          )}
        </NameCell>
      </Td>
      <Td>{new Date(attachment.modifiedDate).toLocaleString()}</Td>
      <Td /> {/* Empty cell for status alignment */}
      <Td>
        {attachment.type === 'binary' ? (
          <ActionButton onClick={() => handleAttachmentDownload(attachment)}>
            <span className="Appkit4-icon icon-download-outline ap-font-16" />
          </ActionButton>
        ) : (
          <ActionButton onClick={() => handleView(attachment)}>
            <span className="Appkit4-icon icon-view-outline ap-font-16" />
          </ActionButton>
        )}
      </Td>
    </tr>
  );
};

const ExpandableRow = styled.tr`
  cursor: ${props => (props.hasAttachments ? 'pointer' : 'default')};
  &:hover {
    background-color: ${props => (props.hasAttachments ? '#f8f9fa' : 'transparent')};
  }
`;

const ChevronIcon = styled.span`
  margin-right: 8px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const ItemRow = ({ item, level = 0 }) => {
  const [expanded, setExpanded] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const history = useHistory();

  const hasAttachments = item.attachments && item.attachments.length > 0;

  const handleRowClick = () => {
    if (hasAttachments) {
      setExpanded(!expanded);
    }
  };

  const handleAwdocView = item => {
    if (item.type === 'aw-doc') {
      history.push(`/review/${encodeURIComponent(item.documentId)}`);
    }
  };

  const handleApprove = async e => {
    e.stopPropagation(); // Prevent row expansion when clicking approve
    try {
      setIsApproving(true);
      await updateWorkflowStatus(item.documentId, 'reviewed');
      // Refresh the document list after approval
      window.location.reload();
    } catch (error) {
      console.error('Error approving document:', error);
    } finally {
      setIsApproving(false);
    }
  };

  function handleShareDocument(e) {
    e.stopPropagation();
    setShareModalVisible(true);
  }

  const getStatusBadge = status => {
    switch (status) {
      case 'in_preparation':
        return <Badge value="In Preparation" type="warning" />;
      case 'reviewed':
        return <Badge value="Reviewed" type="success" />;
      case 'in_signing':
        return <Badge value="In Signing" type="primary" />;
      case 'finalized':
        return <Badge value="Finalized" type="success" />;
      case 'business_support':
        return <Badge value="Business Support" type="info" />;
      default:
        return null;
    }
  };

  // Only show approve button for top-level aw-docs that aren't already approved
  const showApproveButton = item.type === 'aw-doc' && level === 0 && item.status !== 'reviewed';

  return (
    <>
      <ExpandableRow onClick={handleRowClick} hasAttachments={hasAttachments}>
        <Td>
          <NameCell style={{ paddingLeft: `${level * 20}px` }}>
            {hasAttachments && (
              <ChevronIcon>
                {expanded ? (
                  <span className="Appkit4-icon icon-down-chevron-outline ap-font-16" />
                ) : (
                  <span className="Appkit4-icon icon-right-chevron-outline ap-font-16" />
                )}
              </ChevronIcon>
            )}
            <IconWrapper>
              <FileIcon type={item.type} />
            </IconWrapper>
            {item.title}
          </NameCell>
        </Td>
        <Td>{new Date(item.modifiedDate).toLocaleString()}</Td>
        <Td>{getStatusBadge(item?.status)}</Td>
        <Td>
          <ActionButtonsContainer onClick={e => e.stopPropagation()}>
            {item.type === 'aw-doc' && (
              <ActionButton onClick={() => handleAwdocView(item)}>
                <span className="Appkit4-icon icon-view-outline ap-font-16" />
              </ActionButton>
            )}
            <ActionButton onClick={handleShareDocument}>
              <span className="Appkit4-icon icon-share-outline ap-font-16" />
            </ActionButton>
            {showApproveButton && (
              <Button kind="primary" size="small" onClick={handleApprove} disabled={isApproving}>
                {isApproving ? 'Approving...' : 'Approve'}
              </Button>
            )}
          </ActionButtonsContainer>
        </Td>
      </ExpandableRow>
      {expanded &&
        hasAttachments &&
        item.attachments.map((attachment, index) => (
          <AttachmentRow key={attachment.documentId} attachment={attachment} level={level + 1} />
        ))}
      <ShareDocumentModal
        visible={shareModalVisible}
        onClose={() => setShareModalVisible(false)}
        workflowId={item.workflowId}
      />
    </>
  );
};

const DocumentList = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getReviewItems().then(res => {
      if (res?.data) {
        setItems(res.data);
      }
    });
  }, []);

  return (
    <Table>
      <thead>
        <tr>
          <Th>Title</Th>
          <Th>Modified Date</Th>
          <Th>Status</Th>
          <Th>Actions</Th>
        </tr>
      </thead>
      <tbody>
        {items?.results?.map(item => (
          <ItemRow key={item.documentId} item={item} />
        ))}
      </tbody>
    </Table>
  );
};

export default DocumentList;
