import React, { useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { ComposableMap, ZoomableGroup } from 'react-simple-maps';
import { DashboardContext } from '../../DashboardContext';
import WorldMapContent from './WorldMapContent';
import { worldMapPropTypes } from './propTypes';

const WorldMapContainer = React.memo(styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
`);

const WorldMap = ({ properties, childComponents, id, title, data }) => {
  const { addComponentRef } = useContext(DashboardContext);
  const ref = useRef(null);
  // TODO Remove this when back-end version of PDF-creator is implemented
  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  const { markerConfig, filterGeo } = properties;
  const componentData = data[properties.data];

  // Wrap the world map in a ZoomableGroup component if one of it's childComponents contain this element.
  const zoomableGroup = childComponents.find(child => child.type === 'zoomableGroup');

  // Add markers from the data file if the properties are set in the component properties.
  if (componentData?.markers?.length && properties.markerConfig) {
    componentData.markers.forEach(marker => {
      const markerComponent = {
        type: 'marker',
        properties: marker,
        children: []
      };

      // If the component properties contain a circle element then add it.
      if (markerConfig?.circle) {
        markerComponent.children.push({
          type: 'circle',
          properties: markerConfig.circle
        });
      }

      // If the component properties contain a text element and the data entry contains a name property, add the text element.
      if (markerConfig?.text && marker?.name) {
        markerComponent.children.push({
          type: 'text',
          properties: {
            y: marker.y,
            ...markerConfig.text,
            name: marker.name
          }
        });
      }

      // If a circle and/or text child component has been added, then add the new component to the world map it's childComponents.
      if (markerComponent.children.length > 0) {
        childComponents.push(markerComponent);
      }
    });
  }

  const mapProperties = { ...properties };
  delete mapProperties.filterGeo;
  delete mapProperties.markerConfig;

  const worldMapContent = (
    <WorldMapContent childComponents={childComponents} componentData={componentData} filterGeo={filterGeo} />
  );

  return (
    <WorldMapContainer ref={ref}>
      <ComposableMap {...mapProperties}>
        {zoomableGroup != null ? (
          <ZoomableGroup {...zoomableGroup?.properties}>{worldMapContent}</ZoomableGroup>
        ) : (
          worldMapContent
        )}
      </ComposableMap>
    </WorldMapContainer>
  );
};

WorldMap.defaultProps = {
  childComponents: []
};

WorldMap.propTypes = worldMapPropTypes;

export default WorldMap;
