import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingError, LoadingIndicator, UnauthorizedIndicator } from '../../../elements/loading';
import { STATUS } from '../../../util/status';
import { ContextProvider, DashboardContext } from '../../../handles/dashboard/DashboardContext';
import DashboardContent from '../../../handles/dashboard/DashboardContent';
import { requestDisclaimer } from '../../../util/requests';
import { getWorkflowDashboard } from '../../util/requests';

export const Container = styled.div`
  overflow-y: visible;
  padding-left: 0.625rem;
  // Override for checkbox, AppKit alignment seems off by default
  // Logged issue: https://github.pwc.com/AdvisoryAnalyticApps/Appkit-React/issues/1687
  .ap-checkbox {
    display: inline-grid !important;
  }
`;

const Dashboard = ({ dashboardReference }) => {
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [printPage, setPrintPage] = useState(true);
  const [dataLoading, setDataLoading] = useState(STATUS.LOADING);
  const [data, setData] = useState({});
  const [disclaimer, setDisclaimer] = useState('');
  const [reportPrint, setReportPrint] = useState(false);
  const { filters, dataSubsets } = useContext(DashboardContext);

  useEffect(() => {
    const getData = async () => {
      try {
        const disclaimerData = await requestDisclaimer();
        setDisclaimer(disclaimerData.data);

        const dashboardData = await getWorkflowDashboard(dashboardReference);
        setDashboardInfo(dashboardData?.data?.dashboard);

        setPrintPage(dashboardData?.data?.dashboard?.pages?.['dashboard']?.globalProperties?.printPage ?? true);
        setData(dashboardData?.data?.dashboard?.data);
        setReportPrint(dashboardData?.data?.dashboard?.pages?.['dashboard']?.globalProperties?.reportPrint ?? false);
        setDataLoading(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setDataLoading(status);
      }
    };
    getData();
  }, [dashboardReference]);

  const hasIndexComponent =
    Object.values(dashboardInfo?.components ?? {}).find(component => component.type === 'index') != null;

  // adjust the margins for (interactive) dashboard only handles when there are no buttons to render
  const noButtons = !printPage && !hasIndexComponent && filters.length === 0 && dataSubsets.length === 0;

  return (
    <Container data-test-label="workflow-dashboard" style={{ marginTop: noButtons ? '40px' : '60px' }}>
      {/* FIXME: React state update on a unmounted component error comes from Appkit ModalInstaller triggered by <Form/>. */}
      <Content
        dataLoading={dataLoading}
        data={data}
        dashboardInfo={dashboardInfo}
        disclaimer={disclaimer}
        printPage={printPage}
        reportPrint={reportPrint}
      />
    </Container>
  );
};

export const Content = ({ dataLoading, data, dashboardInfo, disclaimer, printPage, reportPrint }) => {
  const { t } = useTranslation();

  if (dataLoading === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (dataLoading === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (dataLoading === STATUS.NOT_FOUND || dataLoading === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator />;
  }

  if (data == null) {
    return <div style={{ textAlign: 'center', marginTop: 50 }}>{t('dashboard.missingData')}</div>;
  }

  if (dashboardInfo == null || dashboardInfo.pages == null || dashboardInfo.components == null) {
    return <div style={{ textAlign: 'center', marginTop: 50 }}>{t('dashboard.missingInfo')}</div>;
  }

  return (
    <ContextProvider>
      <DashboardContent
        printPage={printPage}
        reportPrint={reportPrint}
        dashboard={dashboardInfo}
        initialData={data}
        disclaimer={disclaimer}
        isDemo={false}
      />
    </ContextProvider>
  );
};

Dashboard.propTypes = {
  dashboardReference: PropTypes.string.isRequired
};

export default Dashboard;
