import React, { useContext, useRef } from 'react';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import PropTypes from 'prop-types';
import { DashboardContext } from '../DashboardContext';
import BlockContent from './blockElement/BlockContent';
import { ComponentActions } from './blockElement/ComponentActions';
import { BlockDescription } from './blockElement/BlockElement.styles';
import { TabsPanel, TabsWrapper } from './TabsElement.styles';
import BlockElement from './blockElement/BlockElement';

const TabsElement = ({ pageId, parent, height, width, items, openedByDefault }) => {
  const { pageComponents } = useContext(DashboardContext);
  const tabsRef = useRef();

  if (!items) return null;

  const tabOpenedByDefaultIndex = openedByDefault
    ? items.map(item => item.properties.component).indexOf(openedByDefault)
    : 0;

  return (
    <TabsWrapper
      className="dashboard-tabs"
      height={!parent && height ? height : '100%'}
      width={!parent && width ? width : '100%'}
      ref={tabsRef}
    >
      <TabsPanel>
        <Tabs defaultActiveIndex={tabOpenedByDefaultIndex} className="a-tab-item-padding-10">
          {(items || []).map((item, index) => {
            const { component, title, description, allowExport } = item.properties ?? {};
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Tab value={component} label={title} key={`${pageId}-tabs-${index}`}>
                {allowExport && (
                  <ComponentActions
                    component={component}
                    componentRef={tabsRef}
                    componentHeight={height}
                    allowExport={allowExport}
                    title={title}
                  />
                )}
                <BlockContent component={pageComponents[component]} />
                {description && <BlockDescription>{description}</BlockDescription>}
              </Tab>
            );
          })}
        </Tabs>
      </TabsPanel>
    </TabsWrapper>
  );
};

TabsElement.propTypes = {
  pageId: PropTypes.string.isRequired,
  parent: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(PropTypes.shape(BlockElement.propTypes)),
  openedByDefault: PropTypes.string
};

export default TabsElement;
