import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { APP_NAME, SUPPORT_EMAIL } from '../../config';
import { QSFContext } from '../../context';
import { LoadingWrapper } from '../../styles/LoadingElements';

const LoadingError = () => {
  const { error } = useContext(QSFContext);
  const { t } = useTranslation();
  const supportEmail = `mailto:${SUPPORT_EMAIL}`;
  if (error?.timeout) return null;

  return (
    <LoadingWrapper>
      <div className="a-flex-column" data-test-label="loading-error">
        <h3 className="a-h3">{t('message.error2')}</h3>
        <span>
          <Trans i18nKey="message.contact2" values={{ appName: APP_NAME }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a target="_blank" rel="noopener noreferrer" href={supportEmail} />
          </Trans>
        </span>
      </div>
    </LoadingWrapper>
  );
};

export default LoadingError;
