import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Input } from '@appkit4/react-components/field';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getDefaultValues } from '../../helpers';
import { FormFields } from '../../formComponents';

const Buttons = styled.div`
  display: flex;
  margin: 5px 0 10px auto;
  justify-content: flex-end;

  > :not(:last-child) {
    margin-right: 5px;
  }

  .a-input-size-sm {
    margin-right: auto !important;
  }

  input {
    height: 24px !important;
  }
`;

const GroupName = styled.div`
  margin-right: auto !important;
  font-weight: 500;
`;

const defaultStyle = {
  background: '#fafafa',
  border: '1px solid #eee',
  padding: '10px 10px 0 10px',
  marginBottom: 10
};

const GroupedFields = ({
  value,
  index,
  style,
  fields,
  groupName,
  isExpanded,
  canRemoveGroup,
  expandText,
  collapseText,
  removeText,
  groupNameText,
  onFieldChange,
  onRemoveGroup,
  onCollapse,
  onExpand,
  onGroupNameChange,
  useGroupNameText
}) => {
  const { t } = useTranslation();
  const { errors, unregister, register, watch, getValues, setValue, trigger } = useForm({
    defaultValues: Object.keys(value).length > 0 ? { ...getDefaultValues(fields), ...value } : getDefaultValues(fields),
    mode: 'onBlur'
  });
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    trigger();
  }, [trigger]);

  useEffect(() => {
    if (update === true) {
      onFieldChange(index, getValues());
      setUpdate(false);
    }
    // eslint-disable-next-line
  }, [update]);

  const onChange = (name, value) => {
    setValue(name, value, { shouldValidate: true });
    setUpdate(true);
  };

  return (
    <div key={index} style={style || defaultStyle}>
      <div style={{ display: isExpanded ? '' : 'none' }}>
        <FormFields
          fields={fields}
          disabledSubmitIf={{}}
          onSubmitDisabled={() => {}}
          onFieldChange={onChange}
          {...{ getValues, watch, errors, register, unregister }}
        />
      </div>
      <Buttons>
        {!isExpanded && (
          <>
            {useGroupNameText && <GroupName>{groupName}</GroupName>}
            <Button onClick={() => onExpand(index)} data-test-label="field-expand-btn">
              {expandText || t('form.field.expand')}
            </Button>
          </>
        )}
        {isExpanded && (
          <>
            {useGroupNameText && (
              <Input
                type="text"
                placeholder={groupNameText || t('form.field.groupName')}
                title={groupNameText || t('form.field.groupName')}
                value={groupName}
                onChange={name => onGroupNameChange(index, name)}
              />
            )}
            <Button onClick={() => onCollapse(index)} data-test-label="field-collapse-btn">
              {collapseText || t('form.field.collapse')}
            </Button>
          </>
        )}
        {canRemoveGroup && (
          <Button onClick={() => onRemoveGroup(index)} data-test-label="field-remove-btn">
            {removeText || t('form.field.remove')}
          </Button>
        )}
      </Buttons>
    </div>
  );
};

GroupedFields.defaultProps = {
  groupName: '',
  style: defaultStyle,
  expandText: '',
  collapseText: '',
  removeText: '',
  groupNameText: '',
  useGroupNameText: false
};

GroupedFields.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.objectOf(PropTypes.any),
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupName: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  canRemoveGroup: PropTypes.bool.isRequired,
  expandText: PropTypes.string,
  collapseText: PropTypes.string,
  removeText: PropTypes.string,
  groupNameText: PropTypes.string,
  onFieldChange: PropTypes.func.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onGroupNameChange: PropTypes.func.isRequired,
  useGroupNameText: PropTypes.bool
};

export default GroupedFields;
