import React from 'react';
import PropTypes from 'prop-types';

const Section = ({ style, name, button, children }) => {
  return (
    <div style={{ marginBottom: 30, ...style }} data-test-label={`section-${name}`}>
      <h4 style={{ marginBottom: 20, fontWeight: 300, display: 'flex', alignItems: 'center' }}>
        {name}
        {button && button}
      </h4>
      {children}
    </div>
  );
};

Section.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  button: PropTypes.element,
  children: PropTypes.node
};

export default Section;
