import React from 'react';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Panel } from '@appkit4/react-components/panel';
import { Tab, Tabs } from '@appkit4/react-components/tabs';
import { Table } from '@appkit4/react-components/table';

export const Header = React.memo(styled.div`
  margin: 20px 0;
`);

export const smallPanels = {
  marginTop: 30,
  minHeight: 270
};

export const Container = React.memo(styled.div`
  flex: 1;
  .mini-loader {
    height: 0.75rem;
    width: 0.75rem;
    margin: 0 5px 0 0 !important;
  }
  .a-alert.one-line .a-alert-text-content {
    word-break: unset !important;
  }
`);

export const StyledTable = styled(Table)`
  margin-top: 10px;
  width: 100%;

  .rt-tr,
  .rt-td {
    background-color: white;
  }

  .-loading > div {
    transform: translateY(-52%) !important;
  }
`;

export const StyledTab = React.memo(styled(Tab)`
  padding-top: 0;
`);

export const StyledTabs = React.memo(styled(Tabs)`
  ul {
    margin-bottom: 0;
  }
`);

export const StyledPanel = styled(Panel)`
  padding: 30px;
  margin: 20px 0;
  position: relative;
  z-index: 1;
`;

export const HeaderWrapper = React.memo(styled.div`
  padding: 20px;
  display: flex;
  align-content: space-around;
  background-color: #ffffff;
`);

export const HandleSideWrapper = styled(Panel)`
  padding: 30px;
  margin: 20px 0;
  position: relative;
  z-index: 1;
`;

export const VideoHeaderWrapper = React.memo(styled.div`
  height: 350px;
  display: flex;
  align-content: space-around;
  background: ${props =>
    `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/api/handle/${props.image}/image), #ccc`};
  background-position: center center;
  background-size: cover;
  margin: -90px -64px 0 -64px;
`);

export const TitleWrapper = React.memo(styled.div`
  flex-grow: 1;
  display: flex;
`);

export const ImageLogoWrapper = React.memo(styled.div`
  width: 160px;
  height: 160px;
  border-radius: 4px;
`);

export const TitleApp = React.memo(styled.div`
  margin-left: 40px;
  font-size: 36px;
  font-weight: 300;
`);

export const Actions = React.memo(styled.div`
  display: flex;
  align-items: flex-end;
`);

export const ActionsVideos = React.memo(styled.div`
  display: flex;
  align-items: flex-end;
  padding: 32px 64px;
`);

export const ButtonDiscover = React.memo(styled(Button)`
  background-color: #464646 !important;

  &:hover {
    background-color: #7d7d7d !important;
  }
`);

export const ButtonContact = React.memo(styled(Button)`
  background-color: #fff !important;
  color: #2d2d2d !important;
  min-width: 130px;

  &:hover {
    background-color: #e5e5e5 !important;
  }
`);

export const DescriptionVideos = React.memo(styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  padding: 32px 64px;
`);

export const DescriptionText = React.memo(styled.div`
  font-size: 14px;
  color: #fff;
`);
