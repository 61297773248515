import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DEFAULT_LANGUAGE_ISO_CODE, getNewsList } from '../util/requests';
import { STATUS } from '../util/status';
import { HANDLE_TYPE } from '../util/handleType';
import { APP_NAME } from '../config';
import { QSFContext } from '../context';
import Footer from '../footer/Footer';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../elements/loading';
import { NewsContainer, Wrapper, ContentWrapper, NewsListWrapper } from '../styles/GeneralElements';
import { MainBreakingNewsWrapper } from '../styles/NewsElements';
import { LANGUAGE_KEY } from '../menu/LanguageMenuItem';
import { checkHandlePermissions } from '../util/permissions';
import NewsListHeader from './NewsListHeader';
import HighlightedNewsBlock from './HighlightedNewsBlock';
import FeaturedNewsBlock from './FeaturedNewsBlock';
import RelevantNewsBlock from './RelevantNewsBlock';
import RegularNewsBlock from './RegularNewsBlock';
import SubscriptionsSelector from './SubscriptionsSelector';

const NewsList = () => {
  const [newsList, setNewsList] = useState([]);
  const [status, setStatus] = useState(STATUS.LOADING);
  const { isPwCUser, subscriptionsSelected, setSubscriptionsSelected, permissions } = useContext(QSFContext);
  const history = useHistory();
  const { t } = useTranslation();
  moment.locale(localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE);
  const nowDate = moment().format('YYYY-MM-DD');

  const goHome = useCallback(() => history.push('/'), [history]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getNewsList();
        const news = response.data?.Result?.Items;
        setNewsList(news);
        setStatus(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
      }
    };

    getData();
  }, []);

  // Get all subscriptions from the news list
  const subscriptions = newsList.reduce((subsList, item) => {
    const subsItem = item.subscriptions?.map(subs => subs.name);
    return [...subsList, ...subsItem];
  }, []);

  // Filter subsctiptions to eliminate duplicates
  const subscriptionsSet = subscriptions.filter((item, index) => subscriptions.indexOf(item) === index);

  useEffect(() => {
    if (subscriptionsSelected.length === 0) {
      setSubscriptionsSelected(subscriptionsSet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsList]);

  // Filtered news by subscription
  const newsListFiltered = newsList.filter(item => {
    if (subscriptionsSelected.length === 0 || subscriptionsSelected.length === subscriptionsSet.length) {
      return true;
    }

    return item.subscriptions.find(sub => subscriptionsSelected.includes(sub.name));
  });

  const canReadNews = checkHandlePermissions(permissions || [], 'qsf-api-news', ['view']);

  if (!canReadNews) {
    return <UnauthorizedIndicator handleType={HANDLE_TYPE.DEFAULT} />;
  }

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator handleType={HANDLE_TYPE.DEFAULT} />;
  }

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="news">
      <span className="bc-text" tabIndex={2}>
        {t('menu.news')}
      </span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  const isBreaking = item => item.kind === 'breaking';
  const isFeatured = item => item.kind === 'featured';
  const isRelevant = item => item.kind === 'relevant';
  const isRegular = item => item.kind === 'regular';
  const isNotExpired = item => item.featured_till === '' || moment(item.featured_till).isAfter(nowDate);

  const getHighlightedNews = () => {
    const highlightedNewsComposed = newsList.filter(item => isBreaking(item) && isNotExpired(item));

    if (highlightedNewsComposed.length < 4) {
      highlightedNewsComposed.push(...newsList.filter(item => isFeatured(item) && isNotExpired(item)));
    }

    if (highlightedNewsComposed.length < 4) {
      highlightedNewsComposed.push(...newsList.filter(item => isRelevant(item) && isNotExpired(item)));
    }

    if (highlightedNewsComposed.length < 4) {
      highlightedNewsComposed.push(...newsList.filter(item => isRegular(item)));
    }

    return highlightedNewsComposed;
  };

  const getFeaturedNews = () => {
    const featuredNewsComposed = newsListFiltered.filter(
      item => isFeatured(item) && isNotExpired(item) && !highlightedNews.find(i => i.id === item.id)
    );

    if (featuredNewsComposed.length < 2) {
      featuredNewsComposed.push(...newsListFiltered.filter(item => isRelevant(item) && isNotExpired(item)));
    }

    if (featuredNewsComposed.length < 2) {
      featuredNewsComposed.push(
        ...newsListFiltered.filter(item => isRegular(item) && !highlightedNews.find(i => i.id === item.id))
      );
    }

    return featuredNewsComposed;
  };

  const getRelevantNews = () => {
    const relevantNewsComposed = newsListFiltered.filter(
      item =>
        isRelevant(item) &&
        isNotExpired(item) &&
        !highlightedNews.find(i => i.id === item.id) &&
        !featuredNews.find(i => i.id === item.id)
    );

    if (relevantNewsComposed.length < 4) {
      relevantNewsComposed.push(
        ...newsListFiltered.filter(
          item =>
            isRegular(item) && !highlightedNews.find(i => i.id === item.id) && !featuredNews.find(i => i.id === item.id)
        )
      );
    }

    return relevantNewsComposed;
  };

  const getRegularNews = () => {
    return newsListFiltered.filter(
      item =>
        !highlightedNews.find(i => i.id === item.id) &&
        !featuredNews.find(i => i.id === item.id) &&
        !relevantNews.find(i => i.id === item.id)
    );
  };

  const highlightedNews = getHighlightedNews().splice(0, 4);
  const mainHighlightedNews = highlightedNews[0];
  const mainImageUrl = mainHighlightedNews.image?.sizes?.app;
  const secondaryHighlightedNews = highlightedNews.slice(1, 4);

  const featuredNews = getFeaturedNews().splice(0, 2);
  const relevantNews = getRelevantNews().splice(0, 4);
  const regularNews = getRegularNews().splice(0, 12);

  return (
    <NewsContainer>
      <MainBreakingNewsWrapper image={mainImageUrl}>
        <Breadcrumb className="negative">{breadcrumbs}</Breadcrumb>
        <NewsListHeader info={mainHighlightedNews} />
      </MainBreakingNewsWrapper>
      {secondaryHighlightedNews.length > 0 && <HighlightedNewsBlock list={secondaryHighlightedNews} />}
      <ContentWrapper>
        <Wrapper data-test-label="news-list-container">
          <NewsListWrapper>
            <SubscriptionsSelector onModifySelected={setSubscriptionsSelected} subsSet={subscriptionsSet} />
            {featuredNews.length > 0 && <FeaturedNewsBlock list={featuredNews} />}
            {relevantNews.length > 0 && <RelevantNewsBlock list={relevantNews} />}
            {regularNews.length > 0 && <RegularNewsBlock list={regularNews} />}
          </NewsListWrapper>
        </Wrapper>
        <Footer />
      </ContentWrapper>
    </NewsContainer>
  );
};

export default NewsList;
