import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../../../context';
import { DEFAULT_LANGUAGE_ISO_CODE, requestFileTransformations } from '../../../util/requests';
import {
  STATUS_VALIDATION_INVALID,
  STATUS_VALIDATION_ERROR,
  STATUS_TRANSFORMATION_ERROR,
  STATUS_ASSEMBLING_ERROR,
  STATUS_ASSEMBLING_SUCCESS,
  STATUS_IDLE,
  STATUS_PROCESSING
} from '../../../config';
import DownloadStatus from '../../downloadStatus/DownloadStatus';
import { LANGUAGE_KEY } from '../../../menu/LanguageMenuItem';
// Commented out as we removed 'fire all' handles feature. Uncomment when the feature is reworked.
// import { AllResults } from '../../buttons';
import HandleProgress from '../../handleTabs/handleProgress';
import { Form } from './formComponents';
import { validateForm } from './helpers';

export const Container = styled.div`
  overflow-y: visible;
  padding-left: 0.625rem;

  // Override for checkbox, AppKit alignment seems off by default
  // Logged issue: https://github.pwc.com/AdvisoryAnalyticApps/Appkit-React/issues/1687
  .ap-checkbox {
    display: inline-grid !important;
  }
`;

export const ResultContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

const STOPLOADING = Object.freeze([
  STATUS_VALIDATION_INVALID,
  STATUS_VALIDATION_ERROR,
  STATUS_TRANSFORMATION_ERROR,
  STATUS_ASSEMBLING_ERROR,
  STATUS_ASSEMBLING_SUCCESS
]);

const STATUS = Object.freeze({
  LOADING: Symbol('loading'),
  ERROR: Symbol('error'),
  OK: Symbol('ok')
});

const Results = ({ handleName, handleId, showAll, hasDashboard, isDemo }) => {
  return (
    <ResultContainer data-test-label="download-container">
      <DownloadStatus handleName={handleName} handleId={handleId} hasDashboard={hasDashboard} isDemo={isDemo} />
      {/* Commented out as we removed 'fire all' handles feature. Uncomment when the feature is reworked.
          {showAll && !isDemo && <AllResults handleName={handleName} />} */}
    </ResultContainer>
  );
};

const InvalidForm = ({ error }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ marginBottom: 10 }}>
        <b>{t('form.message.invalid')}</b>
      </span>
      <span>
        <b>{t('form.message.error')}:</b>
      </span>
      <span>{error}</span>
    </div>
  );
};

const focusTooltipById = id => document.getElementById(id)?.focus();

const FormWrapper = ({
  form,
  handleName,
  handleId,
  hasDashboard,
  hasInit,
  isDemo,
  isHandleSide,
  pauseAtDashboardReady
}) => {
  const {
    connectionId,
    transformedResults,
    setRequestId,
    downloads,
    error,
    status,
    setStatus,
    resetTransformedResults,
    setHandleLogs
  } = useContext(QSFContext);
  const downloadStatus = downloads[handleId]?.status ?? '';
  const [loadingStatus, setLoadingStatus] = useState(STATUS.OK);
  const { valid, errors } = validateForm(form);

  // Clear handle logs and status when this handle unmounts
  useEffect(() => () => setStatus(STATUS_IDLE), [setStatus]);

  useEffect(() => {
    if (STOPLOADING.includes(downloadStatus)) {
      setLoadingStatus(STATUS.OK);
    }
  }, [setLoadingStatus, downloadStatus]);

  useEffect(() => {
    if (error) {
      setLoadingStatus(STATUS.ERROR);
    }
  }, [setLoadingStatus, error]);

  const onSubmit = async data => {
    setHandleLogs([]);
    setLoadingStatus(STATUS.LOADING);
    setStatus(STATUS_PROCESSING);
    const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
    const response = await requestFileTransformations({ formToUpload: data }, connectionId, handleId, isDemo, lang);
    setRequestId(response?.data?.requestId);

    if (isDemo) {
      setTimeout(() => focusTooltipById('progressBar'), 100);
    }
  };

  const onReset = () => {
    resetTransformedResults();
    setStatus(STATUS_IDLE);
  };

  if (!valid) {
    return <InvalidForm error={errors} />;
  }

  return (
    <Container data-test-label="form-v2">
      <Form
        form={form}
        handleName={handleName}
        submitData={onSubmit}
        disabled={loadingStatus === STATUS.LOADING}
        resetResults={onReset}
        hasInit={hasInit}
        isDemo={isDemo}
      />
      {status !== STATUS_IDLE && (
        <HandleProgress
          handleName={handleName}
          handleId={handleId}
          status={downloadStatus}
          error={error}
          isHandleSide={isHandleSide}
          pauseAtDashboardReady={pauseAtDashboardReady}
          isDemo={isDemo}
        />
      )}
      {loadingStatus === STATUS.OK && !isHandleSide && (
        <Results
          handleName={handleName}
          handleId={handleId}
          showAll={Object.keys(transformedResults).length > 0}
          hasDashboard={hasDashboard}
          isDemo={isDemo}
        />
      )}
    </Container>
  );
};

FormWrapper.defaultProps = {
  hasInit: false,
  isDemo: false,
  isHandleSide: false
};

FormWrapper.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  handleName: PropTypes.string.isRequired,
  handleId: PropTypes.string.isRequired,
  hasInit: PropTypes.bool,
  isDemo: PropTypes.bool,
  isHandleSide: PropTypes.bool,
  pauseAtDashboardReady: PropTypes.bool
};

export default React.memo(FormWrapper);
