import { Panel } from '@appkit4/react-components/panel';
import styled from 'styled-components';

export const TabsWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: ${props => props.height ?? '100%'};
  position: relative;
`;

export const TabsPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  max-width: ${props => props.width ?? '100%'};
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
  height: ${props => props.height ?? '100%'};

  &:hover .component-actions-btn {
    display: block !important;
  }

  .ap-panel-content {
    height: 100%;
    flex: 1;
    .a-tabs-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      .a-tab-bar-wrapper {
        min-height: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 35px;
        .a-tab-bar-baseline {
          width: calc(100% + 35px);
        }
        .a-tab-bar-scroller {
          height: 100%;
        }
        .a-tab-bar {
          width: 100%;
          ul {
            margin-bottom: 0;
            > li {
              font-size: 1rem;
              font-weight: 500;
              line-height: 50px;
              padding: 0;
              &:hover {
                border-bottom: none !important;
              }
            }
          }
        }
      }
      .a-tab-content {
        height: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        max-height: 100%;
        padding: 15px 0;
        > .a-tab-pane {
          height: 100%;
          > div {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .component-actions-container {
      position: absolute;
      right: 20px;
      top: 12.5px;
      .component-actions-btn {
        cursor: pointer !important;
        span.Appkit4-icon {
          margin-right: 0;
        }
      }
    }
  }
`;
