import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../../context';
import { DEFAULT_LANGUAGE_ISO_CODE, getHandleById, triggerHandleInit } from '../../util/requests';
import {
  DASHBOARD_NO_INPUT_REFERENCE,
  INTERACTIVE_DASHBOARD_ONLY,
  INTERACTIVE_DASHBOARD_ONLY_REFERENCE
} from '../../config';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../../elements/loading';
import { STATUS } from '../../util/status';
import WorkflowHandle from '../../workflows/handle/Handle';
import Form from '../forms/Form';
import HandleAvailableDownloads from '../handleTabs/handleAvailableDownloads';
import HandleUpload from '../handleTabs/handleUpload';
import HandleHeader from '../HandleHeader';
import { LANGUAGE_KEY } from '../../menu/LanguageMenuItem';
import { HANDLE_TYPE } from '../../util/handleType';
import { Container, StyledPanel, StyledTabs, StyledTab } from '../../styles/HandleComponents';
import { checkHandlePermissions } from '../../util/permissions';

const Handle = () => {
  const { id } = useParams();
  const { workflow } = queryString.parse(useLocation().search);
  const { permissions } = useContext(QSFContext);
  const isAuthorized = checkHandlePermissions(permissions, id);

  if (!isAuthorized) {
    return <UnauthorizedIndicator />;
  }

  if (workflow === 'true') {
    return <WorkflowHandle />;
  }

  return <DefaultHandle />;
};

export default Handle;

export const DefaultHandle = () => {
  const { id } = useParams();
  const [info, setInfo] = useState({});
  const [status, setStatus] = useState(STATUS.LOADING);
  const searchQuery = useLocation().search;
  const { tab } = queryString.parse(useLocation().search);

  useEffect(() => {
    const getData = async () => {
      try {
        const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
        let idResponse = await getHandleById(id, lang);
        if (idResponse.data.hasInit && tab !== 'downloads') {
          await triggerHandleInit(idResponse?.data?.id, searchQuery);
          idResponse = await getHandleById(id, lang, idResponse?.data?.handleType || HANDLE_TYPE.DEFAULT);
        }
        setInfo(idResponse?.data);
        setStatus(STATUS.OK);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator />;
  }

  return <HandleContent info={info} />;
};

const HandleContent = ({ info }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();
  const { tab } = queryString.parse(useLocation().search);
  const { availableDownloads } = useContext(QSFContext);
  const hasAvailableDownloads = (availableDownloads[info.id]?.filter(d => !d.isDemo) || []).length > 0;

  const functional = info.functional === true;
  const hasDashboard = info.dashboard === true;
  const pauseAtDashboardReady = info.pauseAtDashboardReady === true;

  // Hides the tabs in case that there's only one tab available
  const isHiddenTab = !hasAvailableDownloads;

  useEffect(() => {
    if (info.handleType === INTERACTIVE_DASHBOARD_ONLY) {
      history.replace(`/handles/${info.id}/${info.name}/dashboard/${INTERACTIVE_DASHBOARD_ONLY_REFERENCE}`);
    } else if (!functional && hasDashboard) {
      history.replace(`/handles/${info.id}/${info.name}/dashboard/${DASHBOARD_NO_INPUT_REFERENCE}`);
    } else if (!tab || (tab === 'downloads' && !hasAvailableDownloads)) {
      // TODO: Modify request/response to filter by functional elements
      functional ? history.replace(`/handles/${info.id}?tab=input`) : history.replace(`/discover/${info.id}`);
      setActiveIndex(0);
    } else if (tab && tab === 'downloads' && hasAvailableDownloads) {
      setActiveIndex(1);
    }
  }, [functional, hasDashboard, info.id, info.name, hasAvailableDownloads, tab, history, info.handleType]);

  const handleTabChange = (i, newTab) => {
    setActiveIndex(i);
    history.push(`/handles/${info.id}/${info.name}?tab=${newTab}`);
  };

  return (
    <Container data-test-label="handle-info-container">
      <HandleHeader id={info.id} handleName={info.name} />
      {functional && (
        <StyledPanel>
          <StyledTabs
            type="underline"
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
            data-test-label="tab-information"
            className={`a-tab-item-padding-10 ${isHiddenTab && 'hidden-tab'}`}
          >
            <StyledTab value="input" data-test-label="tab-upload-data" label={t('handle.label.insertData')} />
            <StyledTab value="downloads" data-test-label="tab-downloads" label={t('handle.label.downloads')} />
          </StyledTabs>
          <div>
            {activeIndex === 0 && info.form ? (
              <Form
                form={info.form}
                handleName={info.name}
                handleId={info.id}
                hasDashboard={hasDashboard}
                pauseAtDashboardReady={pauseAtDashboardReady}
                hasInit={info.hasInit}
              />
            ) : activeIndex === 0 && !info.form ? (
              <HandleUpload
                handleName={info.name}
                transformAllowed={tab !== 'downloads'}
                handleId={info.id}
                hasDashboard={hasDashboard}
                pauseAtDashboardReady={pauseAtDashboardReady}
                isDemo={false}
              />
            ) : activeIndex === 1 && hasAvailableDownloads ? (
              <HandleAvailableDownloads handleName={info.name} handleId={info.id} />
            ) : (
              ''
            )}
          </div>
        </StyledPanel>
      )}
    </Container>
  );
};
