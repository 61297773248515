import React, { useCallback, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components/breadcrumb';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';
import { Container, ContentWrapper } from '../styles/GeneralElements';
import Footer from '../footer/Footer';
import { APP_NAME } from '../config';

const WorkflowsWrapper = ({ children }) => {
  const { isPwCUser } = useContext(QSFContext);
  const { t } = useTranslation();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const goHome = useCallback(() => history.push('/'), [history]);
  const goWorkflows = useCallback(() => history.push('/workflows'), [history]);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>{APP_NAME}</span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="workflows" onClick={goWorkflows}>
      <span className="bc-text" tabIndex={2}>{t('menu.workflows')}</span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>Doing Work</span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  if (
    routeMatch.path === '/workflows/wait-assemble/:workflowId' ||
    routeMatch.path === '/workflows/review-and-publish/:workflowId'
  ) {
    breadcrumbs.push(
      <BreadcrumbItem key="workflow-id">
        <span className="bc-text" tabIndex={3}>{t('menu.workflowDetails')}</span>
      </BreadcrumbItem>
    );
  }

  if (routeMatch.path === '/workflow/dashboard/:handleName/:workflowId/:page') {
    breadcrumbs.push(
      <BreadcrumbItem key="workflow-id">
        <span className="bc-text" tabIndex={4}>{routeMatch.params['handleName']}</span>
      </BreadcrumbItem>
    );
  }

  return (
    <Container data-test-label="workflow-container">
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <ContentWrapper>
        {children}
        <Footer />
      </ContentWrapper>
    </Container>
  );
};

export default WorkflowsWrapper;
