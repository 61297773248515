import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionItem } from '@appkit4/react-components/accordion';
import { sanitizer } from '../../util';

const Paragraph = React.memo(styled.div`
  ul {
    list-style: disc inside;
    margin-left: 20px;
    margin-bottom: 0px;

    li {
      list-style: inherit;
    }
  }
  ,
  ol {
    list-style: lower-roman inside;
    margin-left: 20px;
    margin-bottom: 0px;

    li {
      list-style: inherit;
    }
  }
`);

const tabsPadding = {
  padding: '30px 0px'
};

const HandleDemoDescription = ({ description }) => {
  const { t } = useTranslation();
  const [activeKeys, setActiveKeys] = useState([]);
  const onClickAccordion = (activeKeys) => {
    setActiveKeys(activeKeys);
  }

  return (
    <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
      <AccordionItem title={t('handle.label.demoInfo')} itemKey="panel-1">
        <span className="AccordionItem-text">
          <Paragraph
              style={tabsPadding}
              className="col-12"
              dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
          />
        </span>
      </AccordionItem>
    </Accordion>
  );
};

HandleDemoDescription.propTypes = {
  description: PropTypes.string.isRequired
};

export default HandleDemoDescription;
