import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@appkit4/react-components/button';
import { Input } from '@appkit4/react-components/field';
import { Table } from '@appkit4/react-components/table';
import { useTranslation } from 'react-i18next';
import Section from './Section';

const StyledTable = styled(Table)`
  cursor: default;
  margin-left: -0.5rem;

  .rt-table,
  .rt-tr,
  .rt-tr-group,
  .rt-td {
    background-color: white !important;
  }

  .rt-tr-group {
    margin: 2px 0;
  }
`;

const capitalize = s => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const getColumnWidth = (rows, accessor, headerText) => {
  const maxWidth = 400;
  const magicSpacing = 10;
  const cellLength = Math.max(...rows.map(row => (`${row[accessor]}` || '').length), headerText.length);
  return Math.min(maxWidth, cellLength * magicSpacing);
};

const addUser = (currentUsers, type, users) => {
  (users || []).forEach(user => {
    const existingUser = currentUsers.find(u => u.id === user.id);
    if (existingUser == null) {
      currentUsers.push({
        id: user.id,
        roles: [type],
        permissions: user.access.split(' ')
      });
    } else {
      existingUser.roles.push(type);
      existingUser.permissions = Array.from(new Set([...existingUser.permissions, ...user.access.split(' ')]));
    }
  });
};

const AddUserButton = () => {
  const { t } = useTranslation();
  return (
    <Button style={{ marginLeft: 'auto' }} data-test-label="add-user-btn">
      {t('button.addUser')}
    </Button>
  );
};

const UserManagement = ({ permission }) => {
  const { t } = useTranslation();
  const users = [];
  addUser(users, 'custodian', permission?.custodian);
  addUser(users, 'owner', permission?.owner);
  return (
    <Section style={{ width: 'fit-content' }} name={t('documents.label.userManagement')} button={<AddUserButton />}>
      <StyledTable
        originalData={users}
        hasTitle
        columns={[
          {
            Header: t('documents.label.user'),
            accessor: 'id',
            width: getColumnWidth(users, 'id', 'User') + 20
          },
          {
            Header: t('documents.label.type'),
            accessor: 'id',
            maxWidth: 90,
            style: {
              color: '#b9b9b9'
            },
            Cell: row => (row.value.toLowerCase().endsWith('pwc.com') ? 'PwC' : 'Client')
          },
          {
            Header: t('documents.label.roles'),
            accessor: 'roles',
            width: getColumnWidth(users, 'roles', 'Role(s)') + 40,
            // eslint-disable-next-line
            Cell: row => (
              <Input
                type="text"
                value={(row.value || []).map(s => capitalize(s)).join(' & ')}
                readonly
                disabled
                className="role"
              />
            )
          },
          {
            Header: t('documents.label.permissions'),
            accessor: 'permissions',
            width: getColumnWidth(users, 'permissions', 'Permission(s)') + 40,
            // eslint-disable-next-line
            Cell: row => (
              <Input
                type="text"
                value={(row.value || []).map(s => capitalize(s)).join(' & ')}
                readonly
                disabled
                className="permission"
              />
            )
          },
          {
            Header: '',
            accessor: 'id',
            maxWidth: 50,
            style: {
              justifyContent: 'center'
            },
            // eslint-disable-next-line
            Cell: row => <span className="Appkit4-icon a-font-16 icon-edit-outline" />
          },
          {
            Header: '',
            accessor: 'id',
            maxWidth: 50,
            style: {
              justifyContent: 'center'
            },
            // eslint-disable-next-line
            Cell: row => <span className="Appkit4-icon a-font-16 icon-delete-outline" />
          }
        ]}
        showPagination={false}
        disableDefaultSort
      />
    </Section>
  );
};

UserManagement.propTypes = {
  permission: PropTypes.shape({
    custodian: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        access: PropTypes.string
      })
    ),
    owner: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        access: PropTypes.string
      })
    )
  })
};

export default UserManagement;
