import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { requestUserData } from '../../util/requests';
import { LoadingIndicator } from '../../elements/loading';
import { getParticipantForm, getWorkflowOverview, getSubmittedForm } from '../util/requests';
import { Container, StyledPanel, StyledTabs, StyledTab } from '../../styles/HandleComponents';
import ErrorMessage from '../shared-components/ErrorMessage';
import Form from '../shared-components/form/Form';
import { STATUS } from '../../util/status';
import HandleHeader from '../../handles/HandleHeader';

const getWorkflowData = async (workflowId, formName, preparerEmail) => {
  try {
    const flow = await getWorkflowOverview(workflowId, formName).then(res => res.data);
    const user = await requestUserData();
    const email = user.data.emailAddress;

    const flowData = {
      handleName: flow.handleName,
      handleId: flow.handleId,
      submitted: false,
      isReviewer: false
    };

    if (preparerEmail) {
      const submission = flow.preparers.filter(preparer => preparer.email === preparerEmail)[0].latestSubmission;

      if (submission) {
        flowData.submissionId = submission;
      }
    }

    if (flow.submissions) {
      flowData.submitted = flow.submissions.includes(email);
    } else {
      flowData.submitted = flow.reviewSubmissions.includes(email) || flow.preparerSubmissions.includes(email);
      flowData.isReviewer = flow.reviewers.find(reviewer => reviewer.email === email) !== undefined;
    }

    return flowData;
  } catch (e) {
    throw new Error(e);
  }
};

const ParticipantForm = () => {
  const { t } = useTranslation();
  const [form, setForm] = useState({});
  const route = useRouteMatch();
  const [status, setStatus] = useState(STATUS.LOADING);
  const [handleName, setHandleName] = useState('');
  const [handleId, setHandleId] = useState('');
  const [isReviewer, setIsReviewer] = useState(false);
  const [answers, setAnswers] = useState({});
  const { workflowId, formName, preparerEmail } = useParams();
  const [submissionId, setSubmissionId] = useState('');
  const name = formName || 'input-form.json';

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getWorkflowData(workflowId, name, preparerEmail);
        setHandleName(data.handleName);
        setHandleId(data.handleId);
        setSubmissionId(data.submissionId);
        if (data.isReviewer) {
          setIsReviewer(true);
        }

        if (data.submitted === true) {
          setStatus(STATUS.SUBMITTED);
        } else {
          const res = await getParticipantForm(workflowId, name);
          setAnswers(undefined);
          setForm(res.data);
          if (route.path === '/submitted-form/:workflowId/:preparerEmail') {
            const res = await getSubmittedForm(workflowId, preparerEmail);
            setAnswers(res.data);
          }
          setStatus(STATUS.OK);
        }
      } catch (e) {
        setStatus(STATUS.ERROR);
      }
    };

    getData();
  }, [workflowId, formName, preparerEmail, route, name]);

  return (
    <Container data-test-label="handle-info-container">
      <HandleHeader id={handleId} handleName={handleName} />
      <StyledPanel>
        <StyledTabs
          onChange={() => {}}
          defaultValue="input"
          value="input"
          data-test-label="tab-information"
          className="a-tab-item-padding-10"
        >
          <StyledTab value="input" data-test-label="tab-upload-data" label="Insert Data">
            {status === STATUS.LOADING && <LoadingIndicator />}
            {status === STATUS.SUBMITTED && <div>{t('workflow.message.alreadySubmitted')}</div>}
            {status === STATUS.OK && (
              <Form
                form={form}
                handleName={handleName}
                handleId={handleId}
                isParticipant
                isReviewer={isReviewer}
                workflowId={workflowId}
                formName={name}
                submissionId={submissionId}
                prefilledFields={answers}
              />
            )}
            {status === STATUS.ERROR && <ErrorMessage />}
          </StyledTab>
        </StyledTabs>
      </StyledPanel>
    </Container>
  );
};

export default ParticipantForm;
