import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@appkit4/react-components/select';
import { useTranslation } from 'react-i18next';

const MultipleChoiceSelector = ({ field, value, onChange }) => {
  const { t } = useTranslation();
  const { id, options } = field;
  const [option, setOption] = useState([]);

  const handleSelect = opt => {
    setOption(opt);
  };

  useEffect(() => {
    onChange(id, option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, option]);

  return (
    <Select
      placeholder={t('form.field.chooseOption')}
      selectAllText={t('form.field.selectAll')}
      multiple
      showSelectAll
      onSelect={value => handleSelect(value)}
      value={option}
      data={options}
    />
  );
};

MultipleChoiceSelector.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      }).isRequired
    )
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object)
};

export default MultipleChoiceSelector;
