// This module implements the Assisted Writing Document Review implementation. Including Fonto Review integration.

import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../context';
import { Container, ContentWrapper } from '../styles/GeneralElements';
import { APP_NAME } from '../config';
import DocumentList from './DocumentList';

const ReviewPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isPwCUser } = useContext(QSFContext);
  const goHome = useCallback(() => history.push('/'), [history]);

  let breadcrumbs = [
    <BreadcrumbItem key="home" onClick={goHome}>
      <span className="bc-text" tabIndex={1}>
        {APP_NAME}
      </span>
    </BreadcrumbItem>,
    <BreadcrumbItem key="review">
      <span className="bc-text" tabIndex={2}>
        {t('menu.review')}
      </span>
    </BreadcrumbItem>
  ];

  if (isPwCUser) {
    breadcrumbs = [
      <BreadcrumbItem key="doing-work" onClick={goHome}>
        <span className="bc-text" tabIndex={0}>
          Doing Work
        </span>
      </BreadcrumbItem>,
      ...breadcrumbs
    ];
  }

  return (
    <Container>
      <Breadcrumb>{breadcrumbs}</Breadcrumb>
      <ContentWrapper>
        <DocumentList />
      </ContentWrapper>
    </Container>
  );
};

export default ReviewPage;
