import React, { useCallback, useMemo, useContext, memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button } from '@appkit4/react-components/button';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
// TODO: check build error "Dependency cycle via..."
// so we can remove next eslint line.
// eslint-disable-next-line import/no-cycle
import { COMPONENT } from '../enums';
import { getDefaultValue, ARRAY_TYPES } from '../helpers';
import { DashboardContext } from '../../../dashboard/DashboardContext';

// Use ReactTable instead of AppKit table due to re-rendering issues when the AppKit table cells contain input fields
export const Styled = styled(ReactTable)`
  ${props => props.tableStyle}
  border: 1px solid #eeeeee;

  .-pagination {
    align-items: center;
    justify-content: center;
    border-top: 1px solid #eeeeee;

    .-previous {
      margin-right: 6px;
      width: inherit;
    }

    .-next {
      margin-left: 6px;
      width: inherit;
    }

    input {
      width: 3rem;
      padding: 0 0.3rem;
    }
  }

  .rt-td {
    padding: 0.625rem !important;
    overflow: auto !important;
    align-items: flex-start;
  }

  .a-calendar {
    display: flex;
  }

  .a-date-picker-header {
    width: 100%;
    border: 0.0625rem solid #dedede;
  }

  .react-date-picker {
    position: unset !important;
  }

  .a-date-picker-header-date-input {
    border: none !important;
  }

  .a-dropdown.a-dropdown-default.open {
    position: unset !important;
  }

  .a-calender-picker-button {
    position: relative !important;
    top: 0px !important;
  }

  .a-react-date-picker-calendar {
    top: auto !important;
    bottom: unset !important;
    left: auto !important;
    right: unset !important;
  }

  .a-dropdown .a-select-list-out {
    right: auto;
    left: auto;
    top: auto !important;
    width: auto;
  }

  .a-select-list-out {
    top: 0px !important;
    margin-top: 0px !important;
  }

  .rt-noData {
    top: 40px;
    background: transparent;
  }

  .a-date-picker-header > .ap-field.has-length {
    width: 100% !important;
  }

  .ap-button.deleteRow {
    padding: var(--spacing-3) 0 !important;
    margin: 0 auto;
  }

  .ap-field .ap-field-container .ap-field-wrapper input.ap-field-input {
    margin: 0 0 0 5px !important;
    padding: 0 !important;
  }

  .ap-field-title-container {
    display: none !important;
  }

  .ap-dropdown-list.ap-dropdown-list-default.open {
    z-index: 12001;
    width: 300px !important;
    font-size: 14px;
  }

  .prefix {
    margin-top: 12px !important;
  }

  .timepicker-container {
    min-width: 125px !important;
  }
`;

export const CellWrapper = styled.div`
  white-space: pre-wrap;
  flex: inherit;
  word-break: break-word;
`;

const Error = styled.div`
  font-size: 12px;
  color: red;
  line-height: 12px;
  margin-top: 5px;
  margin-bottom: -5px;
`;

const AddRowButton = styled(Button)`
  margin-top: 10px;
`;

const getColumns = (columns, value, onChange, onDeleteRow, t, referenceStyle) => {
  return columns.map(column => {
    if (column === 'deleteRow') {
      return {
        Header: t('form.field.delete'),
        accessor: 'delete',
        getHeaderProps: () => ({ 'data-test-label': 'delete' }),
        // eslint-disable-next-line react/display-name
        Cell: memo(row => <Delete row={row} onDelete={onDeleteRow} />)
      };
    }

    return {
      Header: column.required === true ? `${column.label} *` : column.label,
      accessor: column.id,
      getHeaderProps: (state, rowInfo, column) => ({ 'data-test-label': column.id }),
      // eslint-disable-next-line react/display-name
      Cell: memo(
        row => (
          <Cell
            key={`${column.id}--${row.index}`}
            index={row.index}
            column={column}
            value={value[row.index][row.column.id]}
            onChange={onChange}
            referenceStyle={referenceStyle}
          />
        ),
        (prevProps, nextProps) => {
          return prevProps.value === nextProps.value;
        }
      )
    };
  });
};

// eslint-disable-next-line react/display-name
const Delete = memo(({ row, onDelete }) => {
  const onClick = useCallback(() => onDelete(row.index), [onDelete, row.index]);
  return (
    <Button
      style={{ backgroundColor: 'transparent' }}
      className="deleteRow"
      onClick={onClick}
      data-test-label="delete-row-btn"
    >
      <span style={{ color: '#d04a02' }} className="Appkit4-icon icon-delete-fill custom-delete" />
    </Button>
  );
});

// eslint-disable-next-line react/display-name
const Cell = memo(
  ({ index, column, value, onChange, referenceStyle }) => {
    const [actualValue, setActualValue] = useState(value);
    const { setLinkToPdfSelected } = useContext(DashboardContext);
    const { t } = useTranslation();
    const FormField = COMPONENT[column.type];
    if (FormField == null) return null;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleChangeActualValue = useCallback(
      (name, val) => {
        if (column.type === 'input' || column.type === 'number' || column.type === 'textArea') {
          setActualValue(val);
        } else {
          onChange(index, column.id, val);
        }
      },
      [setActualValue, index, column.id, column.type, onChange]
    );

    if (column.regex != null) {
      const regExp = new RegExp(column.regex);
      const isValid = regExp.test(actualValue);

      return (
        <CellWrapper data-test-label={`${column.id}-${index}`}>
          <FormField
            field={column}
            onBlur={e => onChange(index, column.id, actualValue)}
            value={actualValue}
            onChange={handleChangeActualValue}
            hideTitleOnInput
          />
          {!isValid && <Error>{column.errorMessage || t('form.field.formatError')}</Error>}
        </CellWrapper>
      );
    }

    if (column.required === true) {
      const isEmpty = ARRAY_TYPES.includes(column.type) ? actualValue?.length === 0 : value === '';
      return (
        <CellWrapper data-test-label={`${column.id}-${index}`}>
          <FormField
            field={column}
            onBlur={e => onChange(index, column.id, actualValue)}
            onChange={handleChangeActualValue}
            value={actualValue}
          />
          {isEmpty && <Error>{t('form.field.required')}</Error>}
        </CellWrapper>
      );
    }

    if (column.isPdfLinks) {
      const linkToPdf = link => {
        setLinkToPdfSelected(link);
      };
      // Group links by their pdfId so we can
      // render them properly
      const groupLinksByPdfId = links => {
        return links.reduce((acc, link) => {
          if (!acc[link.pdfId]) {
            acc[link.pdfId] = [];
          }
          acc[link.pdfId].push(link);
          return acc;
        }, {});
      };

      const groupedLinks = groupLinksByPdfId(actualValue);

      return (
        <CellWrapper data-test-label={`${column.id}-${index}`}>
          {Object.entries(groupedLinks).map(([pdfId, links]) => (
            <>
              <div key={pdfId}>
                <span className="pdf-title">
                  <b>{pdfId}</b>
                </span>
                <br />
              </div>

              {referenceStyle === 'inline' || referenceStyle === 'inlineWithoutText' ? (
                <>
                  {referenceStyle !== 'inlineWithoutText' && links[0].linkText && (
                    <span className="link-pdf-text">{links[0].linkText}</span>
                  )}

                  {links.map((link, index) => (
                    <div key={link.linkId} style={{ display: 'inline' }}>
                      <span className="link-pdf" onClick={() => linkToPdf(link)}>
                        {link.page}
                      </span>
                      {index < links.length - 1 && ', '}
                    </div>
                  ))}
                </>
              ) : (
                links.map(link => (
                  <div key={link.linkId}>
                    {link.linkText && <span className="link-pdf-text">{link.linkText}</span>}
                    <span className="link-pdf" onClick={() => linkToPdf(link)}>
                      {link.page}
                    </span>
                  </div>
                ))
              )}

              <br />
            </>
          ))}
        </CellWrapper>
      );
    }

    return (
      <CellWrapper data-test-label={`${column.id}-${index}`}>
        <FormField
          field={column}
          onBlur={e => onChange(index, column.id, actualValue)}
          onChange={handleChangeActualValue}
          value={actualValue}
        />
      </CellWrapper>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.value === nextProps.value &&
      prevProps.index === nextProps.index &&
      prevProps.column.id === nextProps.column.id &&
      prevProps.referenceStyle === nextProps.referenceStyle
    );
  }
);

const Tabular = ({ field, value, onChange, referenceStyle }) => {
  const { id, columns, addRow, showPagination, pageSizeOptions, defaultPageSize, style, minRows } = field;
  const { t } = useTranslation();

  const onFieldChange = useCallback(
    (rowIndex, columnId, cellValue) => {
      const newValue = cloneDeep(value);
      newValue[rowIndex][columnId] = cellValue;
      onChange(id, newValue);
    },
    [id, onChange, value]
  );

  const onDeleteRow = useCallback(
    rowIndex => {
      const newValue = cloneDeep(value);
      newValue.splice(rowIndex, 1);
      onChange(id, newValue);
    },
    [id, onChange, value]
  );

  const addEmptyRow = useCallback(() => {
    const newValue = cloneDeep(value);
    const emptyRow = Object.fromEntries(columns.map(k => [k.id, getDefaultValue(k)]));
    newValue.push({ ...emptyRow });
    onChange(id, newValue);
  }, [id, onChange, value, columns]);

  if (value?.length < minRows) {
    let k;
    for (k = value?.length; k < minRows; k++) {
      addEmptyRow();
    }
  }

  const getColumnIds = useCallback(
    columns => {
      const ids = cloneDeep(columns);
      if (addRow === true) {
        ids.push('deleteRow');
      }
      return ids;
    },
    [addRow]
  );

  const columnsIds = useMemo(() => getColumnIds(columns), [getColumnIds, columns]);
  const tableColumns = useMemo(
    () => getColumns(columnsIds, value, onFieldChange, onDeleteRow, t, referenceStyle),
    [columnsIds, onDeleteRow, onFieldChange, value, t, referenceStyle]
  );

  return (
    <>
      <Styled
        data={value}
        columns={tableColumns}
        minRows={1} // sets the height of the tableRow
        showPagination={showPagination}
        pageSizeOptions={pageSizeOptions}
        defaultPageSize={defaultPageSize}
        tableStyle={style}
        previousText={t('form.field.table.previous')}
        nextText={t('form.field.table.next')}
        loadingText={t('form.field.table.loading')}
        noDataText={t('form.field.table.noData')}
        pageText={t('form.field.table.page')}
        ofText={t('form.field.table.of')}
        rowsText={t('form.field.table.rows')}
      />
      {addRow === true && (
        <AddRowButton onClick={addEmptyRow} className="addRow" data-test-label="add-row-btn">
          {t('button.addRow')}
        </AddRowButton>
      )}
    </>
  );
};

Tabular.defaultProps = {
  field: {
    style: {}
  }
};

Tabular.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minRows: PropTypes.number,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultValue: PropTypes.arrayOf(PropTypes.object),
    style: PropTypes.shape({})
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  referenceStyle: PropTypes.string
};

export default Tabular;
