import React from 'react';
import styled from 'styled-components';

const ViewerContainer = styled.div`
  border: 2px solid red;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullSizeIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

// TODO: remove this once we have a proper backend

const FontoViewer = ({ documentId }) => {
  // TODO: remove this once we have a proper backend

  const scope = {
    documentIds: [decodeURIComponent(documentId)],
    cmsBaseUrl: '/9024/api/v1/review/',
    // Edit session token is a base64 encoded string that includes the documentId and a random string.
    // It should be the pwc party id or equivelent but the ML backend doesn't check it and get's the user info from the jwt token instead
    editSessionToken: btoa(`${decodeURIComponent(documentId)}1234567890`),
    cmsClient: {
      storage: 'localStorage',
      addHeaders: {
        // [headerName]: [[format], [storageKey]]
        Authorization: ['Bearer %s', 'ROCP_token'],
        'X-ID-Token': ['%s', 'ROCP_idToken'],
        'x-pwc-ppi': ['%s', 'PwCPPI']
      }
    }
  };

  const link = `/fontoviewer/index.html?scope=${encodeURIComponent(JSON.stringify(scope))}`;

  return (
    <ViewerContainer>
      <FullSizeIframe src={link} title="Fonto Viewer" />
    </ViewerContainer>
  );
};

export default FontoViewer;
