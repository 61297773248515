import moment from 'moment';
import 'moment/locale/nl';
import { LANGUAGE_KEY } from '../../menu/LanguageMenuItem';
import { DEFAULT_LANGUAGE_ISO_CODE } from '../../util/requests';

// takes a date in epoch format
export const dateTimeAgo = date => {
  moment.locale(localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE);
  const parsed = moment.unix(date);
  if (!parsed) return '';
  return parsed.fromNow();
};

/**
 * Method to cut a string to max 100 characters and replace the removed part with three dots.
 * @param input string to truncate
 * @returns {string|*} truncated string
 */
export const truncate = input => (input?.length > 100 ? `${input.substring(0, 100)}...` : input);

// convert duration in seconds to hh:mm:ss
export const getDuration = duration => {
  return new Date(duration * 1000).toISOString().substr(11, 8);
};

export const extractTextFromHtml = html => {
  const span = document.createElement('span');
  span.innerHTML = html;
  return span.textContent || span.innerText;
};
