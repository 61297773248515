import PropTypes from 'prop-types';

const pageChildPropTypes = {
  type: PropTypes.oneOf(['rows', 'columns', 'component', 'accordion', 'tabs']).isRequired,
  properties: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    title: PropTypes.string,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        active: PropTypes.bool
      })
    ),
    component: PropTypes.string,
    link: PropTypes.string,
    rightHeaderContent: PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      link: PropTypes.string
    }),
    data: PropTypes.string
  })
};

pageChildPropTypes.children = PropTypes.arrayOf(PropTypes.shape(pageChildPropTypes));

const dashboardPagePropType = {
  id: PropTypes.string.isRequired,
  ...pageChildPropTypes
};
dashboardPagePropType.children = PropTypes.arrayOf(PropTypes.shape(pageChildPropTypes));

export default dashboardPagePropType;
