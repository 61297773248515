import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getHandleById, getVideos } from '../../util/requests';
import { STATUS } from '../../util/status';
import { HANDLE_TYPE } from '../../util/handleType';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../../elements/loading';

import VideoPanel from './VideoPanel';
import MoreVideos from './MoreVideos';

const VideoDisplayWrapper = React.memo(styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;
`);

const VideoDisplay = ({ onVideoName }) => {
  const { handleId, videoId } = useParams();
  const [moreVideos, setMoreVideos] = useState([]);
  const [currentVideo, setCurrentVideo] = useState({});
  const [status, setStatus] = useState(STATUS.LOADING);

  // Reset video name in breadcrumbs when page loads
  useEffect(() => {
    onVideoName('');
  }, [onVideoName]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getHandleById(handleId);
        const list = await getVideos(response.data.id);
        // Sort videos from newest to oldest
        const videos = list?.data.sort((a, b) => b.createdAt - a.createdAt);

        const current = videos.find(video => video.id === videoId);
        const more = videos.filter(video => video.id !== videoId);
        setCurrentVideo(current);
        setMoreVideos(more);
        setStatus(STATUS.OK);
        onVideoName(current.name);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
      }
    };

    getData();
  }, [videoId, onVideoName, handleId]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator handleType={HANDLE_TYPE.DEFAULT} />;
  }

  return (
    <VideoDisplayWrapper>
      <VideoPanel handleId={handleId} video={currentVideo} />
      <MoreVideos handleId={handleId} videos={moreVideos} />
    </VideoDisplayWrapper>
  );
};

VideoDisplay.propTypes = {
  onVideoName: PropTypes.func.isRequired
};

export default VideoDisplay;
