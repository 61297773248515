import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { QSFContext } from '../context';
import {
  NewsTag,
  FeaturedNewsWrapper,
  FeaturedNews,
  FeaturedNewsInfo,
  FeaturedNewsTitle
} from '../styles/NewsElements';

const NewsItem = ({ item }) => {
  const history = useHistory();
  const { subscriptionsSelected } = useContext(QSFContext);
  const { title, subscriptions, id, uri } = item;
  const mainSubscription = subscriptions.find(sub => subscriptionsSelected.find(s => s === sub.name))?.name;
  const image = item.image?.sizes?.thumb;

  const goToDetailPage = useCallback(() => history.push(`/news/${id}?uri=${uri}`), [history, id, uri]);

  return (
    <FeaturedNews image={image}>
      <FeaturedNewsInfo>
        {mainSubscription && <NewsTag>{mainSubscription}</NewsTag>}
        <FeaturedNewsTitle onClick={() => goToDetailPage(uri)}>{title}</FeaturedNewsTitle>
      </FeaturedNewsInfo>
    </FeaturedNews>
  );
};

const FeaturedNewsBlock = ({ list }) => {
  return (
    <FeaturedNewsWrapper>
      <NewsItem item={list[0]} />
      {list[1] && <NewsItem item={list[1]} />}
    </FeaturedNewsWrapper>
  );
};

export default FeaturedNewsBlock;
