import { padNumber } from './padNumber';

export function logWhenEnabled(tag, message) {
  if (localStorage['logObjects'] !== 'yes') {
    // eslint-disable-next-line no-param-reassign
    message = JSON.stringify(message);
  }
  if (localStorage['logEnabled'] === 'yes') {
    const now = new Date();
    const formatted_date = [now.getHours(), now.getMinutes(), now.getSeconds()].map(padNumber).join(':');
    console.log(`[${formatted_date}]`, tag, message);
  }
}
