import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BlockElement from './blockElement/BlockElement';

const AccordionWrapper = styled.div`
  width: 100%;
  height: ${props => props.height ?? '100%'};
  max-height: ${props => props.height ?? `100%`};
  display: flex;
  flex-direction: column;
  .panel-container {
    height: auto;
    &:not(:first-child) .ap-panel-header {
      border-top: 1px solid #eee;
    }
    &:not(.closed) {
      display: flex;
      flex-grow: 1;
    }
    &.closed {
      .ap-panel-body {
        padding: 0;
      }
    }
  }
`;

const AccordionElement = ({ pageId, parent, height, items, openedByDefault }) => {
  const firstVisibleComponent = (items || []).find(c => !c.properties?.collapsed)?.properties?.component;
  const [visibleComponent, setVisibleComponent] = useState(openedByDefault ?? firstVisibleComponent);

  if (!items) return null;

  const onCollapse = component => {
    setVisibleComponent(component === visibleComponent ? null : component);
  };

  return (
    <AccordionWrapper className="dashboard-accordion" height={!parent && height ? height : '100%'} items={items.length}>
      {(items || []).map((item, index) => (
        <BlockElement
          // eslint-disable-next-line react/no-array-index-key
          key={`${pageId}-accordion-${index}`}
          parent
          collapsible
          collapsed={item.properties?.component !== visibleComponent}
          onCollapse={onCollapse}
          {...item?.properties}
        />
      ))}
    </AccordionWrapper>
  );
};

AccordionElement.propTypes = {
  pageId: PropTypes.string.isRequired,
  parent: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(PropTypes.shape(BlockElement.propTypes)),
  openedByDefault: PropTypes.string
};

export default AccordionElement;
