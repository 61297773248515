import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const NumberContainer = React.memo(styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2,
  h3,
  h4 {
    margin: 0;
  }
`);

const NumberElement = ({ properties, data }) => {
  const { title, titleStyle, dataStyle, numberFormat, operationType, operationDataValue } = properties;
  let componentData = data[properties.data];

  if (operationType === 'sum' && Array.isArray(componentData)) {
    componentData = componentData.reduce((acc, item) => acc + item[operationDataValue], 0);
  } else if (operationType === 'avg' && Array.isArray(componentData)) {
    const sumValue = componentData.reduce((acc, item) => acc + item[operationDataValue], 0);
    componentData = Math.round((sumValue / componentData.length) * 100) / 100 || 0;
  }

  if (numberFormat && !isNaN(componentData)) {
    const formatProps = { ...numberFormat };
    delete formatProps.locale;
    componentData = new Intl.NumberFormat(numberFormat?.locale ?? 'nl-NL', formatProps).format(+componentData);
  }

  return (
    <NumberContainer>
      <h3 style={titleStyle}>{title}</h3>
      <h2 style={dataStyle}>{componentData}</h2>
    </NumberContainer>
  );
};

NumberElement.propTypes = {
  properties: PropTypes.shape({
    data: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    dataStyle: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    operationType: PropTypes.string,
    operationDataValue: PropTypes.string
  }).isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
};

export default NumberElement;
