import React from 'react';
import styled from 'styled-components';

export const ProgressbarWrapper = React.memo(styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: absolute;
  width: ${props => (props.expanded ? '650px' : '160px')};
  background: #f3f3f3;
  right: 20px;
  bottom: 85px;
  box-shadow: 0 0 11px 0px #cecece;
  max-height: 250px;
  border-radius: 5px;
  z-index: 999;
  > .bar-header {
    background: rgb(216, 86, 3);
    color: rgb(255, 255, 255);
    padding: 12px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    ${props => (props.expanded ? '' : 'border-radius: 5px;')}
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .toggle {
      cursor: pointer;
    }
    .a-loading {
      color: #fff;
    }
    .Appkit4-icon {
      margin-left: 15px;
    }
  }
  > .bar-content {
    border-radius: 5px;
    > .item-error {
      background: #ffe4e4;
      padding: 10px;
      color: #000;
      border-bottom: 2px solid #c62b13;
      display: flex;
      align-items: center;
      > .Appkit4-icon {
        color: #c52b13;
        margin-right: 5px;
        font-size: 20px;
      }
      > .error-message {
        font-size: 15px;
      }
    }
  }
  .a-loading {
    height: 24px;
    width: 24px;
  }
`);

export const ProgressItem = React.memo(styled.div`
  background: #fff;
  cursor: pointer;
  ${props => (props.isDone ? 'opacity: 0.75;' : '')}
  > .item-content {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .item-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > .step-label {
        font-size: 0.75rem;
        margin-right: 10px;
        color: #585858;
        &.error {
          color: #c62c15;
        }
      }
      .a-loading {
        margin-right: 10px;
      }
      > .abort {
        color: #afafaf;
      }
      > .done {
        color: #22992e;
        font-size: 20px;
      }
    }
    > .handle-name {
      font-weight: 500;
    }
  }
`);
