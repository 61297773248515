import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from '@appkit4/react-components/field';
// TODO: replace name with handleID and remove convertNameToTestLabel
import { convertNameToTestLabel } from '../../../util/test';

const HorizontalInput = styled(Input)`
  display: flex;
  margin: 5px 0;
  label {
    width: 200px;
    margin: 0;
    align-self: center;
  }
  input {
    width: 400px;
  }
`;

// TODO: refactor, copied from /documents
const Property = ({ name, value }) => {
  return (
    <HorizontalInput data-test-label={convertNameToTestLabel(name)} label={name} value={value} disabled readonly />
  );
};

Property.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default Property;
