import { cloneDeep } from 'lodash';
import { FIELD, FORMAT } from '../enums';
import { dateToString } from './dateToString';

const getEpochSeconds = date => {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) / 1000;
};

// Converts a string date to epoch time in seconds
export const toEpoch = value => getEpochSeconds(new Date(value));

// Converts epoch time in seconds to a Date string in format dd-mmm-yyyy
const toDate = value => dateToString(new Date(value * 1000));

// Converts date fields and tabular date fields to either epoch seconds or dates
export const convertData = (fields, data, convertTo) => {
  const adjustedData = cloneDeep(data);
  Object.keys(data).forEach(fieldId => {
    const field = fields.find(f => f.id === fieldId);
    const converter = convertTo === FORMAT.EPOCH ? toEpoch : toDate;

    if (field?.type === FIELD.DATE) {
      adjustedData[fieldId] = converter(data[fieldId]);
    } else if (field?.type === FIELD.TABULAR) {
      const converted = data[fieldId].map(row => {
        const adjustedRow = { ...row };
        Object.keys(adjustedRow).forEach(col => {
          const columnType = field.columns?.find(c => c.id === col)?.type;
          if (columnType === FIELD.DATE) {
            adjustedRow[col] = converter(adjustedRow[col]);
          }
        });
        return adjustedRow;
      });
      adjustedData[fieldId] = converted;
    } else if (field?.type === FIELD.GROUP) {
      const converted = data[fieldId].map(group => {
        const adjustedGroup = { ...group };
        Object.keys(adjustedGroup).forEach(f => {
          const fieldType = field.fields?.find(c => c.id === f)?.type;
          if (fieldType === FIELD.DATE) {
            adjustedGroup[f] = converter(adjustedGroup[f]);
          } else if (fieldType === FIELD.TABULAR) {
            const converted = adjustedGroup[f].map(row => {
              const adjustedRow = { ...row };
              Object.keys(adjustedRow).forEach(col => {
                const columnType = field.fields?.find(c => c.id === f)?.columns?.find(c => c.id === col)?.type;
                if (columnType === FIELD.DATE) {
                  adjustedRow[col] = converter(adjustedRow[col]);
                }
              });
              return adjustedRow;
            });
            adjustedGroup[f] = converted;
          }
        });
        return adjustedGroup;
      });
      adjustedData[fieldId] = converted;
    }
  });
  return adjustedData;
};
