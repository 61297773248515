import React from 'react';
import styled from 'styled-components';

export const MenuUserIcon = React.memo(styled.li`
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  &:hover {
    background-color: #f5f5f5;
  }

  &.active {
    background-color: #ffe2d2;
    border-right: 4px solid #d04a02;
  }

  & > .Appkit4-icon::before {
    font-size: 21px;
    color: #7d7d7d;
  }
`);

export const MenuAWIcon = React.memo(styled(MenuUserIcon)`
  padding-top: 12px;
  & > .Appkit4-icon::before {
    font-size: 18px;
  }
`);
