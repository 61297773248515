import React, { createContext, useCallback, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

function componentReducer(state, action) {
  const newState = { ...state };

  switch (action.type) {
    case 'add':
      // Only consider adding the component to the state when it has content (length > 150)
      if (action.payload.id && action.payload.ref?.innerHTML?.length > 150) {
        if (
          // Additionally, only add the component when one of these conditions apply:
          !Object.prototype.hasOwnProperty.call(newState, action.payload.id) || // - The component is has not been registered yet
          !newState[action.payload.id].html || // - The saved component doesn't contain any html/content
          newState[action.payload.id].html.length < 150 || // - The saved component is bascially empty
          newState[action.payload.id].html !== action.payload.ref?.innerHTML // - The saved component content is outdated and needs to be renewed.
        ) {
          newState[action.payload.id] = { ...action.payload, html: action.payload.ref?.innerHTML };
        }
      }
      break;

    case 'remove':
      if (action.payload.id) {
        delete newState[action.payload.id];
      }
      break;

    default:
      break;
  }
  return newState;
}

export const ContextProvider = ({ children }) => {
  const [pageComponents, setPageComponents] = useState({});
  const [data, setData] = useState({});
  const [filters, setFilters] = useState([]);
  const [dataFilters, setDataFilters] = useState({});
  const [dataSubsets, setDataSubsets] = useState([]);
  const [activeDataSubset, setActiveDataSubset] = useState('');
  const [formValues, setFormValues] = useState({});
  const [isFormLoading, setFormLoading] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);
  const [reportHasBeenGenerated, setReportHasBeenGenerated] = useState(false);
  const [linkToPdfSelected, setLinkToPdfSelected] = useState(null);

  const [componentRefs, dispatch] = useReducer(componentReducer, {});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disabledDataKeys =
    filters
      ?.filter(f => !f.active)
      .map(f => f.dataKeys)
      .flat() ?? [];

  const addComponentRef = useCallback(
    (id, title, ref) => {
      dispatch({ type: 'add', payload: { id, title, ref } });
    },
    [dispatch]
  );

  const removeComponentRef = useCallback(
    (id, title, ref) => {
      dispatch({ type: 'remove', payload: { id, title, ref } });
    },
    [dispatch]
  );

  const state = useMemo(
    () => ({
      pageComponents,
      data,
      filters,
      formValues,
      dataFilters,
      dataSubsets,
      activeDataSubset,
      disabledDataKeys,
      isFormLoading,
      componentRefs,
      generatingReport,
      reportHasBeenGenerated,
      linkToPdfSelected,
      setPageComponents,
      setData,
      setFilters,
      setDataSubsets,
      setActiveDataSubset,
      setFormValues,
      setDataFilters,
      setFormLoading,
      addComponentRef,
      removeComponentRef,
      setGeneratingReport,
      setReportHasBeenGenerated,
      setLinkToPdfSelected
    }),
    [
      pageComponents,
      data,
      filters,
      formValues,
      dataFilters,
      dataSubsets,
      activeDataSubset,
      isFormLoading,
      disabledDataKeys,
      componentRefs,
      generatingReport,
      reportHasBeenGenerated,
      linkToPdfSelected,
      setPageComponents,
      setData,
      setFilters,
      setDataSubsets,
      setActiveDataSubset,
      setFormValues,
      setDataFilters,
      setFormLoading,
      addComponentRef,
      removeComponentRef,
      setGeneratingReport,
      setReportHasBeenGenerated,
      setLinkToPdfSelected
    ]
  );

  return <DashboardContext.Provider value={state}>{children}</DashboardContext.Provider>;
};

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export const DashboardContext = createContext({
  pageComponents: {},
  data: {},
  filters: [],
  dataSubsets: [],
  activeDataSubset: '',
  formValues: {},
  dataFilters: {},
  isFormLoading: {},
  componentRefs: {},
  generatingReport: false,
  reportHasBeenGenerated: false,
  linkToPdfSelected: null,

  setPageComponents: () => {},
  setData: () => {},
  setFilters: () => {},
  setDataSubsets: () => {},
  setActiveDataSubset: () => {},
  setFormValues: () => {},
  setDataFilters: () => {},
  setFormLoading: () => {},
  addComponentRef: () => {},
  removeComponentRef: () => {},
  setGeneratingReport: () => {},
  setReportHasBeenGenerated: () => {},
  setLinkToPdfSelected: () => {}
});
