import React, { useState, useEffect, useContext, useRef } from 'react';
import { BarChart, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import { useUniqueComponentData } from '../../util/useUniqueComponentData';
import { useTopChartData } from '../../util/topChart';
import { DashboardContext } from '../../DashboardContext';
import RechartsComponents from '../RechartsComponents';

const BarDiagram = ({ properties, childComponents, id, title, data }) => {
  const [containerKey, setContainerKey] = useState();
  const { addComponentRef } = useContext(DashboardContext);
  const bundeledData = useUniqueComponentData(data[properties.data], properties?.bundleByDataKey);
  const componentData = useTopChartData(bundeledData, properties?.topChart);
  const ref = useRef(null);

  // const ref = useCallback(
  //   node => {
  //     setTimeout(() => {
  //       addComponentRef(id, title, node);
  //     }, 200);
  //   },
  //   [addComponentRef, id, title]
  // );

  useEffect(() => {
    if (!ref) return;
    setTimeout(() => {
      addComponentRef(id, title, ref.current);
    }, 200);
  }, [addComponentRef, id, title]);

  useEffect(() => setContainerKey(id), [data, id]);

  return (
    <ResponsiveContainer key={containerKey} ref={ref}>
      <BarChart {...properties} data={componentData}>
        {RechartsComponents(childComponents, componentData)}
      </BarChart>
    </ResponsiveContainer>
  );
};

BarDiagram.defaultProps = {
  properties: {},
  childComponents: []
};

BarDiagram.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any),
  childComponents: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default BarDiagram;
