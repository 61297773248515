import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table } from '@appkit4/react-components/table';
import { useTranslation } from 'react-i18next';

const StyledTable = styled(Table)`
  cursor: default;

  .rt-table,
  .rt-tr,
  .rt-tr-group,
  .rt-td {
    background-color: #fcfcfc !important;
  }

  .rt-thead .rt-tr {
    background-color: #f2f2f2 !important;
  }

  .rt-tr-group {
    margin: 2px 0;
  }
`;

const ParticipantsTable = ({ participants, submissions }) => {
  const { t } = useTranslation();

  return (
    <StyledTable
      data={participants}
      columns={[
        {
          Header: t('form.field.firstName'),
          accessor: 'firstName',
          width: 'auto'
        },
        {
          Header: t('form.field.lastName'),
          accessor: 'lastName',
          width: 'auto'
        },
        {
          Header: t('form.field.email'),
          accessor: 'email',
          width: 'auto'
        },
        {
          Header: t('workflow.label.formsCompleted'),
          accessor: 'workflowForms',
          width: 'auto',
          Cell: row => {
            if (!row.row.workflowForms || row.row.workflowForms.length < 1) return null;
            return (
              <span>
                {row.row.workflowForms.filter(form => form.completed).length}/{row.row.workflowForms.length}
              </span>
            );
          }
        },
        {
          Header: t('form.field.submitted'),
          accessor: 'email',
          width: 'auto',
          // eslint-disable-next-line
          Cell: row => {
            const submitted = submissions.includes(row.value);
            return submitted ? (
              <span className="Appkit4-icon icon-circle-checkmark-outline a-text-green a-font-18" />
            ) : (
              <span className="Appkit4-icon icon-circle-delete-outline a-text-red a-font-18" />
            );
          }
        }
      ]}
      showPagination={false}
      sortable={false}
    />
  );
};

ParticipantsTable.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string
    })
  ).isRequired,
  submissions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ParticipantsTable;
