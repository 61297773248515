import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment from 'moment';
import {
  NewsTag,
  HighlightedWrapper,
  HighlightedIcon,
  HighlightedBlockWrapper,
  HighlightedNewsItem,
  HighlightedNewsImage,
  HighlightedNewsItemInfoWrapper,
  HighlightedNewsInfo,
  HighlightedNewsTime,
  HighlightedNewsTitle
} from '../styles/NewsElements';
import Timer from '../elements/Timer';

const NewsItem = ({ item, middle }) => {
  const history = useHistory();
  const { title, date, subscriptions, id, uri } = item;
  const mainSubscription = subscriptions[0]?.name;
  const pastDate = moment(new Date(date));
  const image = item.image?.sizes?.thumb;

  const goToDetailPage = useCallback(
    () => history.push(`/news/${id}?uri=${uri}&format=highlighted`),
    [history, id, uri]
  );

  return (
    <HighlightedNewsItem middle={middle}>
      <HighlightedNewsImage image={image} />
      <HighlightedNewsItemInfoWrapper>
        <HighlightedNewsInfo>
          <HighlightedNewsTime>
            <span className="Appkit4-icon icon-time-outline" style={{ marginRight: 5 }} />
            <Timer end={pastDate} />
          </HighlightedNewsTime>
          {mainSubscription && <NewsTag>{mainSubscription}</NewsTag>}
        </HighlightedNewsInfo>
        <HighlightedNewsTitle onClick={() => goToDetailPage(uri)}>{title}</HighlightedNewsTitle>
      </HighlightedNewsItemInfoWrapper>
    </HighlightedNewsItem>
  );
};

const HighlightedNewsBlock = ({ list }) => {
  const { t } = useTranslation();

  return (
    <HighlightedWrapper>
      <HighlightedIcon>{t('news.highlighted')}</HighlightedIcon>
      <HighlightedBlockWrapper>
        <NewsItem item={list[0]} />
        {list[1] && <NewsItem item={list[1]} middle />}
        {list[2] && <NewsItem item={list[2]} />}
      </HighlightedBlockWrapper>
    </HighlightedWrapper>
  );
};

export default HighlightedNewsBlock;
