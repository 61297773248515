import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import { Tooltip } from '@appkit4/react-components/tooltip';
import BrainIcon from '../styles/BrainIcon';
import BrainDocumentIcon from '../styles/BrainDocumentIcon';
import ReviewIcon from '../styles/ReviewIcon';
const MenuIcon = React.memo(styled.li`
  height: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-top: 12px;
  &:hover {
    background-color: #f5f5f5;
  }

  &.active {
    background-color: #ffe2d2;
    border-right: 4px solid #d04a02;

    > .Appkit4-icon::before {
      color: #d04a02;
      margin-left: 4px;
    }
  }

  & > .Appkit4-icon::before {
    font-size: 21px;
    color: #7d7d7d;
  }
`);

const TooltipContent = name => {
  return <div style={{ fontSize: 10 }}>{name}</div>;
};

const renderIcon = (icon, match) => {
  if (icon === 'brain-icon') {
    return (
      <span className="Appkit4-icon">
        <BrainIcon width={26} height={26} fill={match ? '#d04a02' : '#7d7d7d'} />
      </span>
    );
  } else if (icon === 'docqa-icon') {
    return (
      <span className="Appkit4-icon">
        <BrainDocumentIcon width={26} height={26} fill={match ? '#d04a02' : '#7d7d7d'} />
      </span>
    );
  } else if (icon === 'review-icon') {
    return (
      <span className="Appkit4-icon">
        <ReviewIcon width={26} height={26} fill={match ? '#d04a02' : '#7d7d7d'} />
      </span>
    );
  }

  return <span className={`Appkit4-icon ${icon}`} />;
};

const MenuItem = ({ path, icon, name }) => {
  return (
    <Route
      path={path}
      exact={false}
      // eslint-disable-next-line react/no-children-prop
      children={({ match, history }) => {
        return (
          <Tooltip content={TooltipContent(name)} position="right" mouseEnterDelay={50}>
            <MenuIcon
              data-test-label={name.toLowerCase()}
              onClick={() => history.push(path)}
              className={match && 'active'}
            >
              {renderIcon(icon, match)}
            </MenuIcon>
          </Tooltip>
        );
      }}
    />
  );
};

MenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default MenuItem;
