import React, { useContext, useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css';
import '@appkit4/styles/themes/appkit.orange.min.css';
import '@appkit4/react-components/dist/styles/appkit4-react.min.css';
import { Button } from '@appkit4/react-components/button';
import { Modal } from '@appkit4/react-components/modal';
import app from './app.module.css';
import { QSFContext } from './context';
import TimeoutNotification from './elements/TimeoutNotification';
import Handle from './handles/handle/Handle';
import HandleWrapper from './handles/HandleWrapper';
// import HandleSide from './handles/handle/HandleSide';
// import HandleWrapperSide from './handles/HandleWrapperSide';
import HandlesList from './handles/HandlesList';
import DiscoverList from './handles/DiscoverList';
// import AssistantPage from './assistant/AssistantPage';
import ReviewPage from './review/ReviewPage';
import VideosList from './handles/VideosList';
import DiscoverApp from './handles/discoverApp/DiscoverApp';
import DemoForm from './handles/demo/DemoForm';
import Menu from './menu/Menu';
import AvailableDownloadsPage from './availableDownloads/AvailableDownloadsPage';
import CommentLetterUploadPage from './commentLetter/CommentLetterUploadPage';
import ResultsPage from './results/ResultsPage';
import UnavailablePage from './unavailablePage/UnavailablePage';

import {
  DEFAULT_LANGUAGE_ISO_CODE,
  getUserConsents,
  initializeAjaxRedirects,
  refreshToken,
  requestUserData,
  saveUserToRedis,
  validateLocalStorageToken
} from './util/requests';
import Dashboard from './handles/dashboard/Dashboard';
import DummyDashboard from './handles/dashboard-dummy/DummyDashboard';
import ImpalaDashboard from './handles/impala-dash/ImpalaDashboard';

import Videos from './handles/videos/Videos';
import VideoListWrapper from './handles/videos/VideoListWrapper';
import VideoDetailWrapper from './handles/videos/VideoDetailWrapper';
import VideoDisplay from './handles/videos/VideoDisplay';
import DocumentsRedirect from './documents/DocumentsRedirect';
import DocumentsWrapper from './documents/DocumentsWrapper';
import NewsList from './news/NewsList';
import NewsDetailPage from './news/NewsDetailPage';
import { Workflows, ParticipantForm, WorkflowsWrapper } from './workflows';
import WaitAssembleWorkflowOverview from './workflows/handle/wait-assemble/WaitAssembleWorkflowOverview';
import ReviewPublishWorkflowOverview from './workflows/handle/review-publish/ReviewPublishWorkflowOverview';
import { LANGUAGE_KEY } from './menu/LanguageMenuItem';
import { LoadingIndicator, NotFoundPage } from './elements/loading';
import DashboardWorkflowWrapper from './workflows/shared-components/dashboard/DashboardWorkflowWrapper';
import { ContextProvider } from './handles/dashboard/DashboardContext';
import FontoViewer from './review/fonto-viewer/FontoViewerPage';

const COUNTDOWN_SECONDS = 60;

const ContentWrapper = React.memo(styled.div`
  margin-left: 80px;
  display: flex;
  justify-content: center;
`);

const IframeModeContentWrapper = React.memo(styled.div`
  display: flex;
  justify-content: center;
`);

const App = () => {
  const logout = async () => {
    if (process.env.NODE_ENV !== 'development') {
      setShowTimeout(false);
      const res = await axios.get('/logout');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = res.data.url;
    }
  };

  // calculateRefreshTokenTrigger returns,
  // in milliseconds, 80% of the time until the token expires.
  const calculateRefreshTokenTrigger = () =>
    (localStorage.getItem('ROCP_tokenExpire') - Math.floor(Date.now() / 1000)) * 1000 * 0.8;

  const [isTokenValidated, setIsTokenValidated] = useState(false);

  useEffect(() => {
    const authorization = async () => {
      var redirectURL = null;
      var currentDomain = null;
      var currentURI = null;
      if (localStorage.getItem('ROCP_token')) {
        axios.defaults.headers.common['X-ID-TOKEN'] = localStorage.getItem('ROCP_idToken');
        axios.defaults.headers.common['X-PWC-PPI'] = localStorage.getItem('PwCPPI');
        axios.defaults.headers.common['Authorization'] = localStorage.getItem('ROCP_token');

        try {
          await validateLocalStorageToken(localStorage.getItem('ROCP_token'));
          setIsTokenValidated(true);
        } catch (err) {
          console.error(err);
          currentDomain = window.location.origin;
          currentURI = window.location.href;
          redirectURL = `${currentDomain}?redirect=${encodeURIComponent(currentURI)}`;
          window.location.href = redirectURL;
        }
      } else {
        currentDomain = window.location.origin;
        currentURI = window.location.href;
        redirectURL = `${currentDomain}?redirect=${encodeURIComponent(currentURI)}`;
        window.location.href = redirectURL;
      }
    };
    authorization();
  }, []);

  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const { setStatus, error, setError } = useContext(QSFContext);
  const [videoName, setVideoName] = useState('');
  const [showTimeout, setShowTimeout] = useState(false);
  const [timer, setTimer] = useState(COUNTDOWN_SECONDS);
  const [end, setEnd] = useState(Date.now());
  const [maxIdle, setMaxIdle] = useState(30); // in minutes
  const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
  const onVideoName = useCallback(name => setVideoName(name), [setVideoName]);
  // handleIframeMode is set by quey parameter display_mode=handle_iframe
  // if true, a version of the FE which only show the handle should be shown.
  const [displayMode, setDisplayMode] = useState(null);
  const [hasAgreed, setHasAgreed] = useState(false);

  useEffect(() => {
    const displayModeQueryValue = new URLSearchParams(window.location.search).get('display_mode');
    if (displayModeQueryValue) {
      setDisplayMode(displayModeQueryValue);
    }
  }, [displayMode]);

  const handleOnIdle = () => {
    if (!showTimeout) {
      setEnd(Date.now() + COUNTDOWN_SECONDS * 1000);
      setTimer(COUNTDOWN_SECONDS);
      setShowTimeout(true);
    }
  };

  useEffect(() => {
    const redirectURL = window.location.href;
    getUserConsents(redirectURL).then(res => {
      if (res.data.hasAgreed) {
        setHasAgreed(true);
        return;
      }
      if (res.data.redirectURL) {
        window.location.href = res.data.redirectURL;
        return;
      }
    });
  }, []);

  const { reset } = useIdleTimer({ timeout: 1000 * 60 * maxIdle, onIdle: handleOnIdle, debounce: 500 });

  useEffect(() => {
    initializeAjaxRedirects(t, error => {
      setError({ ...error, isVisible: true });
      setStatus('error');
    });
  }, [t, setError, setStatus]);

  // TODO: Refactor to include user data into Context
  useEffect(() => {
    if (!localStorage.getItem('ROCP_token')) {
      logout();
    }
    requestUserData().then(({ data }) => {
      setEmail(data.emailAddress);
      setMaxIdle(data.maxIdleTime);
    });
  }, []);

  // reset timer on page load so that idle timer gets triggered in case user doesn't do anything after logging in
  useEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    const refresh = async () => {
      const refresh_token = localStorage.getItem('ROCP_refreshToken');
      if (!refresh_token) {
        console.warn('No refresh token found in local storage');
        logout();
        return;
      }

      if (localStorage.getItem('ROCP_refreshInProgress') === 'true') {
        return;
      }

      localStorage.setItem('ROCP_refreshInProgress', 'true');

      const attemptRefresh = async () => {
        try {
          const res = await refreshToken(refresh_token);
          if (res.status !== 200) {
            throw new Error(`Failed with status: ${res.status}`);
          }

          localStorage.setItem('ROCP_refreshToken', res.data?.refresh_token);
          localStorage.setItem('ROCP_refreshTokenExpire', res.data?.refresh_token_expire);
          localStorage.setItem('ROCP_token', res.data?.access_token);
          localStorage.setItem('ROCP_tokenExpire', res.data?.token_expire);
          localStorage.setItem('ROCP_idToken', res.data?.id_token);
          axios.defaults.headers.common['Authorization'] = res.data?.access_token;
          axios.defaults.headers.common['X-ID-TOKEN'] = res.data?.id_token;
          saveUserToRedis();

          // Schedule next refresh after successful token refresh
          const nextRefreshTime = calculateRefreshTokenTrigger();
          console.log('Scheduling next refresh in', nextRefreshTime / 1000, 'seconds');
          setTimeout(refresh, nextRefreshTime);
        } catch (error) {
          console.error('Could not refresh token. Logging out.', error);
          logout();
        } finally {
          localStorage.setItem('ROCP_refreshInProgress', 'false');
        }
      };

      await attemptRefresh();
    };

    // Start the refresh cycle
    refresh();
  }, []);

  // update the countdown timer in the modal
  useEffect(() => {
    let timeout;
    if (showTimeout) {
      timeout = setTimeout(() => setTimer(Math.ceil((end - Date.now()) / 1000)), 1000);
    }

    return () => clearTimeout(timeout);
  }, [showTimeout, timer, end]);

  // logout user if there was no response
  useEffect(() => {
    if (showTimeout && timer <= 0) {
      logout();
    }
  }, [showTimeout, timer]);

  const stay = () => {
    setShowTimeout(false);
    reset();
  };

  useEffect(() => {
    let timer;
    if (error !== null && error.timeout) {
      timer = setTimeout(() => {
        setError({ ...error, isVisible: false });
      }, error.timeout);
    }
    return () => (timer ? clearTimeout(timer) : null);
  }, [error, setError]);

  if (!hasAgreed) {
    return <></>;
  }

  return (
    <Router>
      <Modal
        className="idle-modal"
        data-test-label="idle-modal"
        visible={showTimeout}
        title="Are you still there?"
        closable={false}
        ariaLabel="Are you still there?"
        modalStyle={{ width: '33.75rem' }}
        footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
        footer={
          <>
            <Button
              kind="transparent"
              onClick={stay}
              data-test-label="timeout-continue-btn"
              style={{ marginRight: '8px' }}
            >
              Continue
            </Button>
            <Button kind="transparent" onClick={logout} data-test-label="timeout-logout-btn">
              Log Out
            </Button>
          </>
        }
        bodyStyle={{ minHeight: '92px' }}
      >
        <p>
          Your session has not been active for a while. To protect your data, you will be automatically logged out in{' '}
          {timer} seconds.
        </p>
      </Modal>
      {error && error.kind === 'popup' && <TimeoutNotification {...error} />}
      {isTokenValidated && displayMode !== 'handle_iframe' ? (
        <div className={app.container}>
          <Menu emailAddress={email} handleDisplayMode={null} />
          <ContentWrapper>
            <Switch>
              <Route exact path="/">
                <Redirect to="/handles" />
              </Route>

              <Route exact path="/handles">
                <HandleWrapper hasTagFilters>
                  <HandlesList data-test-label="handle-list-component" key="handles" />
                </HandleWrapper>
              </Route>

              <Route exact path="/discover">
                <HandleWrapper hasTagFilters>
                  <DiscoverList data-test-label="discover-apps-list-component" key="discover" />
                </HandleWrapper>
              </Route>

              <Route exact path="/news">
                <NewsList />
              </Route>

              <Route path="/news/:id">
                <NewsDetailPage />
              </Route>

              <Route exact path="/videos">
                <HandleWrapper>
                  <VideosList data-test-label="handle-video-list-component" key="video-handles" />
                </HandleWrapper>
              </Route>

              <Route path="/videos/:handleId/:handleName/:videoId">
                {/* make sure to pass the video name to the wrapper when it's retrieved based on the video ID */}
                <VideoDetailWrapper videoName={videoName}>
                  <VideoDisplay onVideoName={onVideoName} />
                </VideoDetailWrapper>
              </Route>

              <Route path="/videos/:handleId/:handleName">
                <VideoListWrapper>
                  <Videos />
                </VideoListWrapper>
              </Route>

              {/* {process.env.NODE_ENV === 'development' && ( */}
              <Route path="/dashboard-dummy">
                <HandleWrapper>
                  <DummyDashboard />
                </HandleWrapper>
              </Route>
              {/* )} */}

              {process.env.NODE_ENV === 'development' && (
                <Route path="/impala">
                  <HandleWrapper>
                    <ImpalaDashboard />
                  </HandleWrapper>
                </Route>
              )}

              <Route path="/handles/:handleId/:handleName/dashboard/:reference/:page?">
                <ContextProvider>
                  <Dashboard />
                </ContextProvider>
              </Route>

              <Route path="/handles/:id">
                <HandleWrapper>
                  <Handle data-test-label="handle-info-component" />
                </HandleWrapper>
              </Route>

              <Route exact path="/discover/:handleId">
                <HandleWrapper>
                  <DiscoverApp data-test-label="discover-app-info-component" key="discover" />
                </HandleWrapper>
              </Route>

              <Route path="/discover/:handleId/:handleName/demo">
                <ContextProvider>
                  <HandleWrapper>
                    <DemoForm data-test-label="demo" key="discover" />
                  </HandleWrapper>
                </ContextProvider>
              </Route>

              <Route path="/chatpwc">
                {/** Todo : remove all comments related to chat and docqa after april 1st 2025 */}
                {/* <AssistantPage /> */}
                <UnavailablePage />
              </Route>

              <Route path="/review/:documentId">
                <FontoViewer />
              </Route>

              <Route path="/review">
                <ReviewPage />
              </Route>

              <Route path="/docqa">
                {/* <HandleWrapperSide>
                  <HandleSide data-test-label="handle-info-component" id="qsf-handle-gen-doc-qa" />
                </HandleWrapperSide> */}
                <UnavailablePage />
              </Route>

              <Route path="/documents">
                <DocumentsWrapper>
                  <DocumentsRedirect />
                </DocumentsWrapper>
              </Route>

              <Route exact path="/workflows">
                <WorkflowsWrapper>
                  <Workflows />
                </WorkflowsWrapper>
              </Route>

              <Route path="/workflows/wait-assemble/:workflowId">
                <WorkflowsWrapper>
                  <WaitAssembleWorkflowOverview />
                </WorkflowsWrapper>
              </Route>

              <Route path="/workflows/review-and-publish/:workflowId">
                <WorkflowsWrapper>
                  <ReviewPublishWorkflowOverview />
                </WorkflowsWrapper>
              </Route>

              <Route path="/workflow-form/:workflowId/:formName">
                <WorkflowsWrapper>
                  <ParticipantForm />
                </WorkflowsWrapper>
              </Route>

              <Route path="/submitted-form/:workflowId/:preparerEmail">
                <WorkflowsWrapper>
                  <ParticipantForm />
                </WorkflowsWrapper>
              </Route>

              <Route path="/workflow/dashboard/:handleId/:workflowId/:page">
                <WorkflowsWrapper>
                  <DashboardWorkflowWrapper />
                </WorkflowsWrapper>
              </Route>

              <Route path="/results">
                <ResultsPage />
              </Route>

              <Route path="/downloads">
                <AvailableDownloadsPage lang={lang} />
              </Route>

              <Route path="/comment-letter/upload/:requestId">
                <CommentLetterUploadPage />
              </Route>

              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </ContentWrapper>
        </div>
      ) : null}

      {isTokenValidated && displayMode === 'handle_iframe' ? (
        <div className={app.container}>
          <IframeModeContentWrapper>
            <Switch>
              <Route path="/handles/:id">
                <HandleWrapper>
                  <Handle data-test-label="handle-info-component" />
                </HandleWrapper>
              </Route>
              <Route exact path="/discover/:handleId">
                <HandleWrapper>
                  <DiscoverApp data-test-label="discover-app-info-component" key="discover" />
                </HandleWrapper>
              </Route>
              <Route path="/discover/:handleId/:handleName/demo">
                <ContextProvider>
                  <HandleWrapper>
                    <DemoForm data-test-label="demo" key="discover" />
                  </HandleWrapper>
                </ContextProvider>
              </Route>
              <Route path="*">
                <NotFoundPage />
              </Route>
            </Switch>
          </IframeModeContentWrapper>
        </div>
      ) : null}

      {!isTokenValidated ? (
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingIndicator />
        </div>
      ) : null}
    </Router>
  );
};

export default App;
