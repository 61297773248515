import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@appkit4/react-components/select';
import { useTranslation } from 'react-i18next';
import { FormContext } from '../formComponents';

const SelectPredefined = ({ value, field, onChange }) => {
  const { t } = useTranslation();
  const { id, data, searchable, hasEmptyOption = false } = field;
  const canSearch = searchable == null ? true : searchable;
  const { selectorOptions } = useContext(FormContext);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(selectorOptions[data] || []);
  }, [selectorOptions, data]);

  const newOptions = hasEmptyOption
    ? [{ value: '', label: `— ${t('form.field.chooseOption')} —` }, ...options]
    : options;

  return (
    <>
      <Select
        searchPlaceholder={t('form.field.search')}
        onSelect={value => onChange(id, value)}
        value={value}
        searchable={canSearch}
        placeholder={`— ${t('form.field.chooseOption')} —`}
        data={newOptions}
        dropdownRenderMode="portal"
      />
    </>
  );
};

SelectPredefined.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
    searchable: PropTypes.bool,
    hasEmptyOption: PropTypes.bool
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default SelectPredefined;
