import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DashboardButton from '../../shared-components/buttons/DashboardButton';
import Property from './Property';

const Wrapper = styled.div`
  display: flex;
  .label {
    width: 200px;
    margin: 0;
    align-self: center;
    font-size: 0.75rem;
    line-height: 1.1875rem;
    font-weight: 400;
    padding-top: 15px;
    color: #2d2d2d;
  }
`;

const Dashboard = ({ workflowId, downloadReference }) => {
  const { t } = useTranslation();

  if (!workflowId || !downloadReference) {
    return <Property name={t('workflow.properties.dashboard')} value="N/A" />;
  }

  return (
    <Wrapper>
      <div className="label">{t('button.dashboard')}</div>
      <DashboardButton workflowId={workflowId} />
    </Wrapper>
  );
};

Dashboard.defaultProps = {
  workflowId: ''
};

Dashboard.propTypes = {
  workflowId: PropTypes.string,
  downloadReference: PropTypes.string
};

export default Dashboard;
