import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  MainNewsItem,
  BreakingIcon,
  BreakingRedDot,
  MainNewsTitle,
  MainNewsInfo,
  MainNewsTime,
  NewsTag
} from '../styles/NewsElements';
import Timer from '../elements/Timer';

const NewsListHeader = ({ info, isDetail = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { title, date, subscriptions, uri, id } = info;
  const mainSubscription = subscriptions[0]?.name;
  const pastDate = moment(new Date(date));

  const goToDetailPage = useCallback(
    () => history.push(`/news/${id}?uri=${uri}&format=highlighted`),
    [history, id, uri]
  );

  return (
    <MainNewsItem>
      <BreakingIcon>
        <BreakingRedDot />
        {t('news.highlighted')}
      </BreakingIcon>
      <MainNewsTitle className={!isDetail && 'link'} onClick={() => !isDetail && goToDetailPage(uri)}>
        {title}
      </MainNewsTitle>
      <MainNewsInfo>
        <MainNewsTime>
          <span className="Appkit4-icon icon-time-outline" style={{ marginRight: 5 }} />
          <Timer end={pastDate} />
        </MainNewsTime>
        {mainSubscription && <NewsTag>{mainSubscription}</NewsTag>}
      </MainNewsInfo>
    </MainNewsItem>
  );
};

export default NewsListHeader;
