import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@appkit4/react-components/checkbox';
import { List, ListItem } from '@appkit4/react-components/list';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { ButtonHoverPointer } from '../../styles/GeneralElements';
import { DashboardContext } from './DashboardContext';

export const DataSubsetList = ({ dataSubsets, onChange }) => {
  const renderItem = item => {
    const eventCheck = event => {
      return event.type === 'click' || event.key === 'Enter';
    };

    const handleSelection = (event, item) => {
      if (eventCheck(event)) {
        onChange(item);
      }
    };

    return (
      <ListItem
        key={item.id}
        role="checkbox"
        aria-label={item.name}
        aria-live="off"
        aria-checked={item.active}
        onClick={e => {
          handleSelection(e, item);
        }}
        className="py-0"
      >
        <Checkbox defaultChecked={item.active} tabIndex={-1} onChange={() => onChange(item)}>
          {item.name}
        </Checkbox>
      </ListItem>
    );
  };

  return <List itemKey="id" data={dataSubsets || []} renderItem={renderItem} style={{ display: 'inline-block' }} />;
};

DataSubsetList.defaultProps = {
  dataSubsets: []
};

DataSubsetList.propTypes = {
  dataSubsets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired
    })
  ),
  onChange: PropTypes.func.isRequired
};

const PageDataSubsets = ({ label }) => {
  const { dataSubsets, setDataSubsets, setActiveDataSubset, filters } = useContext(DashboardContext);
  const buttonStyle = filters.length ? { marginLeft: 10 } : {};

  if (dataSubsets.length === 0) {
    return null;
  }

  const onChange = dataSubset => {
    const newDataSubsets = dataSubset.active
      ? dataSubsets.map(subsetItem => ({ ...subsetItem, active: false }))
      : dataSubsets.map(subsetItem => ({ ...subsetItem, active: subsetItem.id === dataSubset.id }));

    setDataSubsets(newDataSubsets);

    // find id of active subset (can only be one) or set to empty string
    const active = newDataSubsets.find(dataSubset => dataSubset.active === true)?.id ?? '';
    setActiveDataSubset(active);
  };

  return (
    <div className="data-subsets-container">
      <Tooltip
        content={<DataSubsetList dataSubsets={dataSubsets} onChange={onChange} />}
        trigger="click"
        tooltipTheme="light"
        position="bottom-right"
        hideTooltipOnBlur={false}
      >
        <ButtonHoverPointer size="lg" style={buttonStyle} data-test-label="data-subsets-btn">
          {label}
        </ButtonHoverPointer>
      </Tooltip>
    </div>
  );
};

PageDataSubsets.defaultProps = {
  label: 'Data Subsets'
};

PageDataSubsets.propTypes = {
  label: PropTypes.string
};

export default PageDataSubsets;
