import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@appkit4/react-components/field';
import Fuse from 'fuse.js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const options = {
  isCaseSensitive: false,
  findAllMatches: false,
  includeMatches: true,
  includeScore: true,
  useExtendedSearch: false,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
  distance: 1000,
  keys: ['name', 'singleLineDescription']
};

const SearchBox = React.memo(styled.div`
  .a-input-text {
    background-color: #f5f5f5;
    border: 1px solid #919191;
    padding: 0 16px;
  }
  > .a-input-size-lg {
    height: 3.125rem;
  }
`);

const HandleSearch = ({ handles, onResult }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fuse = new Fuse(handles, options);
  const { t } = useTranslation();

  const onSearch = useCallback(
    keywords => {
      const result = fuse.search(keywords);
      const fuzzyResults = result.map(r => r.item);
      onResult(keywords ? fuzzyResults : null);
    },
    [fuse, onResult]
  );

  return (
    <SearchBox>
      <Input
        type="text"
        className="a-input-size-lg"
        data-test-label="searchbar-handles"
        onChange={v => onSearch(v)}
        title={t('handle.search')}
        suffix={
          <span
            className="Appkit4-icon icon-search-outline baseline-search-icon"
            style={{ fontSize: '1rem', color: '#9e9e9e' }}
          />
        }
      />
    </SearchBox>
  );
};

const handleType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    singleLineDescription: PropTypes.string
  })
);

HandleSearch.propTypes = {
  handles: handleType.isRequired,
  onResult: PropTypes.func.isRequired
};

export default HandleSearch;
