import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { QSFContext } from '../../context';
import {
  DEFAULT_LANGUAGE_ISO_CODE,
  getDiscoverAppInfo,
  requestHandleAccess,
  requestHandleDemoFormData
} from '../../util/requests';
import { Container, StyledPanel, StyledTabs, StyledTab } from '../../styles/HandleComponents';
import { LoadingIndicator, LoadingError, UnauthorizedIndicator, NotFoundPage } from '../../elements/loading';
import Form from '../forms/Form';
import { STATUS } from '../../util/status';
import { HANDLE_TYPE, isWorkflowHandle } from '../../util/handleType';
import HandleAvailableDownloads from '../handleTabs/handleAvailableDownloads';
import {
  HeaderWrapper,
  TitleWrapper,
  TitleApp,
  Actions,
  ButtonDemo,
  CloseDemoIcon,
  DemoFormLayout,
  ButtonAccess,
  ImageLogoWrapper
} from '../../styles/DiscoverElements';
import HandleUpload from '../handleTabs/handleUpload';
import RequestAccessModal from '../discoverApp/RequestAccessModal';
import AppImage from '../image/AppImage';
import { LANGUAGE_KEY } from '../../menu/LanguageMenuItem';
import { ChevronRight } from '../../styles/GeneralElements';
import Dashboard from '../dashboard/Dashboard';
import DemoInformationBlock from './DemoInformationBlock';

const DemoForm = () => {
  const { handleId } = useParams();
  const { workflow } = queryString.parse(useLocation().search);
  const [info, setInfo] = useState({});
  const [status, setStatus] = useState(STATUS.LOADING);

  useEffect(() => {
    let idResponse;
    let formFields;
    const getData = async () => {
      try {
        const lang = localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE;
        idResponse = await getDiscoverAppInfo(handleId, workflow, lang);
      } catch (e) {
        const responseStatus = e?.response?.status;
        const status =
          responseStatus === 404 ? STATUS.NOT_FOUND : responseStatus === 403 ? STATUS.FORBIDDEN : STATUS.ERROR;
        setStatus(status);
        return;
      }

      // Check if handle has a form, could not have one if it is a file only handle.
      try {
        // requestHandleDemoFormData will fail if handle is a file only handle,
        // we don't want to show an error to the user in this case.
        const form = await requestHandleDemoFormData(idResponse?.data.id);
        formFields = form?.data;
      } catch (e) {
        // Only show error if error code is not 400
        const responseStatus = e?.response?.status;
        if (responseStatus !== 400) {
          setStatus(STATUS.ERROR);
          return;
        }
      }

      setInfo({ ...idResponse?.data, form: formFields });
      setStatus(STATUS.OK);
    };

    getData();
  }, [handleId, workflow]);

  if (status === STATUS.LOADING) {
    return <LoadingIndicator />;
  }

  if (status === STATUS.ERROR) {
    return <LoadingError />;
  }

  if (status === STATUS.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (status === STATUS.FORBIDDEN) {
    return <UnauthorizedIndicator />;
  }

  return <DemoFormContent info={info} />;
};

const DemoFormContent = ({ info }) => {
  const { id, authorized, handleType, name, form, dashboard } = info;
  const [activeIndex, setActiveIndex] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const { availableDownloads, downloads } = useContext(QSFContext);
  const handleDownload = downloads[id] && downloads[id].isDemo ? downloads[id] : {};
  const { reference } = handleDownload;
  const { tab } = queryString.parse(useLocation().search);
  const hasAvailableDownloads = (availableDownloads[id]?.filter(d => d.isDemo) || []).length > 0;
  const history = useHistory();
  const { t } = useTranslation();
  // handleIframeMode is set by quey parameter display_mode=hanlde_iframe
  // if true, a version of the FE which only show the handle should be shown.
  const [displayMode, setDisplayMode] = useState('');

  // Hides the tabs in case that there's only one tab available
  const isHiddenTabLabels = !hasAvailableDownloads && (!dashboard || !reference);
  const isHiddenDasboardTab = !dashboard || !reference;

  useEffect(() => {
    if (!tab || (tab === 'downloads' && !hasAvailableDownloads)) {
      history.replace(`/discover/${id}/${name}/demo?tab=input`);
    } else if (tab === 'dashboard') {
      history.replace(`/discover/${id}/${name}/demo?tab=dashboard&demoReference=${reference}`);
      setActiveIndex(1);
    }
  }, [hasAvailableDownloads, tab, history, reference, id, name]);

  useEffect(() => {
    const displayModeQueryValue = new URLSearchParams(window.location.search).get('display_mode');
    if (displayModeQueryValue) {
      setDisplayMode(`&display_mode=${displayModeQueryValue}`);
    }
  }, [displayMode]);

  const showAccessModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const requestAccess = () => {
    requestHandleAccess(name, id);
  };
  const goBack = async () => {
    const displayModeQueryString = displayMode ? `${displayMode}` : '';
    history.push(`/discover/${id}${displayModeQueryString}`);
  };

  const goToApp = () => {
    const path = handleType === HANDLE_TYPE.VIDEO ? 'videos' : 'handles';
    const displayModeQueryString = displayMode ? `${displayMode}` : '';

    isWorkflowHandle(handleType)
      ? history.push(`/${path}/${name}?workflow=true`)
      : history.push(`/${path}/${id}?tab=input${displayModeQueryString}`);
  };

  const handleTabChange = (i, newTab) => {
    setActiveIndex(i);
    const displayModeQueryString = displayMode ? `&display_mode=${displayMode}` : '';
    history.push(`/discover/${id}/${name}/demo?tab=${newTab}${displayModeQueryString}`);
  };

  const renderTabContent = () => {
    if (activeIndex === 0 && form) {
      return (
        <div>
          <DemoInformationBlock />
          <Form form={form} handleName={name} handleId={id} hasDashboard={dashboard} isDemo />
        </div>
      );
    }

    if (activeIndex === 0 && !form) {
      return (
        <div>
          <DemoInformationBlock />
          <HandleUpload
            handleName={name}
            transformAllowed={tab !== 'downloads'}
            handleId={id}
            hasDashboard={dashboard}
            isDemo
          />
        </div>
      );
    }

    if (activeIndex === 1 && dashboard && reference) {
      return <Dashboard isDemo />;
    }

    if (activeIndex === 2 && hasAvailableDownloads) {
      return <HandleAvailableDownloads handleName={name} handleId={id} isDemo />;
    }

    return null;
  };

  return (
    <DemoFormLayout>
      <HeaderWrapper>
        <TitleWrapper>
          <ImageLogoWrapper>
            <AppImage id={id} name={name} />
          </ImageLogoWrapper>
          <TitleApp>{name}</TitleApp>
        </TitleWrapper>
        <Actions>
          <ButtonDemo className="a-btn-md" onClick={goBack} data-test-label="close-demo-btn">
            {t('button.closeDemo')} <CloseDemoIcon className="Appkit4-icon icon-close-fill" />
          </ButtonDemo>
          {authorized ? (
            <ButtonAccess className="a-btn-md" onClick={goToApp} data-test-label="open-app-btn">
              {t('button.openApp')} <ChevronRight size={14} />
            </ButtonAccess>
          ) : (
            <ButtonAccess className="a-btn-md" onClick={showAccessModal} data-test-label="get-access-btn">
              {t('button.getAccess')} <ChevronRight size={14} />
            </ButtonAccess>
          )}
        </Actions>
      </HeaderWrapper>
      <Container data-test-label="handle-info-container">
        <StyledPanel>
          <StyledTabs
            type="underline"
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
            data-test-label="tab-information"
            className={`a-tab-item-padding-10 ${isHiddenTabLabels && 'hidden-tab'}`}
          >
            <StyledTab value="input" data-test-label="tab-upload-data" label={t('handle.label.insertData')} />
            <StyledTab
              value="dashboard"
              data-test-label="tab-upload-data"
              label="Dashboard"
              disabled={isHiddenDasboardTab}
            />
            <StyledTab value="downloads" data-test-label="tab-output" label="Output" />
          </StyledTabs>
          <div>{renderTabContent()}</div>
        </StyledPanel>
      </Container>
      <RequestAccessModal isVisible={modalVisible} onCloseModal={closeModal} requestAccess={requestAccess} />
    </DemoFormLayout>
  );
};

export default DemoForm;
