import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import DocumentDetails from './documentDetails/DocumentDetails';
import Documents from './Documents';

const DocumentsRedirect = () => {
  // If an uri is provided, show document details page
  const parsed = queryString.parse(useLocation().search);
  return parsed.uri != null ? <DocumentDetails /> : <Documents />;
};

export default DocumentsRedirect;
