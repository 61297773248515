import React, { useMemo } from 'react';
import queryString from 'query-string';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { ArrowLeftIcon, ArrowRightIcon } from '@primer/octicons-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ButtonHoverPointer } from '../../styles/GeneralElements';
import dashboardPagePropType from './dashboardPagePropType';
import IndexTree from './data-components/IndexTree';

const IndexTreeButtonsWrapper = styled.div`
  margin-right: 10px;
  display: flex;
`;

const ButtonBackWrapper = styled.div`
  margin-right: 10px;
`;

const ButtonForwardWrapper = styled.div`
  margin-left: 10px;
`;

const TooltipIndexTree = styled(Tooltip)`
  .ap-tooltip-reactContent {
    min-width: 300px;
    background-color: #fff !important;
    box-shadow: 0 0.4375rem 1.125rem 0.0625rem rgba(0, 0, 0, 0.16);
  }

  .ap-files-fold.active,
  .ap-files-fold:hover {
    background-color: #fff;
  }

  .ap-files-fold.active .ap-fold-name,
  .ap-files-fold .ap-files-fold {
    background-color: #fff;
  }
`;

const ButtonBack = ({ dashboardMatch, index, nodesLinksArray, disabled }) => {
  const history = useHistory();
  const goBackInIndex = () => {
    const backLinkTo = nodesLinksArray[index - 1];
    if (backLinkTo === 'dashboard') {
      if (dashboardMatch.path === '/handles/:handleId/:handleName/dashboard/:reference/:page?') {
        dashboardMatch
          ? history.push(
              `/handles/${dashboardMatch.params.handleId}/${dashboardMatch.params.handleName}/dashboard/${dashboardMatch.params.reference}/`
            )
          : history.push(`/handles/dashboard`);
      }
      if (dashboardMatch.path === '/workflow/dashboard/:handleId/:workflowId/:page') {
        history.push(
          `/workflow/dashboard/${dashboardMatch.params.handleId}/${dashboardMatch.params.handleName}/${backLinkTo}/`
        );
      }
    } else {
      if (dashboardMatch.path === '/handles/:handleId/:handleName/dashboard/:reference/:page?') {
        dashboardMatch
          ? history.push(
              `/handles/${dashboardMatch.params.handleId}/${dashboardMatch.params.handleName}/dashboard/${dashboardMatch.params.reference}/${backLinkTo}`
            )
          : history.push(`/handles/dashboard/${backLinkTo}`);
      }
      if (dashboardMatch.path === '/workflow/dashboard/:handleId/:workflowId/:page') {
        history.push(
          `/workflow/dashboard/${dashboardMatch.params.handleId}/${dashboardMatch.params.workflowId}/${backLinkTo}`
        );
      }
    }
  };

  return (
    <ButtonBackWrapper>
      <Button
        className="a-btn-lg"
        kind="secondary"
        id="indexTreeBackBtn"
        onClick={() => goBackInIndex()}
        disabled={disabled}
        data-test-label="index-tree-back-btn"
      >
        <ArrowLeftIcon />
      </Button>
    </ButtonBackWrapper>
  );
};

const ButtonForward = ({ dashboardMatch, index, nodesLinksArray, disabled }) => {
  const history = useHistory();
  const goForwardInIndex = () => {
    const forwardLinkTo = nodesLinksArray[index + 1];
    if (dashboardMatch.path === '/handles/:handleId/:handleName/dashboard/:reference/:page?') {
      dashboardMatch
        ? history.push(
            `/handles/${dashboardMatch.params.handleId}/${dashboardMatch.params.handleName}/dashboard/${dashboardMatch.params.reference}/${forwardLinkTo}`
          )
        : history.push(`/handles/dashboard/${forwardLinkTo}`);
    }
    if (dashboardMatch.path === '/workflow/dashboard/:handleId/:workflowId/:page') {
      history.push(
        `/workflow/dashboard/${dashboardMatch.params.handleId}/${dashboardMatch.params.workflowId}/${forwardLinkTo}`
      );
    }
  };

  return (
    <ButtonForwardWrapper>
      <Button
        className="a-btn-lg"
        kind="secondary"
        id="indexTreeForwardBtn"
        onClick={() => goForwardInIndex()}
        disabled={disabled}
        data-test-label="index-tree-forward-btn"
      >
        <ArrowRightIcon />
      </Button>
    </ButtonForwardWrapper>
  );
};

const IndexTreeButtons = ({ currentPage, indexComponent, isDemo }) => {
  const indexTreeLabel = indexComponent?.properties?.buttonLabel || 'Index Tree';
  const history = useHistory();
  const dashboardMatch = useRouteMatch();
  const demoDashboardMatch = useRouteMatch('/discover/:handleId/:handleName');
  const { demoReference } = queryString.parse(useLocation().search);
  const nodesLinksArray = [];

  indexComponent?.properties?.nodes?.forEach(node => {
    node.link && nodesLinksArray.push(node.link);
    node.children?.forEach(nodeChild => {
      nodeChild.link && nodesLinksArray.push(nodeChild.link);
    });
  });

  const linkCurrentPageIndex = nodesLinksArray.indexOf(currentPage.id);
  const backButtonEnabled = linkCurrentPageIndex !== 0 && linkCurrentPageIndex !== -1;
  const forwardButtonEnabled = linkCurrentPageIndex < nodesLinksArray.length - 1 && linkCurrentPageIndex !== -1;

  const RenderIndexTreeTooltip = useMemo(() => {
    return (
      <IndexTree
        {...indexComponent}
        isDemo={isDemo}
        demoReference={demoReference}
        dashboardMatch={dashboardMatch}
        demoDashboardMatch={demoDashboardMatch}
        // eslint-disable-next-line no-restricted-globals
        history={history}
      />
    );
  }, [dashboardMatch, demoDashboardMatch, demoReference, history, indexComponent, isDemo]);

  if (!indexComponent) return null;

  return (
    <IndexTreeButtonsWrapper>
      {!isDemo && (
        <ButtonBack
          dashboardMatch={dashboardMatch}
          index={linkCurrentPageIndex}
          nodesLinksArray={nodesLinksArray}
          disabled={!backButtonEnabled}
        />
      )}
      <TooltipIndexTree
        content={RenderIndexTreeTooltip}
        trigger="click"
        tooltipTheme="light"
        clickToClose
        key={currentPage.id}
        position="bottom-right"
        hideTooltipOnBlur={false}
        appendAfterTarget
      >
        <ButtonHoverPointer className="a-btn-lg" id="indexTreeBtn" data-test-label="index-tree-btn">
          {indexTreeLabel}
        </ButtonHoverPointer>
      </TooltipIndexTree>
      {!isDemo && (
        <ButtonForward
          dashboardMatch={dashboardMatch}
          index={linkCurrentPageIndex}
          nodesLinksArray={nodesLinksArray}
          disabled={!forwardButtonEnabled}
        />
      )}
    </IndexTreeButtonsWrapper>
  );
};

IndexTreeButtons.propTypes = {
  indexTreePage: PropTypes.shape(dashboardPagePropType),
  currentPage: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string
  }),
  indexComponent: PropTypes.objectOf(PropTypes.any)
};

export default IndexTreeButtons;
