import React, { useEffect, useState } from 'react';
import { Notification } from '@appkit4/react-components/notification';
import { Panel } from '@appkit4/react-components/panel';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Container, Wrapper } from '../styles/GeneralElements';
import { commentLetterMarklogicUpload } from '../util/requests';
import { STATUS } from '../util/status';
import { LoadingIndicator } from '../elements/loading';

const PageHeader = React.memo(styled.h4`
  margin-left: 30px;
`);

const CommentLetterUploadPage = () => {
  const [status, setStatus] = useState(STATUS.LOADING);
  const { requestId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    commentLetterMarklogicUpload(requestId)
      .then(_ => {
        setStatus(STATUS.OK);
      })
      .catch(_ => {
        setStatus(STATUS.ERROR);
      });
  }, [requestId]);

  return (
    <Container>
      <PageHeader data-test-label="available-downloads-page-title">{t('commentLetter.header')}</PageHeader>
      <Wrapper style={{ padding: 30 }} data-test-label="comment-letter-response">
        <Panel
          style={{ padding: 30, minHeight: 600 }}
          data-test-label="document-details"
        >
          {status === STATUS.LOADING && (
              <Notification
                  data-test-label="comment-letter-error"
                  status="Appkit4-icon icon-information-outline"
                  message={t('commentLetter.alert')}
              />
          )}
          {status === STATUS.LOADING && <LoadingIndicator />}
          {status === STATUS.ERROR && (
              <Notification data-test-label="comment-letter-error" status="error" message={t('commentLetter.error')} />
          )}
          {status === STATUS.OK && (
            <Notification
              data-test-label="comment-letter-error"
              status="success"
              message={t('commentLetter.success')}
              showDefaultIcon
            />
          )}
          {status === STATUS.OK && (
            <p style={{ paddingTop: 30 }}>
              <Trans i18nKey="commentLetter.viewLetter">
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a rel="Comment letter Assisted Writing" href="https://assistedwriting.pwcinternal.com/aw/documents" />
              </Trans>
            </p>
          )}
        </Panel>
      </Wrapper>
    </Container>
  );
};

export default CommentLetterUploadPage;
