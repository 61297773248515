import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SingleResult from '../../handleTabs/singleResult';
import { downloadFiles } from '../../../util/requests';

const Result = ({ handle, handleId, reference, template }) => {
  const { t } = useTranslation();
  const text = template || t('button.download');

  const handleDownload = useCallback(
    async event => {
      event.preventDefault();
      try {
        const response = await downloadFiles(reference);
        const blob = new Blob([response.data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${handle}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading template:', error);
      }
    },
    [reference, handle]
  );

  return (
    <SingleResult data-test-label={`download-file-${handleId}`} onClick={handleDownload}>
      <a
        href="/#"
        data-test-href={`/api/download-files/${reference}`}
        onClick={handleDownload}
        style={{ color: 'white', textDecoration: 'none' }}
      >
        <span className="Appkit4-icon icon-download-fill" />
        {text}
      </a>
    </SingleResult>
  );
};

Result.propTypes = {
  handle: PropTypes.string.isRequired,
  handleId: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  template: PropTypes.string
};

export default Result;
