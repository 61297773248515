import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DashboardElement from './DashboardElement';

const RowsWrapper = styled.div(props => ({
  width: '100%',
  maxHeight: '100%',
  display: 'grid',
  gridAutoRows: props.childrenCollapsed ? 'auto' : props.height,
  gridAutoFlow: 'column dense',
  gridGap: '20px',
  gridTemplateColumns: props.childrenWidth,
  '> div': {
    maxHeight: props.height === 'minmax(0, 1fr)' ? '100%' : props.height
  }
}));

const Rows = ({ pageId, parent, rows }) => {
  return (rows || []).map((row, index) => {
    const childrenWidth =
      row.children && row.type !== 'tabs'
        ? row.children
            .flatMap(c => `minmax(${c?.properties?.width ? `auto,${c?.properties?.width}` : '0,1fr'})`)
            .join(' ')
        : 'minmax(0, 1fr)';

    const childrenCollapsed = row.children?.every(c => c.properties?.collapsed === true);

    return (
      <RowsWrapper
        // eslint-disable-next-line react/no-array-index-key
        key={`${pageId}-row-${index}`}
        parent={parent}
        className="dashboard-row"
        childrenWidth={childrenWidth}
        height={!parent ? row.properties?.height ?? '500px' : 'minmax(0, 1fr)'}
        childrenCollapsed={childrenCollapsed}
      >
        <DashboardElement pageId={pageId} parent {...row} />
      </RowsWrapper>
    );
  });
};

Rows.propTypes = {
  pageId: PropTypes.string.isRequired,
  parent: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.object)
};

export default Rows;
