import moment from 'moment';
import React, { useState, useEffect } from 'react';
import 'moment/locale/nl';
import { LANGUAGE_KEY } from '../menu/LanguageMenuItem';
import { DEFAULT_LANGUAGE_ISO_CODE } from '../util/requests';

const Timer = ({ end }) => {
  moment.locale(localStorage.getItem(LANGUAGE_KEY) || DEFAULT_LANGUAGE_ISO_CODE);
  const now = moment();
  const [duration, setDuration] = useState(moment.duration(end.diff(now)));
  useEffect(() => {
    let interval = null;
    const now = moment();
    if (end.diff(now) < 0) {
      interval = setInterval(() => {
        setDuration(moment.duration(end.diff(now)));
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [end, duration]);

  return <span>{duration.humanize()}</span>;
};

export default Timer;
