import { FIELD } from '../enums';

export const getSelectorDataKeys = fields => {
  if (fields == null) return [];

  const selectorFields = fields.filter(f => f.type === FIELD.SELECT_PREDEFINED);
  const tabularFields = fields.filter(f => f.type === FIELD.TABULAR);
  const tabularColumns = tabularFields
    .map(f => f.columns)
    .filter(c => c != null)
    .flat();
  const keys1 = selectorFields.map(f => f.data);
  const keys2 = tabularColumns.filter(f => f.type === FIELD.SELECT_PREDEFINED).map(f => f.data);
  return [...new Set([...keys1, ...keys2])];
};
